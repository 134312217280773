import { Link, useNavigate } from 'react-router-dom';
import LectureSearchItem from '../../../../components/LectureSearchItem';
import { shallowEqual, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import UpIco from '../../../../components/icons/UpIco';
import DownIco from '../../../../components/icons/DownIco';
import { restApi } from '../../../../common/api';
import Modal from '../../../../components/Modal';
import FileSaver from 'file-saver';
import PaginationMoon from '../../../../components/PaginationMoon';
import produce from 'immer';
import moment from 'moment';




import dayjs from 'dayjs';

export default function LecturesScheduleIndex() {

    const { semesters, codes, semesterId } = useSelector((s) => s.common, shallowEqual);
    const [selected, setSelected] = useState([]);

    

    const navigate = useNavigate();

    const [form, setForm] = useState({
        place: '',
        date: '',
        time: '',
        notice: '',
        showHistory: '',
    });

    // const { lectureId } = useSelector((s) => s.common, shallowEqual);

    const [lectureId, setLectureId] = useState();
    const [typeManual, setTypeManual] = useState(false);

    const [searchFlag, setSearchFlag] = useState(false);

    const [timeData, setTimeData] = useState();
    const [formData, setFormData] = useState({
        timeCode: 0,
        startTime: null,
        endTime: null,
        startDate: null,
        endDate: null,
        lectureId: '',
        page:0
    });

    const [data, setData] = useState();
    const [organizedData, setOrganizedData] = useState();


    const loadData = useCallback(async (e) => {
        if (lectureId) {
            e.preventDefault(); 
        }

        const { data: time } = await restApi.get(`/lectures/timeSchedule`);
        setTimeData(time);

        const { data } = await restApi.get(`/lectures/lectureSchedule`, { params: { ...formData, limit: 10 } });
        setData(data)

        var checkedArr = [];
        data?.content.map((item) => {
            const histories = item.histories
            if (histories.length != 0) {
                item = histories[0]
            }
            checkedArr.push(item.id);
        });
        setOrganizedData(checkedArr);
        
        
    }, [formData.page]);

    // const loadScheduleData = useCallback(async (e) => {

    //     const { data } = await restApi.get(`/lectures/lectureSchedule`, { params: { ...formData, limit: 10 } });
    //     setData(data)
    // }, [formData]);

    const handleSearch = useCallback(async (e) => {
        e.preventDefault();

            // console.log(formData);
        const { data: searchData } = await restApi.get(`/lectures/lectureSchedule/search`, { params: { ...formData, limit: 10 } });
        setData(searchData);
        setSearchFlag(true);

    });

    const handleDownloadSample = (e) => {
        FileSaver.saveAs('/assets/xls/upload_class_schedule.xlsx', '시간표_업로드양식.xlsx');
    };

    const [uploadForm, setUploadForm] = useState(0);
    const handleUploadSubmit = async (e) => {
        e.preventDefault();
    
        const files = e.target.filedata.files;
        if (files.length === 0) {
          alert('파일선택은 필수항목입니다.');
          return;
        } else if (!files[0].name.endsWith('.xlsx')) {
          alert('허용된 파일은 xlsx입니다.');
          return;
        }
    
        setUploadForm(2);
        try {
          const formData = new FormData();
          formData.append('file', files[0]);
    
          const { data } = await restApi.post(`/lectures/lectureSchedule/xlsx`, formData);
          if (data.error) {
            alert(
              '아래 사유로 엑셀업로드가 불가능합니다.\n===================\n' +
                data.message +
                '\n-' +
                data.items?.join('\n -'),
            );
          } else {
              setUploadForm(1);
    
            await Promise.all(
              data.creates?.map((x) => {
                return restApi.post(`/lectures/lectureSchedule/${x.lectureId}`, x);
              }),
            );
            alert('적용되었습니다.');
            await loadData();
            setUploadForm(0);
          }
        } catch (e) {
          console.warn(e);
          alert('서버와 연결이 올바르지 않습니다.');
          setUploadForm(1);
        }
    
        // onClick={() => setUploadForm(2)}
      };

      const destroy = async (id) => {
        await restApi.delete(`/lectures/lectureSchedule/${id}`);
      };
      const handleMultiDelete = async () => {
        if (!selected.length) {
          alert('1개 이상 항목을 선택해주세요.');
          return;
        }
        if (!window.confirm('정말 삭제하시겠습니까?')) return;
        await Promise.all(selected?.map((x) => destroy(x)));
        await loadData();
      };

    var isAllChecked = organizedData?.filter((x) => !selected.includes(x))?.length === 0 && organizedData?.length > 0;

    const handleAllClick = () => {
        var checkedArr = [];
        if (!isAllChecked) {
            data?.content.map((item) => {
                const histories = item.histories
                if (histories.length != 0) {
                    item = histories[0]
                }
                checkedArr.push(item.id);
            });
          setSelected(checkedArr);

        } else {
          setSelected([]);
        }
      };

    useEffect(() => {
        loadData().catch(console.warn);
    }, [loadData]);
    return (
        <div className="content-wrapper container-xxl p-0">
            {/* header s */}
            <form >
                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">시간표</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">시간표</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card" >
                    <div className="table-responsive">
                        <table className="table table-bordered visual-box">
                            <colgroup>
                                <col style={{ width: '11rem' }} />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>
                                        <label className="form-label">학기 / 교육</label>
                                    </th>
                                    <td>
                                        <LectureSearchItem
                                            value={formData.lectureId}
                                            onChange={(value) => {
                                                // setLectureId(value);
                                                setFormData((x) => ({ ...x, lectureId: value, page: 0 }));
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <label className="form-label">날짜</label>
                                    </th>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="date"
                                                className="form-control flatpickr-basic flatpickr-input me-25"
                                                placeholder="YYYY-MM-DD"
                                                style={{ width: 140 }}
                                                onChange={(e) => {
                                                    const v = e.target.value;
                                                    setFormData((x) => ({ ...x, startDate: v }));
                                                }}
                                            />
                                            <span className={'ms-25 me-25'}>~</span>
                                            <input
                                                type="date"
                                                id="fp-range"
                                                className="form-control flatpickr-range flatpickr-input me-25"
                                                placeholder="YYYY-MM-DD"
                                                style={{ width: 140 }}
                                                onChange={(e) => {
                                                    const v = e.target.value;
                                                    setFormData((x) => ({ ...x, endDate: v }));
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>시간</th>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            {!typeManual ?
                                                <select
                                                    className="form-select w-auto pe-3"
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        setFormData((x) => ({ ...x, timeCode: value }));
                                                    }}
                                                >
                                                    <option value={''} key={0}>선택해주세요</option>
                                                    {timeData?.map((item) => (
                                                        <option value={item.code} key={item.code}>
                                                            {item.title}
                                                        </option>
                                                    ))}
                                                </select> :
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        className="form-control flatpickr-basic flatpickr-input"
                                                        placeholder="HH:MM"
                                                        style={{ width: 70 }}
                                                        defaultValue={'00:00'}
                                                        onChange={(e) => {
                                                            const v = e.target.value;
                                                            setFormData((x) => ({ ...x, startTime: v }));
                                                        }
                                                        }
                                                    />
                                                    <span className={'ms-25 me-25'}>~</span>
                                                    <input
                                                        type="text"
                                                        className="form-control flatpickr-basic flatpickr-input"
                                                        placeholder="HH:MM"
                                                        style={{ width: 70 }}
                                                        defaultValue={'00:00'}
                                                        onChange={(e) => {
                                                            const v = e.target.value;
                                                            setFormData((x) => ({ ...x, endTime: v }));
                                                        }
                                                        }
                                                    />
                                                </div>}
                                            <input
                                                className="form-check-input ms-50"
                                                id='ch00'
                                                type="checkbox"
                                                defaultChecked={typeManual}
                                                onClick={() => {
                                                    setTypeManual(!typeManual);
                                                }}
                                            />
                                            <label className="form-check-label ms-50" htmlFor="ch00">
                                                직접 입력
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </form>
            <div className="col-12 mt-50 txt-r mb-50">
                <button
                    type="search"
                    className="btn btn-primary waves-effect waves-float waves-light"
                    onClick={handleSearch}
                >
                    검색
                </button>
            </div>


            <div className={`row `}>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="col-md-6 col-12" style={{ display: 'flex', gap: '10px' }}>
                                <button
                                    type="button"
                                    className="btn btn-outline-dark btn-doc waves-effect"
                                    onClick={handleDownloadSample}
                                >
                                    엑셀양식 파일 <DownIco />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-dark btn-doc waves-effect"
                                    onClick={() => setUploadForm(1)}
                                >
                                    엑셀 업로드 <UpIco />
                                </button>
                            </div>
                        </div>

                        <div className="table-responsive scrollbox2">
                            <table className="table table-bordered visual-box t-padding2">
                                <colgroup>
                                    <col style={{ width: 10 }} />
                                    <col style={{ width: 40 }} />
                                    <col style={{ width: 120 }} />
                                    <col style={{ width: 120 }} />
                                    <col style={{ width: 120 }} />
                                    <col style={{ width: 80 }} />
                                    <col style={{ width: 120 }} />
                                    <col style={{ width: 60 }} />
                                    <col style={{ width: 100 }} />
                                    <col style={{ width: 60 }} />
                                    <col style={{ width: 80 }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                className="form-check-input"
                                                id="allCheck"
                                                type="checkbox"
                                                checked={isAllChecked}
                                                onChange={handleAllClick}
                                            />
                                        </th>
                                        <th>번호</th>
                                        <th>학기</th>
                                        <th>교육</th>
                                        <th>장소</th>
                                        <th>날짜</th>
                                        <th>시간</th>
                                        <th>공지노출</th>
                                        <th>이력 표기</th>
                                        <th>작성자</th>
                                        <th>등록일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.empty ? <td colSpan={12} className="p-1 text-center">
                                        {searchFlag ? "검색 결과가 없습니다." : "시간표를 등록해주세요."}
                                    </td> : data?.content.map((item, index) => {
                                        const histories = item.histories
                                        if (histories.length != 0) {
                                            item = histories[0]
                                            
                                        }
                                        if (searchFlag) {
                                            //검색 비교
                                            //시작 날짜만 하고 검색
                                            if (formData.startDate != null && formData.startDate != '') {
                                                console.log("1")
                                                if (formData.endDate != null && formData.endDate != '') {
                                                    console.log("2")
                                                    if (!((moment(item.date).isSame(formData.startDate) || moment(item.date).isAfter(formData.startDate)) && (moment(item.date).isSame(formData.endDate) || moment(item.date).isBefore(formData.endDate)))) {
                                                        console.log("3")
                                                        return;
                                                    }
                                                }else if (moment(item.date).isBefore(formData.startDate)) {
                                                    console.log("4")
                                                    return;
                                                }
                                            }
                                            //시간 비교
                                            if (typeManual) {
                                                let searchStartTime = new Date (`2002-01-01T${formData.startTime}Z`);
                                                let searchEndTime = new Date (`2002-01-01T${formData.endTime}Z`);
                                                let dataStartTime = new Date (`2002-01-01T${item.startTime}Z`);
                                                let dataEndTime = new Date (`2002-01-01T${item.endTime}Z`);
                                                //직접 입력
                                                if (searchStartTime > dataStartTime || searchEndTime < dataEndTime) {
                                                    return;
                                                }
                                            } else {
                                                if (formData.timeCode != 0) {
                                                    //코드 비교
                                                    if (item.timeCode != formData.timeCode) {
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                        const findIndex = selected.indexOf(item.id);
                                        const checked = findIndex > -1;
              
                                        return (
                                        <tr>
                                            <td className="text-center">
                                                <input
                                                    className="form-check-input"
                                                    id=""
                                                    type="checkbox"
                                                    checked={checked}
                                                    onChange={() => {
                                                        setSelected(
                                                        produce((draft) => {
                                                            if (checked) {
                                                            draft.splice(findIndex, 1);
                                                            } else {
                                                            draft.push(item.id);
                                                            }
                                                        }),
                                                        );
                                                    }}
                                                />
                                            </td>
                                            <td className="txt-c">{index + 1 + data.size * data.number}</td>
                                            <td className="txt-c">{item.semesterYear}년 - {codes?.map?.[item.semesterSession]}</td>
                                            <td className="txt-c">{item.lectureTitle}</td>
                                            <td className="txt-c">
                                                <Link to={`/lms/lectures/schedule/${item.id}`} state={item}>{item.place}</Link>
                                            </td>
                                            <td className="txt-c">{item.date}</td>
                                            <td className="txt-c">{item.timeCode == null ? item.startTime.substring(0,5) + " ~ " + item.endTime.substring(0,5) : item.timeTitle}</td>
                                            <td className="txt-c">{item.noticeYN ? "Y" : "N"}</td>
                                            <td className="txt-c">{item.statusText}</td>
                                            <td className="txt-c">{item.writer}</td>
                                            <td className="txt-c">{dayjs(item.createdAt).format('YYYY-MM-DD')}</td>
                                        </tr>
                                        )
                                        setSearchFlag(faslse);
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row mt-50">
                        <div className="col-md-6">
                            <button type="delete" className="btn btn-outline-danger waves-effect" onClick={handleMultiDelete}>
                                삭제
                            </button>
                        </div>
                        <div className="col-md-6 txt-r">
                            <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-float waves-light"
                                onClick={() => {
                                    navigate('/lms/lectures/schedule/register');
                                }}
                            >
                                등록
                            </button>
                        </div>
                    </div>

                    {/* pagination s */}
                    <nav aria-label="Page navigation">
                    <PaginationMoon data={data} onClick={(page) => setFormData((x) => ({ ...x, page }))} />
                </nav>
                    {/* // pagination e */}
                </div>
                {/* Bordered table end e */}
            </div>


            <Modal className={'fade text-start modal-primary show'} visible={!!uploadForm}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">시간표 업로드</h5>
                            {uploadForm === 1 && <button type="button" className="btn-close" onClick={() => setUploadForm(0)} />}
                        </div>
                        <div className="modal-body">
                            {uploadForm === 1 ? (
                                <form onSubmit={handleUploadSubmit}>
                                    <div className="row mt-1">
                                        <div className="col-12 mb-md-0">
                                            <p>
                                                엑셀을 업로드하여 다수의 정보를 한번에 입력할 수 있습니다.
                                                <br />
                                                정해진 엑셀 양식에 값을 입력하여 업로드 하세요 ( 지원하는 파일 양식 : xlsx)
                                            </p>
                                        </div>
                                        <div className="col-12 mb-md-0 txt-r">
                                            <button
                                                type="button"
                                                className="btn btn-outline-dark btn-doc waves-effect"
                                                onClick={handleDownloadSample}
                                            >
                                                엑셀양식 파일
                                            </button>
                                        </div>
                                        <div className="d-flex flex-column mt-2 mb-2">
                                            <div className="featured-info">
                                                <input className="form-control" type="file" name={'filedata'} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-primary waves-effect waves-float waves-light">
                                            저장
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary waves-effect"
                                            onClick={() => setUploadForm(0)}
                                        >
                                            취소
                                        </button>
                                    </div>
                                </form>
                            ) : (
                                <div className="spinner-wrap">
                                    <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p>
                                        시간표정보를 DB에 업로드 중입니다.
                                        <br />
                                        잠시만 기다려 주십시오
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>

        </div>

    )
}