import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { getFilesHost, restApi } from '../common/api';
import Modal from './Modal';
import { shallowEqual, useSelector } from 'react-redux';
import produce from 'immer';

const NoteContext = createContext({});

export const useNote = () => {
  const context = useContext(NoteContext);
  return { send: context.send, showUser: context.showUser };
};

export const USER_TYPE = {
  TEACHER: '교수',
  STAFF: '튜터',
  MANAGER: '행정실',
  ADMIN: '지원실',
  USER: '원우',
  EXAMSTAFF: '시험감독관',
};

export const userDetail = (user) => {
  let attending = '';
  if (user.type === 'USER') {
    attending = user.attending ? '(재학생)' : '(졸업생)';
  }
  return `${user.name} / ${USER_TYPE[user.type]}${attending}`;
};

const NoteProvider = ({ children }) => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const [showModal, setShowModal] = useState();
  const [findUser, setFindUser] = useState(false);

  const [keyword, setKeyword] = useState('');
  const [accounts, setAccounts] = useState();

  const [selected, setSelected] = useState([]);

  const [showUser, setShowUser] = useState();

  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/accounts/${showUser?.id}/bo`);
      setShowUser((x) => ({ ...x, data }));
    };
    if (showUser?.id) {
      loadData(showUser?.id).catch(console.warn);
    }
  }, [showUser?.id]);

  const handleSelectFinish = () => {
    const users = accounts?.filter((x) => selected?.includes(x.id));
    setShowModal((x) => ({ ...x, senders: [...x.senders, ...users?.map((v) => ({ id: v.id, name: v.name }))] }));
    setFindUser(false);
  };

  const handleSearch = async () => {
    setSelected([]);
    if (!keyword) {
      setAccounts([]);
      return;
    }
    const { data } = await restApi.get(`/accounts/bo`, {
      params: {
        keyword,
        notIds: [principal?.id],
        limit: 9999,
      },
    });

    setAccounts(data?.content ?? []);
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!showModal?.senders?.length) {
      alert('한명 이상을 선택해주세요.');
      return;
    }

    if (!showModal?.contents) {
      alert('내용을 입력해주세요.');
      return;
    }

    setLoading(true);
    try {
      await Promise.all(
        showModal?.senders?.map((x) => {
          return restApi.post(`/notes/all`, {
            receiverId: x.id,
            contents: showModal?.contents,
            receivedNoteId: showModal?.originNoteId,
          });
        }),
      );

      alert('전송되었습니다.');
      setShowModal(undefined);
    } catch (e) {}
    setLoading(false);

    return false;
  };

  const send = useCallback((senders, contents, originNoteId) => {
    setShowModal({
      senders: senders ?? [],
      contents: contents ?? '',
      originNoteId,
    });
    setKeyword('');
  }, []);

  return (
    <NoteContext.Provider value={{ send, showUser: (id) => setShowUser({ id }) }}>
      {children}
      <Modal visible={showModal} className={'fade text-start modal-primary'}>
        <div className="modal-dialog modal-dialog-centered">
          <form className="modal-content" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title">쪽지보내기</h5>
              <button type="button" className="btn-close" onClick={() => setShowModal(false)} />
            </div>
            <div className="modal-body">
              {/* Inputs Group with Dropdown */}
              <div className="col-12 ms-auto">
                <fieldset>
                  <div className="d-flex" style={{ flexWrap: 'wrap', gap: 15, marginTop: 10 }}>
                    <div className="input-group d-flex">
                      <span className="text-black me-1 mt-50">받는사람</span>
                      <div className="inp-list">
                        <input
                          name="keyword"
                          type="text"
                          className="form-control"
                          placeholder=""
                          aria-label="Amount"
                          value={keyword}
                          onChange={(e) => {
                            const v = e.target.value;
                            setKeyword(v);
                          }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                              handleSearch().catch(console.warn);
                            }
                          }}
                        />
                        {/* active class : 활성 有 , 비활성 無 */}
                        <ul className={accounts?.length ? 'active' : undefined}>
                          {accounts?.map((account) => {
                            const findIndex = showModal?.senders.findIndex((receiver) => receiver.id === account.id);
                            const active = findIndex > -1;
                            return (
                              <li key={`Account-${account.id}`}>
                                <button
                                  type="button"
                                  style={active ? { backgroundColor: '#c1c1e7' } : undefined}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setShowModal((x) => ({
                                      ...x,
                                      senders: [...x.senders, account],
                                    }));
                                    setAccounts([]);
                                  }}
                                >
                                  {userDetail(account)}
                                </button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                        onClick={() => {
                          handleSearch().catch(console.warn);
                        }}
                      >
                        검색
                      </button>
                    </div>
                    {!!showModal?.senders?.length &&
                      showModal?.senders?.map((receiver, idx) => (
                        <div className={'flex-h align-items-center'} key={`receiver-${idx}`}>
                          <span style={{ fontSize: 14, fontWeight: 'bold' }}>{userDetail(receiver)}</span>
                          <button
                            type="button"
                            className="btn-file-del"
                            style={{ marginLeft: 5 }}
                            onClick={() => {
                              setShowModal(
                                produce((draft) => {
                                  draft.senders.splice(idx, 1);
                                }),
                              );
                            }}
                          />
                        </div>
                      ))}
                  </div>
                </fieldset>
              </div>
              <div className="col-12 my-75">
                <div className="form-floating mb-0">
                  <textarea
                    name="contents"
                    data-length={20}
                    className="form-control char-textarea"
                    id="textarea-counter"
                    rows={3}
                    placeholder="Counter"
                    style={{ height: 200 }}
                    value={showModal?.contents}
                    onChange={(e) => {
                      const value = e.target.value;
                      setShowModal((x) => ({ ...x, contents: value }));
                    }}
                    maxLength={!showModal?.originNoteId ? 1000 : null}
                  />
                  <label htmlFor="textarea-counter">Counter</label>
                </div>
                {!showModal?.originNoteId && (
                  <small className="textarea-counter-value float-end">
                    <span className="char-count">{showModal?.contents?.length ?? 0}</span> / 1000자
                  </small>
                )}
              </div>
              <small className="text-muted">
                ※ 검색&gt;대상선택을 통해, 쪽지를 동시에 여러 명에게 발송할 수 있습니다.
              </small>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-outline-primary waves-effect">
                보내기
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal visible={!!showUser} className={'fade text-start modal-primary'}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{showUser?.data?.name}님 원우정보</h5>
              <button type="button" className="btn-close" onClick={() => setShowUser(undefined)} />
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-striped t-border t-fixed">
                  <colgroup>
                    <col />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '25%' }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td rowSpan={4} className="img-pic-box">
                        <img src={`${getFilesHost()}/${showUser?.data?.imageId}`} alt={showUser?.data?.name} />
                      </td>
                      <th>이름</th>
                      <td>{showUser?.data?.name}</td>
                      <th>성별</th>
                      <td>{showUser?.data?.gender === 'MALE' ? '남자' : '여자'}</td>
                    </tr>
                    <tr>
                      <th>기수</th>
                      <td>{showUser?.data?.period}기</td>
                      <th>출생년도</th>
                      <td>{showUser?.data?.birthday}</td>
                    </tr>
                    <tr>
                      <th>이메일</th>
                      <td>
                        <a href={`mailto:${showUser?.data?.email}`}>{showUser?.data?.email}</a>
                      </td>
                      <th>전화</th>
                      <td>
                        <a href={`tel:${showUser?.data?.phone}`}>{showUser?.data?.phone}</a>
                      </td>
                    </tr>
                    <tr>
                      <th>휴대폰</th>
                      <td>
                        <a href={`tel:${showUser?.data?.mobilePhone}`}>{showUser?.data?.mobilePhone}</a>
                      </td>
                      <th>지역</th>
                      <td>{!showUser?.data?.isForeign ? '국내' : `해외 (${showUser?.data?.area?.join(' / ')})`}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive">
                <table className="table table-striped t-border t-fixed">
                  <colgroup>
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '35%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '35%' }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>회사</th>
                      <td colSpan={3}>{showUser?.data?.companyName}</td>
                    </tr>
                    <tr>
                      <th>기업분류</th>
                      <td>{showUser?.data?.workCorpType}</td>
                      <th>부서</th>
                      <td>{showUser?.data?.companyTeam}</td>
                    </tr>
                    <tr>
                      <th>직급</th>
                      <td>{showUser?.data?.companyPosition}</td>
                      <th>직종</th>
                      <td>{showUser?.data?.workJobType}</td>
                    </tr>
                    <tr>
                      <th>회사소개</th>
                      <td colSpan={3}>{showUser?.data?.bio}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary waves-effect"
                onClick={() => setShowUser(undefined)}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </NoteContext.Provider>
  );
};

export default NoteProvider;
