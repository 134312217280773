import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { getFilesHost, restApi } from '../../../../common/api';
import PaginationMoon from '../../../../components/PaginationMoon';
import { useNote } from '../../../../components/NoteProvider';

export default function LecturesStudentManageIndex() {
  const note = useNote();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get('/accounts/bo', {
      params: {
        ...params,
        type: 'USER',
        sort: 'name',
        order: 'ASC',
      },
    });
    setData(data);
  }, [params]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">원우관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">원우관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <Link
            class="nav-link active"
            aria-current="page"
            data-bs-toggle="tab"
            onClick={() =>
              setParams({
                ...params,
                attending: '',
              })
            }
          >
            전체
          </Link>
        </li>
        <li class="nav-item">
          <Link
            class="nav-link"
            data-bs-toggle="tab"
            onClick={() =>
              setParams({
                ...params,
                attending: 1,
              })
            }
          >
            재학생
          </Link>
        </li>
        <li class="nav-item">
          <Link
            class="nav-link"
            data-bs-toggle="tab"
            onClick={() =>
              setParams({
                ...params,
                attending: 0,
              })
            }
          >
            졸업생
          </Link>
        </li>
      </ul>
      {/* con s */}
      {params.attending === 1 && (
        <div className="txt-r mb-1">
          <button
            type="button"
            className="btn btn-outline-dark waves-effect"
            data-bs-toggle="modal"
            data-bs-target="#btnSend"
            onClick={() => {
              note.send(data?.content);
            }}
          >
            전체 쪽지보내기
          </button>
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header align-items-end">
              <div className="col">
                <h5>총 : {data?.totalElements}명</h5>
              </div>
              {/* Inputs Group with Dropdown */}
              <div className="col-md-6 col-12 ms-auto">
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    setParams({
                      ...params,
                      keywordType: event.target.keywordType.value,
                      keyword: event.target.keyword.value,
                    });
                  }}
                >
                  <div className="input-group">
                    <select className="form-select" name="keywordType" defaultValue={params.keywordType}>
                      <option value={'name'}>이름</option>
                      <option value={'period'}>IMBA기수</option>
                      <option value={'idno'}>학번</option>
                    </select>
                    <input
                      name="keyword"
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="Amount"
                      defaultValue={params.keyword}
                    />
                    <button className="btn btn-outline-primary waves-effect" type="submit">
                      Search
                    </button>
                  </div>
                </form>
              </div>
              {/* Inputs Group with Dropdown end */}{' '}
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col />
                  <col style={{ width: '50%' }} />
                </colgroup>
                <tbody>
                  {data?.content
                    .reduce((p, c, i) => {
                      const _i = Math.floor(i / 2);
                      if (p[_i]) {
                        p[_i].push(c);
                      } else {
                        p[_i] = [c];
                      }
                      return p;
                    }, [])
                    .map((users, usersIndex) => {
                      return (
                        <tr key={`Users-${usersIndex}`}>
                          {users.map((user) => {
                            return (
                              <td key={`Users-${usersIndex}-${user.id}`}>
                                <div className="row d-flex box-flex2">
                                  <div className="left d-flex align-items-center">
                                    {user.imageId ? (
                                      <div className="img-pic">
                                        <img src={`${getFilesHost()}/${user.imageId}`} alt={user.name} />
                                      </div>
                                    ) : (
                                      <div className="img-pic img-none">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={14}
                                          height={14}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="feather feather-user font-medium-4"
                                        >
                                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                          <circle cx={12} cy={7} r={4} />
                                        </svg>
                                      </div>
                                    )}
                                    <div className="col">
                                      <h4>
                                        <button type="button" className="name" onClick={() => note.showUser(user?.id)}>
                                          {user.name}
                                        </button>
                                      </h4>
                                      <p className="txt-info">
                                        <strong>
                                          IMBA {user.period}기 /{' '}
                                          {user.isForeign ? (user.area ? user.area.join(' ') : '해외') : '국내'}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right">
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary waves-effect"
                                      onClick={() => {
                                        note.send([user]);
                                      }}
                                    >
                                      쪽지보내기
                                    </button>
                                  </div>
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination s */}
          <nav aria-label="Page navigation">
            <PaginationMoon
              data={data}
              onClick={(page) => {
                setParams((x) => ({ ...x, page }));
              }}
            />
          </nav>
          {/* // pagination e */}
        </div>
      </div>

      {/* // con e */}
    </div>
  );
}
