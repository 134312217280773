import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LecturesAttendancesLnb from './_lnb';
import { restApi } from '../../../../common/api';
import moment from 'moment';
import queryString from 'query-string';
import { shallowEqual, useSelector } from 'react-redux';
import Modal from '../../../../components/Modal';
import { useLoading } from '../../../../nav/AppContainer';
import FileSaver from 'file-saver';

const T = {
  offline: 'STUDY',
  qna: 'QNA',
};
export default function LecturesAttendancesCheck() {
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);
  const param = useParams();

  const [head, setHead] = useState();
  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const loadData = useCallback(async () => {
    const { data: head } = await restApi.get(`/lectures/0/offlines/${T[param.type]}/${param.id}`);
    setHead(head);

    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get(`/lectures/${lectureId}/attendances`, { params: { ...params, limit: 5000 } });
    setData(data);
  }, [param]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData, showModal]);

  const items = useMemo(() => {
    return (data?.content ?? []).reduce(
      (a, item, index) => {
        if (index % 2 == 0) {
          a[0].push(item);
        } else {
          a[1].push(item);
        }

        return a;
      },
      [[], []],
    );
  }, [data]);

  const handleSubmit = async (uid, state) => {
    await restApi.post(`/lectures/0/offlines/${T[param.type]}/${head.id}/attandance?uid=${uid}&state=${state}`);
    await loadData();
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            {param.type == 'offline' ? (
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">오프라인 강의</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">출석</li>
                    <li className="breadcrumb-item active">오프라인 강의</li>
                  </ol>
                </div>
              </div>
            ) : (
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">화상 Q&A</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">출석</li>
                    <li className="breadcrumb-item active">화상 Q&A</li>
                  </ol>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row">
        <div className="col-12">
          {/* Inputs Group with Dropdown */}
          <div className="col-md-6 col-12 ms-auto">
            <fieldset>
              <div className="input-group">
                <select className="form-select">
                  <option value={1}>전체</option>
                  <option value={2}>이름</option>
                  <option value={3}>학번</option>
                </select>
                <input type="text" className="form-control" placeholder="" aria-label="Amount" />
                <button className="btn btn-outline-primary waves-effect" type="button">
                  검색
                </button>
              </div>
            </fieldset>
          </div>
          {/* Inputs Group with Dropdown end */}
        </div>
      </div>
      <ul className="nav nav-tabs" role="tablist">
        <LecturesAttendancesLnb active={param.type} />
      </ul>
      {/* Bordered table start s */}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header pb-0 attendance-layout">
              <div className="col">
                <h4 className="d-flex">{head?.title}</h4>
                <p>
                  출석 체크<span className="text-black ms-75 me-2">{moment(head?.startAt).format('YYYY-MM-DD')}</span>
                </p>
              </div>
              <div className="w-auto txt-r">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    style={{ marginTop: 2, backgroundColor: '#009000', border: 0 }}
                    checked
                  />
                  <label className="form-check-label" htmlFor="ra0202" style={{ cursor: 'default' }}>
                    자동 출석 체크
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    style={{ marginTop: 2, backgroundColor: '#00467f', border: 0 }}
                    checked
                  />
                  <label className="form-check-label" htmlFor="ra0202" style={{ cursor: 'default' }}>
                    수동 출석 체크
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    style={{ marginTop: 2, backgroundColor: '#CC00CC', border: 0 }}
                    checked
                  />
                  <label className="form-check-label" htmlFor="ra0202" style={{ cursor: 'default' }}>
                    Zoom 출석 체크
                  </label>
                </div>
                <div className="d-inline-block">
                  <button
                    className="btn btn-outline-primary waves-effect"
                    type="button"
                    onClick={() => setShowModal(true)}
                    disabled={
                      !head?.zoomId || (head?.zoomId && moment().isSameOrBefore(moment(head?.endAt).add(0, 'minutes')))
                    }
                  >
                    ZOOM 출결현황
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="col-md-7 ps-2 pe-1 pb-2" style={{ width: '50%' }}>
                <table className="table table-bordered visual-box txt-c" style={{ tableLayout: 'fixed' }}>
                  <colgroup>
                    <col style={{ width: 20 }} />
                    <col style={{ width: 50 }} />
                    <col style={{ width: 50 }} />
                    <col style={{ width: 50 }} />
                    <col style={{ width: 45 }} />
                    <col style={{ width: 30 }} />
                    <col style={{ width: 30 }} />
                    <col style={{ width: 30 }} />
                    <col style={{ width: 50 }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>기수</th>
                      <th>이름</th>
                      <th>학번</th>
                      <th>해외/국내</th>
                      <th>출석</th>
                      <th>지각</th>
                      <th>결석</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {items?.[0]?.map((item, index) => {
                      const value = item[param.type + 's']?.find((x) => x.offlineId === head.id)?.state;
                      const isApp = item[param.type + 's']?.find((x) => x.offlineId === head.id)?.isApp;
                      const isZoom = item[param.type + 's']?.find((x) => x.offlineId === head.id)?.isZoom;
                      return (
                        <tr>
                          {/* <td>{data.totalElements - index * 2}</td> */}
                          <td>{index * 2 + 1}</td>
                          <td>IMBA {item.account.period}기</td>
                          <td>{item.account.name}</td>
                          <td>{item.account.userId}</td>
                          <td>{item.account.isForeign ? '해외' : '국내'}</td>
                          <td>
                            <input
                              className={`form-check-input ${isApp ? 'is_app' : ''} ${isZoom ? 'is_zoom' : ''}`}
                              type="radio"
                              name={'radio_' + item.account.id}
                              onClick={() => handleSubmit(item.account.id, 'ACTIVE')}
                              checked={value === 'ACTIVE'}
                            />
                          </td>
                          <td>
                            <input
                              className={`form-check-input ${isApp ? 'is_app' : ''} ${isZoom ? 'is_zoom' : ''}`}
                              type="radio"
                              name={'radio_' + item.account.id}
                              onClick={() => handleSubmit(item.account.id, 'LATE')}
                              checked={value === 'LATE'}
                            />
                          </td>
                          <td>
                            <input
                              className={`form-check-input ${isApp ? 'is_app' : ''} ${isZoom ? 'is_zoom' : ''}`}
                              type="radio"
                              name={'radio_' + item.account.id}
                              onClick={() => handleSubmit(item.account.id, 'NONE')}
                              checked={value === 'NONE'}
                            />
                          </td>
                          <td>
                            <button
                              type="reset"
                              className="btn btn-outline-secondary waves-effect btn-sm "
                              data-bs-toggle="modal"
                              data-bs-target="#btnCancel"
                              onClick={() => {
                                if (window.confirm('출석을 취소하시겠습니까?\n취소된 출석 데이터는 엑셀파일을 참고하여 수동으로 체크하셔야 합니다.')) {
                                  handleSubmit(item.account.id, '');
                                }
                              }}
                            >
                              취소
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="col-md-7 ps-1 pe-2 pb-2" style={{ width: '50%' }}>
                <table className="table table-bordered visual-box txt-c" style={{ tableLayout: 'fixed' }}>
                  <colgroup>
                    <col style={{ width: 20 }} />
                    <col style={{ width: 50 }} />
                    <col style={{ width: 50 }} />
                    <col style={{ width: 50 }} />
                    <col style={{ width: 45 }} />
                    <col style={{ width: 30 }} />
                    <col style={{ width: 30 }} />
                    <col style={{ width: 30 }} />
                    <col style={{ width: 50 }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>기수</th>
                      <th>이름</th>
                      <th>학번</th>
                      <th>해외/국내</th>
                      <th>출석</th>
                      <th>지각</th>
                      <th>결석</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {items?.[1]?.map((item, index) => {
                      const value = item[param.type + 's']?.find((x) => x.offlineId === head.id)?.state;
                      const isApp = item[param.type + 's']?.find((x) => x.offlineId === head.id)?.isApp;
                      const isZoom = item[param.type + 's']?.find((x) => x.offlineId === head.id)?.isZoom;
                      return (
                        <tr>
                          <td>{index * 2 + 2}</td>
                          <td>IMBA {item.account.period}기</td>
                          <td>{item.account.name}</td>
                          <td>{item.account.userId}</td>
                          <td>{item.account.isForeign ? '해외' : '국내'}</td>
                          <td>
                            <input
                              className={`form-check-input ${isApp ? 'is_app' : ''} ${isZoom ? 'is_zoom' : ''}`}
                              type="radio"
                              name={'radio_' + item.account.id}
                              onClick={() => handleSubmit(item.account.id, 'ACTIVE')}
                              checked={value === 'ACTIVE'}
                            />
                          </td>
                          <td>
                            <input
                              className={`form-check-input ${isApp ? 'is_app' : ''} ${isZoom ? 'is_zoom' : ''}`}
                              type="radio"
                              name={'radio_' + item.account.id}
                              onClick={() => handleSubmit(item.account.id, 'LATE')}
                              checked={value === 'LATE'}
                            />
                          </td>
                          <td>
                            <input
                              className={`form-check-input ${isApp ? 'is_app' : ''} ${isZoom ? 'is_zoom' : ''}`}
                              type="radio"
                              name={'radio_' + item.account.id}
                              onClick={() => handleSubmit(item.account.id, 'NONE')}
                              checked={value === 'NONE'}
                            />
                          </td>
                          <td>
                            <button
                              type="reset"
                              className="btn btn-outline-secondary waves-effect btn-sm "
                              data-bs-toggle="modal"
                              data-bs-target="#btnCancel"
                              onClick={() => {
                                if (window.confirm('출석을 취소하시겠습니까?\n취소된 출석 데이터는 엑셀파일을 참고하여 수동으로 체크하셔야 합니다.')) {
                                  handleSubmit(item.account.id, '');
                                }
                              }}
                            >
                              취소
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* Bordered table end e */}
      </div>
      <ZoomModal visible={showModal} onExit={() => setShowModal(false)} head={head} offlineId={param.id} />
      {/* // con e */}
    </div>
  );
}

function ZoomModal({ visible, onExit, head, offlineId }) {
  const [, setLoading] = useLoading();
  const [zoomData, setZoomData] = useState();
  const [approveCheckTime, setApproveCheckTime] = useState();

  const handleExit = () => {
    setApproveCheckTime('');
    onExit();
  };

  const xlsDownload = async () => {
    setLoading(true);
    const response = await restApi.get(`lectures/attandances/zooms/${offlineId}/excel`, {
      responseType: 'arraybuffer',
      exposeHeaders: ['content-disposition'],
    });

    const headers = response.headers;
    const contentDisposition = headers['content-disposition'];

    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+)"/);
      if (match) {
        const fileName = match[1]; // 파일 이름 추출
        // console.log('파일 이름:', fileName);

        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        FileSaver.saveAs(blob, decodeURIComponent(fileName));
      } else {
        console.log('파일 이름을 찾을 수 없습니다.');
      }
    }
    setLoading(false);
  };

  const loadData = useCallback(async () => {
    try {
      const { data } = await restApi.get(`/lectures/attandances/zooms/${offlineId}`);
      setZoomData(data.items);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404 || error.response.data.code === '3011 error') {
          // 최초 줌 데이터 get 실패
          try {
            await restApi.post(`/lectures/attandances/zooms/${offlineId}`);
            const { data } = await restApi.get(`/lectures/attandances/zooms/${offlineId}`);
            setZoomData(data.items);
          } catch (error) {
            if (error.response) {
              if (error.response.status === 400) {
                // post 실패
                alert('잠시 후 다시 시도해주세요.');
                handleExit();
              }
              if (error.response.status === 404 && error.response.data.code === '3011 error') {
                // get 2차 시도 실패
                alert('잠시 후 다시 시도해주세요.');
                handleExit();
              }
            }
          }
        }
      }
    }
  }, [offlineId]);

  useEffect(() => {
    if (visible) {
      loadData().catch(console.warn);
    }
  }, [visible, loadData]);

  return (
    <Modal className={' text-start modal-primary'} visible={visible}>
      <div className="modal-dialog modal-dialog-centered modal-lg" style={{ maxWidth: '850px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">ZOOM 출결현황</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleExit}
            />
          </div>
          <div className="modal-body">
            <div className="my-1 d-flex justify-content-between align-items-end">
              <div>
                <h4 className="d-flex">{head?.title + "(" + moment(head?.startAt).format('HH:mm') + " : " + moment(head?.endAt).format('HH:mm') + ")"}</h4>
                <p className="mb-0">
                  출석 체크
                  <span className="text-black ms-75 me-2">{moment(head?.startAt).format('YYYY-MM-DD')}</span>
                </p>
              </div>
              <button type="button" className="btn btn-outline-dark btn-doc waves-effect" onClick={xlsDownload}>
                엑셀 다운로드{' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-log-in"
                >
                  <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                  <polyline points="10 17 15 12 10 7"></polyline>
                  <line x1="15" y1="12" x2="3" y2="12"></line>
                </svg>
              </button>
            </div>
            <p className="mt-1">{"<ZOOM 참여 데이터>"}</p>
            <div style={{ maxHeight: 600, overflowY: 'scroll' }}>
              <table className="table table-bordered visual-box txt-c" style={{ tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                    <th>참석자명</th>
                    <th>입장</th>
                    <th>퇴장</th>
                    <th>참여 시간(분)</th>
                  </tr>
                </thead>
                <tbody>
                  {zoomData?.map((item) => (
                    <tr>
                      <td>{item.name}</td>
                      <td>{moment(item.joinAt).format('YYYY-MM-DD HH:mm')}</td>
                      <td>{moment(item.leaveAt).format('YYYY-MM-DD HH:mm')}</td>
                      <td>{item.duration}분</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p className="mt-1">※ 실제 참여 시간은 실제 수업 시작 시간부터 계산됩니다.</p>
            <p className="mt-1">※ 상단 노출되는 입장, 퇴장, 참여시간 정보는 ZOOM 참석 시간으로 출결 인정시간과 상이할 수 있습니다.</p>
          </div>
          <form
            className="modal-footer d-flex justify-content-between"
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              restApi.put(`/lectures/attandances/zooms/${offlineId}`, null, {
                params: {
                  approveCheckTime: approveCheckTime,
                },
              });

              setLoading(false);
              handleExit();
            }}
          >
            <div className="d-flex align-items-center gap-1">
              <p className="mb-0" style={{ minWidth: 110 }}>
                출결 인정시간(분):
              </p>
              <input
                type="text"
                name="approveCheckTime"
                className="form-control"
                value={approveCheckTime}
                onChange={(e) => setApproveCheckTime(e.target.value)}
              />
            </div>
            <div>
              <button type="submit" className="btn btn-primary waves-effect waves-float waves-light">
                저장
              </button>
              <button
                type="button"
                className="btn btn-outline-primary waves-effect ms-1"
                data-bs-dismiss="modal"
                onClick={handleExit}
              >
                취소
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
