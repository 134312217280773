import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';

export default function SiteScheduleIdIndex() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const query = useParams();

  const [data, setData] = useState();
  const loadData = async () => {
    const { data } = await restApi.get(`/calendars/${query.id}`);
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [query.id]);

  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/calendars/${query.id}`);
    alert('삭제되었습니다.');
    window.history.back();
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">학사일정</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">학사일정</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">학기</label>
                    </th>
                    <td>{codes?.map[data?.category]}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">기간</label>
                    </th>
                    <td>
                      {dayjs(data?.startAt).format('YYYY-MM-DD')} ~ {dayjs(data?.endAt).format('YYYY-MM-DD')}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">색상</label>
                    </th>
                    {/* <td>{data?.color}</td> */}
                    <td className="p-schedule-add">
                      <div className="checks">
                        <input
                          type="radio"
                          id="cal-c-red"
                          className="cal-c-red"
                          name="color"
                          title="연한 빨강색"
                          checked={data?.color === 'red'}
                        />
                        <label htmlFor="cal-c-red" />
                        <input
                          type="radio"
                          id="cal-c-orange"
                          className="cal-c-orange"
                          name="color"
                          title="연한 주황색"
                          checked={data?.color === 'orange'}
                        />
                        <label htmlFor="cal-c-orange" />
                        <input
                          type="radio"
                          id="cal-c-yellow"
                          className="cal-c-yellow"
                          name="color"
                          title="연한 노란색"
                          checked={data?.color === 'yellow'}
                        />
                        <label htmlFor="cal-c-yellow" />
                        <input
                          type="radio"
                          id="cal-c-green"
                          className="cal-c-green"
                          name="color"
                          title="연한 초록색"
                          checked={data?.color === 'green'}
                        />
                        <label htmlFor="cal-c-green" />
                        <input
                          type="radio"
                          id="cal-c-blue"
                          className="cal-c-blue"
                          name="color"
                          title="연한 파랑색"
                          checked={data?.color === 'blue'}
                        />
                        <label htmlFor="cal-c-blue" />
                        <input
                          type="radio"
                          id="cal-c-purple"
                          className="cal-c-purple"
                          name="color"
                          title="연한 보라색"
                          checked={data?.color === 'purple'}
                        />
                        <label htmlFor="cal-c-purple" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">작성자</label>
                    </th>
                    <td>{data?.account?.name}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">등록일</label>
                    </th>
                    <td>{dayjs(data?.createdAt).format('YYYY-MM-DD')}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">내용</label>
                    </th>
                    <td>{data?.title}</td>
                  </tr>
                  {/* <tr>
                    <th>
                      <label className="form-label">장소</label>
                    </th>
                    <td>{data?.area}</td>
                  </tr> */}
                  {/* <tr>
                    <th>
                      <label className="form-label">내용</label>
                    </th>
                    <td>{data?.contents}</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button
              type="button"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={() => {
                navigate(`/site/schedule/${query.id}/edit`);
              }}
            >
              수정
            </button>
            <button
              type="button"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={handleRemove}
            >
              삭제
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/site/schedule');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>

      {/* <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="row mx-0">
            <select className="form-select mb-2" style={{ width: 100 }}>
              <option value="">2023</option>
            </select>
            <select className="form-select mb-2 ms-75" style={{ width: 100 }}>
              <option value="">1학기</option>
              <option value="">2학기</option>
            </select>
          </div>

          <h4>1 월</h4>
          <div className="card card-schedule">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        시작 ~ 종료일
                      </label>
                    </th>
                    <td>
                      <p className="txt-datepicker">
                        <span className="text-black me-2">2022.01.05 ~ 2022.01.15</span>
                        2023학년도 1학기 복학신청
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <h4>2 월</h4>
          <div className="card card-schedule">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        시작 ~ 종료일
                      </label>
                    </th>
                    <td>
                      <p className="txt-datepicker">
                        <span className="text-black me-2">2022.02.05 ~ 2022.02.15</span>
                        2023학년도 1학기 복학신청
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        시작 ~ 종료일
                      </label>
                    </th>
                    <td>
                      <p className="txt-datepicker">
                        <span className="text-black me-2">2022.02.15 ~ 2022.02.25</span>
                        2023학년도 1학기 복학신청
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button
              type="submit"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={() => {
                navigate('/site/schedule/1/edit');
              }}
            >
              수정
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/site/schedule');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
}
