import React from 'react';
import { Link } from 'react-router-dom';

const LecturesAttendancesLnb = ({ active }) => (
  <>
    <li className="nav-item">
      <Link className={`nav-link ${!active ? 'active' : ''}`} role="tab" to={'/lms/lectures/attendance'}>
        전체
      </Link>
    </li>
    <li className="nav-item">
      <Link
        className={`nav-link ${active == 'video' ? 'active' : ''}`}
        role="tab"
        to={'/lms/lectures/attendance/video'}
      >
        동영상 강의
      </Link>
    </li>
    <li className="nav-item">
      <Link
        className={`nav-link ${active == 'offline' ? 'active' : ''}`}
        role="tab"
        to={'/lms/lectures/attendance/offline'}
      >
        오프라인 강의
      </Link>
    </li>
    <li className="nav-item">
      <Link className={`nav-link ${active == 'qna' ? 'active' : ''}`} role="tab" to={'/lms/lectures/attendance/qna'}>
        화상 Q&amp;A
      </Link>
    </li>
  </>
);

export default LecturesAttendancesLnb;
