import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../common/api';
import BoardAttachSingle from '../../components/boards/BoardAttachSingle';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { validPassword } from '../../common/utils';
import dayjs from 'dayjs';
import Modal from '../../components/Modal';
import { Postcode } from '@actbase/react-daum-postcode';
import { useLoading } from '../../nav/AppContainer';
import { me } from '../../data/auth';

export default function ProfileIdEdit() {
  const navigate = useNavigate();
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { codes } = useSelector((s) => s.common, shallowEqual);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/accounts/${principal?.id}/bo`);
    const init = {
      emailFront: data.email?.split('@')[0],
      emailBack: data.email?.split('@')[1],
      birthdayYear: data.birthday?.split?.('-')?.[0] || '1990',
      birthdayMonth: data.birthday?.split?.('-')?.[1] || '01',
      birthdayDay: data.birthday?.split?.('-')?.[2] || '01',
      lunar: data.lunar ? '음력' : '양력',
      phone1: data.phone?.split?.('-')?.[0],
      phone2: data.phone?.split?.('-')?.[1],
      phone3: data.phone?.split?.('-')?.[2],
      mobilePhone1: data.mobilePhone?.split?.('-')?.[0],
      mobilePhone2: data.mobilePhone?.split?.('-')?.[1],
      mobilePhone3: data.mobilePhone?.split?.('-')?.[2],
      gender: data?.gender || 'MALE',
    };
    setData({ ...data, ...init });
  }, [principal?.id]);

  const attacher = useRef();
  const dispatch = useDispatch();
  const [findPostcode, setFindPostcode] = useState();
  const [loading, setLoading] = useLoading();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      name: data?.name,
      // lunar: e.target.lunar.value ? '양력' : '음력', // 라디오
      lunar: e.target.lunar.value === '음력' ? true : false, // 라디오
      gender: e.target.gender.value,
      email: [e.target.emailFront.value, data.emailBack].join('@'),
      phone: [e.target.phone1.value, e.target.phone2.value, e.target.phone3.value].join('-'),
      mobilePhone: [e.target.mobilePhone1.value, e.target.mobilePhone2.value, e.target.mobilePhone3.value].join('-'),
      zipcode: e.target.zipcode.value,
      address: e.target.address.value,
      addressDetails: e.target.addressDetails.value,
      password: data.password,
      workPlace: data?.workPlace,
      imageId: data?.imageId,
      nowPassword: e.target.nowPassword.value,
    };

    if (!payload.nowPassword) {
      alert('현재 비밀번호를 입력해주세요.');
      return;
    }

    if (data.password) {
      if (!validPassword(data.password)) {
        alert('8~12자의 숫자와 영문자와 특수문자 조합으로 비밀번호를 변경하십시오.');
        return;
      }
      if (data.password !== data.passwordConfirm) {
        alert('비밀번호가 일치하지 않습니다.');
        return;
      }
    }

    const bd = [e.target.birthdayYear.value, e.target.birthdayMonth.value, e.target.birthdayDay.value].filter(
      (x) => !!x,
    );
    if (bd.length === 3) {
      payload.birthday = bd.join('-');
    } else {
      alert('생일을 정확히 입력해주세요.');
      return;
    }
    if (!data.lunar) {
      alert('생일 양력/음력을 입력해주세요.');
      return;
    }

    if (!payload.gender) {
      alert('성별을 입력해주세요.');
      return;
    }

    if (!payload.email) {
      alert('메일주소를 정확히 입력해주세요.');
      return;
    }

    if (!e.target.phone1.value || !e.target.phone2.value || !e.target.phone3.value) {
      alert('전화번호를 정확히 입력해주세요.');
      return;
    }

    if (!e.target.mobilePhone1.value || !e.target.mobilePhone2.value || !e.target.mobilePhone3.value) {
      alert('휴대폰 번호를 정확히 입력해주세요.');
      return;
    }

    if (!payload.zipcode || !payload.address || !payload.addressDetails) {
      alert('집주소를 정확히 입력해주세요.');
      return;
    }

    setLoading(true);
    try {
      payload.imageId = await attacher.current.getFileId();
      await restApi.put(`/accounts/${data.id}`, payload);
      alert('저장되었습니다.');
      dispatch(me());
      navigate('/profile');
    } catch (e) {
      // console.warn(e);
      alert('사진을 첨부해주세요.');
    }
    setLoading(false);
  };

  const handleOnchange = (e) => {
    const v = e.target.value;
    setData((x) => ({
      ...x,
      [e.target.name]: v,
    }));
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">개인정보수정</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">개인정보수정</li>
                  <li className="breadcrumb-item active">정보수정</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}

      {/* Bordered table start s */}
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {/* Bordered table start */}
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>아이디</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: 200 }}
                              readOnly
                              value={data?.userId}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">이름</label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: 200 }}
                              readOnly
                              value={data?.name}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>현재 비밀번호</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="password"
                              className="form-control"
                              style={{ width: 200 }}
                              name="nowPassword"
                              value={data?.nowPassword}
                              onChange={handleOnchange}
                            />
                            <span className="text-danger ms-1">현재 비밀번호를 입력해주세요.</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>비밀번호</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="password"
                              className="form-control"
                              style={{ width: 200 }}
                              name="password"
                              value={data?.password}
                              onChange={handleOnchange}
                            />
                            <span className="text-danger ms-1">
                              ※ 8~12자의 숫자와 영문자와 특수문자 조합으로 비밀번호를 변경하십시오.
                              <br />
                              새로운 비밀번호를 입력하시면 비밀번호가 변경됩니다.
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>비밀번호 확인</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="password"
                              className="form-control"
                              style={{ width: 200 }}
                              name={'passwordConfirm'}
                              value={data?.passwordConfirm}
                              onChange={handleOnchange}
                            />
                            {/*<span className="text-primary ms-1">비밀번호가 일치합니다.</span>*/}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>사진</th>
                        <td className="d-flex">
                          <BoardAttachSingle ref={attacher} defaultValue={data?.imageId} />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">생년월일</label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <select
                              className="form-select w-auto pe-3"
                              name="birthdayYear"
                              onChange={handleOnchange}
                              value={data?.birthdayYear}
                              defaultValue={data?.birthdayYear}
                            >
                              {[...new Array(100)].map((_, index) => {
                                const value = new Date().getFullYear() - 20 - index;
                                return (
                                  <option key={`Birth-Year-${index}`} value={value}>
                                    {value}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="ms-25 me-1">년</span>
                            <select
                              className="form-select w-auto pe-3"
                              name="birthdayMonth"
                              onChange={handleOnchange}
                              value={data?.birthdayMonth}
                              defaultValue={data?.birthdayMonth}
                            >
                              {[...new Array(12)].map((_, index) => {
                                const value = index + 1;
                                return (
                                  <option key={`Birth-Month-${index}`} value={String(value).padStart(2, '0')}>
                                    {value}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="ms-25 me-1">월</span>
                            <select
                              className="form-select w-auto pe-3"
                              name="birthdayDay"
                              onChange={handleOnchange}
                              value={data?.birthdayDay}
                              defaultValue={data?.birthdayDay}
                            >
                              {[
                                ...new Array(
                                  dayjs(`${data?.birthdayYear}-${data?.birthdayMonth}`, 'YYYY-MM').daysInMonth() || 0,
                                ),
                              ].map((_, index) => {
                                return (
                                  <option key={`Birth-Month-${index}`} value={String(index + 1).padStart(2, '0')}>
                                    {index + 1}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="ms-25 me-2">일</span>
                            <div className="d-flex align-items-center" onChange={handleOnchange}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="lunar"
                                  value={'양력'}
                                  checked={data?.lunar === '양력'}
                                  // defaultChecked={data?.lunar}
                                  id={'inlineRadio1'}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio1">
                                  양력
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="lunar"
                                  checked={data?.lunar === '음력'}
                                  value={'음력'}
                                  id={'inlineRadio2'}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio2">
                                  음력
                                </label>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">성별</label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center" onChange={handleOnchange}>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="inlineRadio221"
                                name="gender"
                                value="MALE"
                                checked={data?.gender == 'MALE'}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio221">
                                남
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="inlineRadio222"
                                name="gender"
                                value="FEMALE"
                                checked={data?.gender == 'FEMALE'}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio222">
                                여
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">이메일</label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: 200 }}
                              name="emailFront"
                              value={data?.emailFront}
                              onChange={handleOnchange}
                            />
                            <span className="mx-75">@</span>
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: 200 }}
                              name="emailBack"
                              value={data?.emailBack}
                              onChange={handleOnchange}
                            />
                            <select
                              className="form-select w-auto pe-3 ms-75"
                              value={data?.emailBack}
                              name={'emailBack'}
                              onChange={handleOnchange}
                            >
                              <option value="">직접입력</option>
                              {codes?.tree?.MAIL?.map((code) => (
                                <option value={code.label} key={code.code}>
                                  {code.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">전화번호</label>
                        </th>
                        <td>
                          <div className="row mx-0">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              style={{ width: 100 }}
                              name="phone1"
                              value={data?.phone1}
                              onChange={handleOnchange}
                            />
                            <span className="hipen">-</span>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              style={{ width: 100 }}
                              name="phone2"
                              value={data?.phone2}
                              onChange={handleOnchange}
                            />
                            <span className="hipen">-</span>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              style={{ width: 100 }}
                              name="phone3"
                              value={data?.phone3}
                              onChange={handleOnchange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">휴대폰번호</label>
                        </th>
                        <td>
                          <div className="row mx-0">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              style={{ width: 100 }}
                              name="mobilePhone1"
                              value={data?.mobilePhone1}
                              onChange={handleOnchange}
                            />
                            <span className="hipen">-</span>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              style={{ width: 100 }}
                              name="mobilePhone2"
                              value={data?.mobilePhone2}
                              onChange={handleOnchange}
                            />
                            <span className="hipen">-</span>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue=""
                              style={{ width: 100 }}
                              name="mobilePhone3"
                              value={data?.mobilePhone3}
                              onChange={handleOnchange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">집주소</label>
                        </th>
                        <td>
                          <div className="row mx-auto mb-1">
                            <input
                              type="text"
                              className="form-control me-75"
                              defaultValue=""
                              style={{ width: 100 }}
                              name="zipcode"
                              value={data?.zipcode}
                              readOnly
                            />
                            <button
                              type="button"
                              className="btn btn-outline-primary waves-effect w-auto ms-75"
                              onClick={() => {
                                setFindPostcode({
                                  fn: (data) => {
                                    setData((x) => ({ ...x, zipcode: data?.zonecode, address: data?.roadAddress }));
                                    setFindPostcode(undefined);
                                  },
                                });
                              }}
                            >
                              우편번호 검색
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control my-1"
                            defaultValue=""
                            name="address"
                            value={data?.address}
                            onChange={handleOnchange}
                            readOnly
                          />
                          <input
                            type="text"
                            className="form-control"
                            name="addressDetails"
                            value={data?.addressDetails}
                            onChange={handleOnchange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>근무지</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control"
                              name={'workPlace'}
                              value={data?.workPlace}
                              onChange={handleOnchange}
                            />
                            {/*<span className="text-primary ms-1">비밀번호가 일치합니다.</span>*/}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-12 txt-r">
                <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                  저장
                </button>
                <button
                  type="reset"
                  className="btn btn-outline-secondary waves-effect"
                  onClick={() => {
                    navigate('/profile');
                  }}
                >
                  취소
                </button>
              </div>
              {/* Bordered table end */}
            </div>
          </div>
        </div>
        {/* Bordered table end e */}
      </form>
      {/* // con e */}
      <Modal visible={!!findPostcode} className={'fade text-start modal-primary'}>
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">주소찾기</h5>
              <button type="button" className="btn-close" onClick={() => setFindPostcode(undefined)}></button>
            </div>
            <div className="modal-body">
              <Postcode
                style={{ width: '100%' }}
                jsOptions={{ animation: false, hideMapBtn: true }}
                onSelected={findPostcode?.fn}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
