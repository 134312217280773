import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LecturesAttendancesLnb from './_lnb';
import queryString from 'query-string';
import { Link, useSearchParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';
import LectureSearch from '../../../../components/LectureSearch';
import RequireLecture from '../../../../components/RequireLecture';
import { useLoading } from '../../../../nav/AppContainer';
import FileSaver from 'file-saver';
import moment from 'moment';
import DownIco from '../../../../components/icons/DownIco';

const LecturesAttendancesVideoIndex = (props) => {
  const { semesterId, lectureId } = useSelector((s) => s.common, shallowEqual);

  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = React.useState(queryString.parse(window.location.search));
  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const [head, setHead] = useState();
  const loadData = useCallback(async () => {
    const { data: head } = await restApi.get(`/lectures/${lectureId}/attendances/index`);
    setHead(head);

    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get(`/lectures/${lectureId}/attendances`, { params: { ...params, limit: 99999 } });
    setData(data);
  }, [lectureId, params]);

  const tableWidth = useMemo(() => {
    let size = 40 + 100 + 150 + 60 * (1 + head?.videos?.length);
    return size;
  }, [head]);

  useEffect(() => {
    if (lectureId) {
      loadData().catch(console.warn);
    }
  }, [loadData]);

  const [, setLoading] = useLoading();

  const xlsDownload = async () => {
    setLoading(true);
    const { data } = await restApi.get(`/lectures/${lectureId}/attendances/video/xlsx`, {
      responseType: 'arraybuffer',
      params,
    });
    FileSaver.saveAs(new Blob([data]), `출석관리_동영상_${moment().utcOffset('+0900').format('YYYYMMDD_HHmmss')}.xlsx`);
    setLoading(false);
  };

  const xlsDownloadAll = async () => {
    setLoading(true);
    const { data } = await restApi.get(`/lectures/semester/${semesterId}/attendances/video/xlsx`, {
      responseType: 'arraybuffer',
      params,
    });
    FileSaver.saveAs(
      new Blob([data]),
      `출석관리_전과목_동영상_${moment().utcOffset('+0900').format('YYYYMMDD_HHmmss')}.xlsx`,
    );
    setLoading(false);
  };

  return (
    <>
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">동영상 강의</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">출석</li>
                    <li className="breadcrumb-item active">동영상 강의</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // header e */}

        <LectureSearch />

        <RequireLecture
          lectureId={lectureId}
          extra={
            <button type="button" className="btn btn-outline-dark btn-doc waves-effect" onClick={xlsDownloadAll}>
              엑셀 다운로드
              <DownIco />
            </button>
          }
        >
          {/* con s */}
          <div className="row">
            <div className="col-12">
              {/* Inputs Group with Dropdown */}
              <div className="col-md-6 col-12 ms-auto">
                <fieldset>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      setParams({
                        keyword: event.target.keyword.value,
                        keywordType: event.target.keywordType.value,
                      });
                    }}
                  >
                    <div className="input-group">
                      <select name="keywordType" className="form-select" defaultValue={params.keywordType}>
                        <option value={''}>전체</option>
                        <option value={'name'}>이름</option>
                        <option value={'idno'}>학번</option>
                      </select>

                      <input
                        name="keyword"
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        defaultValue={params.keyword}
                      />
                      <button className="btn btn-outline-primary waves-effect" type="submit">
                        검색
                      </button>
                    </div>
                  </form>
                </fieldset>
              </div>
              {/* Inputs Group with Dropdown end */}
            </div>
          </div>

          <ul className="nav nav-tabs" role="tablist">
            <LecturesAttendancesLnb active={'video'} />
          </ul>
          {/* Bordered table start s */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header pb-1">
                  <div className="col">
                    <h4 className="d-flex align-items-center">동영상 강의</h4>
                  </div>
                  <div className="w-auto txt-r">
                    <button type="button" className="btn btn-outline-dark btn-doc waves-effect" onClick={xlsDownload}>
                      엑셀 다운로드{' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-log-in"
                      >
                        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                        <polyline points="10 17 15 12 10 7"></polyline>
                        <line x1="15" y1="12" x2="3" y2="12"></line>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="table-responsive scrollbox2 alltablebox">
                  <table className="table table-bordered visual-box" style={{ minWidth: tableWidth }}>
                    <colgroup>
                      <col style={{ width: 40 }} />
                      <col style={{ width: 100 }} />
                      <col />
                      {head?.videos?.map((item, ix) => (
                        <col key={`${ix}`} style={{ width: 60 }} />
                      ))}
                      <col style={{ width: 60 }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>번호</th>
                        <th>이름</th>
                        <th>학번</th>
                        {head?.videos?.map((item, ix) => (
                          <th key={`${ix}`}>{item.week}</th>
                        ))}
                        <th>진도율</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.content?.map((item, index) => {
                        const videos = [...head?.videos].map((o, ix) => {
                          const videoByWeek = item?.videos.filter((x) => x.week === o.week);
                          const isExcludeProgress = videoByWeek.filter((x) => x.isExcludeProgress);
                          if (videoByWeek.length === isExcludeProgress.length) {
                            return 0;
                          } else {
                            return videoByWeek.reduce((a, c) => a + c.percent, 0) / o.percent;
                          }
                        });

                        const totalWeeks = [...head?.videos]
                          .map((o) => item?.videos.filter((x) => x.week === o.week && !x.isExcludeProgress))
                          .filter((x) => x.length);

                        const videoTotal = Math.round(videos.reduce((a, b) => a + b, 0) / totalWeeks.length);

                        return (
                          <tr key={`${item.account?.id}`}>
                            <td className="txt-c">{index + 1 + data?.size * data?.number}</td>
                            <td className="txt-c">{item?.account?.name}</td>
                            <td className="txt-c">{item?.account?.idno || item?.account?.userId}</td>
                            {videos?.map((item, ix) => (
                              <td className="txt-c">{item ? `${item.toFixed(0)}%` : '-'}</td>
                            ))}
                            <td className="txt-c">{videoTotal ? `${videoTotal.toFixed(0)}%` : '-'}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* Bordered table end e */}
          </div>
          {/* // con e */}
        </RequireLecture>
      </div>
    </>
  );
};

export default LecturesAttendancesVideoIndex;
