import React, { useCallback, useEffect, useState } from 'react';

import FileSaver from 'file-saver';
import { getAPIHost, restApi } from '../../common/api';
import { downloadLink } from '../../common/utils';

const BoardAttachList = ({ ids }) => {
  const [items, setItems] = useState();

  const loadData = useCallback(async () => {
    if (!ids) return;
    const { data: files } = await restApi.get(`/files/infos`, { params: { ids } });
    setItems(files);
  }, [ids]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const handleDownload = async (file) => {
    FileSaver.saveAs(downloadLink(file), file.filename);
  };

  return !items?.length ? (
    <p className="my-50">첨부된 파일이 없습니다.</p>
  ) : (
    items?.map((file) => (
      <p className="my-50" key={`${file.id}`}>
        <a onClick={() => handleDownload(file)}>{file.filename}</a>
      </p>
    ))
  );
};

export default BoardAttachList;
