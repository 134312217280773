import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { shallowEqual, useSelector } from 'react-redux';
import LectureSearch from '../../../../components/LectureSearch';
import RequireLecture from '../../../../components/RequireLecture';
import PaginationMoon from '../../../../components/PaginationMoon';
import queryString from 'query-string';
import Chart from 'chart.js/auto';
import { Bar, Line, Pie } from 'react-chartjs-2';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Modal from '../../../../components/Modal';
import { restApi } from '../../../../common/api';
import produce from 'immer';
import { useLoading } from '../../../../nav/AppContainer';
import moment from 'moment';
import dayjs from 'dayjs';

const TYPE_TEXTS = { NOTE: '쪽지', MAIL: '이메일', SMS: '문자' };

const chartOption = {
  indexAxis: 'y',
  scales: {
    x: {
      max: 100,
      min: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export default function LecturesDurationIndex() {
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));
  const [showModal, setShowModal] = useState(undefined);
  const [showTransfers, setShowTransfers] = useState(undefined);
  const navigate = useNavigate();

  const [transData, setTransData] = useState();
  useEffect(() => {
    if (!showTransfers) {
      setTransData(undefined);
      return;
    }

    const loadData = async () => {
      const { data } = await restApi.get(`/lectures/${lectureId}/dashboard/${showTransfers.accountId}/transfer`, {
        params: showTransfers,
      });
      setTransData(data);
    };
    loadData().catch(console.warn);
  }, [showTransfers]);

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [message, setMessage] = useState();
  const [ids, setIds] = useState([]);

  const [lectureData, setLectureData] = useState();
  const loadLectureData = useCallback(async () => {
    if (!lectureId) return;
    const { data } = await restApi.get(`/lectures/${lectureId}`);
    setLectureData(data);
  }, [lectureId, params]);

  useEffect(() => {
    loadLectureData().catch(console.warn);
  }, [loadLectureData]);

  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState();
  const loadData = useCallback(async () => {
    if (!lectureId) return;
    const { data } = await restApi.get(`/lectures/${lectureId}/dashboard`, { params: { order: 'ASC' } });
    setData(data);
    setMessage(
      `[학습독려안내]\n\n현재 수강중인 ${data?.lectureTitle} 학습 진도율이 \n부진합니다. 기간내 학습이 완료될 수 있도록 서둘러 주시기 바랍니다.`,
    );
    setIds([]);
    setFilteredData(data?.users);
  }, [lectureId]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const handleOnchange = (e) => {
    setParams((x) => ({
      ...x,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearch = () => {
    let list = data?.users;

    if (params.keywordType && params.keyword) {
      list = list.filter((user) => user[params.keywordType].includes(params.keyword));
    } else if (!params.keywordType && params.keyword) {
      list = list.filter((user) => {
        return Object.values(user).some((value) => {
          if (typeof value === 'string') {
            return value.includes(params.keyword);
          }
          return false;
        });
      });
    }
    if (!!params.progressStart || !!params.progressEnd) {
      list = list.filter((user) => {
        const videoRate = user.videoRate;
        if (params.progressStart && params.progressEnd) {
          return videoRate >= parseInt(params.progressStart) && videoRate <= parseInt(params.progressEnd);
        } else if (params.progressStart) {
          return videoRate >= parseInt(params.progressStart) && videoRate <= 100;
        } else if (params.progressEnd) {
          return videoRate <= parseInt(params.progressEnd) && videoRate >= 0;
        }
        return false;
      });
    }
    if (params.homework) {
      if (params.homework === 'done') {
        list = list.filter((user) => user.homeworkPercent > 0);
      } else if (params.homework === 'undone') {
        list = list.filter((user) => user.homeworkPercent === 0);
      }
    }
    if (params.quiz) {
      if (params.quiz === 'done') {
        list = list.filter((user) => user.quizPercent > 0);
      } else if (params.quiz === 'undone') {
        list = list.filter((user) => user.quizPercent === 0);
      }
    }
    if (params.exam) {
      if (params.exam === 'done') {
        list = list.filter((user) => user.examType1 === 'Y' || user.examType2 === 'Y');
      } else if (params.exam === 'undone') {
        list = list.filter((user) => user.examType1 === 'N' && user.examType2 === 'N');
      }
    }
    setFilteredData(list);
  };

  function getAverage(rate) {
    let total = 0;

    //수료 여부
    if (rate === 'isFinish') {
      for (let i = 0; i < data?.users.length; i++) {
        if (data?.users[i].isFinish) {
          total++;
        }
      }
      const average = (total / data?.users.length) * 100;
      return average;

      // 시험 응시율
    } else if (rate === 'examType1' || rate === 'examType2') {
      for (let i = 0; i < data?.users.length; i++) {
        if (data?.users[i][rate] === 'Y') {
          total++;
        }
      }
      const average = (total / data?.users.length) * 100;
      return average;

      // 정수형 퍼센트인 경우
    } else {
      for (let i = 0; i < data?.users.length; i++) {
        total += data?.users[i][rate];
      }
      const average = total / data?.users.length;
      return average;
    }
  }

  const [chartData1, chartData2, chartData3, chartData4, chartData5] = useMemo(() => {
    const chartData1 = {
      labels: ['평균', '권장'],
      datasets: [
        {
          backgroundColor: ['#FFC002', '#5B9BD5'],
          borderWidth: 1,
          data: [getAverage('videoRate'), 50],
        },
      ],
    };
    const chartData2 = {
      labels: ['오프라인', '화상Q&A'],
      datasets: [
        {
          backgroundColor: ['#FFC002', '#5B9BD5'],
          borderWidth: 1,
          data: [getAverage('offlineRate'), getAverage('qnaRate')],
        },
      ],
    };
    const chartData3 = {
      labels: ['중간', '기말'],
      datasets: [
        {
          backgroundColor: ['#FFC002', '#5B9BD5'],
          borderWidth: 1,
          data: [getAverage('examType1'), getAverage('examType2')],
        },
      ],
    };
    const chartData4 = {
      labels: ['퀴즈', '과제'],
      datasets: [
        {
          backgroundColor: ['#FFC002', '#5B9BD5'],
          borderWidth: 1,
          data: [getAverage('quizPercent'), getAverage('homeworkPercent')],
        },
      ],
    };
    const chartData5 = {
      labels: ['수료', '미수료'],
      datasets: [
        {
          backgroundColor: ['#FFC002', '#5B9BD5'],
          borderWidth: 1,
          data: [getAverage('isFinish'), data?.users.length - getAverage('isFinish')],
        },
      ],
    };
    return [chartData1, chartData2, chartData3, chartData4, chartData5];
  }, [data]);

  const [limitPer, setLimitPer] = useState(60);
  const items = useMemo(() => {
    if (!data?.users) return [];
    return filteredData;
  }, [limitPer, data?.users, filteredData]);

  useEffect(() => {
    setIds((draft) => {
      return draft.filter((x) => items.map((v) => v.id).includes(x));
    });
  }, [items]);

  const [, setLoading] = useLoading();
  const sendToMessage = async (type) => {
    if (ids.length > 0) {
      setShowModal(undefined);
      if (type === 'msg') {
        try {
          setLoading(true);
          for (const id of ids) {
            await restApi.post(`/notes/all`, {
              receiverId: id,
              contents: message,
              lectureId,
            });
          }
          alert('전송이 완료되었습니다.');
        } catch (e) {
          console.warn(e);
        }
        setLoading(false);
      } else if (type === 'sms') {
        setTimeout(() => navigate('/lms/talks/messages/write', { state: { ids, message, lectureId } }), 100);
      } else {
        setTimeout(() => navigate('/lms/talks/mail/write', { state: { ids, message, lectureId } }), 100);
      }
    } else {
      alert('원우를 선택해주세요');
    }
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">학습현황관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">학습현황관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <LectureSearch />
      {/* Bordered table start */}

      <RequireLecture lectureId={lectureId}>
        <div className="row" id="table-bordered">
          <div className="col-12">
            <h5 className="text-black mt-2">과목 학습 현황 (전체 수강생)</h5>
            <div style={{ position: 'relative' }}>
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                slidesPerView={3}
                spaceBetween={30}
                direction={'horizontal'}
                navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
              >
                <SwiperSlide className="card py-1 px-2">
                  <h4 className="card-title txt-c">진도율</h4>
                  <div className="mt-1 d-flex  justify-content-center" style={{ height: 150, margin: '10px auto' }}>
                    <Bar data={chartData1} options={chartOption} />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="card py-1 px-2">
                  <h4 className="card-title txt-c">평균 출석률</h4>
                  <div className="mt-1 d-flex  justify-content-center" style={{ height: 150, margin: '10px auto' }}>
                    <Bar data={chartData2} options={chartOption} />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="card py-1 px-2">
                  <h4 className="card-title txt-c">시험 응시율</h4>
                  <div className="mt-1 d-flex  justify-content-center" style={{ height: 150, margin: '10px auto' }}>
                    <Bar data={chartData3} options={chartOption} />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="card py-1 px-2">
                  <h4 className="card-title txt-c">퀴즈&과제 응시율</h4>
                  <div className="mt-1 d-flex  justify-content-center" style={{ height: 150, margin: '10px auto' }}>
                    <Bar data={chartData4} options={chartOption} />
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  className={`card py-1 px-2 ${moment().isBefore(lectureData?.semester?.endAt) && 'chart-inactive'}`}
                >
                  <h4 className="card-title txt-c">수료율</h4>
                  <div className="mt-1 d-flex  justify-content-center" style={{ height: 150, margin: '10px auto' }}>
                    <Pie
                      data={chartData5}
                      options={{
                        plugins: {
                          legend: {
                            display: true,
                          },
                        },
                      }}
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
              {/* Add Arrows */}
              <div
                className="swiper-button-next"
                tabIndex={0}
                role="button"
                aria-label="Next slide"
                aria-controls="swiper-wrapper-7a97b6ac88fb70f10"
                aria-disabled="false"
              />
              <div
                className="swiper-button-prev swiper-button-disabled"
                tabIndex={-1}
                role="button"
                aria-label="Previous slide"
                aria-controls="swiper-wrapper-7a97b6ac88fb70f10"
                aria-disabled="true"
              />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col style={{ width: '32%' }} />
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                          <label className="form-label">(동영상)진도율</label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              style={{ width: 55 }}
                              name="progressStart"
                              onChange={handleOnchange}
                            />
                            <span className="hipen">~</span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              style={{ width: 55 }}
                              name="progressEnd"
                              onChange={handleOnchange}
                            />
                            <span className="hipen">%</span>
                          </div>
                        </td>
                        <th>
                          <label className="form-label">과제</label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center" onChange={handleOnchange} defaultValue="">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="check01"
                                type="radio"
                                name="homework"
                                value=""
                                defaultChecked
                              />
                              <label htmlFor="check01" className="form-check-label">
                                전체
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="check02"
                                type="radio"
                                name="homework"
                                value="done"
                              />
                              <label htmlFor="check02" className="form-check-label">
                                제출
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="check03"
                                type="radio"
                                name="homework"
                                value="undone"
                              />
                              <label htmlFor="check03" className="form-check-label">
                                미제출
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">시험</label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center" onChange={handleOnchange}>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="check0201"
                                type="radio"
                                name="exam"
                                value=""
                                defaultChecked
                              />
                              <label htmlFor="check0201" className="form-check-label">
                                전체
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="check0202"
                                type="radio"
                                name="exam"
                                value="done"
                              />
                              <label htmlFor="check0202" className="form-check-label">
                                응시
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="check0203"
                                type="radio"
                                name="exam"
                                value="undone"
                              />
                              <label htmlFor="check0203" className="form-check-label">
                                미응시
                              </label>
                            </div>
                          </div>
                        </td>
                        <th>
                          <label className="form-label">퀴즈</label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center" onChange={handleOnchange}>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="check0301"
                                type="radio"
                                name="quiz"
                                value=""
                                defaultChecked
                              />
                              <label htmlFor="check0301" className="form-check-label">
                                전체
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="check0302"
                                type="radio"
                                name="quiz"
                                value="done"
                              />
                              <label htmlFor="check0302" className="form-check-label">
                                응시
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="check0303"
                                type="radio"
                                name="quiz"
                                value="undone"
                              />
                              <label htmlFor="check0303" className="form-check-label">
                                미응시
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <select
                            className="form-select pe-3"
                            name="keywordType"
                            defaultValue={params.keywordType}
                            onChange={handleOnchange}
                          >
                            <option value="">전체</option>
                            <option value="name">이름</option>
                            <option value="period">기수</option>
                            <option value="userId">학번</option>
                          </select>
                        </th>
                        <td colSpan={3}>
                          <div className="d-flex gap-1">
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              maxLength={20}
                              name="keyword"
                              defaultValue={params.keyword}
                              onChange={handleOnchange}
                            />
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-float waves-light"
                              style={{ width: 100 }}
                            >
                              검색
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
            {/* 조회 전 */}
            <div className="col-12 mt-50 mb-2 txt-r mb-75">
              <button
                type="button"
                className="btn btn-primary waves-effect waves-float waves-light"
                data-bs-toggle="modal"
                data-bs-target="btnEncourage"
                onClick={() => setShowModal(1)}
              >
                독려하기
              </button>
            </div>

            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box txt-c middle t-padding3">
                  <thead>
                    <tr>
                      <th rowSpan={2} style={{ verticalAlign: 'middle' }}>
                        번호
                      </th>
                      <th rowSpan={2} style={{ verticalAlign: 'middle' }}>
                        이름
                      </th>
                      <th rowSpan={2} style={{ verticalAlign: 'middle' }}>
                        기수
                      </th>
                      <th rowSpan={2} style={{ verticalAlign: 'middle' }}>
                        학번
                      </th>
                      <th rowSpan={2} style={{ verticalAlign: 'middle' }}>
                        해외/국내
                      </th>
                      <th rowSpan={2} style={{ verticalAlign: 'middle' }}>
                        진도율
                      </th>
                      <th colSpan={2}>출석률</th>
                      <th colSpan={2}>시험응시</th>
                      <th rowSpan={2} style={{ verticalAlign: 'middle' }}>
                        퀴즈응시
                      </th>
                      <th rowSpan={2} style={{ verticalAlign: 'middle' }}>
                        과제제출
                      </th>
                      <th rowSpan={2} style={{ verticalAlign: 'middle' }}>
                        수료
                      </th>
                      <th rowSpan={2} style={{ verticalAlign: 'middle' }}>
                        메일/문자
                      </th>
                    </tr>
                    <tr>
                      <th>오프라인강의</th>
                      <th>화상Q&amp;A</th>
                      <th>중간</th>
                      <th>기말</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData?.length > 0 ? (
                      filteredData.map((item, index) => (
                        <tr key={`${item.id}`}>
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.period}기</td>
                          <td>{item.idno || item.userId}</td>
                          <td>{item.isForeign ? '해외' : '국내'}</td>
                          <td>{item.videoRate}%</td>
                          <td>{item.offlineRate || 0}%</td>
                          <td>{item.qnaRate || 0}%</td>
                          <td>{item.examType1 || '-'}</td>
                          <td>{item.examType2 || '-'}</td>
                          <td>{item.quizPercent || 0}%</td>
                          <td>{item.homeworkPercent || 0}%</td>
                          <td>{item.isFinish ? 'Y' : 'N'}</td>
                          <td>
                            {item.noteCount > 0 ? (
                              <a
                                className="underline"
                                onClick={() => setShowTransfers({ accountId: item.id })}
                                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                              >
                                {item.noteCount}
                              </a>
                            ) : (
                              item.noteCount
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan={12} className="p-1">
                        검색 결과가 없습니다.
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* pagination s */}
            {/* // pagination e */}
          </div>
        </div>
        <Modal className={'text-start modal-primary'} visible={!!showTransfers}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">메일/문자 이력</h5>
                <button type="button" className="btn-close" onClick={() => setShowTransfers(undefined)} />
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box" style={{ tableLayout: 'fixed' }}>
                    <colgroup>
                      <col style={{ width: '8rem' }} />
                      <col />
                      <col style={{ width: '10rem' }} />
                      <col style={{ width: '10rem' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>이력</th>
                        <th>내용</th>
                        <th>날짜</th>
                        <th>발신자</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transData?.content?.map((item) => (
                        <tr key={`${item.id}`}>
                          <td className="txt-c">{TYPE_TEXTS[item.type]}</td>
                          <td className="txt-100">{item.message}</td>
                          <td className="txt-c">
                            {dayjs(item.createdAt).format('YYYY.MM.DD ')}[{dayjs(item.createdAt).format('HH:mm')}]
                          </td>
                          <td className="txt-c">{item?.sender?.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <small className="text-muted">
                  ※ 문자/메일의 상세 내용은 학습현황&gt;메일/문자 발송 이력(전체)에서 확인할 수 있습니다.
                </small>
                {/* pagination s */}
                <nav aria-label="Page navigation">
                  <PaginationMoon data={transData} onClick={(page) => setParams((x) => ({ ...x, page }))} />
                </nav>
                {/* // pagination e */}
              </div>
            </div>
          </div>
        </Modal>
        <Modal className={' text-start modal-primary'} visible={showModal == 1}>
          <div className="modal-dialog modal-dialog-centered modal-lg" style={{ maxWidth: '850px' }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">독려 알림 보내기</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowModal(undefined)}
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-stretch">
                  <div className="col-md-7 col-12">
                    {/* Inputs Group with Dropdown end */}
                    <div className="table-responsive scrollbox">
                      <table className="table table-bordered visual-box">
                        <colgroup>
                          <col style={{ width: '4rem' }} />
                          <col style={{ width: '5rem' }} />
                          <col />
                          <col style={{ width: '8rem' }} />
                          <col style={{ width: '7rem' }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={items.length === ids.length}
                                onClick={() => {
                                  const checked = items.length === ids.length;
                                  if (!checked) {
                                    setIds(items?.map((x) => x.id));
                                  } else {
                                    setIds([]);
                                  }
                                }}
                              />
                            </th>
                            <th>번호</th>
                            <th>이름</th>
                            <th>기수</th>
                            <th>해외/국내</th>
                            <th>진도율</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items?.map((item, ix) => (
                            <tr key={`${item.id}`}>
                              <td className="txt-c">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={ids.includes(item.id)}
                                  onClick={() => {
                                    const ix = ids.indexOf(item.id);
                                    setIds(
                                      produce((draft) => {
                                        if (ix >= 0) {
                                          draft.splice(ix, 1);
                                        } else {
                                          draft.push(item.id);
                                        }
                                      }),
                                    );
                                  }}
                                />
                              </td>
                              <td className="txt-c">{ix + 1}</td>
                              <td className="txt-c">{item.name}</td>
                              <td className="txt-c">IMBA{item.period}기</td>
                              <td className="txt-c">{item.isForeign ? '해외' : '국내'}</td>
                              <td className="txt-c">{item.videoRate}%</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-5 col-12 ps-1">
                    <textarea
                      style={{ width: '100%', height: '100%' }}
                      className="form-control char-textarea"
                      value={message}
                      onChange={(e) => {
                        const v = e.target.value;
                        setMessage(v);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-float waves-light"
                  onClick={() => sendToMessage('sms')}
                >
                  SMS 발송
                </button>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-float waves-light"
                  onClick={() => sendToMessage('mail')}
                >
                  메일 발송
                </button>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-float waves-light"
                  onClick={() => sendToMessage('msg')}
                >
                  쪽지 발송
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary waves-effect"
                  data-bs-dismiss="modal"
                  onClick={() => setShowModal(undefined)}
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </RequireLecture>
      {/* Bordered table end */}
      {/* // con e */}
    </div>
  );
}
