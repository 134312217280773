import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { getAPIHost, restApi } from '../../../../common/api';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import PaginationMoon from '../../../../components/PaginationMoon';
import Modal from '../../../../components/Modal';
import { downloadLink, formatBytes } from '../../../../common/utils';
import queryString from 'query-string';
import { useLoading } from '../../../../nav/AppContainer';
import DownIco from '../../../../components/icons/DownIco';

export default function LmsHomeworksIdDetails() {

  let submittedCount = 0;

  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  const [submittedFlag, setSubmittedFlag] = useState(true);

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  // const navigate = useNavigate();
  const { lectureId } = useSelector((s) => s.common, shallowEqual);
  const { type, id } = useParams();

  const [data, setData] = useState();
  const [data1, setData1] = useState();
  const [view, setView] = useState();
  const loadData = useCallback(
    async (page) => {
      const { data } = await restApi.get(`/lectures/${lectureId ?? 0}/homeworks/${id}`, {
        params: { ...params, page:0, limit: 30000 }
      });
      setView(data);

      const { data: items } = await restApi.get(`/lectures/${lectureId ?? 0}/homeworks/${id}/items`, {
        params: { ...params, limit: 20 },
      });
      setData(items);

      const { data: items1 } = await restApi.get(`/lectures/${lectureId ?? 0}/homeworks/${id}/items`, {
        params: { ...params, page:0, limit: 30000 },
      });
      setData1(items1);

      if (items.empty) {
        setSubmittedFlag(false)
      }
    },
    [lectureId, id, params]
  );
  useEffect(() => {
    loadData(0).catch(console.warn);
  }, [loadData]);

  const [files, setFiles] = useState([]);
  useEffect(() => {
    const ids = data?.content?.reduce((a, x) => a.concat(x.files), []);
    const loadData = async () => {
      const { data } = await restApi.get(`/files/infos`, { params: { ids } });
      setFiles(data);
    };
    if (ids?.length > 0) loadData().catch(console.warn);
  }, [data?.content]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      replyContents: showDetails?.replyContents,
      replyPoint: showDetails?.replyPoint,
      accountId: showDetails?.account?.id,
    };

    await restApi.post(`/lectures/0/homeworks/${id}/result`, payload);
    alert('저장되었습니다.');
    loadData().catch(console.warn);
    setShowDetails(undefined);
  };

  const [showDetails, setShowDetails] = useState();

  const showDetailsFiles = files?.filter((x) => showDetails?.files?.includes(String(x.id)));

  const query = useParams();

  const [, setLoading] = useLoading();
  const handleDownloadZip = async (item) => {
    setLoading(true);
    const filename = `과제_${item?.title}_${dayjs().format('YYYYMMDD')}`;
    try {
      const { data } = await restApi.get(`/files/compression/direct`, {
        responseType: 'arraybuffer',
        params: {
          ids: item?.files ?? [],
          name: 'homeworks.zip',
        },
      });
      FileSaver.saveAs(new Blob([data]), `${filename}.zip`);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
      console.warn(e);
    }
    setLoading(false);
  };

  const handleDownloadAll = async () => {
    setLoading(true);
    const filename = `과제_일괄다운로드_${view?.title}_${dayjs().format('YYYYMMDD')}`;
    try {
      const { data: resp } = await restApi.get(`/lectures/${lectureId ?? 0}/homeworks/${id}/zip`, {
        responseType: 'arraybuffer',
        params: {
          ...params,
        },
      });
      FileSaver.saveAs(new Blob([resp]), `${filename}.zip`);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
      console.warn(e);
    }
    setLoading(false);
  };

  const handleDownloadXlsx = async () => {
    setLoading(true);
    const filename = `과제_채점지_${view?.title}_${dayjs().format('YYYYMMDD')}`;
    try {
      const t = type === 'single' ? 'user_xlsx' : 'team_xlsx';
      const { data: resp } = await restApi.get(`/lectures/${lectureId ?? 0}/homeworks/${id}/${t}`, {
        responseType: 'arraybuffer',
        params: {
          ...params,
        },
      });
      FileSaver.saveAs(new Blob([resp]), `${filename}.xlsx`);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
      console.warn(e);
    }
    setLoading(false);
  };

  const handleDownload = async (file) => {
    setLoading(true);
    if (file.ids.length === 1) {
      const path =
        getAPIHost() +
        'api/files/' +
        file.ids?.[0] +
        '?response-content-disposition=' +
        encodeURI(`attachment;filename*=utf-8''${encodeURI(file.filename)}`);
      FileSaver.saveAs(path, file.filename);
    } else if (file.ids.length > 0) {
      const { data: resp } = await restApi.get(`/files/compression/direct`, {
        responseType: 'arraybuffer',
        params: {
          ids: file.ids,
          name: 'homeworks.zip',
        },
      });
      FileSaver.saveAs(new Blob([resp]), `${file.filename}`);
    }
    setLoading(false);
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="row">
        <div className="col-12">
          <div className="card mb-1">
            <div className="row d-flex box-flex">
              <div className="left">
                <span className="badge bg-warning mb-25">{view?.week === 0 ? '전체' : view?.week + '주차'}</span>{' '}
                {dayjs(view?.limitAt).diff(dayjs(), 'days') < 0 ? (
                  <span className="badge bg-danger mb-25">종료</span>
                ) : (
                  <span className="badge bg-success mb-25">진행중</span>
                )}
                <h4>
                  {view?.title}

                  {!!view?.files?.length && (
                    <>
                      <span style={{ marginLeft: 10, display: 'inline-block' }} onClick={() => handleDownloadZip(view)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-paperclip"
                        >
                          <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                        </svg>
                      </span>
                    </>
                  )}
                </h4>
                <p className="txt-info">
                  <strong>마감일 </strong>
                  {dayjs(view?.limitAt).format('M월 D일 H:mm')}
                  {view?.continueAt && (
                    <>
                      <strong>제출기한 연장 </strong>
                      {dayjs(view?.continueAt).format('M월 D일 H:mm')}까지
                    </>
                  )}
                  <strong>점수</strong> 
                  {view?.showScore ? (
                    <span><strong>공개</strong>{dayjs(view?.showScoreStartAt).format('M월 D일 H:mm')} ~ {dayjs(view?.showScoreEndAt).format('M월 D일 H:mm')}</span>
                  ) : (
                    <span>비공개</span>
                  )}
                </p>
              </div>
              {/* <div className="right row align-items-center justify-content-end">
                미제출
                <h2 className="display-6 text-danger w-auto mb-0">
                  {query?.type === 'single' && (view?.lecture?.accountSize ?? 0) - (data?.totalElements ?? 0)}
                  {query?.type === 'group' && (view?.teams.totalElements ?? 0) - (data?.totalElements ?? 0)}
                </h2>
              </div> */}
              <div className="right2 row align-items-center justify-content-end">
                  <button
                    
                    type="button"
                    className={submittedFlag ? "btn btn-primary waves-effect waves-float waves-light" : "btn btn-outline-primary waves-effect"}
                    onClick={() => setSubmittedFlag(true)}
                  >
                    제출
                    <h2 className="display-8 text-danger w-auto ms-0">
                      {query?.type === 'single' && (data?.totalElements ?? 0)}
                      {query?.type === 'group' && (data?.totalElements ?? 0)}
                    </h2>
                  </button>
              </div>
              <div className="right2 row align-items-center justify-content-end">
                  <button
                    type="button"
                    className={!submittedFlag ? "btn btn-primary waves-effect waves-float waves-light" : "btn btn-outline-primary waves-effect"}
                    onClick={() => setSubmittedFlag(false)}
                  >
                    미제출
                    <h2 className="display-8 text-danger w-auto ms-0">
                      {query?.type === 'single' && (view?.lecture?.accountSize ?? 0) - (data?.totalElements ?? 0)}
                      {query?.type === 'group' && (view?.teams.totalElements ?? 0) - (data?.totalElements ?? 0)}
                    </h2>
                  </button>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              {submittedFlag && 
                <div className="col-md-6 col-12">
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-doc waves-effect"
                    onClick={() => handleDownloadAll()}
                  >
                    과제 일괄 다운로드
                    <DownIco />
                  </button>{' '}
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-doc waves-effect"
                    onClick={handleDownloadXlsx}
                  >
                    채점지 다운로드
                    <DownIco />
                  </button>
                </div>
            }
              {/* Inputs Group with Dropdown */}
              {/* <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setParams((x) => ({
                    ...x,
                    keywordType: e.target.keywordType.value,
                    keyword: e.target.keyword.value,
                  }));
                }}
                className="col-md-6 col-12 ms-auto"
              >
                <fieldset>
                  <div className="input-group">
                    <select className="form-select" name={'keywordType'} defaultValue={params.keywordType}>
                      <option value={''}>전체</option>
                      <option value={'id.account.name'}>이름</option>
                      <option value={'replyPoint'}>점수</option>
                    </select>
                    <input type="text" className="form-control" name={'keyword'} defaultValue={params.keyword} />
                    <button className="btn btn-outline-primary waves-effect" type="submit">
                      검색
                    </button>
                  </div>
                </fieldset>
              </form> */}
              {/* Inputs Group with Dropdown end */}
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '20%' }} />
                  {!submittedFlag && query?.type === 'group' && <col style={{ width: 150 }} />}
                  <col style={{ width: '10%' }} />
                  {submittedFlag && query?.type === 'group' && <col style={{ width: 150 }} />}
                  {submittedFlag && query?.type === 'group' && <col style={{ width: '15%' }} />}
                  <col />
                </colgroup>
                <thead>
                  {submittedFlag ? (
                  <tr>
                  <th>NO</th>
                    <th >{query?.type === 'single' ? "이름/기수": "조장/기수"}</th>
                    <th>점수</th>
                    <th>{query?.type === 'single' ? "제출 과제" : "그룹정보/제출 과제"}</th>
                    <th>제출여부</th>
                  </tr> 
                  ) : (
                    <tr>
                      <th>NO</th>
                        <th >{query?.type === 'single' ? "이름/기수": "조장/기수"}</th>
                        {query?.type === 'group' && <th>{"그룹정보"}</th>}
                        <th>제출여부</th>
                      </tr> 
                  )}
                </thead>
                {submittedFlag ? (
                  <tbody>
                    {data?.content?.map((item, ix) => {

                      

                      const file = {
                        ids: item.files,
                      };

                      let fname = ``;
                      if (query?.type === 'single') {
                        fname = `${item?.account?.idno || item?.account?.userId}_${item?.account?.name}`;
                      } else if (item.team != null) {
                        fname = `${item.team?.title}(${item?.account?.name})_그룹과제`;
                      }

                      const ext = item.files?.length > 1 ? 'zip' : files.find((x) => x.id == item.files?.[0])?.extension;
                      file.filename = fname + '.' + ext;

                      return (
                        <React.Fragment >
                          {query?.type === 'single' && (
                            <>
                              <tr>
                                <td className="txt-c">
                                  {ix + 1 + data?.size * data.number}
                                </td>
                                <td className="txt-c">
                                  <div className="row mx-0">
                                    <p className="text-primary">{item?.account?.name}/{item?.account?.period}기</p>
                                    {item.replyPoint ? (
                                        <button
                                          type="button"
                                          className="btn btn-primary waves-effect waves-float waves-light"
                                          onClick={() => setShowDetails({ ...item })}
                                        >
                                          평가수정
                                        </button> 
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary waves-effect"
                                          onClick={() => setShowDetails({ ...item })}
                                        >
                                          평가하기
                                        </button>
                                      )
                                  }
                                  </div>
                                </td>
                                <td className="txt-c">
                                  {item?.replyPoint || '-'}
                                </td> 
                                <td>
                                  <p className="my-50">
                                    {item.files.length > 0 ? (
                                      <>
                                        <a onClick={() => handleDownload(file)}>{file?.filename}</a>
                                      </>
                                    ) : (
                                      '-'
                                    )}
                                    {item?.isLate && <span className="badge rounded-pill bg-danger ms-1">지각</span>}
                                  </p>
                                </td> 
                                <td >
                                  <p className="txt-info">
                                    <strong>제출</strong>
                                    <div>{dayjs(item?.sendAt).format('YYYY-MM-DD HH:mm')}</div>
                                  </p>
                                </td>
                              </tr>
                            </>
                          )}
                          {query?.type === 'group' && (
                            <>
                              <tr>
                                <td rowSpan={2} className="txt-c">
                                  {ix + 1 + data?.size * data.number}
                                </td>
                                <td rowSpan={2} className="txt-c">
                                  <div className="row mx-0">
                                    <p className="text-primary">{item?.account?.name}/{item?.account.period}기</p>
                                    {item.replyPoint ? (
                                      <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-float waves-light"
                                        onClick={() => setShowDetails({ ...item })}
                                      >
                                        평가수정
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-outline-primary waves-effect"
                                        onClick={() => setShowDetails({ ...item })}
                                      >
                                        평가하기
                                      </button>
                                    )}
                                  </div>
                                </td>
                                <td rowSpan={2} className="txt-c">
                                  {item?.replyPoint || '-'}
                                </td>
                                <td>
                                <p className="txt-info">
                                    <strong>{item.team.title}</strong>
                                  </p>
                                  <br></br>
                                  <p className="txt-info">
                                    <strong>조장</strong>
                                    {item.team?.master?.name}
                                  </p>
                                  <p className="txt-info">
                                    <strong>조원</strong>
                                    {item.team?.names?.join(', ')}
                                  </p>
                                </td>
                                <td rowSpan={2}>
                                  <p className="txt-info">
                                    <strong>제출일시</strong>
                                    <br />
                                    {dayjs(item?.sendAt).format('YYYY-MM-DD HH:mm')}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p className="my-50">
                                    {item.files.length > 0 ? (
                                      <>
                                        <a onClick={() => handleDownload(file)}>{file?.filename}</a>
                                      </>
                                    ) : (
                                      '-'
                                    )}
                                    {item?.isLate && <span className="badge rounded-pill bg-danger ms-1">지각</span>}
                                  </p>
                                </td>
                              </tr>
                            </>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody> ) : (
                    <tbody>
                    {query?.type === 'single' && view?.users.content.map((item, ix) => {
                      

                      for (let index = 0; index < data1?.content.length; index++) {
                        const element = data1?.content[index];
                        if (element.account.id == item.id) {
                          submittedCount++
                          return;
                        }
                      }
                      return (
                        <React.Fragment >
                          <>
                            <tr>
                              <td className="txt-c">
                                {ix+1-submittedCount}
                              </td>
                              <td className="txt">
                                <div className="row mx-0">
                                  <p className="text-primary">{item?.name}/{item?.period}기</p>
                                </div>
                              </td>
                              <td>
                                <p className="txt-info">
                                  <strong>미제출</strong>
                                </p>
                              </td>
                            </tr>
                          </>
                        </React.Fragment>
                      );
                    }
                    )}
                    {query?.type === 'group' && view?.teams.content.map((item, ix) => {
                      

                      for (let index = 0; index < data1?.content.length; index++) {
                        const element = data1?.content[index];
                        if (element.team.id == item.id) {
                          submittedCount++
                          return;
                        }
                      }
                      return (
                        <React.Fragment >
                          <>
                            <tr>
                              <td className="txt-c">
                                {ix+1-submittedCount}
                              </td>
                              <td className="txt">
                                <div className="row mx-0">
                                  <p className="text-primary">{item?.master.name}/{item?.master.period}기</p>
                                </div>
                              </td>
                              <td>
                                  <p className="txt-info">
                                  <strong>{item.title}</strong>
                                  </p>
                                  <br></br>
                                  <p className="txt-info">
                                    <strong>조장</strong>
                                    {item.master?.name}
                                  </p>
                                  <p className="txt-info">
                                    <strong>조원</strong>
                                    {item?.names?.join(', ')}
                                  </p>
                                </td>
                              <td>
                                <p className="txt-info">
                                  <strong>미제출</strong>
                                </p>
                              </td>
                            </tr>
                          </>
                        </React.Fragment>
                      );
                    }
                    )}
                  </tbody>
                  )}
              </table>
            </div>
          </div>
          {/* pagination s */}
          {submittedFlag && 
          <nav aria-label="Page navigation">
            <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
          </nav> }
          {/* // pagination e */}
        </div>

        <Modal className="text-start modal-primary" id="btnModify" tabIndex={-1} visible={!!showDetails}>
          <div className="modal-dialog modal-dialog-centered">
            <form onSubmit={handleSubmit} className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">과제평가</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowDetails(false)} />
              </div>
              <div className="modal-body">
                <div className="row align-items-center mx-0">
                  <span className="avatar avatar2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={14}
                      height={14}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                  </span>
                  <div className="col mt-75">
                    <h3 className="fw-normal">{showDetails?.account?.name}</h3>
                    <p>IMBA {showDetails?.account?.period ?? 0}기</p>
                  </div>
                </div>
                <div className="col-12 mb-md-0 my-2">
                  <div className="box-gray">
                    <dl className="txt-info">
                      <dt>내용</dt>
                      <dd>{showDetails?.contents}</dd>
                    </dl>
                    <dl className="txt-info">
                      <dt>등록일</dt>
                      <dd>{dayjs(showDetails?.createdAt).format('YYYY-MM-DD HH:mm')}</dd>
                    </dl>
                    {showDetailsFiles.length > 0 && (
                      <dl className="txt-info">
                        <dt>파일명</dt>
                        <dd className="file">
                          {showDetailsFiles?.map((showDetailsFile) => (
                            <p key={`${showDetailsFile?.id}`}>
                              <a href={downloadLink(showDetailsFile)} target={'_blank'} rel="noreferrer">
                                {showDetails?.isLate && '지각!_'}
                                {showDetailsFile?.filename}
                              </a>{' '}
                              <span>({formatBytes(showDetailsFile?.size)})</span>
                            </p>
                          ))}
                        </dd>
                      </dl>
                    )}
                  </div>
                </div>
                <div className="col-12 mb-md-0 my-2">
                  <textarea
                    className="form-control my-75"
                    rows={3}
                    placeholder=""
                    value={showDetails?.replyContents ?? ''}
                    onChange={(e) => {
                      const v = e.target.value;
                      setShowDetails((x) => ({ ...x, replyContents: v }));
                    }}
                  />
                  <div className="row mt-1 mx-0 align-items-center justify-content-center">
                    <label className="form-label w-auto">과제평가</label>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: 100 }}
                      value={showDetails?.replyPoint ?? ''}
                      onChange={(e) => {
                        const v = e.target.value;
                        setShowDetails((x) => ({ ...x, replyPoint: v }));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary waves-effect waves-float waves-light">확인</button>
                <button
                  type="button"
                  className="btn btn-outline-primary waves-effect"
                  onClick={() => setShowDetails(undefined)}
                >
                  취소
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  );
}
