import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import LecturesAttendancesIndex from './lms/lectures/attendance';
import { shallowEqual, useSelector } from 'react-redux';
import DashboardAdmin from '../components/dashboards/admin';
import LmsContentsIndex from './lms/contents';
import { restApi } from '../common/api';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import queryString from 'query-string';




const TYPE_TO_LABEL = {
  contacts: '문의게시판',
};

const TYPE_TO_LINK = {
  contacts: '/lms/messages/contacts',
};

const HomeIndex = (props) => {
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);
  const [params, setParams] = useState(queryString.parse(window.location.search));
  
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const { lectureId: originLecId } = useSelector((x) => x.common, shallowEqual);
  // /api/lectures/{id}/dashboard

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/lectures/${originLecId}/dashboard`);
    setData(data);
  }, [originLecId]);

  const [entData, setEntData] = useState();
  const loadEntData = useCallback(async () => {
    if (lectureId == null) {
      return
    }
    // console.log(lectureId)
    const { data } = await restApi.get('/lms/messages', {
      params: {
        ...params,
        lectureId,
        type: 'contacts',
      },
    });
    // console.log(data)
    setEntData(data);
  }, [params, lectureId]);

  useEffect(() => {
    Promise.all([loadData(), loadEntData()]).catch(console.warn);
  }, [loadData, loadEntData]);

  if (['ADMIN', 'MANAGER'].includes(principal?.type)) {
    return <DashboardAdmin />;
  }
  if (['CP'].includes(principal?.type)) {
    return <LmsContentsIndex />;
  }

  
  

  return (
    <div>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <div className="content-wrapper container-xxl p-0">
        <div className="content-body">
          {/* Dashboard Analytics Start */}
          <section className="row align-items-start">
            <div className="row match-height col-sm-6">
              <h4>1. 진도율</h4>
              <div className="d-flex align-items-stretch">
                {/* Subscribers Chart Card starts */}
                <div className="card d-left2">
                  <div className="card-header align-items-center">
                    <div className="avatar bg-light-primary p-50 m-0">
                      <div className="avatar-content">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={14}
                          height={14}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-book"
                        >
                          <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20" />
                          <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z" />
                        </svg>
                      </div>
                    </div>
                    <div className="col">
                      <h2 className="fw-bolder">
                        <span className="txt-h-g">평균</span>
                        <span className="text-primary">{`${data?.learnRate ?? 0}%`}</span>
                      </h2>
                    </div>
                  </div>
                </div>
                {/* Subscribers Chart Card ends */}
                {/* Orders Chart Card starts */}
                <div className="card col justify-content-center">
                  <div className="card-header flex-column align-items-start">
                    <div className="card-text txt-d mb-1">
                      <strong>최고</strong>
                      <span className="txt-s">{data?.learnMaxName}</span>
                      <span className="txt-p">{`${data?.learnMaxRate ?? 0}%`}</span>
                    </div>
                    <div className="card-text txt-d">
                      <strong>최저</strong>
                      <span className="txt-s">{data?.learnMinName}</span>
                      <span className="txt-p">{`${data?.learnMinRate ?? 0}%`}</span>
                    </div>
                  </div>
                </div>
                {/* Orders Chart Card ends */}
              </div>
            </div>
            <div className="row match-height col-sm-6">
              <h4>2. 출석률</h4>
              <div className="d-flex align-items-stretch">
                {/* Subscribers Chart Card starts */}
                <div className="card d-left2">
                  <div className="card-header align-items-center flex-wrap">
                    <div className="avatar bg-light-primary p-50 m-0">
                      <div className="avatar-content">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={14}
                          height={14}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-clock"
                        >
                          <circle cx={12} cy={12} r={10} />
                          <polyline points="12 6 12 12 16 14" />
                        </svg>
                      </div>
                    </div>
                    <div className="col">
                      <h2 className="fw-bolder">
                        <span className="txt-h-tit">오프라인 강의</span>
                        <span className="txt-h-g">평균</span>
                        <span className="text-primary">{`${data?.offlineRate ?? 0}%`}</span>
                      </h2>
                    </div>
                  </div>
                </div>
                {/* Subscribers Chart Card ends */}
                {/* Subscribers Chart Card starts */}
                <div className="card d-left2 me-0">
                  <div className="card-header align-items-center flex-wrap">
                    <div className="avatar bg-light-primary p-50 m-0">
                      <div className="avatar-content">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={14}
                          height={14}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-clock"
                        >
                          <circle cx={12} cy={12} r={10} />
                          <polyline points="12 6 12 12 16 14" />
                        </svg>
                      </div>
                    </div>
                    <div className="col">
                      <h2 className="fw-bolder">
                        <span className="txt-h-tit">화상Q&amp;A</span>
                        <span className="txt-h-g">평균</span>
                        <span className="text-primary">{`${data?.qnaRate ?? 0}%`}</span>
                      </h2>
                    </div>
                  </div>
                </div>
                {/* Subscribers Chart Card ends */}
              </div>
            </div>
            <div className="row match-height col-sm-6">
              <h4>
                3. 과제제출
                <span className="text-muted ms-25" style={{ fontSize: 14 }}>
                  {moment(data?.homeworkDate).format('YYYY-MM-DD')}
                </span>
              </h4>
              {data?.homework ? (
                <div className="d-flex align-items-stretch">
                  {/* Subscribers Chart Card starts */}
                  <div className="card d-left2">
                    <div className="card-header align-items-center">
                      <div className="avatar bg-light-primary p-50 m-0">
                        <div className="avatar-content">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={14}
                            height={14}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-users"
                          >
                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                            <circle cx={9} cy={7} r={4} />
                            <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          </svg>
                        </div>
                      </div>
                      <div className="col">
                        <h2 className="fw-bolder">
                          <span className="txt-h-g">제출학생</span>
                          <span className="text-primary">{data?.homeworkUserSuccess}명</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* Subscribers Chart Card ends */}
                  {/* Orders Chart Card starts */}
                  <div className="card d-left2 justify-content-center me-0">
                    <div className="card-header align-items-center">
                      <div className="avatar bg-light-primary p-50 m-0">
                        <div className="avatar-content">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={14}
                            height={14}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-users"
                          >
                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                            <circle cx={9} cy={7} r={4} />
                            <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          </svg>
                        </div>
                      </div>
                      <div className="col">
                        <h2 className="fw-bolder">
                          <span className="txt-h-g">미제출학생</span>
                          <span className="text-primary">{data?.homeworkUserFailure}명</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* Orders Chart Card ends */}
                </div>
              ) : (
                <p className="p-2">등록된 과제가 없습니다.</p>
              )}
            </div>
            <div className="row match-height col-sm-6">
              <h4>
                4. 시험
                <span className="text-muted ms-25" style={{ fontSize: 14 }}>
                  {data?.examTitle}
                </span>
              </h4>
              {data?.exam ? (
                <div className="d-flex align-items-stretch">
                  {/* Subscribers Chart Card starts */}
                  <div className="card d-left2">
                    <div className="card-header col align-items-center flex-wrap">
                      <div className="avatar bg-light-primary p-50 m-0">
                        <div className="avatar-content">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={14}
                            height={14}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-edit-3"
                          >
                            <path d="M12 20h9" />
                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
                          </svg>
                        </div>
                      </div>
                      <div className="col">
                        <h2 className="fw-bolder">
                          <span className="txt-h-g">응시학생</span>
                          <span className="text-primary">{data?.examUserSuccess}명</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* Subscribers Chart Card ends */}
                  {/* Subscribers Chart Card starts */}
                  <div className="card d-left2 me-0">
                    <div className="card-header align-items-center flex-wrap">
                      <div className="avatar bg-light-primary p-50 m-0">
                        <div className="avatar-content">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={14}
                            height={14}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-edit-3"
                          >
                            <path d="M12 20h9" />
                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
                          </svg>
                        </div>
                      </div>
                      <div className="col">
                        <h2 className="fw-bolder">
                          <span className="txt-h-g">미응시학생</span>
                          <span className="text-primary">{data?.examUserFailure}명</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* Subscribers Chart Card ends */}
                </div>
              ) : (
                <p className="p-2">등록된 시험이 없습니다.</p>
              )}
            </div>
            <div className="col-md-12">
            <h4>
              5. 문의게시판
              <Link to={TYPE_TO_LINK['contacts']} className="text-danger">
                {' '}
                (신규 문의 : {entData?.content.filter((item) => item.isNew).length}건 / 답변완료 :{' '}
                {entData?.content.filter((item) => item.hasReply).length}건)
              </Link>
              {/* <a href="#n" className="text-danger">

              </a> */}
            </h4>
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box t-padding">
                  <colgroup>
                    <col />
                    <col style={{ width: '7rem' }} />
                    <col style={{ width: '9rem' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>제목</th>
                      <th>처리상태</th>
                      <th>등록일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entData?.content.slice(0,5).map((item, index) => (
                        <tr key={`ent-${item.id}`}>
                        <td className="txt-100">
                          <Link to={`/lms/messages/contacts/${item.id}`}>{item.title}</Link>
                        </td>
                        <td className="txt-c">{item.hasReply ? '답변완료' : '미답변'}</td>
                        <td className="txt-c">{dayjs(item.createdAt).format('YYYY-MM-DD')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </section>
          {/* Dashboard Analytics end */}
        </div>
      </div>
    </div>
  );
};

export default HomeIndex;
