import React from 'react';

const RequireLecture = ({ lectureId, children, extra }) => (
  <>
    {!lectureId ? (
      <div
        style={{
          background: '#fff',
          padding: '100px 0px',
          border: '1px solid #ddd',
          textAlign: 'center',
          marginBottom: 50,
        }}
      >
        <p>학기 / 과목을 선택해주세요.</p>
        {extra}
      </div>
    ) : (
      children
    )}
  </>
);

export default RequireLecture;
