import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import { editorInitOptions } from '../../../../common/utils';
import FileAttacher from '../../../../components/FileAttacher';
import { useLoading } from '../../../../nav/AppContainer';

export default function SiteBoardResearchWrite() {
  const navigate = useNavigate();
  const query = useParams();
  const editorRef = React.useRef();
  const attacher = useRef();
  const [initData, setInitData] = useState();

  const [crews, setCrews] = useState([]);

  useEffect(() => {
    const loadCrew = async () => {
      const { data } = await restApi.get(`/crews`, { params: { type: 'research' } });
      setCrews(data.content);
    };

    loadCrew().catch(console.warn);

    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/web/messages/${query.id}`);
        setInitData(data);
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);
  const [loading, setLoading] = useLoading();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...initData,
      contentsWithMarkup: editorRef.current.getContent(),
      title: e.target.title.value,
      type: 'RESEARCH',
      crewId: e.target.crewId.value,
    };

    if (!payload.crewId) {
      alert('동호회를 입력해주세요.');
      return false;
    }

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return false;
    }

    if (!payload.contentsWithMarkup) {
      alert('내용을 입력해주세요.');
      return false;
    }

    setLoading(true);
    try {
      const ids = await attacher.current.getFileIds();
      payload.files = ids;

      if (query?.id) {
        await restApi.put(`/web/messages/${query?.id}`, payload);
        alert('저장되었습니다.');
        await navigate(`/site/board/research/${query?.id}`);
      } else {
        await restApi.post(`/web/messages`, payload);
        alert('등록되었습니다.');
        await navigate('/site/board/research');
      }
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">연구회 공지사항</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#n">게시판 관리</Link>
                  </li>
                  <li className="breadcrumb-item active">연구회 공지사항</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        동호회<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="flex">
                        <select
                          key={String(initData?.crew?.id)}
                          name={'crewId'}
                          className={'form-select w-auto'}
                          defaultValue={String(initData?.crew?.id)}
                        >
                          <option value={''}>전체</option>
                          {crews?.map((item) => (
                            <option value={String(item.id)} key={`${item.id}`}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        제목<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="flex">
                        <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control flex-grow-1 w-auto"
                          name={'title'}
                          defaultValue={initData?.title}
                        />
                        <span className="txt-limit">0/50</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        내용<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <Editor
                        initialValue={initData?.contentsWithMarkup}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={editorInitOptions}
                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일
                      </label>
                    </th>
                    <td>
                      <FileAttacher ref={attacher} values={initData?.files} limitSize={10} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="row" id="table-bordered">
            <div className="col-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row mt-50">
            <div className="col-md-6"></div>
            {!loading && (
              <div className="col-md-6 txt-r">
                <button
                  type="submit"
                  className="btn btn-primary me-1 waves-effect waves-float waves-light"
                  data-bs-toggle="modal"
                  data-bs-target="#btnSave"
                >
                  저장
                </button>
                <button
                  type="reset"
                  className="btn btn-outline-secondary waves-effect"
                  onClick={() => {
                    navigate('/site/board/research');
                  }}
                >
                  취소
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* // con e */}
    </form>
  );
}
