import dayjs from 'dayjs';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { restApi } from '../common/api';
import Modal from './Modal';

function NoticeOrderModal({ visible, onExit, fixedData, type }) {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const [data, setData] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [deletedItems, setDeletedItems] = useState([]);

  useEffect(() => {
    setData(
      fixedData
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map((item, index) => ({
          ...item,
          orderIndex: index + 1,
        })),
    );
  }, [fixedData]);

  const handleExit = () => {
    setIsEditMode(false);
    setData(fixedData);
    setDeletedItems([]);
    onExit();
  };

  async function handleSubmit() {
    // console.log(data);

    const payload = {
      items: data
        ?.map((row) => ({
          id: row.id,
          isImportant: true,
          startAt: row.startAt,
          endAt: row.endAt,
        }))
        .concat(
          deletedItems?.map((row) => ({
            id: row.id,
            isImportant: false,
            startAt: row.startAt,
            endAt: row.endAt,
          })),
        ),
      ids: data?.filter((x) => !x.disabled)?.map((x) => x.id),
    };

    await restApi.put(`/${type}/messages`, payload);
    alert('저장되었습니다.');
    handleExit();
  }

  return (
    <Modal className="fade text-start modal-primary" visible={visible} id="noticeorder">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: 1200, width: '90%' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">공지 관리</h5>
            <button type="button" className="btn-close" onClick={handleExit} />
          </div>
          <div className="modal-body">
            <div className="card">
              <div className="table-responsive" style={{ maxHeight: 700, overflowY: 'scroll' }}>
                <table className="table table-bordered visual-box">
                  <thead>
                    <tr>
                      <th>No</th>
                      {type === 'web' && <th>구분</th>}
                      <th>제목</th>
                      <th>작성일</th>
                      <th colSpan={2}>순서</th>
                      <th>노출기간</th>
                      <th>중요공지 해제</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!data || data?.length === 0 ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          데이터가 없습니다.
                        </td>
                      </tr>
                    ) : (
                      data?.map((item, i) => {
                        return (
                          <tr key={`popup-${item.id}`}>
                            <td className="txt-c">{item.id}</td>
                            {type === 'web' && <td className="txt-c">{codes?.map[item.category]}</td>}
                            <td className="txt-c">{item.title}</td>
                            <td className="txt-c">{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                            <td className="txt-c">{i + 1}</td>
                            <td className="txt-c">
                              <button
                                className="btn"
                                style={{ padding: '0.1rem' }}
                                disabled={i === 0 || !isEditMode}
                                onClick={() => {
                                  if (i === 0) {
                                    return;
                                  }
                                  setData(
                                    produce(data, (draft) => {
                                      const temp = draft[i].orderIndex;
                                      draft[i].orderIndex = draft[i - 1].orderIndex;
                                      draft[i - 1].orderIndex = temp;
                                      draft.sort((a, b) => a.orderIndex - b.orderIndex);
                                    }),
                                  );
                                }}
                              >
                                ▲
                              </button>
                              <button
                                className="btn"
                                style={{ padding: '0.1rem' }}
                                disabled={i === data.length - 1 || !isEditMode}
                                onClick={() => {
                                  if (i === data.length - 1) {
                                    return;
                                  }
                                  setData(
                                    produce(data, (draft) => {
                                      const temp = draft[i].orderIndex;
                                      draft[i].orderIndex = draft[i + 1].orderIndex;
                                      draft[i + 1].orderIndex = temp;
                                      draft.sort((a, b) => a.orderIndex - b.orderIndex);
                                    }),
                                  );
                                }}
                              >
                                ▼
                              </button>
                            </td>
                            <td className="txt-c">
                              <div className="d-flex align-items-center justify-content-center gap-50">
                                <input
                                  type="date"
                                  className="form-control flatpickr-basic flatpickr-input "
                                  style={{ width: 134 }}
                                  readOnly={!isEditMode}
                                  defaultValue={item.startAt}
                                  onChange={(e) => {
                                    setData(
                                      produce(data, (draft) => {
                                        draft[i].startAt = e.target.value;
                                      }),
                                    );
                                  }}
                                />
                                {' ~ '}
                                <input
                                  type="date"
                                  className="form-control flatpickr-basic flatpickr-input "
                                  style={{ width: 134 }}
                                  readOnly={!isEditMode}
                                  defaultValue={item.endAt}
                                  onChange={(e) => {
                                    setData(
                                      produce(data, (draft) => {
                                        draft[i].endAt = e.target.value;
                                      }),
                                    );
                                  }}
                                />
                              </div>
                            </td>
                            <td className="txt-c">
                              <button
                                className="btn btn-outline-primary"
                                disabled={!isEditMode}
                                onClick={async () => {
                                  if (window.confirm('해제하시겠습니까?')) {
                                    setDeletedItems((x) => x.concat([item]));
                                    setData(
                                      produce(data, (draft) => {
                                        draft.splice(i, 1);
                                      }),
                                    );
                                  }
                                }}
                              >
                                해제
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <p className="mt-1">
              ※ 공지 관리를 통해 게시판의 최상단에 중요 공지를 지정할 수 있습니다.
              <br />
              {type === 'web'
                ? '※ 추가 노출을 통해 강의실에도 중요공지를 노출한 경우, 동일하게 중요공지를 해제할 수 있습니다.'
                : '※ 홈페이지 > 공지사항> 추가 노출을 통해 불러온 중요공지의 해제는 원글에서 가능합니다.'}
            </p>
            <div className="col-12 mt-2 mb-1 txt-c">
              {isEditMode ? (
                <button
                  type="submit"
                  className="btn btn-primary me-1 waves-effect waves-float waves-light"
                  onClick={handleSubmit}
                >
                  저장
                </button>
              ) : (
                <button
                  className="btn btn-primary me-1 waves-effect waves-float waves-light"
                  onClick={() => setIsEditMode(true)}
                >
                  수정
                </button>
              )}
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  handleExit();
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NoticeOrderModal;
