import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../../common/api';
import BoardAttachList from '../../../../../components/boards/BoardAttachList';

export default function SiteBoardApplicationIdIndex() {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const query = useParams();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/admission-guides/${query.id}`);
    setData(data);
  }, []);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/admission-guides/${query.id}`);
    alert('삭제되었습니다.');
    window.history.back();
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">모집요강</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#n">입학관리</Link>
                  </li>
                  <li className="breadcrumb-item active">모집요강</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link
            className="nav-link active"
            id="tab1"
            data-bs-toggle="page"
            to="./guide.html"
            aria-controls="tab1"
            role="tab"
            aria-selected="true"
          >
            모집요강
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            id="tab2"
            data-bs-toggle="page"
            to="./interview.html"
            aria-controls="tab2"
            role="tab"
            aria-selected="false"
          >
            면접안내
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            id="tab3"
            data-bs-toggle="page"
            to="./faq.html"
            aria-controls="tab3"
            role="tab"
            aria-selected="false"
          >
            입학FAQ
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            id="tab4"
            data-bs-toggle="page"
            to="./entranceQna.html"
            aria-controls="tab4"
            role="tab"
            aria-selected="false"
          >
            입학Q&amp;A
          </Link>
        </li>
      </ul> */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">작성자</label>
                    </th>
                    <td>{data?.createdBy?.name}</td>
                    <th>
                      <label className="form-label">작성일시</label>
                    </th>
                    <td>{dayjs(data?.createdAt).format('YYYY-MM-DD')}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        제목<span className="require">*</span>
                      </label>
                    </th>
                    <td colSpan={3}>2022학년도 후기 IMBA 신입학 면접 안내(6/11)_6/8</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        모집요강 파일<span className="require">*</span>
                      </label>
                    </th>
                    <td colSpan={3}>
                      <p className="my-50">
                        <BoardAttachList ids={data?.file} />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        입학설명회 링크<span className="require">*</span>
                      </label>
                    </th>
                    <td colSpan={3}>
                      <Link to={`${data?.applyLink}`}>{data?.applyLink}</Link>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        유의사항<span className="require">*</span>
                      </label>
                    </th>

                    <td colSpan={3}>
                      {/* <ul style={{ listStyle: 'none' }}> */}
                      {data?.guides.map((item, index) => {
                        return (
                          // <li key={index}>
                          <>
                            <span className="f-notokr-500"></span> <p>{item}</p>
                          </>
                          // </li>
                        );
                      })}
                      {/* </ul> */}
                      {/* 가. 입학원서는 100% 인터넷으로 접수합니다. <br />
                      나. 성적은 전 학년 평점평균을 소수점 둘째자리까지 기재함(예: 3.75/4.50만점)
                      <br />
                      다. 전형료 및 제출서류가 미비된 원서는 접수하지 않을 수 있으며, 제출서류나 전형료는 반환하지 않음
                      <br />
                      라. 제출서류 미비로 인한 불이익은 지원자 본인이 감수해야 하며, 원서 기재사항 및 제출서류가 허위로
                      확인되거나, <br />
                      학기 개시일 (2023. 2. 20)이전까지 학위를 취득하지 못한 경우 재학 중에도 합격과 입학을
                      취소함(등록금 반환 불가)
                      <br />
                      마. 현역 군인은 2023. 2. 20 (월) 이전 전역예정자로서 소속 부대장이 발행한 ‘전역예정증명서’를
                      첨부하거나, <br />
                      소속 부대장의 ‘취학승인서’를 제출해야 함<br />
                      바. 등록 후 불가피한 사정으로 등록을 포기하고자 하는 경우에는, 합격포기 및 등록금 환불 신청을
                      2023. 2. 16 (목) 16시 까지 신청을 완료하여야 합니다. <br />
                      (신분증, 통장사본 지참 후 경영대학행정실 방문 신청)
                      <br />
                      ※ 학기 개시일 이후 신청 시 자퇴처리 되며, 환불금액은 본교의 규정에 따라 산정됩니다.
                      <br />
                      (등록금 납부금액 중 입학금과 등록금 일부 제외). 사. 외국 소재 대학(원) 출신자의 경우 대학(원)이
                      해당 국가의 공인을 받은 경우에만 본교에 지원이 가능함
                      <br />
                      (입학/졸업 후라도 해당 내용에 문제가 있을 경우, 본교의 입학 등 모든 사항은 취소함).
                      <br />
                      아. 외국 소재 대학(원) 출신 지원자는 입학 후에라도 본교에서 학력조회에 관한 소명을 요청받을 경우
                      적극 협조해야 합니다.
                      <br />
                      (협조하지 않을 경우 학력에 문제가 있는 것으로 간주하여 입학 후에도 합격과 입학을 취소할 수 있음)
                      <br />
                      *자세한 사항은 상단의 모집요강을 다운로드 하셔서 확인하시기 바랍니다. */}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        원서접수 기간<span className="require">*</span>
                      </label>
                    </th>
                    <td colSpan={3}>{data?.time}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        원서접수 링크<span className="require">*</span>
                      </label>
                    </th>
                    <td colSpan={3}>
                      <Link to={`${data?.link}`}>{data?.link}</Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button type="button" className="btn btn-outline-danger waves-effect me-1" onClick={handleRemove}>
              글 삭제
            </button>
            <button
              type="submit"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={() => {
                navigate(`/site/board/application/${query.id}/edit`);
              }}
            >
              수정
            </button>
            <button
              type="reset"
              className="btn btn-outline-primary waves-effect"
              onClick={() => {
                navigate('/site/board/application');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>
      {/* // con e */}
    </div>
  );
}
