import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { restApi } from '../../../../common/api';
import { setLectureId } from '../../../../data/common';
import { Editor } from '@tinymce/tinymce-react';
import { editorInitOptions } from '../../../../common/utils';
import LectureSearchItem from '../../../../components/LectureSearchItem';
import dayjs from 'dayjs';
import UserSelectForExam from '../../../../components/UserSelectForExam';
import { all } from 'axios';

const numReg = /^[0-9]+$/;

const timeReg = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;

function isValidTime(time) {
  if (!timeReg.test(time)) {
    return false;
  }
  const [hours, minutes] = time.split(':');
  if (!numReg.test(hours) || !numReg.test(minutes)) {
    return false;
  }
  const parsedHours = parseInt(hours, 10);
  const parsedMinutes = parseInt(minutes, 10);
  if (parsedHours < 0 || parsedHours > 23 || parsedMinutes < 0 || parsedMinutes > 59) {
    return false;
  }
  return true;
}

export default function LmsExamsItemsWrite() {
  const navigate = useNavigate();
  const editorRef = React.useRef();

  const { lectureId: originLecId } = useSelector((x) => x.common, shallowEqual);
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const [allUsers, setAllUsers] = useState();

  const query = useParams();

  const { state } = useLocation();

  const dispatch = useDispatch();
  const now = dayjs();

  const [lectureId, setLecId] = useState(originLecId);
  const [formData, setFormData] = useState({
    // type: undefined,
    type: 'EXAM',
    session: undefined,
    title: undefined,
    contents: undefined,
    examStyle: undefined,
    active: true,
    startAt: undefined,
    endAt: undefined,
    suffle: false,
    timeAttack: undefined,
    oneQuestion: false,
    showAnswer: false,
    showAnswerStartAt: undefined,
    showAnswerEndAt: undefined,
    startTime: now.format('HH:mm'),
    endTime: now.add(1, 'hour').format('HH:mm'),
    startDate: now.add(1, 'day').format('YYYY-MM-DD'),
    endDate: now.add(1, 'day').format('YYYY-MM-DD'),
    showAnswerStartTime: '00:00',
    showAnswerEndTime: '00:00',
    isMixQuestion: false,
    examAccountIds: [],
    offlineAccountIds: [],
    isShowExamResult : false,
    showExamResultStartAt : undefined,
    showExamResultEndAt : undefined,
    showExamResultStartTime : now.add(1, 'hour').format('HH:mm'),
    showExamResultEndTime : now.add(2, 'hour').format('HH:mm'),
    showExamResultStartDate : now.add(1, 'day').format('YYYY-MM-DD'),
    showExamResultEndDate : now.add(1, 'day').format('YYYY-MM-DD'),
    //new 20240620
    isOffline : false,
    showExamResultOnlyPoint : true,
    offlinePlace : "",
  });

  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/lectures/0/exams/${query.id}`);

        setLecId(data?.lecture?.id);
        setFormData((x) => ({
          ...x,
          title: data?.title,
          type: data?.type,
          session: data?.session,
          contents: data?.contents,
          startAt: data?.startAt,
          endAt: data?.endAt,
          items: data?.items,
          examStyle: data?.examStyle,
          active: data?.active,
          suffle: data?.suffle,
          startDate: data?.startAt.split('T')[0],
          startTime: data?.startAt.split('T')[1]?.substring(0, 5),
          endDate: data?.endAt.split('T')[0],
          endTime: data?.endAt.split('T')[1]?.substring(0, 5),
          timeAttack: data?.timeAttack,
          showAnswer: data?.showAnswer,
          oneQuestion: data?.oneQuestion,
          showAnswerStartDate: !data?.showAnswerStartAt
            ? undefined
            : dayjs(data?.showAnswerStartAt).format('YYYY-MM-DD'),
          showAnswerStartTime: !data?.showAnswerStartAt ? '00:00' : dayjs(data?.showAnswerStartAt).format('HH:mm'),
          showAnswerEndDate: !data?.showAnswerEndAt ? undefined : dayjs(data?.showAnswerEndAt).format('YYYY-MM-DD'),
          showAnswerEndTime: !data?.showAnswerEndAt ? '00:00' : dayjs(data?.showAnswerEndAt).format('HH:mm'),
          isMixQuestion: data?.isMixQuestion,
          examAccountIds: data?.examAccountIds,
          offlineAccountIds: data?.offlineAccountIds,
          isShowExamResult: data?.isShowExamResult,
          showExamResultStartAt: data?.showExamResultStartAt,
          showExamResultEndAt: data?.showExamResultEndAt,
          showExamResultStartDate: data?.showExamResultStartAt ? data?.showExamResultStartAt.split('T')[0] : now.add(1, 'day').format('YYYY-MM-DD'),
          showExamResultEndDate: data?.showExamResultEndAt ?  data?.showExamResultEndAt.split('T')[0] : now.add(1, 'day').format('YYYY-MM-DD'),
          showExamResultStartTime: data?.showExamResultStartAt ?  data?.showExamResultStartAt.split('T')[1]?.substring(0, 5) : now.format('HH:mm'),
          showExamResultEndTime: data?.showExamResultEndAt ?  data?.showExamResultEndAt.split('T')[1]?.substring(0, 5) : now.add(1, 'hour').format('HH:mm'),
          //new 20240620
          isOffline: data?.isOffline,
          showExamResultOnlyPoint: data?.showExamResultOnlyPoint,
          offlinePlace: data?.offlinePlace
        }));
      };
      loadData().catch(console.warn);
    }
  }, [lectureId, query?.id]);

  const offliceClicked = async (e) => {

    if (!formData.isOffline) {
      console.log("offline checked")
      const { data } = await restApi.get(`/lectures/${lectureId}/exams/accounts`, {
        params: {
          limit: 99999,
          // isForeign: !formData.isOffline && formData.examStyle === undefined ? false : formData.examStyle === undefined ? false : true,
          isForeign: false,
        },
      });
      const offlineIds = data.content.filter((data) => (data.isForeign == false || data.isForeign == null) && !formData.examAccountIds.includes(data.id)).map((item) => item.id);
      setFormData((x) => ({ ...x,offlineAccountIds: offlineIds }));
    }
    else {
      console.log("offline unchecked")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.type) {
      alert('유형을 입력해주세요.');
      return false;
    }

    if (formData.type == 'EXAM' && !formData.session) {
      alert('유형을 입력해주세요.');
      return false;
    }

    if (!formData.title) {
      alert('제목을 입력해주세요.');
      return false;
    }

    const contents = editorRef.current.getContent();
    if (!contents) {
      alert('내용을 입력해주세요.');
      return false;
    }

    //new 20240620 - 오프라인 방식 체크도 추가해야함
    if (formData.type == 'EXAM' && !formData.examStyle && !formData.isOffline) {
      alert('시험 방식을 1개 이상 선택해주세요.');
      return false;
    }

    if (!formData.startDate) {
      alert('기간을 입력해주세요.');
      return false;
    }

    if (!isValidTime(formData.startTime) || !isValidTime(formData.endTime)) {
      alert('시간을 정확히 입력해주세요. ex) 00:00');
      return false;
    }

    if (formData.type == 'QUIZ' && !formData.endDate) {
      alert('기간을 입력해주세요1.');
      return false;
    }

    if (formData.showAnswer && (!formData.showAnswerStartDate || !formData.showAnswerEndDate)) {
      alert('정답공개 기간을 입력해주세요.');
      return false;
    }

    if (
      formData.showAnswer &&
      (!isValidTime(formData.showAnswerStartTime) || !isValidTime(formData.showAnswerEndTime))
    ) {
      alert('정답 공개 시간을 정확히 입력해주세요. ex) 00:00');
      return false;
    }

    if (formData.timeAttack >= 0) {
      if (numReg.test(formData.timeAttack)) {
        formData.timeAttack = Number(formData.timeAttack);
      } else {
        alert('시간제한은 정수 숫자로 입력해주세요');
        return false;
      }
    }
    if (formData.type == 'QUIZ' && (formData.timeAttack <= 0 || !formData.timeAttack)) {
      alert('제한시간을 입력해주세요.');
      return false;
    }

    if (formData.examStyle == "DOWNLOAD" && formData.isShowExamResult && (!formData.showExamResultStartDate || !formData.showExamResultEndDate)) {
      alert('점수 공개 기간을 입력해주세요.');
      return false;
    }

    if (formData.examStyle == "DOWNLOAD" && formData.isShowExamResult && (!isValidTime(formData.showExamResultStartTime) || !isValidTime(formData.showExamResultEndTime))) {
      alert('점수 공개 시간을 정확히 입력해주세요. ex) 00:00');
      return false;
    }
    if (formData.isOffline && (formData.offlinePlace == undefined || formData.offlinePlace == '')) {
      alert('오프라인 시험 장소를 입력해 주시기 바랍니다.');
      return false;
    }

    if (formData.examAccountIds.length < 1 && formData.offlineAccountIds.length < 1) {
      alert('온라인 및 다운로드 시험유형 선택 시 응시자 1명 이상 선택해 해주시 바랍니다.');
      return false;
    }

    const payload = {
      ...formData,
      session: formData.type === 'EXAM' ? formData.session : undefined,
      contents: contents,
      examStyle: formData.type === 'EXAM' ? formData.examStyle : 'ONLINE',
      startAt: `${formData?.startDate}T${formData?.startTime}:00.000Z`,
      endAt: `${formData?.endDate}T${formData?.endTime}:00.000Z`,
      showAnswerStartAt: formData.showAnswer
        ? `${formData?.showAnswerStartDate}T${formData?.showAnswerStartTime}:00.000Z`
        : '',
      showAnswerEndAt: formData.showAnswer
        ? `${formData?.showAnswerEndDate}T${formData?.showAnswerEndTime}:00.000Z`
        : '',
      showExamResultStartAt: formData.isShowExamResult ? `${formData?.showExamResultStartDate}T${formData?.showExamResultStartTime}:00.000Z` : '',
      showExamResultEndAt: formData.isShowExamResult ? `${formData?.showExamResultEndDate}T${formData?.showExamResultEndTime}:00.000Z` : '', 
      isOffline: formData.isOffline,
      showExamResultOnlyPoint: formData.showExamResultOnlyPoint,
      offlinePlace: formData.offlinePlace
    };

    if (dayjs(payload.endAt).isBefore(payload.startAt)) {
      alert('시험 시작기간이 종료기간보다 빠릅니다.');
      return;
    }

    if (
      (formData.showAnswer && ((dayjs(payload.showAnswerStartAt).isBefore(payload.endAt)) ||
      dayjs(payload.showAnswerStartAt).isSame(payload.endAt)))
    ) {
      alert('정답 공개는 시험기간 이후에만 가능합니다.');
      return;
    }

    if (formData.showAnswer && dayjs(payload.showAnswerEndAt).isBefore(payload.showAnswerStartAt)) {
      alert('정답 공개 시작기간이 종료기간보다 빠릅니다.');
      return;
    }

    if (
      formData.examStyle === 'DOWNLOAD' && (formData.isShowExamResult && (dayjs(payload.showExamResultStartAt).isBefore(payload.endAt) ||
      dayjs(payload.showExamResultStartAt).isSame(payload.endAt)))
    ) {
      alert('점수 공개는 시험기간 이후에만 가능합니다.');
      return;
    }

    if (formData.examStyle === 'DOWNLOAD' && formData.isShowExamResult && dayjs(payload.showExamResultEndAt).isBefore(payload.showExamResultStartAt)) {
      alert('점수 공개 시작기간이 종료기간보다 빠릅니다.');
      return;
    }
    // console.log(payload)

    dispatch(setLectureId(lectureId));
    if (query?.id) {
      await restApi.put(`/lectures/${lectureId}/exams/${query?.id}`, payload);
      alert('저장되었습니다.');
      await navigate(`/lms/exams/items/${query?.id}`);
    } else {
      const res = await restApi.post(`/lectures/${lectureId}/exams`, payload);
      alert('등록되었습니다.');
      await navigate(`/lms/exams/items/${res.data.id}/paper`);
    }
  };

  useEffect(() => {
    if (lectureId && !state.flag) {
      //신규 등록 시
      const loadUserData = async () => {
        const { data } = await restApi.get(`/lectures/${lectureId}/exams/accounts`, {
          params: {
            limit: 99999,
            // isForeign: !formData.isOffline && formData.examStyle === undefined ? false : formData.examStyle === undefined ? false : true,
            isForeign: false,
          },
        });
        if (formData.isOffline) {
          if (formData.examStyle === 'ONLINE' || formData.examStyle === 'DOWNLOAD') {
            const onlineIds = data.content.filter((data) => data.isForeign == true).map((item) => item.id);
            const offlineIds = data.content.filter((data) => data.isForeign == false ||data.isForeign == null).map((item) => item.id);
            setFormData((x) => ({ ...x, examAccountIds: onlineIds, offlineAccountIds: offlineIds }));
          } else {
            const userIds = data.content.map((item) => item.id);
            setFormData((x) => ({ ...x, examAccountIds: [], offlineAccountIds: userIds }));
          }
        } else {
          if (formData.examStyle === 'ONLINE' || formData.examStyle === 'DOWNLOAD') {
            const onlineIds = data.content.filter((data) => data.isForeign == true).map((item) => item.id);
            const offlineIds = data.content.filter((data) => data.isForeign == false ||data.isForeign == null).map((item) => item.id);
            console.log(onlineIds);
            console.log(offlineIds);
            setFormData((x) => ({ ...x, examAccountIds: onlineIds, offlineAccountIds: offlineIds }));
          } else {
            const userIds = data.content.map((item) => item.id);
            setFormData((x) => ({ ...x, examAccountIds: [], offlineAccountIds: [] }));
          }
        }
        // const userIds = data.content.map((item) => item.id);
        // setFormData((x) => ({ ...x, examAccountIds: userIds }));
      };
      
      loadUserData();
    } else {
    }
  }, [lectureId,formData.isOffline, formData?.examStyle]);

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">시험</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">시험</li>
                  <li className="breadcrumb-item active">시험</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}

      <div className="content-wrapper container-xxl p-0">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {!['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type) && (
                      <tr>
                        <th>
                          <label className="form-label">과목</label>
                        </th>
                        <td>
                          <LectureSearchItem value={lectureId} onChange={setLecId} />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>
                        <label className="form-label">시험유형</label>
                      </th>
                      <td>
                        <div className="row mx-0">
                          <select
                            className="form-select me-75 pe-3"
                            style={{ width: 'auto' }}
                            value={formData.type}
                            onChange={async (e) => {
                              const v = e.target.value;
                              let examAccountIds = [];
                              if (formData.type !== v) {
                                const { data } = await restApi.get(`/lectures/${lectureId}/exams/accounts`, {
                                  params: {
                                    limit: 99999,
                                    isForeign: v === 'EXAM',
                                  },
                                });
                                setAllUsers(data);
                                examAccountIds = data.content.map((item) => item.id);
                              }
                              setFormData((x) => ({ ...x, type: v, examAccountIds }));
                            }}
                          >
                            <option value={''}>선택해주세요</option>
                            <option value={'QUIZ'}>퀴즈</option>
                            <option value={'EXAM'}>시험</option>
                          </select>

                          {formData.type === 'EXAM' && (
                            <select
                              className="form-select pe-3"
                              style={{ width: 'auto' }}
                              value={formData.session}
                              onChange={(e) => {
                                const v = e.target.value;
                                setFormData((x) => ({ ...x, session: v }));
                              }}
                            >
                              <option value={''}>선택해주세요</option>
                              <option value={'EXTP001'}>중간고사</option>
                              <option value={'EXTP002'}>기말고사</option>
                            </select>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">시험명</label>
                      </th>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name={'title'}
                          defaultValue={formData.title}
                          onChange={(e) => {
                            const v = e.target.value;
                            setFormData((x) => ({ ...x, title: v }));
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">내용</label>
                      </th>
                      <td>
                        <Editor
                          initialValue={formData.contents}
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          init={editorInitOptions}
                          tinymceScriptSrc="/tinymce/tinymce.min.js"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">응시자</label>
                      </th>
                      <td className="d-flex align-items-center">
                        <UserSelectForExam
                          isOver={formData.endAt == undefined ? false : dayjs(formData.endAt).isBefore(now)}
                          title={'시험 응시자'}
                          maxLength={9999}
                          isOffline = {formData.isOffline}
                          examStyle = {formData.examStyle}
                          lectureId={lectureId}
                          name="examAccountIds"
                          online={formData.examAccountIds}
                          offline={formData.offlineAccountIds}
                          onChange={(v,s) => {
                            setFormData((x) => ({ ...x, examAccountIds: v , offlineAccountIds: s }));
                          }}
                        />
                      </td>
                      {formData.isOffline && formData.examStyle != null && (<label className="form-label">※ 선택된 응시자는 온라인 또는 시험지 다운로드를 통해 시험을 응시합니다.<br />
                      (미선택된 응시자는 오프라인 시험을 통해 응시할 수 있습니다.)</label>)}
                    </tr>

                    {formData.type === 'EXAM' && (
                      <tr>
                        <th>
                          <label className="form-label">시험 방식</label>
                        </th>
                        <td>
                          <div className="form-check align-items-center d-flex" style={{ height: 40 }}>
                            <input
                              className="form-check-input me-25"
                              id="ch00"
                              type="checkbox"
                              defaultChecked={formData.isOffline}
                              checked={formData.isOffline}
                              onChange={(e) => {
                                offliceClicked();
                                setFormData((x) => ({ ...x, isOffline: !x.isOffline }));
                              }}
                            />
                            <label className="form-check-label ms-25" htmlFor="ch00">
                              오프라인 시험
                            </label>
                            {formData.isOffline && (
                                <input
                                type="text"
                                className="form-control ms-1"
                                style={{width: 400}}
                                placeholder='시험장소를 입력해주세요'
                                name={'OfflinePlace'}
                                defaultValue={formData.offlinePlace}
                                onChange={(e) => {
                                  const v = e.target.value;
                                  setFormData((x) => ({ ...x, offlinePlace: v }));
                                }}
                              />
                            )}
                          </div>
                          <div className="form-check align-items-center d-flex" style={{ height: 40 }}>
                            <input
                              className="form-check-input me-25"
                              id="ch01"
                              type="checkbox"
                              defaultChecked={formData.examStyle === 'ONLINE'}
                              checked={formData.examStyle === 'ONLINE'}
                              onChange={(e) => {
                                setFormData((x) => ({ ...x, isShowExamResult: false, examStyle: formData.examStyle === 'ONLINE' ? undefined : 'ONLINE' }));
                              }}
                            />
                            <label className="form-check-label ms-25" htmlFor="ch01">
                              온라인 시험
                            </label>
                          </div>
                          <div className="form-check align-items-center d-flex" style={{ height: 40 }}>
                            <input
                              className="form-check-input me-25"
                              id="ch02"
                              type="checkbox"
                              defaultChecked={formData.examStyle === 'DOWNLOAD'}
                              checked={formData.examStyle === 'DOWNLOAD'}
                              onChange={(e) => {
                                setFormData((x) => ({ ...x, showAnswer: false, examStyle: formData.examStyle === 'DOWNLOAD' ? undefined : 'DOWNLOAD' }));
                              }}
                            />
                            <label className="form-check-label ms-25" htmlFor="ch02">
                              시험지 다운로드
                            </label>
                            {formData.examStyle === 'DOWNLOAD' && (
                            <label className="form-label">※ 1개 파일만 제출이 가능합니다.</label>
                          )}
                          </div>
                        </td>
                        {/* <td>
                        <div className="form-check-inline">
                          <select
                            className="form-select pe-3"
                            style={{ width: 'auto' }}
                            value={formData.examStyle}
                            onChange={(e) => {
                              const v = e.target.value;
                              setFormData((x) => ({ ...x, examStyle: v }));
                            }}
                          >
                            <option value={''}>선택해주세요</option>
                            <option value={'ONLINE'}>온라인 시험</option>
                            <option value={'DOWNLOAD'}>시험지 다운로드</option>
                          </select>
                          </div>
                          {formData.examStyle === 'DOWNLOAD' && (
                            <label className="form-label">※ 1개 파일만 제출이 가능합니다.</label>
                          )}
                        </td> */}
                      </tr>
                    )}
                    <tr>
                      <th>
                        <label className="form-label">공개여부</label>
                      </th>
                      <td>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="ra01"
                            defaultChecked={formData.active}
                            checked={formData.active}
                            onClick={(e) => {
                              setFormData((x) => ({ ...x, active: true }));
                            }}
                          />
                          <label className="form-check-label" htmlFor="ra01">
                            공개
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="ra02"
                            defaultChecked={!formData.active}
                            checked={!formData.active}
                            onClick={(e) => {
                              setFormData((x) => ({ ...x, active: false }));
                            }}
                          />
                          <label className="form-check-label" htmlFor="ra02">
                            비공개
                          </label>
                        </div>
                      </td>
                    </tr>
                    {!!formData.type && (
                      <tr>
                        <th>
                          <label className="form-label">시험 공개 기간</label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="date"
                              className="form-control flatpickr-basic flatpickr-input me-25"
                              placeholder="YYYY-MM-DD"
                              style={{ width: 140 }}
                              value={formData.startDate}
                              defaultValue={formData.startDate}
                              onChange={(e) => {
                                const v = e.target.value;
                                setFormData((x) => ({ ...x, startDate: v }));
                              }}
                            />
                            <input
                              type="text"
                              className="form-control flatpickr-basic flatpickr-input"
                              placeholder="HH:MM"
                              style={{ width: 70 }}
                              value={formData.startTime}
                              defaultValue={'00:00'}
                              onChange={(e) => {
                                const v = e.target.value;
                                setFormData((x) => ({ ...x, startTime: v }));
                              }}
                            />
                            <span className={'ms-25 me-25'}>~</span>
                            <input
                              type="date"
                              id="fp-range"
                              className="form-control flatpickr-range flatpickr-input me-25"
                              placeholder="YYYY-MM-DD"
                              style={{ width: 140 }}
                              value={formData.endDate}
                              onChange={(e) => {
                                const v = e.target.value;
                                setFormData((x) => ({ ...x, endDate: v }));
                              }}
                            />

                            <input
                              type="text"
                              className="form-control flatpickr-basic flatpickr-input"
                              placeholder="HH:MM"
                              style={{ width: 70 }}
                              value={formData.endTime}
                              defaultValue={'00:00'}
                              onChange={(e) => {
                                const v = e.target.value;
                                setFormData((x) => ({ ...x, endTime: v }));
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                    {formData.examStyle == undefined || formData.examStyle == 'DOWNLOAD' || (formData.examStyle == 'DOWNLOAD' && formData.isOffline) ? (
                      <tr>
                        <th>
                          <label className="form-label">점수 공개</label>
                        </th>
                        <td>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions1"
                              id="ra03"
                              defaultChecked={formData.isShowExamResult}
                              checked={formData.isShowExamResult}
                              onClick={(e) => {
                                setFormData((x) => ({ ...x, isShowExamResult: true }));
                              }}
                            />
                            <label className="form-check-label" htmlFor="ra03">
                              공개
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions1"
                              id="ra04"
                              defaultChecked={!formData.isShowExamResult}
                              checked={!formData.isShowExamResult}
                              onClick={(e) => {
                                setFormData((x) => ({ ...x, isShowExamResult: false }));
                              }}
                            />
                            <label className="form-check-label" htmlFor="ra04">
                              비공개
                            </label>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>
                          <label className="form-label">설정</label>
                        </th>
                        <td>
                          {formData.type === 'QUIZ' && (
                            <div className="form-check align-items-center d-flex" style={{ height: 40 }}>
                              <input
                                className="form-check-input me-25"
                                id="ch03"
                                type="checkbox"
                                defaultChecked={formData.type === 'QUIZ'}
                                checked={formData.type === 'QUIZ'}
                                onChange={(e) => {
                                  setFormData((x) => ({ ...x, timeAttack: x.timeAttack >= 0 ? undefined : 0 }));
                                }}
                              />
                              <label className="form-check-label ms-25" htmlFor="ch03">
                                시간제한
                              </label>
                              <input
                                type="text"
                                className="form-control d-sm-inline-block ms-1 me-25"
                                style={{ width: 100 }}
                                value={formData.timeAttack}
                                onChange={(e) => {
                                  const v = e.target.value;
                                  setFormData((x) => ({ ...x, timeAttack: v }));
                                }}
                              />
                              분
                            </div>
                          )}
                          <div className="form-check align-items-center d-flex" style={{ height: 40 }}>
                            <input
                              className="form-check-input me-25"
                              id="ch04"
                              type="checkbox"
                              defaultChecked={formData.isMixQuestion}
                              checked={formData.isMixQuestion}
                              onChange={(e) => {
                                setFormData((x) => ({ ...x, isMixQuestion: !x.isMixQuestion }));
                              }}
                            />
                            <label className="form-check-label ms-25" htmlFor="ch04">
                              문제 순서 섞기
                            </label>
                          </div>
                          <div className="form-check align-items-center d-flex" style={{ height: 40 }}>
                            <input
                              className="form-check-input me-25"
                              id="ch05"
                              type="checkbox"
                              defaultChecked={formData.suffle}
                              checked={formData.suffle}
                              onChange={(e) => {
                                setFormData((x) => ({ ...x, suffle: !x.suffle }));
                              }}
                            />
                            <label className="form-check-label ms-25" htmlFor="ch05">
                              답변 순서 섞기
                            </label>
                          </div>
                          <div className="form-check align-items-center d-flex" style={{ height: 40 }}>
                            <input
                              className="form-check-input me-25"
                              id="ch06"
                              type="checkbox"
                              defaultChecked={formData.oneQuestion}
                              checked={formData.oneQuestion}
                              onChange={(e) => {
                                setFormData((x) => ({ ...x, oneQuestion: !x.oneQuestion }));
                              }}
                            />
                            <label className="form-check-label ms-25" htmlFor="ch06">
                              한 번에 한 문제만 표시
                            </label>
                          </div>
                          <div className="form-check align-items-center d-flex" style={{ height: 40 }}>
                              <input
                                className="form-check-input me-25"
                                id="ch07"
                                type="checkbox"
                                defaultChecked={formData.showAnswer}
                                checked={formData.showAnswer}
                                onChange={(e) => {
                                  setFormData((x) => ({ ...x, showAnswer: !x.showAnswer }));
                                }}
                              />
                              <label className="form-check-label ms-25" htmlFor="ch07">
                                정답 공개하기
                              </label>
                          </div>
                            {formData.showAnswer && (
                              <>
                              <div className="align-items-center d-flex" style={{ height: 40 }}>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions1"
                                    id="ra06"
                                    defaultChecked={formData.showExamResultOnlyPoint}
                                    checked={formData.showExamResultOnlyPoint}
                                    onClick={(e) => {
                                      setFormData((x) => ({ ...x, showExamResultOnlyPoint: true }));
                                    }}
                                  />
                                  <label className="form-check-label" htmlFor="ra06">
                                    합산 점수 (문제 제외)
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions1"
                                    id="ra04"
                                    defaultChecked={!formData.showExamResultOnlyPoint}
                                    checked={!formData.showExamResultOnlyPoint}
                                    onClick={(e) => {
                                      setFormData((x) => ({ ...x, showExamResultOnlyPoint: false }));
                                    }}
                                  />
                                  <label className="form-check-label" htmlFor="ra04">
                                    문제 및 정답
                                  </label>
                                </div>
                              </div>
                              <div className="align-items-center d-flex" style={{ height: 40 }}>
                                <span className="me-1">정답노출 기간</span>
                                <input
                                  type="date"
                                  className="form-control flatpickr-basic flatpickr-input me-25"
                                  placeholder="YYYY-MM-DD"
                                  style={{ width: 140 }}
                                  value={formData.showAnswerStartDate}
                                  defaultValue={formData.showAnswerStartDate}
                                  onChange={(e) => {
                                    const v = e.target.value;
                                    setFormData((x) => ({ ...x, showAnswerStartDate: v }));
                                  }}
                                />
                                <input
                                  type="text"
                                  className="form-control flatpickr-basic flatpickr-input"
                                  placeholder="HH:MM"
                                  style={{ width: 70 }}
                                  value={formData.showAnswerStartTime}
                                  defaultValue={'00:00'}
                                  onChange={(e) => {
                                    const v = e.target.value;
                                    setFormData((x) => ({ ...x, showAnswerStartTime: v }));
                                  }}
                                />
                                <span className="ms-25 me-25"> ~ </span>
                                <input
                                  type="date"
                                  className="form-control flatpickr-basic flatpickr-input me-25"
                                  placeholder="YYYY-MM-DD"
                                  style={{ width: 140 }}
                                  value={formData.showAnswerEndDate}
                                  defaultValue={formData.showAnswerEndDate}
                                  onChange={(e) => {
                                    const v = e.target.value;
                                    setFormData((x) => ({ ...x, showAnswerEndDate: v }));
                                  }}
                                />
                                <input
                                  type="text"
                                  className="form-control flatpickr-basic flatpickr-input"
                                  placeholder="HH:MM"
                                  style={{ width: 70 }}
                                  value={formData.showAnswerEndTime}
                                  defaultValue={'00:00'}
                                  onChange={(e) => {
                                    const v = e.target.value;
                                    setFormData((x) => ({ ...x, showAnswerEndTime: v }));
                                  }}
                                />
                                </div>
                              </>
                            )}
                          {formData.showAnswer && (
                            <p style={{ color: 'red' }}>* 온라인 시험의 주관식의 경우 채점 완료 후 정답여부 및 점수가 표시됩니다.<br />
                            * 오프라인 시험, 시험지 다운로드 응시자의 경우 합산 점수만 확인할 수 있습니다.</p>
                          )}
                        </td>
                      </tr>
                    )}
                    {formData.isShowExamResult && (formData.examStyle == 'DOWNLOAD' || (formData.examStyle == 'DOWNLOAD' || formData.isOffline)) && (
                      <tr>
                        <th>
                          <label className="form-label">점수 공개 기간</label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="date"
                              className="form-control flatpickr-basic flatpickr-input me-25"
                              placeholder="YYYY-MM-DD"
                              style={{ width: 140 }}
                              value={formData.showExamResultStartDate}
                              defaultValue={formData.showExamResultStartDate}
                              onChange={(e) => {
                                const v = e.target.value;
                                setFormData((x) => ({ ...x, showExamResultStartDate: v }));
                              }}
                            />
                            <input
                              type="text"
                              className="form-control flatpickr-basic flatpickr-input"
                              placeholder="HH:MM"
                              style={{ width: 70 }}
                              value={formData.showExamResultStartTime}
                              defaultValue={'00:00'}
                              onChange={(e) => {
                                const v = e.target.value;
                                setFormData((x) => ({ ...x, showExamResultStartTime: v }));
                              }}
                            />
                            <span className={'ms-25 me-25'}>~</span>
                            <input
                              type="date"
                              id="fp-range"
                              className="form-control flatpickr-range flatpickr-input me-25"
                              placeholder="YYYY-MM-DD"
                              style={{ width: 140 }}
                              value={formData.showExamResultEndDate}
                              onChange={(e) => {
                                const v = e.target.value;
                                setFormData((x) => ({ ...x, showExamResultEndDate: v }));
                              }}
                            />

                            <input
                              type="text"
                              className="form-control flatpickr-basic flatpickr-input"
                              placeholder="HH:MM"
                              style={{ width: 70 }}
                              value={formData.showExamResultEndTime}
                              defaultValue={'00:00'}
                              onChange={(e) => {
                                const v = e.target.value;
                                setFormData((x) => ({ ...x, showExamResultEndTime: v }));
                              }}
                            />
                          </div>
                          <p style={{ color: 'red' }}>* 온라인 시험의 주관식의 경우 채점 완료 후 정답여부 및 점수가 표시됩니다.<br />
                            * 오프라인 시험, 시험지 다운로드 응시자의 경우 합산 점수만 확인할 수 있습니다.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-12 txt-r">
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                저장
              </button>
              <button
                type="submit"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/lms/exams/items');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
