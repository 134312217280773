import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../../common/api';
import LectureSearchItem from '../../../../../components/LectureSearchItem';
import UserSelect from '../../../../../components/UserSelect';
import { setLectureId } from '../../../../../data/common';

export default function LmsLecturesMembersGroupsCreate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const principal = useSelector((s) => s.auth?.principal, shallowEqual);
  const originLecId = useSelector((s) => s.common.lectureId, shallowEqual);
  const [formData, setFormData] = useState({
    title: '',
    memo: '',
    masterId: undefined,
    userIds: [],
  });

  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/lectures/0/teams/${id}`);
      setLecId(data?.lecture?.id);
      setFormData((x) => ({
        ...x,
        title: data?.title,
        memo: data?.memo,
        masterId: data?.master?.id,
        userIds: data?.users?.map((x) => x.id),
      }));
    };
    if (id) loadData().catch(console.warn);
  }, [id]);

  const [lectureId, setLecId] = useState(originLecId);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.title) {
      alert('제목은 필수항목입니다.');
      return;
    }
    if (!formData.masterId) {
      alert('조장은 필수항목입니다.');
      return;
    }
    dispatch(setLectureId(lectureId));
    if (id) {
      await restApi.put(`/lectures/${lectureId}/teams/${id}`, formData);
      alert('저장되었습니다.');
    } else {
      await restApi.post(`/lectures/${lectureId}/teams`, formData);
      alert('저장되었습니다.');
    }
    navigate('/lms/lectures/members/groups');
  };

  return (
    <>
      <div className="content-wrapper container-xxl p-0">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">멤버</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">멤버</li>
                    <li className="breadcrumb-item active">그룹</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <Link to="/lms/lectures/members/members" className="nav-link" role="tab">
              원우관리
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/lms/lectures/members/groups" className="nav-link active" role="tab">
              그룹관리
            </Link>
          </li>
        </ul>
        <form onSubmit={handleSubmit} className="row">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {!['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type) && (
                      <tr>
                        <th>
                          <label className="form-label">과목</label>
                        </th>
                        <td>
                          <LectureSearchItem value={lectureId} onChange={setLecId} disabled={!!id} noAll />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>
                        <label className="form-label">그룹명</label>
                      </th>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.title}
                          onChange={(e) => {
                            const v = e.target.value;
                            setFormData((x) => ({ ...x, title: v }));
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">조장</label>
                      </th>
                      <td>
                        <UserSelect
                          title={'조장 검색'}
                          type={'USER'}
                          maxLength={1}
                          values={[formData?.masterId].filter((x) => !!x)}
                          onChange={(v) => {
                            setFormData((x) => ({ ...x, masterId: v?.[0] }));
                          }}
                          extraParams={{ lectureId, onlyNoTeam: true, teamId: id }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">조원</label>
                      </th>
                      <td>
                        <UserSelect
                          title={'조원 검색'}
                          type={'USER'}
                          maxLength={500}
                          values={formData?.userIds}
                          onChange={(v) => {
                            setFormData((x) => ({ ...x, userIds: v ?? [] }));
                          }}
                          extraParams={{ lectureId, onlyNoTeam: true, teamId: id }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">비고</label>
                      </th>
                      <td>
                        <textarea
                          className={'form-control'}
                          value={formData.memo}
                          onChange={(e) => {
                            const v = e.target.value;
                            setFormData((x) => ({ ...x, memo: v }));
                          }}
                        ></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <small className="d-block text-muted mt-m15 txt-h-g">※ 그룹과제는 조장만 제출이 가능합니다.</small>
            <div className="col-12 mt-50 txt-r">
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/lms/lectures/members/groups');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
