import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAPIHost, restApi } from '../../../../../common/api';

export default function SiteSiteManagerMainVisualIdIndex() {
  const navigate = useNavigate();

  const query = useParams();

  const [data, setData] = useState();

  const loadData = async () => {
    const { data } = await restApi.get(`/main_visuals/${query.id}`);
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [query.id]);

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">메인 비주얼 관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">사이트관리</Link>
                  </li>
                  <li className="breadcrumb-item active">메인 비주얼 관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-title">
                        비주얼 정렬순서
                      </label>
                    </th>
                    <td>{data?.orderIndex}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        제목<span className="require">*</span>
                      </label>
                    </th>
                    <td>{data?.title}</td>
                  </tr>
                  {/*    <tr>
                    <th>
                      <label className="form-label">
                        메인 텍스트<span className="require">*</span>
                      </label>
                    </th>
                    <td>The Only, The Best</td>
                  </tr> */}
                  {/* <tr>
                    <th>
                      <label className="form-label">서브 텍스트</label>
                    </th>
                    <td>국내 최고의 Blended MBA | 성균관대학교 경영대학원 IMBA</td>
                  </tr> */}
                  <tr>
                    <th>
                      <label className="form-label">노출여부</label>
                    </th>
                    <td>{data?.active ? '노출' : '미노출'}</td>
                  </tr>
                  {/* <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        슬라이드 링크
                      </label>
                    </th>
                    <td>적용안함</td>
                  </tr> */}
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        비주얼 이미지 (web)<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="d-flex flex-column flex-md-row">
                        {data?.typeP === 'VIDEO' ? (
                          <video
                            src={`${getAPIHost()}api/files/${data?.fileP}`}
                            controls
                            playsInline
                            loop
                            style={{ width: 500, maxWidth: '100%' }}
                          />
                        ) : (
                          <img src={`${getAPIHost()}api/files/${data?.fileP}`} />
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug2">
                        비주얼 이미지 (mobile)<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="d-flex flex-column flex-md-row">
                        {data?.typeM === 'VIDEO' ? (
                          <video
                            src={`${getAPIHost()}api/files/${data?.fileM}`}
                            controls
                            playsInline
                            loop
                            style={{ width: 500, maxWidth: '100%' }}
                          />
                        ) : (
                          <img src={`${getAPIHost()}api/files/${data?.fileM}`} />
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button
              type="submit"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={() => {
                navigate(`/site/siteManager/mainVisual/${query.id}/edit`);
              }}
            >
              수정
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/site/siteManager/mainVisual');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
