import React, { useMemo } from 'react';

const PaginationMoon = ({ data, onClick }) => {
  const [pages, maxPage] = useMemo(() => {
    if (!data) return [null, null];

    const maxPage = Math.ceil(data?.totalElements / data?.size);
    const pages = Array(maxPage ?? 0)
      .fill('')
      .map((_, ix) => ix)
      .filter((x) => x > data.number - 5)
      .filter((x) => x < data.number + 5);

    return [pages, maxPage];
  }, [data]);
  if (!data) return null;
  return (
    <ul className="pagination justify-content-center mt-2">
      <li className="page-item prev">
        <a className="page-link" onClick={() => onClick(Math.max(0, data.number - 5))}></a>
      </li>
      {pages.map((ix) => {
        const act = data.number == ix;
        return (
          <li key={`${ix}`} className={`page-item ${act ? 'active' : ''}`} aria-current="page">
            <a onClick={() => onClick(ix)} className="page-link" aria-current="page">
              {ix + 1}
            </a>
          </li>
        );
      })}
      <li className="page-item next">
        <a className="page-link" onClick={() => onClick(Math.min(maxPage - 1, data.number + 5))}></a>
      </li>
    </ul>
  );
};

export default PaginationMoon;
