import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LecturesAttendancesLnb from './_lnb';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { restApi } from '../../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';
import LectureSearch from '../../../../components/LectureSearch';
import RequireLecture from '../../../../components/RequireLecture';
import { useLoading } from '../../../../nav/AppContainer';
import FileSaver from 'file-saver';
import moment from 'moment';

const LecturesAttendancesOffline = (props) => {
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);
  const navigate = useNavigate();

  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = React.useState(queryString.parse(window.location.search));
  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const [head, setHead] = useState();
  const loadData = useCallback(async () => {
    const { data: head } = await restApi.get(`/lectures/${lectureId}/attendances/index`);
    setHead(head);

    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get(`/lectures/${lectureId}/attendances`, { params: { ...params, limit: 99999 } });
    setData(data);
  }, [lectureId, params]);

  const tableWidth = useMemo(() => {
    let size = 40 + 100 + 150 + 60 * 3 + 120 * head?.offlines?.length;
    return size;
  }, [head]);

  useEffect(() => {
    if (lectureId) {
      loadData().catch(console.warn);
    }
  }, [loadData]);

  const [, setLoading] = useLoading();

  const xlsDownload = async () => {
    setLoading(true);
    const { data } = await restApi.get(`/lectures/${lectureId}/attendances/offline/xlsx`, {
      responseType: 'arraybuffer',
      params,
    });
    FileSaver.saveAs(
      new Blob([data]),
      `출석관리_오프라인_${moment().utcOffset('+0900').format('YYYYMMDD_HHmmss')}.xlsx`,
    );
    setLoading(false);
  };
  const xlsDownload2 = async () => {
    setLoading(true);
    const { data } = await restApi.get(`/lectures/${lectureId}/attendances/offline/xlsx2`, {
      responseType: 'arraybuffer',
      params,
    });
    FileSaver.saveAs(
      new Blob([data]),
      `영상시청관리_오프라인_${moment().utcOffset('+0900').format('YYYYMMDD_HHmmss')}.xlsx`,
    );
    setLoading(false);
  };

  return (
    <>
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">오프라인 강의</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">출석</li>
                    <li className="breadcrumb-item active">오프라인 강의</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // header e */}

        <LectureSearch />

        <RequireLecture lectureId={lectureId}>
          {/* con s */}
          <div className="row">
            <div className="col-12">
              {/* Inputs Group with Dropdown */}
              <div className="col-md-6 col-12 ms-auto">
                <fieldset>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      setParams({
                        keyword: event.target.keyword.value,
                        keywordType: event.target.keywordType.value,
                      });
                    }}
                  >
                    <div className="input-group">
                      <select name="keywordType" className="form-select" defaultValue={params.keywordType}>
                        <option value={''}>전체</option>
                        <option value={'name'}>이름</option>
                        <option value={'idno'}>학번</option>
                      </select>

                      <input
                        type="text"
                        name="keyword"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        defaultValue={params.keyword}
                      />
                      <button className="btn btn-outline-primary waves-effect" type="submit">
                        검색
                      </button>
                    </div>
                  </form>
                </fieldset>
              </div>
              {/* Inputs Group with Dropdown end */}
            </div>
          </div>

          <ul className="nav nav-tabs" role="tablist">
            <LecturesAttendancesLnb active={'offline'} />
          </ul>
          {/* Bordered table start s */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header pb-0 attendance-layout">
                  <div className="col">
                    <h4>오프라인 강의</h4>
                    <p>
                      출석<span className="text-success ms-75 me-2">●</span> 지각
                      <span className="text-danger ms-75 me-2">▲</span>
                      결석<span className="text-black ms-75 me-2">×</span>
                    </p>
                  </div>
                  <div className="w-auto txt-r">
                    <button type="button" className="btn btn-outline-dark btn-doc waves-effect" onClick={xlsDownload2}>
                      영상시청 데이터 다운로드{' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-log-in"
                      >
                        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                        <polyline points="10 17 15 12 10 7"></polyline>
                        <line x1="15" y1="12" x2="3" y2="12"></line>
                      </svg>
                    </button>
                    <button type="button" className="btn btn-outline-dark btn-doc waves-effect" onClick={xlsDownload}>
                      오프라인 강의 출석 엑셀 다운로드{' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-log-in"
                      >
                        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                        <polyline points="10 17 15 12 10 7"></polyline>
                        <line x1="15" y1="12" x2="3" y2="12"></line>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="table-responsive scrollbox2 alltablebox">
                  <table className="table table-bordered visual-box" style={{ minWidth: tableWidth }}>
                    <colgroup>
                      <col style={{ width: 40 }} />
                      <col style={{ width: 100 }} />
                      <col />
                      <col style={{ width: 80 }} />
                      <col style={{ width: 60 }} />
                      <col style={{ width: 60 }} />
                      <col style={{ width: 60 }} />
                      {head?.offlines?.map((item, ix) => (
                        <col key={`${ix}`} style={{ width: 120 }} />
                      ))}
                    </colgroup>
                    <thead>
                      <tr>
                        <th>번호</th>
                        <th>이름</th>
                        <th>학번</th>
                        <th>국네/해외</th>
                        <th>출석</th>
                        <th>지각</th>
                        <th>결석</th>
                        {head?.offlines?.map((item, ix) => (
                          <th key={`${ix}`}>
                            {item.date}
                            <br />
                            <button
                              type="button"
                              className="btn btn-primary btn-sm waves-effect waves-float waves-light"
                              onClick={() => {
                                navigate(`/lms/lectures/attendance/offline/${item.id}`);
                              }}
                            >
                              출석체크
                            </button>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.content?.map((item, index) => {
                        return (
                          <tr key={`${item.account?.id}`}>
                            {/* <td className="txt-c">{data.totalElements - index - data?.size * data?.number}</td> */}
                            <td className="txt-c">{index + 1}</td>
                            <td className="txt-c">{item?.account?.name}</td>
                            <td className="txt-c">{item?.account?.idno || item?.account?.userId}</td>
                            <td className="txt-c">{item?.account?.isForeign ? '해외' : '국내'}</td>
                            <td className="txt-c">{item.offlines?.filter((x) => x.state === 'ACTIVE')?.length ?? 0}</td>
                            <td className="txt-c">{item.offlines?.filter((x) => x.state === 'LATE')?.length ?? 0}</td>
                            <td className="txt-c">{item.offlines?.filter((x) => x.state === 'NONE')?.length ?? 0}</td>
                            {head?.offlines?.map((o) => {
                              const s = item.offlines?.find((x) => x.offlineId === o.id)?.state;
                              if (s === 'ACTIVE') {
                                return <td className="txt-c text-success">●</td>;
                              } else if (s === 'LATE') {
                                return <td className="txt-c text-danger">▲</td>;
                              } else if (s === 'NONE') {
                                return <td className="txt-c text-black">×</td>;
                              }
                              return <td className="txt-c">-</td>;
                            })}
                          </tr>
                        );
                      })}
                      {/*<tr>*/}
                      {/*  <td className="txt-c">10</td>*/}
                      {/*  <td className="txt-c">홍길동</td>*/}
                      {/*  <td className="txt-c">2021900254</td>*/}
                      {/*  <td className="txt-c">10</td>*/}
                      {/*  <td className="txt-c">10</td>*/}
                      {/*  <td className="txt-c">10</td>*/}
                      {/*  <td className="txt-c text-success">●</td>*/}
                      {/*  <td className="txt-c"></td>*/}
                      {/*  <td className="txt-c"></td>*/}
                      {/*  <td className="txt-c"></td>*/}
                      {/*</tr>*/}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* Bordered table end e */}
            {/* <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} /> */}
          </div>
          {/* // con e */}
        </RequireLecture>
      </div>
    </>
  );
};

export default LecturesAttendancesOffline;
