import produce from 'immer';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../../common/api';
import BoardAttachList from '../../../../../components/boards/BoardAttachList';
import { parseHTML } from '../../../../../common/define';

export default function SiteBoardResearchIdIndex() {
  const navigate = useNavigate();
  const query = useParams();
  const [data, setData] = useState();
  const [commentsData, setCommentsData] = useState();
  const [selected, setSelected] = useState([]);
  const loadData = async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get(`/web/messages/${query.id}`, {
      params: {
        ...params,
        type: 'RESEARCH',
      },
    });
    const { data: commentsData } = await restApi.get(`/web/messages/${query.id}/comments`);
    setData(data);
    setCommentsData(commentsData);
  };
  useEffect(() => {
    loadData().catch(console.warn);
  }, [query.id]);

  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/web/messages/${query.id}`);
    alert('삭제되었습니다.');
    navigate('/site/board/research');
  };

  const handleCommentsRemove = async () => {
    if (!selected.length) {
      alert('선택 후 클릭해주세요.');
      return;
    }
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await Promise.all(
      selected.map((id) => {
        return restApi.delete(`/web/messages/${query.id}/comments/${id}`);
      }),
    );
    alert('삭제되었습니다.');
    loadData();
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">연구회 공지사항</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#n">게시판 관리</Link>
                  </li>
                  <li className="breadcrumb-item active">연구회 공지사항</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        연구회<span className="require">*</span>
                      </label>
                    </th>
                    <td>{data?.crew?.name}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        제목<span className="require">*</span>
                      </label>
                    </th>
                    <td>{data?.title}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        작성자<span className="require">*</span>
                      </label>
                    </th>
                    <td>{data?.writer}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        내용<span className="require">*</span>
                      </label>
                    </th>
                    <td
                      className={'content-area'}
                      dangerouslySetInnerHTML={{ __html: parseHTML(data?.contentsWithMarkup) }}
                    ></td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일
                      </label>
                    </th>
                    <td>
                      <p className="my-50">
                        <BoardAttachList ids={data?.files} />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row" id="table-bordered">
            <div className="col-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      {commentsData?.content?.map((item) => (
                        <>
                          <tr>
                            <th>
                              <input
                                className="form-check-input mx-25"
                                checked={selected?.includes(item.id)}
                                type="checkbox"
                                onClick={() => {
                                  setSelected(
                                    produce((draft) => {
                                      const ix = draft.indexOf(item.id);
                                      if (ix >= 0) {
                                        draft.splice(ix, 1);
                                      } else {
                                        draft.push(item.id);
                                      }
                                    }),
                                  );
                                }}
                              />
                              {item?.writer}
                            </th>
                            <td>
                              {item?.contents}
                              <p className="text-black-50 mb-0">{item?.createdAt}</p>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r" style={{ position: 'relative' }}>
            {data?.totalElements > 0 && (
              <button
                type="button"
                className="btn btn-outline-danger waves-effect"
                data-bs-toggle="modal"
                data-bs-target="#btnDel"
                style={{ position: 'absolute', left: '0' }}
                onClick={handleCommentsRemove}
              >
                삭제
              </button>
            )}
            <button type="button" className="btn btn-outline-danger waves-effect me-1" onClick={handleRemove}>
              글 삭제
            </button>
            <button
              type="submit"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={() => {
                navigate(`/site/board/research/${query.id}/edit`);
              }}
            >
              수정
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/site/board/research');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
