import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { restApi } from '../../../../common/api';
import PaginationMoon from '../../../../components/PaginationMoon';
import produce from 'immer';
import { shallowEqual, useSelector } from 'react-redux';

export default function SiteProfessorManagerSubjectIndex() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get('/admin/main-classes', { params: { ...params } });
    setData(data);
  }, [params]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const [selected, setSelected] = useState([]);
  const isAllChecked = data?.content?.map((v) => v.id).filter((x) => !selected.includes(x)).length === 0;
  const handleRemove = async () => {
    if (!selected.length) {
      alert('선택 후 클릭해주세요.');
      return;
    }

    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    await Promise.all(
      selected.map((id) => {
        return restApi.put(`/admin/main-classes/${id}/extra`, { siteType: null });
      }),
    );

    loadData();
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">교과목소개 등록</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">교과목소개 등록</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link
            className={`nav-link ${searchParams.get('siteType') ? '' : 'active'}`}
            data-bs-toggle="tab"
            role="tab"
            aria-selected="true"
            onClick={() =>
              setParams({
                ...params,
                siteType: '',
                page: 0,
              })
            }
          >
            전체
          </Link>
        </li>
        {codes?.tree?.MCTP.map((item) => (
          <li className="nav-item">
            <Link
              className="nav-link"
              data-bs-toggle="tab"
              role="tab"
              aria-selected="false"
              onClick={() =>
                setParams({
                  ...params,
                  siteType: item.code,
                  page: 0,
                })
              }
            >
              {item.label}
            </Link>
          </li>
        ))}
      </ul>

      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="col-md-6 col-12 ms-auto">
                <fieldset>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      setParams({
                        keyword: event.target.keyword.value,
                        keywordType: event.target.keywordType.value,
                      });
                    }}
                  >
                    <div className="input-group">
                      <select
                        name="keywordType"
                        className="form-select"
                        id="selectDefault"
                        defaultValue={params.keywordType}
                      >
                        <option value={''}>전체</option>
                        <option value={'siteType'}>분류</option>
                        <option value={'category'}>분야</option>
                        <option value={'title'}>과목명</option>
                        <option value={'master.name'}>교수명</option>
                      </select>
                      <input
                        name="keyword"
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        defaultValue={params.keyword}
                      />
                      <button className="btn btn-outline-primary waves-effect" type="submit">
                        검색
                      </button>
                    </div>
                  </form>
                </fieldset>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '4rem' }} />
                  <col style={{ width: '6rem' }} />
                  <col style={{ width: '14%' }} />
                  <col style={{ width: '14%' }} />
                  <col />
                  <col style={{ width: '12%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isAllChecked}
                        onClick={() => {
                          if (isAllChecked) {
                            setSelected([]);
                          } else {
                            setSelected(data?.content?.filter((d) => d.siteType).map((v) => v.id));
                          }
                        }}
                      />
                    </th>
                    <th>번호</th>
                    <th>분류</th>
                    <th>분야</th>
                    <th>과목명</th>
                    <th>교수명</th>
                    <th>공개여부</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.content
                    .filter((d) => d.siteType)
                    .map((item, ix) => (
                      <tr key={`subject-${ix}`}>
                        <td className="txt-c">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={selected?.includes(item.id)}
                            onClick={() => {
                              setSelected(
                                produce((draft) => {
                                  const ix = draft.indexOf(item.id);
                                  if (ix >= 0) {
                                    draft.splice(ix, 1);
                                  } else {
                                    draft.push(item.id);
                                  }
                                }),
                              );
                            }}
                          />
                        </td>
                        <td className="txt-c">{data?.totalElements - ix - data?.size * data?.number}</td>
                        <td className="txt-c">
                          <Link to={`/site/professorManager/subject/${item.id}`}>
                            {codes?.tree?.MCTP?.find((c) => c.code === item.siteType)?.label}
                          </Link>
                        </td>
                        <td className="txt-c">
                          <Link to={`/site/professorManager/subject/${item.id}`}>
                            {codes?.tree?.MCST?.find((c) => c.code === item.category)?.label}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/site/professorManager/subject/${item.id}`}>{item.title}</Link>
                        </td>
                        <td className="txt-c">{item.master.name}</td>
                        <td className="txt-c">{item.isActive ? 'Y' : 'N'}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-50">
            <div className="col-md-6">
              <button
                type="button"
                className="btn btn-outline-danger waves-effect"
                data-bs-toggle="modal"
                data-bs-target="#btnDel"
                onClick={handleRemove}
              >
                삭제
              </button>
            </div>
            <div className="col-md-6 txt-r">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-float waves-light"
                onClick={() => {
                  navigate('/site/professorManager/subject/write');
                }}
              >
                등록
              </button>
            </div>
          </div>
          <nav aria-label="Page navigation">
            <PaginationMoon
              data={data}
              onClick={(page) => {
                setParams((x) => ({ ...x, page }));
              }}
            />
          </nav>
        </div>
      </div>
    </div>
  );
}
