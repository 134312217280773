import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import Modal from '../../../../components/Modal';
import { useNote } from '../../../../components/NoteProvider';
import PaginationMoon from '../../../../components/PaginationMoon';
import DownIco from '../../../../components/icons/DownIco';
import UpIco from '../../../../components/icons/UpIco';
import { useLoading } from '../../../../nav/AppContainer';


const USER_TYPE = {
  TEACHER: '교수',
  STAFF: '튜터',
  MANAGER: '행정실',
  ADMIN: '지원실',
  USER: '원우',
  CP: 'CP',
  EXAMSTAFF: '시험감독관',
};

export default function MultiCampusDataIndex() {
    const note = useNote();
    const navigate = useNavigate();
    const [, setSearchParams] = useSearchParams();
    const [params, setParams] = useState(queryString.parse(window.location.search));
    const [isSelect2On, setIsSelect2On] = useState(false);
    const [semester, setSemester] = useState();
    const [subject, setSubject] = useState('');
  
    useEffect(() => {
      setSearchParams(params);
    }, [params]);
  
    const [data, setData] = useState();
    const loadData = useCallback(async () => {
      const params = queryString.parse(window.location.search);
      const { data } = await restApi.get('/admin/multicampusData', {
        params: {...params, limit: 50, sort: 'id', order: 'DESC' },
      });
      setData(data);
    }, [params]);

    const [semesterData, setSemesterData] = useState();
    const loadSemesterData = useCallback(async () => {
      const { data } = await restApi.get('/admin/multicampusData/allsemester');
    setSemesterData(data);
    });

    const [subjectData, setSubjectData] = useState();
    const loadSubjectData = useCallback(async () => {
      // console.log(semester)
      const { data } = await restApi.get('/admin/multicampusData/subject', {
        params: {semester: semester}
      });
      setSubjectData(data);
    }, [semester]);

    const handleSemesterSelect = (e) => {
      setSemester(e.target.value)
      setIsSelect2On(true);
    }
    const handleSubjectSelect = (e) => {
      // console.log(e.target.value)
      setSubject(e.target.value)
    }
   
    useEffect(() => {
      loadData();
    }, [loadData]);

    useEffect(() => {
      loadSemesterData();
    }, []);

    useEffect(() => {
      loadSubjectData();
    }, [semester]);


    const handleSubmit = (e) => {
      e.preventDefault();
  
      const newParams = {
        ...params,
        keywordType: e.target.keywordType.value,
        // attending:
        //   e.target.typeSelector?.value?.toUpperCase() === 'USER'
        //     ? true
        //     : e.target.typeSelector?.value?.toUpperCase() === 'GRADUATE'
        //     ? false
        //     : undefined,
        // type:
        //   e.target.typeSelector?.value?.toUpperCase() === 'GRADUATE'
        //     ? 'USER'
        //     : e.target.typeSelector?.value?.toUpperCase() ?? '',
        // onlyNoSiteTeacher: false,
        // period: e.target.keywordType.value === 'period' ? e.target.keyword?.value : '',
        keyword: e.target.keyword?.value,
        semester: semester,
        subject: subject,
        page : 0
      };
      setParams(newParams);
    };

    const [, setLoading] = useLoading();
    const xlsDownload = async () => {
        setLoading(true);
        try {
            const { data } = await restApi.get(`/admin/multicampusData/xlsx`, {
            responseType: 'arraybuffer',
            params: {...params, sort: 'id',order: 'ASC'},
            });
            FileSaver.saveAs(new Blob([data]), `기존이력확인.xlsx`);
            setLoading(false);
        } catch (e) {
            alert('다운로드 제한을 초과하였습니다.');
            setLoading(false);
        }
      };
    
    return (
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">기존 이력 확인</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">기존 이력 확인</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className={`row `}>
          <div className="col-12">
            <div className="card">
              <div className="card-header">
              <button type="button" className="btn btn-outline-dark btn-doc waves-effect" onClick={xlsDownload}>
                      엑셀 다운로드{' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-log-in"
                      >
                        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                        <polyline points="10 17 15 12 10 7"></polyline>
                        <line x1="15" y1="12" x2="3" y2="12"></line>
                      </svg>
                    </button>
                    <div className="col-md-2 col-3">
                        <select
                          className="form-select"
                          id="selectDefault1"
                          name="semester"
                          // onChange={({ target }) => {
                            
                          //   // setSemester(target.value)
                          //   setIsSelect2On(true);
                          //   console.log(target.value)
                          //   setSemester(target.value)
                          //   loadSubjectData();
                          // }}
                          onChange={handleSemesterSelect}
                        >
                          {<option value={''}>년도-학기</option>}
                          {semesterData?.map((item) => (
                            <option value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                    </div>
                    <div className="col-md-4 col-3">
                        <select
                          className="form-select"
                          id="selectDefault"
                          name="keywordType"
                          defaultValue={params.keywordType}
                          disabled={!isSelect2On}
                          onChange={handleSubjectSelect}
                        >
                          {<option value={''}>강의명</option>}
                          {subjectData?.map((item) => (
                            <option value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                    </div>
                <div className="col-md-3 col-3">
                  <form onSubmit={handleSubmit}>
                    <div className="input-group">
                      <select
                        className="form-select"
                        id="selectDefault"
                        name="keywordType"
                        defaultValue={params.keywordType}
                      >
                        <option value={'period'}>기수</option>
                        <option value={'name'}>이름</option>
                      </select>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          aria-label="Amount"
                          name="keyword"
                          defaultValue={params.keyword}
                        />
                      <button className="btn btn-outline-primary waves-effect" type="submit">
                        검색
                      </button>
                    </div>
                  </form>
                </div>
              </div>
  
              <div className="table-responsive scrollbox2">
                <table className="table table-bordered visual-box t-padding2">
                  <colgroup>
                    <col style={{ width: 40}} />
                    <col style={{ width: 130 }} />
                    <col style={{ width: 60 }} />
                    <col style={{ width: 60 }} />
                    <col style={{ width: 100 }} />
                    <col style={{ width: 100 }} />
                    <col style={{ width: 60 }} />
                    <col style={{ width: 60 }} />
                    <col style={{ width: 50 }} />
                    <col style={{ width: 40 }} />
                    <col style={{ width: 100 }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>년도-학기</th>
                      <th>담당교수</th>
                      <th>학수번호</th>
                      <th>강의명</th>
                      <th>주차</th>
                      <th>기수</th>
                      <th>학번</th>
                      <th>이름</th>
                      <th>학습 시간</th>
                      <th>최종 학습 날짜</th>
                    </tr>
                  </thead>
                  <tbody style={{height : 50}}>
                  {/* <td className="txt-c"> 1</td>
                  <td className="txt-c"> 40기</td>
                  <td className="txt-c"> 2004721197</td>
                  <td className="txt-c"> 강성진</td>
                  <td className="txt-c"> 2004-2학기</td>
                  <td className="txt-c"> 7800004</td>
                  <td className="txt-c"> 이건창</td>
                  <td className="txt-c"> 경영정보시스템</td>
                  <td className="txt-c"> 01_Master Intro</td>
                  <td className="txt-c"> 00:12:59</td>
                  <td className="txt-c"> 2004년12월10일 23:33:58</td> */}
                    {data?.content.map((item, index) => (
                      <tr key={item.id}>
                        <td className="txt-c">{index + 1 + data?.size * data?.number}</td>
                        <td className="txt-c">{item.semester}</td>
                        <td className="txt-c">{item.professor}</td>
                        <td className="txt-c">{item.haksunum}</td>
                        <td className="txt-c">{item.subject}</td>
                        <td className="txt-c">{item.classNm}</td>
                        <td className="txt-c">{item.period && `IMBA ${item.period}`}</td>
                        <td className="txt-c">{item.idno}</td>
                        <td className="txt-c">{item.name}</td>
                        <td className="txt-c">{item.learningTime}</td>
                        <td className="txt-c">{item.learningEndDt}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
  
            {/* pagination s */}
            <nav aria-label="Page navigation">
              <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
            </nav>
            {/* // pagination e */}
          </div>
          {/* Bordered table end e */}
        </div>
      </div>
    );
  }
