import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';
import { editorInitOptions } from '../../../../common/utils';
import FileAttacher from '../../../../components/FileAttacher';
import LectureSearchItem from '../../../../components/LectureSearchItem';
import { useLoading } from '../../../../nav/AppContainer';

export default function LmsMessagesPdsWrite() {
  const { lectureId } = useSelector((x) => x.common, shallowEqual);
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const navigate = useNavigate();

  const query = useParams();

  const attacher = useRef();
  const editorRef = useRef();

  const [initData, setInitData] = useState();
  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/lms/messages/${query.id}`);
        setInitData(data);
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  const [loading, setLoading] = useLoading();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...initData,
      contentsWithMarkup: editorRef.current.getContent(),
      title: e.target.title.value,
      type: 'pds',
    };

    if (['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type)) {
      payload.lectureId = lectureId;
    } else {
      payload.lectureId = e.target.lectureId.value;
    }

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return false;
    }

    if (!payload.contentsWithMarkup) {
      alert('내용을 입력해주세요.');
      return false;
    }

    setLoading(true);
    const ids = await attacher.current.getFileIds();
    payload.files = ids;

    if (query?.id) {
      await restApi.put(`/lms/messages/${query?.id}`, payload);
      alert('저장되었습니다.');
      await navigate(`/lms/messages/pds/${query?.id}`);
    } else {
      await restApi.post(`/lms/messages`, payload);
      alert('등록되었습니다.');
      await navigate('/lms/messages/pds?category=' + (query.category ?? ''));
    }
    setLoading(false);
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">자료실</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">자료실</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form
        className="row"
        onSubmit={handleSubmit}
        onReset={() => {
          navigate('/lms/messages/pds');
        }}
      >
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  {!['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type) && (
                    <tr>
                      <th>
                        <label className="form-label">과목</label>
                      </th>
                      <td>
                        {!!query?.id ? (
                          <LectureSearchItem value={!initData ? lectureId : initData?.lecture?.id} disabled />
                        ) : (
                          <LectureSearchItem defaultValue={!initData ? lectureId : initData?.lecture?.id} noAll />
                        )}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>
                      <label className="form-label">제목</label>
                    </th>
                    <td>
                      <input name={'title'} defaultValue={initData?.title} type="text" className="form-control" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">내용</label>
                    </th>
                    <td>
                      <Editor
                        initialValue={initData?.contentsWithMarkup}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={editorInitOptions}
                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일
                      </label>
                    </th>

                    <td>
                      <FileAttacher ref={attacher} values={initData?.files} limitSize={100} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
              저장
            </button>
            <button type="reset" className="btn btn-outline-secondary waves-effect">
              취소
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
