import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { editorInitOptions } from '../../../common/utils';
import { restApi } from '../../../common/api';
import { useLoading } from '../../../nav/AppContainer';
import FileAttacher from '../../../components/FileAttacher';

const yearPattern = /^\d{4}$/;
function isValidYear(year) {
  return yearPattern.test(year);
}

export default function SiteInfoPolicyWrite() {
  const navigate = useNavigate();
  const query = useParams();
  const attacher = useRef();
  const editorRef = useRef();

  const [initData, setInitData] = useState();
  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/privacy/${query.id}`);
        setInitData(data);
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  const [loading, setLoading] = useLoading();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...initData,
      title: e.target.title.value,
      contentsWithMarkup: editorRef.current.getContent(),
      year: e.target.year.value,
    };

    if (!isValidYear(payload.year)) {
      alert('연도는 4자리 숫자로 입력해주세요.');
      return false;
    }

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return false;
    }

    if (!payload.contentsWithMarkup) {
      alert('내용을 입력해주세요.');
      return false;
    }

    setLoading(true);
    try {
      const ids = await attacher.current.getFileIds();
      if (ids) {
        payload.files = ids;
      }

      if (query?.id) {
        await restApi.put(`/privacy/${query?.id}`, payload);
        alert('저장되었습니다.');
        await navigate(`/site/infoPolicy/${query?.id}`);
      } else {
        await restApi.post(`/privacy`, payload);
        alert('등록되었습니다.');
        await navigate('/site/infoPolicy');
      }
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">개인정보처리방침</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">개인정보처리방침</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">년도</label>
                    </th>
                    <td>
                      {/* <select
                        className="form-select mb-75"
                        style={{ width: 170 }}
                        name="year"
                        defaultValue={initData?.year}
                      >
                        <option>2022</option>
                      </select> */}
                      <input
                        type="text"
                        id="blog-edit-slug"
                        className="form-control flex-grow-1 w-auto"
                        name="year"
                        defaultValue={initData?.year}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        제목<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="flex">
                        <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control flex-grow-1 w-auto"
                          name="title"
                          defaultValue={initData?.title}
                        />
                        <span className="txt-limit">0/50</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        내용<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <Editor
                        initialValue={initData?.contentsWithMarkup}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={editorInitOptions}
                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="d-flex flex-column flex-md-row">
                        {/* <div className="featured-info">
                          <p className="my-50">
                            <Link to="#" id="blog-image-text">
                              C:\fakepath\banner.jpg
                            </Link>
                          </p>
                          <div className="d-inline-block">
                            <input className="form-control" type="file" id="blogCustomFile" accept="image/*" />
                          </div>
                        </div> */}

                        <FileAttacher ref={attacher} values={initData?.files} limitSize={10} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {!loading && (
            <div className="col-12 mt-50 txt-r">
              <button
                type="submit"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                data-bs-toggle="modal"
                data-bs-target="#btnSave"
              >
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                data-bs-toggle="modal"
                data-bs-target="#btnCancel"
                onClick={() => {
                  navigate('/site/infoPolicy');
                }}
              >
                취소
              </button>
            </div>
          )}
        </div>
      </div>
      {/* // con e */}
    </form>
  );
}
