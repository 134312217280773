import React, { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import produce from 'immer';
import dayjs from 'dayjs';
import LectureSearch from '../../../../components/LectureSearch';
import PaginationMoon from '../../../../components/PaginationMoon';

export default function LmsPdsIndex() {
  const navigate = useNavigate();
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);

  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/lms/messages', {
      params: {
        ...params,
        lectureId,
        type: 'pds',
      },
    });

    setData(data);
  }, [params, lectureId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">자료실</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">자료실</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LectureSearch />
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="col-md-6 col-12 ms-auto">
                <fieldset>
                  <form
                    className="input-group"
                    onSubmit={(event) => {
                      event.preventDefault();
                      setParams(
                        produce((draft) => {
                          draft.keywordType = event.target.keywordType.value;
                          draft.keyword = event.target.keyword.value;
                        }),
                      );
                    }}
                  >
                    <select name="keywordType" className="form-select" defaultValue={params.keywordType}>
                      <option value="">전체</option>
                      <option value="title">제목</option>
                      <option value="contents">내용</option>
                      <option value="account.name">작성자</option>
                    </select>
                    <input
                      type="text"
                      name="keyword"
                      className="form-control"
                      placeholder=""
                      aria-label="Amount"
                      defaultValue={params.keyword}
                    />
                    <button className="btn btn-outline-primary waves-effect" type="submit">
                      검색
                    </button>
                  </form>
                </fieldset>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '6rem' }} />
                  {!lectureId && <col style={{ width: '12rem' }} />}
                  <col />
                  <col style={{ width: '10rem' }} />
                  <col style={{ width: '6rem' }} />
                  <col style={{ width: '10rem' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    {!lectureId && <th>과목</th>}
                    <th>제목</th>
                    <th>작성자</th>
                    <th>첨부</th>
                    <th>등록일</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.content.map((pds, index) => {
                    return (
                      <tr key={`pds-${pds.id}`}>
                        <td className="txt-c">{data?.totalElements - data?.size * data?.number - index}</td>
                        {!lectureId && (
                          <td
                            style={{
                              maxWidth: '12rem',
                            }}
                          >
                            <p
                              style={{ margin: 0, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                            >
                              {pds.lecture?.mainClass?.title}
                            </p>
                          </td>
                        )}
                        <td>
                          <Link to={`/lms/messages/pds/${pds.id}`}>{pds.title}</Link>
                        </td>
                        <td className="txt-c">{pds.account?.name}</td>
                        <td className="txt-c">
                          {!!pds.files?.length && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={14}
                              height={14}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-paperclip"
                            >
                              <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                            </svg>
                          )}
                        </td>
                        <td className="txt-c">{dayjs(pds.createdAt).format('YYYY-MM-DD')}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 txt-r">
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-float waves-light"
              onClick={() => {
                navigate('/lms/messages/pds/write');
              }}
            >
              등록
            </button>
          </div>
          <nav aria-label="Page navigation">
            <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
          </nav>
        </div>
      </div>
    </div>
  );
}
