import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import BoardAttachSingle from '../../../../components/boards/BoardAttachSingle';
import { useLoading } from '../../../../nav/AppContainer';

export default function SiteSiteManagerMainVisualWrite() {
  const navigate = useNavigate();

  const query = useParams();

  const attacherP = useRef();
  const attacherM = useRef();

  const [initData, setInitData] = useState();
  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/main_visuals/${query.id}`);
        setInitData(data);
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  const [loading, setLoading] = useLoading();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...initData,
      title: e.target.title.value,
      orderIndex: e.target.orderIndex.value,
      active: e.target.active.value === '노출',
      typeP: e.target.typeP.value,
      typeM: e.target.typeM.value,
    };

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return false;
    }

    if (!payload.typeP) {
      alert('파일 타입을 입력해주세요.');
      return false;
    }
    if (!payload.typeM) {
      alert('파일 타입을 입력해주세요.');
      return false;
    }

    setLoading(true);
    try {
      payload.fileP = await attacherP.current.getFileId();
      payload.fileM = await attacherM.current.getFileId();

      if (query?.id) {
        await restApi.put(`/main_visuals/${query?.id}`, payload);
        alert('저장되었습니다.');
        await navigate(`/site/siteManager/mainVisual/${query?.id}`);
      } else {
        await restApi.post(`/main_visuals/`, payload);
        alert('등록되었습니다.');
        await navigate('/site/siteManager/mainVisual');
      }
    } catch (e) {
      // alert(e);
      alert('파일을 모두 첨부해주세요.');
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">메인 비주얼 관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">사이트관리</Link>
                  </li>
                  <li className="breadcrumb-item active">메인 비주얼 관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-title">
                        비주얼 정렬순서
                      </label>
                    </th>
                    <td>
                      <select
                        className="form-select"
                        id="blog-edit-title"
                        style={{ width: 170 }}
                        name="orderIndex"
                        defaultValue={initData?.orderIndex}
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        제목<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="flex">
                        <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control flex-grow-1 w-auto"
                          name="title"
                          defaultValue={initData?.title}
                        />
                        <span className="txt-limit">0/50</span>
                      </div>
                    </td>
                  </tr>
                  {/* <tr>
                    <th>
                      <label className="form-label">
                        메인 텍스트<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <Editor
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={editorInitOptions}
                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                      />
                    </td>
                  </tr> */}
                  {/* <tr>
                    <th>
                      <label className="form-label">서브 텍스트</label>
                    </th>
                    <td>
                      <Editor
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={editorInitOptions}
                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                      />
                    </td>
                  </tr> */}
                  <tr>
                    <th>
                      <label className="form-label">노출여부</label>
                    </th>
                    <td>
                      <select
                        className="form-select mb-75"
                        style={{ width: 170 }}
                        name="active"
                        defaultValue={initData?.active}
                      >
                        <option value={'노출'}>노출</option>
                        <option value={'비노출'}>비노출</option>
                      </select>
                    </td>
                  </tr>
                  {/* <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        슬라이드 링크
                      </label>
                    </th>
                    <td>
                      <div className="col-12">
                        <select className="form-select mb-75" style={{ width: 170 }}>
                          <option>적용 안 함</option>
                          <option>새창</option>
                          <option>현재창</option>
                        </select>
                      </div>
                      <input type="url" id="blog-edit-slug" className="form-control" placeholder="https://" />
                    </td>
                  </tr> */}
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        비주얼 이미지 (web)<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <select
                        key={`${initData?.typeP}`}
                        className="form-select mb-75"
                        id="blog-edit-title1"
                        style={{ width: 170 }}
                        name="typeP"
                        defaultValue={initData?.typeP}
                      >
                        <option value="VIDEO">영상</option>
                        <option value="IMAGE">이미지</option>
                      </select>
                      <BoardAttachSingle ref={attacherP} name={'fileP'} defaultValue={initData?.fileP} />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug2">
                        비주얼 이미지 (mobile)<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <select
                        key={`${initData?.typeM}`}
                        className="form-select mb-75"
                        id="blog-edit-title2"
                        style={{ width: 170 }}
                        name="typeM"
                        defaultValue={initData?.typeM}
                      >
                        <option value="VIDEO">영상</option>
                        <option value="IMAGE">이미지</option>
                      </select>
                      <BoardAttachSingle ref={attacherM} name={'fileM'} defaultValue={initData?.fileM} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {!loading && (
            <div className="col-12 mt-50 txt-r">
              <button
                type="submit"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                data-bs-toggle="modal"
                data-bs-target="#btnSave"
              >
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                data-bs-toggle="modal"
                data-bs-target="#btnCancel"
                onClick={() => {
                  navigate('/site/siteManager/mainVisual');
                }}
              >
                취소
              </button>
            </div>
          )}
        </div>
      </div>
      {/* // con e */}
    </form>
  );
}
