import React, { useEffect, useImperativeHandle, useState } from 'react';
import { getAPIHost, restApi } from '../../common/api';

const BoardAttachSingle = React.forwardRef((props, ref) => {
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  useImperativeHandle(ref, () => ({
    getFileId: async () => {
      if (file?.id) return file?.id;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('secret', false);
        const { data } = await restApi.post('/files', formData);
        return data.id;
      }
    },
    isEmpty: () => !file,
  }));

  useEffect(() => {
    const loadData = async (ids) => {
      if (!ids.length) return;
      const { data: fdata } = await restApi.get(`/files/infos`, { params: { ids } });
      const file = fdata?.map((x) => ({ id: x.id, name: x.filename }))?.[0];
      if (file) {
        setFile(file);
        setPreview(`${getAPIHost()}api/files/${file?.id}`);
      }
    };

    loadData([props?.defaultValue]).catch(console.warn);
  }, [props?.defaultValue]);

  return (
    <div className="d-flex flex-column flex-md-row">
      <img
        src={preview || '/assets/images/no-img.png'}
        id="blog-feature-image"
        className="rounded me-2 mb-1 mb-md-0"
        width={170}
        height={110}
        alt="Blog Featured Image"
        style={{ objectFit: 'cover' }}
      />
      <div className="featured-info">
        {!!file && (
          <p className="my-50">
            <a>{file?.name}</a>
          </p>
        )}
        <div className="d-inline-block">
          <input
            className="form-control"
            type="file"
            id="blogCustomFile"
            accept="image/*"
            onChange={(event) => {
              event.preventDefault();
              setPreview(undefined);
              setFile(event.target.files?.[0]);
              var reader = new FileReader();
              reader.onload = function () {
                setPreview(reader.result);
              };
              reader.readAsDataURL(event.target.files[0]);
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default BoardAttachSingle;
