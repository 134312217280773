import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { restApi } from '../../../../common/api';
import PaginationMoon from '../../../../components/PaginationMoon';
import { useNote } from '../../../../components/NoteProvider';
import produce from 'immer';
import { me } from '../../../../data/auth';
import { useDispatch } from 'react-redux';

const USER_TYPE = {
  TEACHER: '교수',
  STAFF: '튜터',
  MANAGER: '행정실',
  ADMIN: '지원실',
  USER: '원우',
  CP: 'CP',
  EXAMSTAFF: '시험감독관',
};

export default function LmsAccountsPermissionIndex() {
  const note = useNote();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get('/permissions', { params: { ...params, limit: 10, sort: 'id', order: 'DESC' } });
    setData(data);
  }, [params]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setParams({
      keyword: e.target.keyword.value,
      keywordType: e.target.keywordType.value,
    });
  };

  const dispatch = useDispatch();
  const handleInputChange = async (e, item) => {
    const { name, checked } = e.target;
    const payload = { ...item, [name]: checked };
    await restApi.put(`/permissions/${item.id}`, payload);
    dispatch(me());
    setData((prev) => {
      item[name] = checked;
      return { ...prev };
    });
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">계정/권한관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">계정/권한관리</li>
                  <li className="breadcrumb-item active">권한설정</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link className={`nav-link`} role="tab" to="/lms/accounts">
            계정관리
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link active`} role="tab" to="/lms/accounts/permission">
            권한설정
          </Link>
        </li>
      </ul>
      {/* Bordered table start s */}

      <div className={`row `}>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="col-md-6 col-12"></div>
              {/* Inputs Group with Dropdown */}
              <div className="col-md-6 col-12 ms-auto">
                <form onSubmit={handleSubmit}>
                  <div className="input-group">
                    <select
                      className="form-select"
                      id="selectDefault"
                      name="keywordType"
                      defaultValue={params.keywordType}
                    >
                      <option value={''}>전체</option>
                      <option value={'parent'}>대메뉴</option>
                      <option value={'name'}>화면명</option>
                    </select>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="Amount"
                      name="keyword"
                      defaultValue={params.keyword}
                    />
                    <button className="btn btn-outline-primary waves-effect" type="submit">
                      검색
                    </button>
                  </div>
                </form>
              </div>
              {/* Inputs Group with Dropdown end */}
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box t-fixed">
                <colgroup>
                  <col style={{ width: '8rem' }} />
                  <col style={{ width: '13rem' }} />
                  <col />
                  <col style={{ width: '8rem' }} />
                  <col style={{ width: '8rem' }} />
                  <col style={{ width: '8rem' }} />
                  <col style={{ width: '8rem' }} />
                  <col style={{ width: '8rem' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th className="align-middle" rowSpan={2}>
                      No
                    </th>
                    <th className="align-middle" rowSpan={2}>
                      대메뉴
                    </th>
                    <th className="align-middle" rowSpan={2}>
                      화면명
                    </th>
                    <th className="align-middle" colSpan={6}>
                      사용여부
                    </th>
                  </tr>
                  <tr>
                    <th>교수</th>
                    <th>튜터</th>
                    <th>행정실</th>
                    <th>지원실</th>
                    <th>CP</th>
                    <th>시험감독관</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.content?.map((item, ix) => (
                    <tr key={`${item.id}`}>
                      <td className="txt-c">{ix + 1 + data.size * data.number}</td>
                      <td className="txt-c">{item.parent}</td>
                      <td className="">{item.name}</td>
                      <td className="txt-c">
                        {!item.origin.includes('TEACHER') ? (
                          '-'
                        ) : (
                          <input
                            className="form-check-input"
                            id=""
                            type="checkbox"
                            name="activeTeacher"
                            disabled={!item.origin.includes('TEACHER')}
                            checked={item.activeTeacher}
                            onChange={(e) => handleInputChange(e, item)}
                          />
                        )}
                      </td>
                      <td className="txt-c">
                        {!item.origin.includes('STAFF') ? (
                          '-'
                        ) : (
                          <input
                            className="form-check-input"
                            id=""
                            type="checkbox"
                            name="activeStaff"
                            disabled={!item.origin.includes('STAFF')}
                            checked={item.activeStaff}
                            onChange={(e) => handleInputChange(e, item)}
                          />
                        )}
                      </td>
                      <td className="txt-c">
                        {!item.origin.includes('MANAGER') ? (
                          '-'
                        ) : (
                          <input
                            className="form-check-input"
                            id=""
                            type="checkbox"
                            name="activeManager"
                            disabled={!item.origin.includes('MANAGER')}
                            checked={item.activeManager}
                            onChange={(e) => handleInputChange(e, item)}
                          />
                        )}
                      </td>
                      <td className="txt-c">
                        {!item.origin.includes('ADMIN') ? (
                          '-'
                        ) : (
                          <input
                            className="form-check-input"
                            id=""
                            type="checkbox"
                            name="activeAdmin"
                            disabled={!item.origin.includes('ADMIN')}
                            checked={item.activeAdmin}
                            onChange={(e) => handleInputChange(e, item)}
                          />
                        )}
                      </td>
                      <td className="txt-c">
                        {!item.origin.includes('CP') ? (
                          '-'
                        ) : (
                          <input
                            className="form-check-input"
                            id=""
                            type="checkbox"
                            name="activeCp"
                            disabled={!item.origin.includes('CP')}
                            checked={item.activeCp}
                            onChange={(e) => handleInputChange(e, item)}
                          />
                        )}
                      </td>
                      <td className="txt-c">
                        {!item.origin.includes('EXAMSTAFF') ? (
                          '-'
                        ) : (
                          <input
                            className="form-check-input"
                            id=""
                            type="checkbox"
                            name="activeExamStaff"
                            disabled={!item.origin.includes('EXAMSTAFF')}
                            checked={item.activeExamStaff}
                            onChange={(e) => handleInputChange(e, item)}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* pagination s */}
        <nav aria-label="Page navigation">
          <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
        </nav>
        {/* // pagination e */}
      </div>

      {/* // con e */}
    </div>
  );
}
