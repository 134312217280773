import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { shallowEqual, useSelector } from 'react-redux';
import PaginationMoon from '../../../components/PaginationMoon';
import { restApi } from '../../../common/api';
import FileSaver from 'file-saver';
import UpIco from '../../../components/icons/UpIco';
import DownIco from '../../../components/icons/DownIco';
import Modal from '../../../components/Modal';
import FileAttacher from '../../../components/FileAttacher';
import produce from 'immer';

const LmsRecodeIndex = () => {
  const { semesters, codes, semesterId: defSid, lectureId } = useSelector((s) => s.common, shallowEqual);

  const [sid, setSemesterId] = useState(defSid);
  const semesterId = sid ?? defSid;

  const navigate = useNavigate();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  const [, setSearchParams] = useSearchParams();
  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    if (!semesterId) return;
    const { data } = await restApi.get(`/lectures/accounts`, {
      params: { ...params, semesterId: semesterId },
    });
    setData(data);
  }, [params, semesterId]);

  useLayoutEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const handleDownloadSample = () => {
    FileSaver.saveAs('/assets/xls/upload_records2.xlsx', '원우수강내역_업로드양식.xlsx');
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();

    const files = e.target.filedata.files;
    if (files.length === 0) {
      alert('파일선택은 필수항목입니다.');
      return;
    } else if (!files[0].name.endsWith('.xlsx')) {
      alert('허용된 파일은 xlsx입니다.');
      return;
    }

    setUploadForm(2);
    try {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('semesterId', semesterId);

      const { data } = await restApi.post(`/lectures/accounts/xlsx`, formData);
      if (data.error) {
        alert(
          '아래 사유로 엑셀업로드가 불가능합니다.\n===================\n' +
            data.message +
            '\n-' +
            data.items?.join('\n -'),
        );
        setUploadForm(1);
      } else {
        const msg =
          '엑셀 업로드 시 다음과 같은 변동사항이 있습니다.\n\n' +
          `신규 추가 레코드 : ${data.creates.length}건\n` +
          `삭제 예정 레코드 : ${data.removes.length}건` +
          '\n진행하시겠습니까?';
        if (!window.confirm(msg)) {
          setUploadForm(1);
          return;
        }

        await Promise.all(
          data.creates
            ?.map((x) => {
              return restApi.post(`/lectures/accounts`, x);
            })
            .concat(
              data.removes?.map((x) => {
                return restApi.delete(`/lectures/accounts`, {
                  params: x,
                });
              }),
            ),
        );
        alert('적용되었습니다.');
        await loadData();
        setUploadForm(0);
      }
    } catch (e) {
      console.warn(e);
      alert('서버와 연결이 올바르지 않습니다.');
      setUploadForm(1);
    }

    // onClick={() => setUploadForm(2)}
  };

  const [uploadForm, setUploadForm] = useState(0);

  const [selected, setSelected] = useState([]);

  const isAllChecked = selected.length === data?.content?.length;

  const handleRemove = async () => {
    if (!selected.length) {
      alert('선택 후 클릭해주세요.');
      return;
    }

    if (!window.confirm('선택된 수강 정보를 삭제하시겠습니까? 삭제가 되면 진도율 정보도 삭제됩니다.')) return;

    await Promise.all(
      selected.map(({ lectureId, accountId }) => {
        return restApi.delete(`/lectures/accounts`, {
          params: {
            lectureId: lectureId,
            accountId: accountId,
          },
        });
      }),
    );
    alert('삭제되었습니다.');

    loadData();
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">원우수강이력</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">원우수강이력</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">학기</label>
                    </th>
                    <td>
                      <select
                        className="form-select pe-3 me-75 w-auto"
                        value={semesterId}
                        onChange={(e) => {
                          const value = e.target.value;
                          setSemesterId(value);
                        }}
                      >
                        {semesters?.map((item) => (
                          <option value={item.id} key={`${item.id}`}>
                            {item.year}년 {codes?.map?.[item.session]}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="col-md-6 col-12">
                <button
                  type="button"
                  className="btn btn-outline-dark btn-doc waves-effect"
                  onClick={handleDownloadSample}
                >
                  엑셀양식 파일 <DownIco />
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark btn-doc waves-effect ms-1"
                  onClick={() => setUploadForm(1)}
                >
                  엑셀 업로드 <UpIco />
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-doc waves-effect waves-float waves-light ms-1"
                  onClick={() => {
                    navigate('/lms/record/write');
                  }}
                >
                  정정등록
                </button>
              </div>
              {/* Inputs Group with Dropdown */}
              <form
                className="col-md-6 col-12 ms-auto"
                onSubmit={(event) => {
                  event.preventDefault();
                  setParams({
                    keyword: event.target.keyword.value,
                    keywordType: event.target.keywordType.value,
                  });
                }}
              >
                <fieldset>
                  <div className="input-group">
                    <select
                      className="form-select"
                      id="selectDefault"
                      name="keywordType"
                      defaultValue={params.keywordType}
                    >
                      {/* <option value={1}>학수번호</option> */}
                      <option value="">전체</option>
                      <option value={'id.lecture.mainClass.title'}>교과목명</option>
                      <option value={'id.account.idno'}>학번</option>
                      <option value={'id.account.name'}>이름</option>
                      {/* <option value={5}>대표교강사</option> */}
                    </select>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="Amount"
                      name="keyword"
                      defaultValue={params.keyword}
                    />
                    <button className="btn btn-outline-primary waves-effect" type="submit">
                      Search
                    </button>
                  </div>
                </fieldset>
              </form>
              {/* Inputs Group with Dropdown end */}
            </div>
            <div class="fixed-table-container">
              <div class="fixed-table-body" style={{ overflowX: 'auto' }}>
                <table className="table table-bordered visual-box t-padding">
                  <colgroup>
                    <col style={{ width: '4rem' }} />
                    <col width={60} />
                    <col width={100} />
                    <col width={100} />
                    <col width={100} />
                    <col />
                    <col width={140} />
                    <col width={140} />
                    <col width={160} />
                    <col width={100} />
                    <col width={130} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <input
                          className="form-check-input"
                          id=""
                          type="checkbox"
                          checked={isAllChecked}
                          onClick={() => {
                            if (isAllChecked) {
                              setSelected([]);
                            } else {
                              setSelected(
                                data?.content.map(({ lecture, account }) => {
                                  return {
                                    lectureId: lecture.id,
                                    accountId: account.id,
                                  };
                                }),
                              );
                            }
                          }}
                        />
                      </th>
                      <th>번호</th>
                      <th>개설년도</th>
                      <th>개설학기</th>
                      <th>구분</th>
                      <th>교과목명</th>
                      <th>학번</th>
                      <th>이름</th>
                      <th>핸드폰</th>
                      <th>성별</th>
                      <th>대표교강사</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.content.map(({ lecture, account }, ix) => {
                      const semester = lecture.semester;
                      return (
                        <tr key={`${lecture.id}_${account.id}`}>
                          {' '}
                          <td className="txt-c">
                            <input
                              className="form-check-input"
                              id=""
                              type="checkbox"
                              checked={selected?.some((v) => v.lectureId === lecture.id && v.accountId === account.id)}
                              onClick={() => {
                                setSelected(
                                  produce((draft) => {
                                    const index = draft.findIndex(
                                      (v) => v.lectureId === lecture.id && v.accountId === account.id,
                                    );

                                    if (index >= 0) {
                                      draft.splice(index, 1);
                                    } else {
                                      draft.push({ lectureId: lecture.id, accountId: account.id });
                                    }
                                  }),
                                );
                              }}
                            />
                          </td>
                          <td className="txt-c">{ix + 1 + data.size * data.number}</td>
                          <td className="txt-c">{semester.year}년</td>
                          <td className="txt-c">{codes?.map?.[semester.session]}</td>
                          <td className="txt-c">{account.attending ? '재학생' : '졸업생'}</td>
                          <td>
                            <Link to={`/lms/record/write?lectureId=${lecture.id}&accountId=${account.id}`}>
                              {lecture?.mainClass.title}
                            </Link>
                          </td>
                          <td className="txt-c">{account.idno || account.userId}</td>
                          <td className="txt-c">{account.name}</td>
                          <td className="txt-c">{account.mobilePhone}</td>
                          <td className="txt-c">{account.gender == 'MALE' ? 'M' : 'F'}</td>
                          <td className="txt-c">{lecture?.mainClass.master.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row mt-50">
            <div className="col-md-6">
              <button type="button" className="btn btn-outline-danger waves-effect" onClick={handleRemove}>
                삭제
              </button>
            </div>
          </div>
          {/* pagination s */}
          <nav aria-label="Page navigation">
            <PaginationMoon
              data={data}
              onClick={(page) => {
                setParams((x) => ({ ...x, page }));
              }}
            />
          </nav>
          {/* // pagination e */}
        </div>
      </div>
      {/* Bordered table end */}
      {/* // con e */}

      <Modal className={'fade text-start modal-primary show'} visible={!!uploadForm}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">계정정보 업로드</h5>
              {uploadForm === 1 && <button type="button" className="btn-close" onClick={() => setUploadForm(0)} />}
            </div>
            <div className="modal-body">
              {uploadForm === 1 ? (
                <form onSubmit={handleUploadSubmit}>
                  <div className="row mt-1">
                    <div className="col-12 mb-md-0">
                      <p>
                        엑셀을 업로드하여 다수의 정보를 한번에 입력할 수 있습니다.
                        <br />
                        정해진 엑셀 양식에 값을 입력하여 업로드 하세요 ( 지원하는 파일 양식 : xlsx)
                      </p>
                    </div>
                    <div className="col-12 mb-md-0 txt-r">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-doc waves-effect"
                        onClick={handleDownloadSample}
                      >
                        엑셀양식 파일
                      </button>
                    </div>
                    <div className="d-flex flex-column mt-2 mb-2">
                      <div className="featured-info">
                        <input className="form-control" type="file" name={'filedata'} />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary waves-effect waves-float waves-light">
                      저장
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary waves-effect"
                      onClick={() => setUploadForm(0)}
                    >
                      취소
                    </button>
                  </div>
                </form>
              ) : (
                <div className="spinner-wrap">
                  <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <p>
                    원우정보를 DB에 업로드 중입니다.
                    <br />
                    잠시만 기다려 주십시오
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LmsRecodeIndex;
