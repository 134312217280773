import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import commonReducer from './common';

export default configureStore({
  reducer: {
    auth: authReducer,
    common: commonReducer,
  },
});
