import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { restApi } from '../../../../common/api';
import dayjs from 'dayjs';
import { VideoContext } from '../../../../components/VideoUploadProvider';
import moment from 'moment';
import LectureSearch from '../../../../components/LectureSearch';
import RequireLecture from '../../../../components/RequireLecture';
import LectureVideoRow from '../../../../components/lectures_video/LectureVideoRow';
import { useLoading } from '../../../../nav/AppContainer';
import { Link } from 'react-router-dom';

const LecturesVideosIndex = (props) => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { lectureId } = useSelector((s) => s.common, shallowEqual);
  const context = useContext(VideoContext);

  const [weeks, setWeeks] = useState();
  const [videos, setVideos] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/lectures/${lectureId}/weeks`, {});
    setWeeks(data);
    const { data: videos } = await restApi.get(`/lectures/${lectureId}/videos`, {});
    setVideos(videos);
  }, [lectureId]);
  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  useEffect(() => {
    const handleUpdated = () => {
      loadData().catch(console.warn);
    };
    context.addListener(handleUpdated);
    return () => context.removeListener(handleUpdated);
  }, [loadData]);

  const handleVideoCreate = async (week, item) => {
    const args = {
      orderIndex: -1,
      title: item?.title,
      type: 'VIDEO',
      videoContentId: item?.id,
      week,
      isExcludeProgress: item?.isExcludeProgress,
    };
    await restApi.post(`/lectures/${lectureId}/videos`, args);
    loadData().catch(console.warn);
    alert('등록되었습니다.');
  };

  const handleRemove = async (id) => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/lectures/${lectureId}/videos/${id}`);
    loadData().catch(console.warn);
    alert('삭제되었습니다.');
  };

  const modalHtml = useRef();
  const bsModal = useRef();
  const [editForm, setEditForm] = useState();

  const handleEdit = async (item) => {
    if (!bsModal.current) {
      bsModal.current = new window.bootstrap.Modal(modalHtml.current, {
        keyboard: false,
      });
    }
    setEditForm({
      index: item.index,
      startAt: moment(item.startAt).format('YYYY-MM-DD'),
      endAt: moment(item.endAt).format('YYYY-MM-DD'),
    });
    bsModal.current.show();
  };

  const [loading, setLoading] = useLoading();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await restApi.put(`/lectures/${lectureId}/weeks/${editForm.index}`, {
      startAt: editForm.startAt,
      endAt: editForm.endAt,
    });

    setLoading(false);
    loadData().catch(console.warn);
  };

  return (
    <>
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">동영상 강의</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">동영상 강의</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // header e */}

        <LectureSearch />

        <RequireLecture lectureId={lectureId}>
          {/* con s */}
          <div className="row">
            <div className="col-12">
              {weeks?.map((week) => {
                const children = videos?.filter?.((x) => x.week == week.index);
                return (
                  <React.Fragment key={`${week.index}`}>
                    <div className="d-flex align-items-center justify-content-sm-between mb-50">
                      <div className="d-flex align-items-center">
                        <h4 className="my-0">{week.index}주차</h4>
                      </div>

                      <div className="d-flex align-items-center">
                        <p className="my-0 me-1 text-black">
                          학습기간 : {dayjs(week.startAt).format('YYYY/MM/DD')}~{dayjs(week.endAt).format('YYYY/MM/DD')}
                        </p>
                        <button
                          onClick={() => handleEdit({ ...week })}
                          type="button"
                          className="btn btn-primary btn-sm waves-effect waves-float waves-light"
                        >
                          수정
                        </button>
                        {principal?.type === 'ADMIN' && (
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm waves-effect waves-float waves-light ms-50"
                            onClick={async () => {
                              // console.log(lectureId, week);
                              if (window.confirm(`${week.index}주차를 삭제하시겠습니까?`)) {
                                await restApi.delete(`/lectures/${lectureId}/weeks/${week.index}`);
                                alert(`${week.index}주차가 삭제되었습니다.`);
                                loadData();
                              } else {
                                return;
                              }
                            }}
                          >
                            삭제
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="card mb-4">
                      <div className="table-responsive">
                        <table className="table table-bordered visual-box visual-box2">
                          <colgroup>
                            <col />
                            <col style={{ width: '18rem' }} />
                            <col style={{ width: '200px' }} />
                          </colgroup>
                          <tbody>
                            {children?.map((item) => (
                              <LectureVideoRow
                                key={`${item.id}`}
                                item={item}
                                lectureId={lectureId}
                                handleRemove={handleRemove}
                                onRefresh={loadData}
                              />
                            ))}
                            <tr>
                              <td></td>
                              <td className="text-center"></td>
                              <td style={{ textAlign: 'right' }}>
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-float waves-light"
                                  onClick={() => {
                                    context.createForm('VIDEO', (item) => handleVideoCreate(week.index, item));
                                  }}
                                >
                                  추가
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
              {/* 반복 구간 s */}
              {/* // 반복 구간 e */}
            </div>
          </div>
          {/* // con e */}
        </RequireLecture>
      </div>

      <div
        ref={modalHtml}
        className="modal fade text-start modal-primary"
        tabIndex="-1"
        aria-labelledby="myModalLabel160"
        aria-hidden="true"
      >
        <form onSubmit={handleSubmit} className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">학습기간 수정</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="box-gray">
                <h4>{editForm?.index}주차</h4>
              </div>
              <div className="col-12 mb-md-0 mb-2">
                <div className="row mt-1">
                  <div className="col-md-6">
                    <label className="form-label">학습기간 시작일</label>
                    <input
                      type="date"
                      className="form-control flatpickr-range flatpickr-input"
                      placeholder="YYYY-MM-DD"
                      value={editForm?.startAt}
                      onChange={(e) => {
                        const value = e.target.value;
                        setEditForm((x) => ({ ...x, startAt: value }));
                      }}
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">학습기간 종료일</label>
                    <input
                      type="date"
                      className="form-control flatpickr-range flatpickr-input"
                      placeholder="YYYY-MM-DD"
                      value={editForm?.endAt}
                      onChange={(e) => {
                        const value = e.target.value;
                        setEditForm((x) => ({ ...x, endAt: value }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-float waves-light"
                data-bs-dismiss="modal"
              >
                수정
              </button>
              <button type="button" className="btn btn-outline-primary waves-effect" data-bs-dismiss="modal">
                닫기
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default LecturesVideosIndex;
