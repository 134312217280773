import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';
import produce from 'immer';

export default function LmsMasterWrite() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const params = useParams();

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    year: '',
    session: 'SETP001',
    startAt: '',
    endAt: '',
    listenStartAt: '',
    listenEndAt: '',
    weeks: [],
  });

  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/semesters/${params.id}`);
      const args = { ...formData };
      if (data.year) args.year = data.year;
      if (data.session) args.session = data.session;
      if (data.startAt) args.startAt = data.startAt;
      if (data.endAt) args.endAt = data.endAt;
      if (data.listenStartAt) args.listenStartAt = data.listenStartAt;
      if (data.listenEndAt) args.listenEndAt = data.listenEndAt;
      if (data.weeks) {
        args.weeks = data.weeks?.map((item) => ({
          startAt: item.startAt,
          endAt: item.endAt,
        }));
      }
      setFormData(args);
    };
    loadData().catch(console.warn);
  }, [params.id]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.year) {
      alert('연도를 입력해주세요.');
      return;
    }
    if (!formData.session) {
      alert('학기를 입력해주세요.');
      return;
    }
    if (!formData.startAt && !formData.endAt) {
      alert('운영기간을 입력해주세요.');
      return;
    }

    const payload = {
      ...formData,
      year: parseInt(formData.year, 10),
      weeks: formData.weeks?.map((x, index) => {
        return {
          ...x,
          index,
        };
      }),
    };

    if (params.id) {
      await restApi.put(`/semesters/${params.id}`, payload);
      alert('저장되었습니다.');
      navigate(`/lms/master/${params.id}`);
    } else {
      await restApi.post(`/semesters`, payload);
      alert('저장되었습니다.');
      navigate('/lms/master');
    }
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">마스터값 설정</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">마스터값 설정</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      {/* Bordered table start */}
      <form
        className="row"
        onSubmit={handleSubmit}
        onReset={() => {
          navigate('/lms/master');
        }}
      >
        <div className="row">
          <div className="col-12">
            <h5>년도/학기 설정</h5>
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label">년도/학기</label>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control me-25 ms-1"
                            style={{ width: 136 }}
                            value={formData.year}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setFormData((x) => {
                                return {
                                  ...x,
                                  year: inputValue.replace(/[^0-9]/g, ''),
                                };
                              });
                            }}
                            maxLength={4}
                          />
                          <select
                            className="form-select w-auto pe-3 ms-1"
                            value={formData.session}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setFormData((x) => {
                                return {
                                  ...x,
                                  session: inputValue,
                                };
                              });
                            }}
                          >
                            {codes?.tree?.SETP.map((item) => (
                              <option value={item.code} key={item.code}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <h5>운영기간 설정</h5>
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label">운영기간</label>
                      </th>
                      <td style={{ display: 'flex' }}>
                        <input
                          type="date"
                          placeholder="YYYY-MM-DD"
                          className="form-control me-25 ms-1"
                          style={{ width: 136 }}
                          value={formData.startAt}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => {
                              return {
                                ...x,
                                startAt: inputValue,
                                listenStartAt: inputValue,
                              };
                            });
                          }}
                        />
                        <input
                          type="date"
                          placeholder="YYYY-MM-DD"
                          className="form-control me-25 ms-1"
                          style={{ width: 136 }}
                          value={formData.endAt}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => {
                              return {
                                ...x,
                                endAt: inputValue,
                                listenEndAt: inputValue,
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">청강기간</label>
                      </th>
                      <td style={{ display: 'flex' }}>
                        <input
                          type="date"
                          placeholder="YYYY-MM-DD"
                          className="form-control me-25 ms-1"
                          style={{ width: 136 }}
                          value={formData.listenStartAt}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => {
                              return {
                                ...x,
                                listenStartAt: inputValue,
                              };
                            });
                          }}
                        />
                        <input
                          type="date"
                          placeholder="YYYY-MM-DD"
                          className="form-control me-25 ms-1"
                          style={{ width: 136 }}
                          value={formData.listenEndAt}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => {
                              return {
                                ...x,
                                listenEndAt: inputValue,
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {formData?.weeks?.length === 0 && (
              <div className="col-12 mt-50 mb-3 txt-r">
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-float waves-light"
                  onClick={() => {
                    if (!formData.startAt || !formData.endAt) {
                      alert('운영기간을 입력해주세요.');
                      return;
                    }
                    setFormData(
                      produce((draft) => {
                        for (let i = 0; i < 16; i++) {
                          draft.weeks.push({
                            startAt: formData.startAt,
                            endAt: formData.endAt,
                          });
                        }
                      }),
                    );
                  }}
                >
                  주차등록
                </button>
              </div>
            )}

            {formData?.weeks?.length > 0 && (
              <>
                <h5>주차등록</h5>
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-bordered visual-box">
                      <colgroup>
                        <col style={{ width: '11rem' }} />
                        <col />
                      </colgroup>
                      <tbody>
                        {formData?.weeks?.map((week, ix) => (
                          <tr key={`weeks-${ix}`}>
                            <th>
                              <label className="form-label">{ix + 1}주차</label>
                            </th>
                            <td style={{ display: 'flex' }}>
                              <input
                                type="date"
                                placeholder="YYYY-MM-DD"
                                className="form-control me-25 ms-1"
                                style={{ width: 136 }}
                                value={week?.startAt}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  setFormData(
                                    produce((draft) => {
                                      draft.weeks[ix].startAt = inputValue;
                                    }),
                                  );
                                }}
                              />
                              <input
                                type="date"
                                placeholder="YYYY-MM-DD"
                                className="form-control me-25 ms-1"
                                style={{ width: 136 }}
                                value={week?.endAt}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  setFormData(
                                    produce((draft) => {
                                      draft.weeks[ix].endAt = inputValue;
                                    }),
                                  );
                                }}
                              />
                              <div style={{ marginLeft: 'auto' }}>
                                {ix + 1 === formData.weeks.length ? (
                                  <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-float waves-light"
                                    onClick={() => {
                                      setFormData(
                                        produce((draft) => {
                                          draft.weeks.push({
                                            startAt: '',
                                            endAt: '',
                                          });
                                        }),
                                      );
                                    }}
                                  >
                                    추가
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary waves-effect"
                                    onClick={() => {
                                      setFormData(
                                        produce((draft) => {
                                          draft.weeks.splice(ix, 1);
                                        }),
                                      );
                                    }}
                                  >
                                    삭제
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            <div className="col-12 mt-50 txt-r">
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                저장
              </button>
              <button
                type="submit"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/lms/master');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* Bordered table end */}
      {/* // con e */}
    </div>
  );
}
