import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getAPIHost, restApi } from '../../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import moment from 'moment';

const LecturesOfflinesDetails = (props) => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { lectureId } = useSelector((s) => s.common, shallowEqual);
  const { id } = useParams();

  const [data, setData] = useState();
  const [fileData, setFileData] = useState();
  const loadData = useCallback(
    async (page) => {
      const { data } = await restApi.get(`/lectures/${lectureId}/offlines/qna/${id}`, {});
      setData(data);

      if (data.paper) {
        const { data: fdata } = await restApi.get(`/files/infos`, { params: { ids: [data.paper] } });
        setFileData(fdata?.[0]);
      }
    },
    [lectureId, id],
  );
  useEffect(() => {
    loadData(0).catch(console.warn);
  }, [loadData]);

  const handleDelete = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/lectures/${lectureId}/offlines/qna/${id}`);
    alert('삭제되었습니다.');
    window.history.back();
  };
  return (
    <>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">화상 Q&A</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">화상 Q&A</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // header e */}
        {/* con s */}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label">수업명</label>
                      </th>
                      <td>{data?.title}</td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">날짜 / 시간</label>
                      </th>
                      <td>
                        {dayjs(data?.startAt).format('YYYY년 M월 D일 (dd)')} / {dayjs(data?.startAt).format('HH:mm')} ~{' '}
                        {dayjs(data?.endAt).format('HH:mm')}
                      </td>
                    </tr>
                    <tr>
                      <th rowSpan={2}>
                        <label className="form-label">화상 접속정보</label>
                      </th>
                      <td>{data?.zoomId}</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <a className="col" href={data?.zoomLink} target="_blank" rel="noreferrer">
                            {data?.zoomLink}
                          </a>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm waves-effect waves-float waves-light ms-1"
                            style={{ width: 100 }}
                          >
                            연결테스트
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {!!data?.videoContent && (
              <>
                <h5>동영상 정보</h5>
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-bordered visual-box">
                      <colgroup>
                        <col style={{ width: '11rem' }} />
                        <col />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>
                            <label className="form-label">동영상 선택 </label>
                          </th>
                          <td>{data?.videoContent?.title}</td>
                        </tr>
                        <tr>
                          <th>
                            <label className="form-label">등록일시</label>
                          </th>
                          <td>{moment(data?.videoContent?.createdAt).format('YYYY-MM-DD')}</td>
                        </tr>
                        <tr>
                          <th>
                            <label className="form-label">공개 여부 </label>
                          </th>
                          <td>
                            {data?.accessPublic ? '공개' : '비공개'}
                            {!!data?.comming && <>/ {moment(data?.comming).format('YYYY-MM-DD HH:mm')} 예정</>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            {!!fileData && (
              <>
                <h5>강의교안 정보</h5>
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-bordered visual-box">
                      <colgroup>
                        <col style={{ width: '11rem' }} />
                        <col />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>
                            <label className="form-label">강의교안 선택 </label>
                          </th>
                          <td>
                            <a href={`${getAPIHost()}api/files/${fileData.id}`} target={'_blank'} rel="noreferrer">
                              {fileData.filename}
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            <div className="col-12 mt-50 txt-r">
              <Link to={`/lms/lectures/qnas/view/${id}/edit`}>
                <button type="button" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                  수정
                </button>
              </Link>
              {principal?.type !== 'CP' && (
                <button
                  type="button"
                  className="btn btn-primary me-1 waves-effect waves-float waves-light"
                  onClick={handleDelete}
                >
                  삭제
                </button>
              )}
              <Link to={'/lms/lectures/qnas'}>
                <button type="reset" className="btn btn-outline-secondary waves-effect">
                  목록
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* // con e */}
      </div>
    </>
  );
};

export default LecturesOfflinesDetails;
