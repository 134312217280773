import React, { useEffect, useImperativeHandle, useState } from 'react';
import { getAPIHost, restApi } from '../common/api';

const FileAttacher = React.forwardRef(
  ({ showDesc = true, values = [], maxLength = 5, limitSize = -1, showPreview = false, disabled = false }, ref) => {
    const [files, setFiles] = useState([]);
    useImperativeHandle(ref, () => ({
      getFileIds: async () => {
        const ss = await Promise.all(
          files?.map(async (file) => {
            if (file.id) return file;

            const formData = new FormData();
            formData.append('file', file);
            formData.append('secret', false);
            const { data } = await restApi.post('/files', formData);
            return { id: data.id, name: file.name };
          }),
        );
        return ss?.map((x) => x.id);
      },
    }));

    useEffect(() => {
      const loadData = async (ids) => {
        if (!ids.length) return;
        const { data: fdata } = await restApi.get(`/files/infos`, { params: { ids } });
        setFiles(
          fdata?.map((x) => ({
            id: x.id,
            name: x.filename,
            path: `${getAPIHost()}api/files/${x.id}`,
          })),
        );
      };

      loadData(values?.filter((x) => !!x && files.findIndex((y) => y.id === x) < 0)).catch(console.warn);
    }, [values]);
    useEffect(() => {
      const loadData = async () => {};
      loadData().catch(console.warn);
      // onChange(files);
    }, [values]);

    const setFileItems = (f) => {
      const files = [];
      for (let i = 0; i < f.length; i++) {
        const tempFile = f.item(i);
        tempFile['path'] = URL.createObjectURL(tempFile);
        files.push(tempFile);
      }

      const maxSize = files?.reduce((a, b) => Math.max(a, b.size), 0);
      if (limitSize > 0 && maxSize > limitSize * 1024 * 1024) {
        alert(`파일의 허용가능한 용량은 ${limitSize}M 입니다.`);
      } else {
        setFiles((prevState) => [...prevState, ...files]);
      }
    };

    return (
      <div className="featured-info">
        <div>
          {showPreview &&
            files.map((file, fileIndex) => {
              return (
                <span style={{ display: 'inline-block' }}>
                  <img style={{ margin: '0.5rem', width: '75%' }} src={file.path} />
                </span>
              );
            })}
        </div>
        <div className="d-inline-block">
          {!disabled && (
            <input
              className="form-control"
              type="file"
              multiple={maxLength > 1}
              onChange={(event) => {
                event.preventDefault();
                if (files.length + event.target.files.length > maxLength) {
                  alert(`최대 ${maxLength}개의 파일을 등록할 수 있습니다.`);
                  return;
                }
                setFileItems(event.target.files);
              }}
            />
          )}
        </div>
        {!disabled &&
          files.map((file, fileIndex) => {
            return (
              <p className="my-50" key={`File-${fileIndex}`}>
                <a id="blog-image-text">{file.name}</a>
                <button
                  type="button"
                  className="btn-file-del"
                  onClick={() => {
                    setFiles((prevState) => prevState.filter((_, index) => index !== fileIndex));
                  }}
                >
                  파일삭제
                </button>
              </p>
            );
          })}
        {!!showDesc && maxLength > 1 && (
          <>
            <small className="d-block text-muted mt-75">
              ※ 최대 {maxLength}개의 파일을 등록할 수 있습니다. (각 파일당 {limitSize}M 이하)
            </small>
            <small className="d-block text-muted">
              ※ 첨부파일은 문서파일(pdf, hwp, docx, xlsx, pptx), 이미지파일(jpg, bmp, png), 압축파일(zip) 을 등록할 수
              있습니다.
            </small>
          </>
        )}
      </div>
    );
  },
);

export default FileAttacher;
