import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';

export default function LmsSubjectIdIndex() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const query = useParams();

  const [data, setData] = useState();
  const loadData = async () => {
    const { data } = await restApi.get(`/main-classes/${query.id}`);
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [query.id]);

  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/main-classes/${query.id}`);
    alert('삭제되었습니다.');
    window.history.back();
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">과목관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">과목관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col style={{ width: '35%' }} />
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">교수명</label>
                    </th>
                    <td>{data?.master.name}</td>
                    <th>
                      <label className="form-label">과목명</label>
                    </th>
                    <td>{data?.title}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">분류 </label>
                    </th>
                    <td>{codes?.tree?.MCTP?.find((c) => c.code === data?.siteType)?.label}</td>
                    <th>
                      <label className="form-label">분야 </label>
                    </th>
                    <td>{codes?.tree?.MCST?.find((c) => c.code === data?.category)?.label}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">청강여부</label>
                    </th>
                    <td>{data?.isAudit ? 'Y' : 'N'}</td>
                    <th>
                      <label className="form-label">학수번호</label>
                    </th>
                    <td>{data?.academicCode}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button
              type="submit"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={() => {
                navigate(`/lms/subject/view/${query.id}/edit`);
              }}
            >
              수정
            </button>
            <button
              type="button"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={handleRemove}
            >
              삭제
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/lms/subject');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>
      {/* // con e */}
    </div>
  );
}
