import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';
import { editorInitOptions } from '../../../../common/utils';
import FileAttacher from '../../../../components/FileAttacher';
import LectureSearchItem from '../../../../components/LectureSearchItem';
import { useLoading } from '../../../../nav/AppContainer';

export default function LmsMessagesNoticesWrite() {
  const { lectureId: originLectureId } = useSelector((x) => x.common, shallowEqual);
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const navigate = useNavigate();

  const query = useParams();

  const attacher = useRef();
  const editorRef = useRef();
  const [loading, setLoading] = useLoading();

  const [lectureId, setLectureId] = useState(originLectureId);
  const [initData, setInitData] = useState();
  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/lms/messages/${query.id}`);
        setInitData(data);
        if (data.lecture.id) {
          setLectureId(data.lecture.id);
        }
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...initData,
      contentsWithMarkup: editorRef.current.getContent(),
      title: e.target.title.value,
      category: e.target.category?.value,
      type: 'notice',
      lectureId: lectureId,
      isImportant: false,
    };

    console.log(payload);

    //if (['TEACHER', 'STAFF'].includes(principal?.type)) {
    //  payload.isImportant = e.target.isImportant.;
    //} else {
    //  payload.isImportant = e.target.isImportant.checked;
    //}

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return false;
    }

    if (!payload.contentsWithMarkup) {
      alert('내용을 입력해주세요.');
      return false;
    }

    setLoading(true);
    try {
      const ids = await attacher.current.getFileIds();
      payload.files = ids;

      if (query?.id) {
        // console.log({ payload });
        await restApi.put(`/lms/messages/${query?.id}`, payload);
        alert('저장되었습니다.');
        await navigate(`/lms/messages/notices/${query?.id}`);
      } else {
        await restApi.post(`/lms/messages`, payload);
        alert('등록되었습니다.');
        await navigate('/lms/messages/notices?category=' + (query.category ?? ''));
      }
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  };

  const handleOnchange = (e) => {
    const v = e.target.value;
    setInitData((x) => ({
      ...x,
      [e.target.name]: v,
    }));
  };

  if (query?.id && !initData) {
    return undefined;
  }

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">공지사항</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">공지사항</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form
        className="row"
        onSubmit={handleSubmit}
        onReset={() => {
          navigate('/lms/messages/notices');
        }}
      >
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                {['TEACHER', 'STAFF'].includes(principal?.type) && (
                    <tr>
                      <th>
                        <label className="form-label">공지타입</label>
                      </th>
                      <td className="d-flex gap-1">
                        <select
                          className="form-select"
                          id="blog-edit-title"
                          defaultValue={initData?.category}
                          style={{ width: 170 }}
                          name={'category'}
                        >
                          {lectureId ? (
                            <>
                              <option value={''}>수업</option>
                              <option value={'BCNO007'}>시험</option>
                              <option value={'BCNO008'}>과제</option>
                              <option value={'BCNO004'}>기타</option>
                            </>
                          ) : (
                            <>
                              {/* <option value={'BCNO001'}>수업</option> */}
                              <option value={'BCNO002'}>학사</option>
                              <option value={'BCNO006'}>시간표</option>
                              <option value={'BCNO004'}>기타</option>
                            </>
                          )}
                        </select>
                      </td>
                    </tr>
                  )}
                  {!['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type) && (
                    <tr>
                      <th>
                        <label className="form-label">과목</label>
                      </th>
                      <td className="d-flex gap-1">
                        {!!query?.id ? (
                          <LectureSearchItem value={!initData ? lectureId : lectureId} disabled />
                        ) : (
                          <LectureSearchItem
                            defaultValue={!initData ? lectureId : initData?.lecture?.id}
                            onChange={setLectureId}
                          />
                        )}
                        <select
                          className="form-select"
                          id="blog-edit-title"
                          defaultValue={initData?.category}
                          style={{ width: 170 }}
                          name={'category'}
                        >
                          {lectureId ? (
                            <>
                              <option value={''}>수업</option>
                              <option value={'BCNO007'}>시험</option>
                              <option value={'BCNO008'}>과제</option>
                              <option value={'BCNO004'}>기타</option>
                            </>
                          ) : (
                            <>
                              {/* <option value={'BCNO001'}>수업</option> */}
                              <option value={'BCNO002'}>학사</option>
                              <option value={'BCNO006'}>시간표</option>
                              <option value={'BCNO004'}>기타</option>
                            </>
                          )}
                        </select>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>
                      <label className="form-label">제목</label>
                    </th>
                    <td>
                      <input name={'title'} defaultValue={initData?.title} type="text" className="form-control" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">내용</label>
                    </th>
                    <td>
                      <Editor
                        initialValue={initData?.contentsWithMarkup}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={editorInitOptions}
                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일
                      </label>
                    </th>
                    <td>
                      <FileAttacher ref={attacher} values={initData?.files} limitSize={100} />
                    </td>
                  </tr>
                  {/* <tr>
                    <th>
                      <label className="form-label">중요공지</label>
                    </th>
                    <td className="d-flex align-items-center gap-1">
                      <label>
                        <input
                          className="form-check-input me-50"
                          type="checkbox"
                          name="isImportant"
                          defaultChecked={initData?.isImportant}
                        />
                        선택
                      </label>
                      <div className="d-flex align-items-center">
                        <span className="ms-2 me-1">노출 기간</span>
                        <input
                          type="date"
                          className="form-control flatpickr-basic flatpickr-input me-25"
                          placeholder="YYYY-MM-DD"
                          style={{ width: 140 }}
                          name="startAt"
                          defaultValue={initData?.startAt}
                          onChange={handleOnchange}
                        />
                        <span className="ms-25 me-25"> ~ </span>
                        <input
                          type="date"
                          className="form-control flatpickr-basic flatpickr-input me-25"
                          placeholder="YYYY-MM-DD"
                          style={{ width: 140 }}
                          name="endAt"
                          defaultValue={initData?.endAt}
                          onChange={handleOnchange}
                        />
                      </div>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-50 row">
            <div className="col-8 txt-l">※ 중요 공지 순서는 자동 지정되며, 순서 조정은 공지 관리에서 가능합니다.</div>
            {!loading && (
              <div className="col-16 txt-r">
                <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                  저장
                </button>
                <button type="reset" className="btn btn-outline-secondary waves-effect">
                  취소
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
