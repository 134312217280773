import React from 'react';
import { Link } from 'react-router-dom';

const NoMatch = (props) => (
  <div>
    <h2>권한이 없습니다</h2>
    <p>
      <Link to="/">메인으로 이동하기</Link>
    </p>
  </div>
);

export default NoMatch;
