import dayjs from 'dayjs';
import produce from 'immer';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { restApi } from '../../../common/api';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PaginationMoon from '../../../components/PaginationMoon';
import { me } from '../../../data/auth';
import { useNote, userDetail } from '../../../components/NoteProvider';
import Modal from '../../../components/Modal';

export default function LmsNotesIndex() {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const note = useNote();

  const [, setSearchParams] = useSearchParams();
  const query = useParams();

  const [data, setData] = useState();
  const [details, setDetails] = useState();
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();

  const initialParams = React.useMemo(() => {
    const params = queryString.parse(window.location.search);
    return {
      keywordType: params.keywordType ?? '',
      keyword: params.keyword ?? '',
    };
  }, []);

  const [params, setParams] = React.useState(initialParams);

  React.useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const loadData = useCallback(async () => {
    if (!principal) return;
    const { data } = await restApi.get(`/notes/${query?.type}`, {
      params: {
        ...query,
        ...params,
      },
    });
    setData(data);
  }, [query, principal?.id, params]);

  useEffect(() => {
    setSelected([]);
    loadData().catch(console.warn);
  }, [loadData]);

  const type = query.type;

  const show = async (id) => {
    const { data } = await restApi.get(`/notes/${type}/${id}`);
    setDetails({ ...data, type });
    dispatch(me());
    await loadData();
  };

  const stored = async (type, id) => {
    await restApi.put(`/notes/${type}/${id}/store`);
  };

  const destroy = async (type, id) => {
    await restApi.delete(`/notes/${type}/${id}`);
  };
  const handleMultiDelete = async () => {
    if (!selected.length) {
      alert('1개 이상 항목을 선택해주세요.');
      return;
    }
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await Promise.all(selected?.map((x) => destroy(type, x)));
    await loadData();
  };

  const handleMultiStore = async () => {
    if (!selected.length) {
      alert('1개 이상 항목을 선택해주세요.');
      return;
    }
    await Promise.all(
      selected?.map(async (x) => {
        const details = data?.content?.find((y) => y.id === x);
        const t = details.account?.id === principal?.id ? 'receive' : 'send';
        await stored(t, x);
      }),
    );
    await loadData();
    if (type === 'store') {
      alert('보관 해제 되었습니다.');
    } else {
      alert('보관함으로 이동하였습니다.');
    }
  };

  const handleSingleDelete = async () => {
    if (!details) {
      alert('1개 이상 항목을 선택해주세요.');
      return;
    }
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await destroy(type, details?.id);
    await loadData();
    setDetails(undefined);
  };

  const handleSingleStore = async () => {
    if (!details) {
      alert('1개 이상 항목을 선택해주세요.');
      return;
    }
    const t = details.account?.id === principal?.id ? 'receive' : 'send';
    await stored(t, details?.id);
    await loadData();
    if (details?.isStore) {
      alert('보관해제 되었습니다.');
      setDetails((x) => ({ ...x, isStore: false }));
      setDetails(undefined);
    } else {
      alert('보관되었습니다.');
      setDetails((x) => ({ ...x, isStore: true }));
      setDetails(undefined);
    }
  };

  const isAllChecked =
    data?.content?.filter((x) => !selected.includes(x.id))?.length === 0 && data?.content?.length > 0;

  const handleAllClick = () => {
    if (!isAllChecked) {
      setSelected(data?.content?.map((x) => x.id));
    } else {
      setSelected([]);
    }
  };

  return (
    <>
      <div className="content-wrapper container-xxl p-0">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">쪽지함</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">쪽지함</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <Link to="/lms/notes/receive" className={`nav-link${query.type === 'receive' ? ' active' : ''}`}>
              받은 쪽지함
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/lms/notes/send" className={`nav-link${query.type === 'send' ? ' active' : ''}`}>
              보낸 쪽지함
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/lms/notes/store" className={`nav-link${query.type === 'store' ? ' active' : ''}`}>
              쪽지보관함
            </Link>
          </li>
        </ul>
        <div className="tab-content">
          <div className={`tab-pane active`} id="kr" aria-labelledby="kr-tab" role="tabpanel">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="col-md-6 col-12 ms-auto">
                      <fieldset>
                        <form
                          className="input-group"
                          onSubmit={(event) => {
                            event.preventDefault();
                            setParams(
                              produce((draft) => {
                                draft.keywordType = event.target.keywordType.value;
                                draft.keyword = event.target.keyword.value;
                              }),
                            );
                          }}
                        >
                          <select className="form-select" name="keywordType" defaultValue={initialParams.keywordType}>
                            <option value="">전체</option>
                            <option value="user">이름</option>
                            <option value="contents">내용</option>
                          </select>
                          <input
                            name="keyword"
                            type="text"
                            className="form-control"
                            placeholder=""
                            aria-label="Amount"
                            defaultValue={initialParams.keyword}
                          />
                          <button className="btn btn-outline-primary waves-effect" type="submit">
                            검색
                          </button>
                        </form>
                      </fieldset>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered visual-box">
                      <colgroup>
                        <col style={{ width: '4rem' }} />
                        <col style={{ width: '4rem' }} />
                        <col style={{ width: '13rem' }} />
                        <col />
                        <col style={{ width: '13rem' }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>
                            <input
                              className="form-check-input"
                              id=""
                              type="checkbox"
                              checked={isAllChecked}
                              onChange={handleAllClick}
                            />
                          </th>
                          <th>개봉</th>
                          <th>{type === 'send' ? '받은사람' : '보낸사람'}</th>
                          <th>내용</th>
                          <th>받은 날짜</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.content.map((note) => {
                          const findIndex = selected.indexOf(note.id);
                          const checked = findIndex > -1;

                          return (
                            <tr key={`Note-${note.id}`}>
                              <td className="txt-c">
                                <input
                                  className="form-check-input"
                                  id=""
                                  type="checkbox"
                                  checked={checked}
                                  onChange={() => {
                                    setSelected(
                                      produce((draft) => {
                                        if (checked) {
                                          draft.splice(findIndex, 1);
                                        } else {
                                          draft.push(note.id);
                                        }
                                      }),
                                    );
                                  }}
                                />
                              </td>
                              <td className="txt-c">
                                {!note.readAt && note.account.id === principal?.id ? (
                                  <i className="icon-message unread"></i>
                                ) : note.isReply ? (
                                  <i className="icon-message reply"></i>
                                ) : (
                                  <i className="icon-message"></i>
                                )}
                              </td>
                              <td>{type === 'send' ? userDetail(note.account) : userDetail(note.sender)}</td>
                              <td>
                                <a onClick={() => show(note?.id)}>{note.contents}</a>
                              </td>
                              <td className="txt-c">{dayjs(note.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mt-50">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-outline-danger me-1 waves-effect"
                      onClick={() => handleMultiDelete()}
                    >
                      삭제
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-dark me-1 waves-effect"
                      onClick={() => handleMultiStore()}
                    >
                      {type === 'store' ? '보관 해제' : '보관'}
                    </button>
                  </div>
                  <div className="col-md-6 txt-r">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-float waves-light"
                      onClick={() => note?.send()}
                    >
                      쪽지보내기
                    </button>
                  </div>
                </div>
                <nav aria-label="Page navigation">
                  <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal visible={!!details} className="fade text-start modal-primary">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">쪽지상세</h5>
              <button type="button" className="btn-close" onClick={() => setDetails(undefined)} />
            </div>
            <div className="modal-body">
              <div className="col-12 ms-auto">
                <fieldset>
                  <div className="input-group d-flex">
                    <span className="text-black me-1 mt-50">보낸사람</span>
                    <div className="inp-list">
                      <input
                        name="keyword"
                        type="text"
                        className="form-control bg-white"
                        placeholder=""
                        aria-label="Amount"
                        readOnly
                        value={details ? userDetail(details.sender) : ''}
                      />
                    </div>
                  </div>
                  <div className="input-group d-flex mt-50">
                    <span className="text-black me-1 mt-50">받는사람</span>
                    <div className="inp-list">
                      <input
                        name="keyword"
                        type="text"
                        className="form-control bg-white"
                        placeholder=""
                        aria-label="Amount"
                        readOnly
                        value={details ? userDetail(details.account) : ''}
                      />
                    </div>
                  </div>
                  <div className="input-group d-flex mt-50">
                    <span className="text-black me-1 mt-50">받은시간</span>
                    <div className="inp-list">
                      <input
                        name="keyword"
                        type="text"
                        className="form-control bg-white"
                        placeholder=""
                        aria-label="Amount"
                        readOnly
                        value={details ? dayjs(details?.createdAt).format('YYYY-MM-DD HH:mm') : ''}
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="col-12 my-75">
                <div className="form-floating mb-0">
                  <textarea
                    name="contents"
                    data-length={20}
                    className="form-control char-textarea bg-white"
                    id="textarea-counter"
                    rows={3}
                    placeholder="Counter"
                    style={{ height: 200 }}
                    value={details ? details?.contents : ''}
                    readOnly
                  />
                  <label htmlFor="textarea-counter">Counter</label>
                </div>
              </div>
              <small className="text-muted">※ 읽은 쪽지는 30일 후에 자동으로 삭제됩니다.</small>
            </div>
            <div className="modal-footer">
              {query.type === 'receive' && (
                <button
                  className="btn btn-primary waves-effect"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#btnReply"
                  onClick={() => {
                    setDetails(undefined);
                    const originNote = `
\n\n\n
-----------------------------------
보낸사람 : ${userDetail(details.sender)}
받은시간 : ${dayjs(details.createdAt).format('YYYY-MM-DD HH:mm')}
내용 :
${details.contents}
`;
                    note.send([details.sender], originNote, details.id);
                  }}
                >
                  답장
                </button>
              )}
              <button className="btn btn-outline-primary waves-effect" onClick={() => handleSingleStore()}>
                {details?.isStore ? '보관해제' : '보관'}
              </button>
              <button className="btn btn-outline-primary waves-effect" onClick={() => handleSingleDelete()}>
                삭제
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
