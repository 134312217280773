import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAPIHost, restApi } from '../../../../../common/api';
import dayjs from 'dayjs';

export default function SiteSiteManagerPopUpIdIndex() {
  const navigate = useNavigate();
  const query = useParams();

  const [data, setData] = useState();

  const loadData = async () => {
    const { data } = await restApi.get(`/banners/${query.id}`);
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [query.id]);

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">팝업 관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">사이트관리</Link>
                  </li>
                  <li className="breadcrumb-item active">팝업 관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        팝업명<span className="require">*</span>
                      </label>
                    </th>
                    <td>{data?.title}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-title">
                        팝업 종류
                      </label>
                    </th>
                    <td>{data?.type === 'TOP' ? '띠배너' : '메인팝업'}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="fp-range">
                        팝업 노출기간<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      {dayjs(data?.startAt).format('YYYY-MM-DD')} ~ {dayjs(data?.endAt).format('YYYY-MM-DD')}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        팝업 링크
                      </label>
                    </th>
                    <td>
                      {/* <span className="col-12 me-2">새창</span>
                      https://imba.ac.kr/ */}
                      {data?.linkTarget && <span className="col-12 me-2"></span>}
                      {data?.link}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-left">
                        팝업 위치
                      </label>
                    </th>
                    <td>
                      X축: {data?.dimensions?.x}px / Y축: {data?.dimensions?.y}px
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">노출여부</label>
                    </th>
                    <td>{data?.active ? '노출' : '미노출'}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일(web)
                      </label>
                    </th>
                    <td>
                      <div className="d-flex flex-column flex-md-row">
                        <img src={`${getAPIHost()}api/files/${data?.imageId}`} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일(mobile)
                      </label>
                    </th>
                    <td>
                      <div className="d-flex flex-column flex-md-row">
                        <img src={`${getAPIHost()}api/files/${data?.imageIdM}`} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button
              type="submit"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={() => {
                navigate(`/site/siteManager/popup/${query.id}/edit`);
              }}
            >
              수정
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/site/siteManager/popup');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
