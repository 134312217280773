import React, { useCallback, useContext, useEffect, useState } from 'react';
import { restApi } from '../../../common/api';
import dayjs from 'dayjs';
import { CONTENT_TYPES } from '../../../common/define';
import { parseTime } from '../../../common/utils';
import PaginationMoon from '../../../components/PaginationMoon';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { VideoContext } from '../../../components/VideoUploadProvider';

const LmsContentsIndex = (props) => {
  const context = useContext(VideoContext);
  const [data, setData] = useState();
  const { lectureData, lectureId } = useSelector((s) => s.common, shallowEqual);

  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));
  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const loadData = useCallback(
    async (page) => {
      const { data } = await restApi.get(`/contents`, {
        params: { ...params, page, mainClassId: lectureData?.mainClass?.id },
      });
      setData(data);
    },
    [lectureData?.mainClass?.id, params],
  );
  useEffect(() => {
    loadData(0).catch(console.warn);
  }, [loadData]);

  const handlePreview = (id) => {
    context
      .showPreview(lectureId, id, function () {
        loadData(params.page ?? 0).catch(console.warn);
      })
      .catch(console.warn);
  };

  return (
    <>
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">콘텐츠 관리</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">콘텐츠 관리</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // header e */}

        {/* Bordered table start */}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                {/* Inputs Group with Dropdown */}
                <div className="col-md-6 col-12 ms-auto">
                  <fieldset>
                    <form
                      onSubmit={(event) => {
                        event.preventDefault();
                        setParams({
                          keyword: event.target.keyword.value,
                          keywordType: event.target.keywordType.value,
                          type: params.type ?? '',
                        });
                      }}
                    >
                      <div className="input-group">
                        <select
                          className="form-select"
                          id="selectDefault"
                          value={params.type ?? ''}
                          onChange={(e) => {
                            setParams((x) => ({ ...x, type: e.target.value }));
                          }}
                        >
                          <option value={''}>속성별</option>
                          {Object.keys(CONTENT_TYPES)?.map((key) => (
                            <option key={key} value={key}>
                              {CONTENT_TYPES[key]}
                            </option>
                          ))}
                        </select>
                        <select
                          name="keywordType"
                          className="form-select"
                          id="selectDefault"
                          defaultValue={params.keywordType}
                        >
                          <option value={''}>전체</option>
                          <option value={'title'}>제목</option>
                          <option value={'contents'}>내용</option>
                        </select>

                        <input
                          name="keyword"
                          type="text"
                          className="form-control"
                          placeholder="제목, 내용 검색"
                          aria-label="Amount"
                          defaultValue={params.keyword}
                        />
                        <button className="btn btn-outline-primary waves-effect" type="submit">
                          검색
                        </button>
                      </div>
                    </form>
                  </fieldset>
                </div>
                {/* Inputs Group with Dropdown end */}
              </div>

              <div className="table-responsive bd-top">
                <table className="table visual-box">
                  <colgroup>
                    <col />
                    <col style={{ width: '11rem' }} />
                    <col style={{ width: '11rem' }} />
                  </colgroup>
                  <tbody>
                    {data?.content?.map((item) => (
                      <tr key={`${item.id}`}>
                        <td>
                          <h4 className="text-black mb-1">
                            <a onClick={() => handlePreview(item.id)}>{item.title}</a>
                          </h4>
                          <p className="txt-info">
                            <span className="d-block">
                              <strong>등록</strong>
                              {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                            </span>
                            <span className="d-block">
                              <strong>동영상시간</strong>
                              {parseTime(item.videoDuration)}
                            </span>
                            <span className="d-block">
                              <strong>수정</strong>
                              {!!item.updatedOn && dayjs(item.updatedOn).format('YYYY-MM-DD HH:mm:ss')}
                            </span>
                          </p>
                        </td>
                        <td className="txt-c">{item.mainClass?.title}</td>
                        <td className="txt-c">
                          <div className="row mx-0">
                            <p className="text-primary">{CONTENT_TYPES[item.type] ?? item.type}</p>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-12 txt-r">
              <button
                type="button"
                className="btn btn-primary waves-effect waves-float waves-light"
                onClick={() => {
                  context.createForm('', () => {
                    loadData(0).catch(console.warn);
                  });
                }}
              >
                등록
              </button>
            </div>

            {/* pagination s */}
            <nav aria-label="Page navigation">
              <PaginationMoon data={data} onClick={loadData} />
            </nav>
            {/* // pagination e */}
          </div>
        </div>
        {/* Bordered table end */}
        {/* // con e */}
      </div>
    </>
  );
};

export default LmsContentsIndex;
