import { Editor } from '@tinymce/tinymce-react';
import React, { useRef, useState } from 'react';
import { editorInitOptions } from '../../../../common/utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import UserSelect from '../../../../components/UserSelect';
import { restApi } from '../../../../common/api';

export default function LmsTalksMailWrite() {
  const navigate = useNavigate();
  const editorRef = useRef();
  const { state } = useLocation();

  const [formData, setFormData] = useState({
    contentsWithMarkup: state ? state.message : undefined,
    title: state ? state.message : undefined,
    type: 'MAIL',
    users: state ? state.ids : [],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      contentsWithMarkup: editorRef.current.getContent(),
      lectureId: state ? state.lectureId : undefined,
    };

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return;
    }
    if (payload.users.length < 1) {
      alert('받는 사람을 선택해주세요.');
      return;
    }
    if (!payload.contentsWithMarkup) {
      alert('내용을 입력해주세요.');
      return;
    }

    await restApi.post(`/broadcasts`, payload);
    alert('저장되었습니다.');
    navigate('/lms/talks/mail');
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">메시지 보내기</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">메시지 보내기</li>
                  <li className="breadcrumb-item active">메일 보내기</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link
            className="nav-link"
            id="tab1"
            data-bs-toggle="page"
            to="/lms/talks/messages/write"
            aria-controls="tab1"
            role="tab"
            aria-selected="false"
          >
            SMS 보내기
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link active"
            id="tab2"
            data-bs-toggle="page"
            to="/lms/talks/mail/write"
            aria-controls="tab2"
            role="tab"
            aria-selected="true"
          >
            메일 보내기
          </Link>
        </li>
      </ul>
      {/* con s */}
      <div className="row" id="table-bordered">
        <form className="col-12" onSubmit={handleSubmit}>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">제목</label>
                    </th>
                    <td>
                      <input
                        value={formData.title}
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={(e) => {
                          const v = e.target.value;
                          setFormData((x) => ({
                            ...x,
                            title: v,
                          }));
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">보내는 사람</label>
                    </th>
                    <td>imba@skku.edu</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">받는 사람</label>
                    </th>
                    <td>
                      <UserSelect
                        title={'찾아보기'}
                        type={'USER'}
                        maxLength={500}
                        name="users"
                        values={formData.users}
                        onChange={(v) => {
                          setFormData((x) => ({ ...x, users: v ?? [] }));
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">메일 내용</label>
                    </th>
                    <td>
                      <Editor
                        initialValue={formData.contentsWithMarkup}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={editorInitOptions}
                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-float waves-light me-1"
              data-bs-toggle="modal"
              data-bs-target="#btnSend"
            >
              메일 발송하기
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/lms/talks/mail');
              }}
            >
              취소
            </button>
          </div>
        </form>
      </div>
      {/* // con e */}
    </div>
  );
}
