import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { getConfigValue, restApi } from '../common/api';

export const TOKEN_STORE_KEY = 'imba-tokens';

const FrontRedirect = ({ onFinish, link }) => {
  const iframe = useRef();

  const [loaded, setLoaded] = useState(false);

  useLayoutEffect(() => {
    if (!loaded) return;

    const onMessage = function (e) {
      window.location.href = link;
    };

    window.addEventListener('message', onMessage);
    const initToken = JSON.parse(window?.sessionStorage?.getItem(TOKEN_STORE_KEY) ?? '{}');
    iframe.current.contentWindow.postMessage(initToken, '*');

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [loaded]);

  return (
    <div>
      <iframe ref={iframe} src={`${getConfigValue('WEB_HOST')}auth_copy.html`} onLoad={() => setLoaded(true)} />
    </div>
  );
};

export default FrontRedirect;
