import React, { useEffect, useMemo } from 'react';
import './App.css';
import AppContainer from './nav/AppContainer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppReducer from './data/reducer';
import SignIn from './components/SignIn';
import { clearPrincipal, me } from './data/auth';
import { setToken, TOKEN_STORE_KEY } from './common/api';
import { getMenuItems } from './common/define';
import NoMatch from './components/NoMatch';
import HomeIndex from './pages';

import 'dayjs/locale/ko';
import dayjs from 'dayjs';
import { getCodes, getSemesters } from './data/common';
import VideoUploadProvider from './components/VideoUploadProvider';
import PageRedirect from './pages/redirect';

dayjs.locale('ko');

function RouteView() {
  const [init, setInit] = React.useState(false);
  const initToken = JSON.parse(window?.sessionStorage?.getItem(TOKEN_STORE_KEY) ?? '{}');

  const dispatch = useDispatch();
  useEffect(() => {
    const loadData = async () => {
      setInit(false);
      try {
        // Login
        if ((Boolean(initToken?.access_token) && Boolean(initToken?.uuid)) || Boolean(initToken?.access_token)) {
          await setToken(initToken);
          await dispatch(me());
        }
      } catch (error) {
        console.log(error);
        setToken(null, true);
        dispatch(clearPrincipal());
      }

      dispatch(getCodes());

      const { payload } = await dispatch(me()).catch(console.warn);
      if (['ADMIN', 'CP'].includes(payload?.principal?.type)) {
        dispatch(getSemesters());
      }
      setInit(true);
    };
    loadData().catch(console.warn);
  }, []);

  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const routes = useMemo(() => {
    return getMenuItems(principal, false);
  }, [principal?.type, principal?.unlimitPrivacy]);

  if (!init) return null;

  const t = principal?.unlimitPrivacy;
  const lockPrivacy = !t || dayjs().add(-1, 'hours').isAfter(dayjs(t));

  return (
    <AppContainer>
      {!principal ? (
        <SignIn />
      ) : (
        <VideoUploadProvider>
          <Routes>
            <Route path="/" index element={<HomeIndex />} />
            {routes?.map((route) => {
              const Element = route.component;
              const allowPrivacy = !route.privacy || !lockPrivacy;
              if (!allowPrivacy) return undefined;
              return <Route key={route.path} path={route.path} element={<Element />} extra={'1'} />;
            })}
            <Route path={'/redirect'} element={<PageRedirect />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </VideoUploadProvider>
      )}
    </AppContainer>
  );
}

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={AppReducer}>
        <BrowserRouter>
          <RouteView />
        </BrowserRouter>
      </Provider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
