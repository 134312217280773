import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import FrontRedirect from '../components/FrontRedirect';

const PageRedirect = () => {
  const { search } = useLocation();
  const p = useMemo(() => {
    return qs.parse(search.substring(1));
  }, [search]);

  return <FrontRedirect link={p.r} />
};

export default PageRedirect;
