import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { editorInitOptions } from '../../../../common/utils';
import FileAttacher from '../../../../components/FileAttacher';
import { restApi } from '../../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';
import { useLoading } from '../../../../nav/AppContainer';

export default function SiteBoardNoticeWrite() {
  const { codes } = useSelector((s) => s.common, shallowEqual);

  const navigate = useNavigate();
  const query = useParams();

  const attacher = useRef();
  const editorRef = useRef();

  const [initData, setInitData] = useState({
    isExposure: false,
    isImportant: false,
  });
  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/web/messages/${query.id}`);
        setInitData(data);
      };
      loadData().catch(console.warn);
    } else {
      setInitData((x) => ({ ...x, category: 'BCNO002' }));
    }
  }, [query?.id]);

  const [loading, setLoading] = useLoading();

  const handleOnchange = (e) => {
    const v = e.target.value;
    setInitData((x) => ({
      ...x,
      [e.target.name]: v,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (initData?.category !== 'BCNO002') {
      setInitData((x) => ({ ...x, isExposure: false, isImportant: false }));
    }
    const payload = {
      ...initData,
      type: 'NOTICE',
      contentsWithMarkup: editorRef.current.getContent(),
    };

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return false;
    }

    if (!payload.contentsWithMarkup) {
      alert('내용을 입력해주세요.');
      return false;
    }

    setLoading(true);
    try {
      const ids = await attacher.current.getFileIds();
      payload.files = ids;

      if (query?.id) {
        await restApi.put(`/web/messages/${query?.id}`, payload);
        alert('저장되었습니다.');
        await navigate(`/site/board/notice/${query?.id}`);
      } else {
        await restApi.post(`/web/messages`, payload);
        alert('등록되었습니다.');
        await navigate('/site/board/notice?category=' + (query.category ?? ''));
      }
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  };

  if (query.id && !initData) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit} className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">공지사항</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#n">게시판 관리</Link>
                  </li>
                  <li className="breadcrumb-item active">공지사항</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-title">
                        카테고리
                      </label>
                    </th>
                    <td>
                      {initData?.category && (
                        <select
                          className="form-select"
                          id="blog-edit-title"
                          style={{ width: 170 }}
                          name={'category'}
                          defaultValue={initData.category}
                          onChange={handleOnchange}
                        >
                          {codes?.tree?.BCNO?.map((code) => (
                            code.code === 'BCNO007' || code.code === 'BCNO008' ||
                            <option value={code.code} key={code.code}>
                              {code.label}
                            </option>
                          ))}
                        </select>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        제목<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="flex">
                        <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control flex-grow-1 w-auto"
                          name={'title'}
                          defaultValue={initData?.title}
                          onChange={handleOnchange}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        내용<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <Editor
                        initialValue={initData?.contentsWithMarkup}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={editorInitOptions}
                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일
                      </label>
                    </th>
                    <td>
                      <FileAttacher ref={attacher} values={initData?.files} limitSize={100} />
                    </td>
                  </tr>
                  {(initData?.category === 'BCNO001' || initData?.category === 'BCNO002' || initData?.category === 'BCNO006') && (
                    <>
                      <tr>
                        <th>
                          <label className="form-label">중요공지</label>
                        </th>
                        <td className="d-flex align-items-center gap-1">
                          <label>
                            <input
                              className="form-check-input me-50"
                              type="checkbox"
                              name="isImportant"
                              defaultChecked={initData?.isImportant}
                              onChange={() => {
                                setInitData((prev) => ({ ...prev, isImportant: !prev.isImportant }));
                              }}
                            />
                            선택
                          </label>
                          <div className="d-flex align-items-center">
                            <span className="ms-2 me-1">노출 기간</span>
                            <input
                              type="date"
                              className="form-control flatpickr-basic flatpickr-input me-25"
                              placeholder="YYYY-MM-DD"
                              style={{ width: 140 }}
                              name="startAt"
                              defaultValue={initData?.startAt}
                              onChange={handleOnchange}
                            />
                            <span className="ms-25 me-25"> ~ </span>
                            <input
                              type="date"
                              className="form-control flatpickr-basic flatpickr-input me-25"
                              placeholder="YYYY-MM-DD"
                              style={{ width: 140 }}
                              name="endAt"
                              defaultValue={initData?.endAt}
                              onChange={handleOnchange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">추가노출</label>
                        </th>
                        <td>
                          <label>
                            <input
                              className="form-check-input me-50"
                              type="checkbox"
                              name="isExposure"
                              defaultChecked={initData?.isExposure}
                              onChange={() => {
                                setInitData((prev) => ({ ...prev, isExposure: !prev.isExposure }));
                              }}
                            />
                            강의실 전체 공지
                          </label>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-50">
            <div className="col-md-6">※ 중요 공지 순서는 자동 지정되며, 순서 조정은 공지 관리에서 가능합니다.</div>
            {!loading && (
              <div className="col-md-6 txt-r">
                <button
                  type="submit"
                  className="btn btn-primary me-1 waves-effect waves-float waves-light"
                  data-bs-toggle="modal"
                  data-bs-target="#btnSave"
                >
                  저장
                </button>
                <button
                  type="reset"
                  className="btn btn-outline-secondary waves-effect"
                  onClick={() => {
                    navigate('/site/board/notice');
                  }}
                >
                  취소
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* // con e */}
    </form>
  );
}
