import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import dayjs from 'dayjs';
import { restApi } from '../../../../common/api';
import produce from 'immer';
import PaginationMoon from '../../../../components/PaginationMoon';

export default function SiteBoardFapIndex() {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/web/messages', {
      params: {
        ...params,
        type: 'IN_FAQ',
        sort: 'id',
        order: 'ASC',
      },
    });

    setData(data);
  }, [params]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const [selected, setSelected] = useState([]);
  const isAllChecked = data?.content?.map((v) => v.id).filter((x) => !selected.includes(x)).length === 0;
  const handleRemove = async () => {
    if (!selected.length) {
      alert('선택 후 클릭해주세요.');
      return;
    }

    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    await Promise.all(
      selected.map((id) => {
        return restApi.delete(`/web/messages/${id}`);
      }),
    );

    loadData();
  };
  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">입학 FAQ</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#n">게시판 관리</Link>
                  </li>
                  <li className="breadcrumb-item active">입학 FAQ</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link
            className="nav-link active"
            id="kr-tab"
            data-bs-toggle="tab"
            to="#kr"
            aria-controls="kr"
            role="tab"
            aria-selected="true"
          >
            국문
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link
            className="nav-link"
            id="en-tab"
            data-bs-toggle="tab"
            to="#en"
            aria-controls="en"
            role="tab"
            aria-selected="false"
          >
            영문
          </Link>
        </li> */}
      </ul>
      <div className="tab-content">
        <div className="tab-pane active" id="kr" aria-labelledby="kr-tab" role="tabpanel">
          <div className="row" id="table-bordered">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="col-md-6 col-12 ms-auto">
                    <fieldset>
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                          setParams({
                            keyword: event.target.keyword.value,
                            keywordType: event.target.keywordType.value,
                          });
                        }}
                      >
                        <div className="input-group">
                          <select
                            name="keywordType"
                            className="form-select"
                            id="selectDefault"
                            defaultValue={params.keywordType}
                          >
                            <option value={''}>전체</option>
                            <option value={'title'}>제목</option>
                          </select>
                          <input
                            name="keyword"
                            type="text"
                            className="form-control"
                            placeholder=""
                            aria-label="Amount"
                            defaultValue={params.keyword}
                          />
                          <button className="btn btn-outline-primary waves-effect" type="submit">
                            검색
                          </button>
                        </div>
                      </form>
                    </fieldset>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '4rem' }} />
                      <col style={{ width: '6rem' }} />
                      <col />
                      <col style={{ width: '12%' }} />
                      <col style={{ width: '12%' }} />
                      <col style={{ width: '14%' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          <input
                            className="form-check-input"
                            id=""
                            type="checkbox"
                            checked={isAllChecked}
                            onClick={() => {
                              if (isAllChecked) {
                                setSelected([]);
                              } else {
                                setSelected(data?.content?.map((v) => v.id));
                              }
                            }}
                          />
                        </th>
                        <th>번호</th>
                        <th>제목</th>
                        <th>첨부파일</th>
                        <th>작성자</th>
                        <th>등록일</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.content?.map((item, ix) => (
                        <tr key={`${item.id}`}>
                          <td className="txt-c">
                            <input
                              className="form-check-input"
                              id=""
                              type="checkbox"
                              checked={selected?.includes(item.id)}
                              onClick={() => {
                                setSelected(
                                  produce((draft) => {
                                    const ix = draft.indexOf(item.id);
                                    if (ix >= 0) {
                                      draft.splice(ix, 1);
                                    } else {
                                      draft.push(item.id);
                                    }
                                  }),
                                );
                              }}
                            />
                          </td>
                          <td className="txt-c">{ix + 1 + data?.size * data?.number}</td>

                          <td>
                            <Link to={`/site/board/faq/${item.id}`}>{item.title}</Link>
                          </td>
                          <td className="txt-c">
                            {item.files?.length > 0 && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={14}
                                height={14}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-paperclip"
                              >
                                <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                              </svg>
                            )}
                          </td>
                          <td className="txt-c">{item.writer}</td>
                          <td className="txt-c">{dayjs(item.createdAt).format('YYYY-MM-DD')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row mt-50">
                <div className="col-md-6">
                  <button type="button" className="btn btn-outline-danger waves-effect" onClick={handleRemove}>
                    삭제
                  </button>
                </div>
                <div className="col-md-6 txt-r">
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-float waves-light"
                    onClick={() => {
                      navigate('/site/board/faq/write');
                    }}
                  >
                    등록
                  </button>
                </div>
              </div>

              <nav aria-label="Page navigation">
                <PaginationMoon
                  data={data}
                  onClick={(page) => {
                    setParams((x) => ({ ...x, page }));
                  }}
                />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
