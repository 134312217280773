import React, { createContext, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAPIHost, restApi } from '../common/api';
import { getMenuItems } from '../common/define';
import { Link, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { clearPrincipal, me } from '../data/auth';
import CustomScroll from 'react-custom-scroll';
import HeadLectures from '../components/HeadLectures';
import NoteProvider from '../components/NoteProvider';
import { fetchToken, messaging } from '../common/firebase';
import { onMessage } from 'firebase/messaging';
import dayjs from 'dayjs';
import MenuIco from '../components/icons/MenuIco';
import Modal from '../components/Modal';

const USER_TYPE = {
  TEACHER: '교수',
  STAFF: '튜터',
  CP: '컨텐츠관리자',
  ADMIN: '지원실',
  MANAGER: '행정실',
  EXAMSTAFF: '시험감독관',
};

const LayoutContext = createContext({});

export const useLoading = () => {
  const { loading, setLoading } = useContext(LayoutContext) ?? {};
  return [loading, setLoading];
};

function formatTime(minutes) {
  const mins = Math.floor(minutes % 60);
  const paddedMins = mins < 10 ? `0${mins}` : mins;
  const hours = Math.floor(minutes / 60);
  const paddedHours = hours < 10 ? `0${hours}` : hours;
  return `${paddedHours}:${paddedMins}`;
}

const MenuItem = ({ item, ixData, focus, onClick }) => {
  const [open, setOpen] = useState();
  const items = item.items?.filter((x) => x.menu);
  let className = '';

  if (!!items?.length) {
    className += 'has-sub';
    if (open || focus) {
      className += ' sidebar-group-active open';
    }
  } else if (focus) {
    className = 'active';
  } // items?.length ? `has-sub` : ''} ${!open ? '' : 'sidebar-group-active open'} ${
  //       focus ? 'active' : ''

  return (
    <li className={`nav-item ${className}`}>
      {!!items?.length ? (
        <>
          <a className="d-flex align-items-center" onClick={() => setOpen((x) => !x)}>
            <span className="menu-title text-truncate" data-i18n="Chat">
              {item.menu}
            </span>
          </a>
          <ul className="menu-content">
            {items
              ?.filter((x) => !!x.menu)
              ?.map((child, idx) => (
                <li key={`${child.path}${idx}`} className={ixData?.[2] === idx ? 'active' : ''}>
                  <Link
                    className="d-flex align-items-center"
                    to={child.path}
                    onClick={(e) => {
                      const v = onClick?.(e, child);
                      if (v?.lock) {
                        e.preventDefault();
                        return;
                      }
                      // if (child.ready) {
                      //   e.preventDefault();
                      //   alert('준비중입니다.');
                      //   return;
                      // }
                    }}
                  >
                    <span className="menu-item text-truncate" data-i18n="List">
                      {child.menu}
                    </span>
                  </Link>
                </li>
              ))}
          </ul>
        </>
      ) : (
        <Link
          className="d-flex align-items-center"
          to={item.path}
          onClick={(e) => {
            const v = onClick?.(e, item);
            if (v?.lock) {
              e.preventDefault();
              return;
            }
            // if (item.ready) {
            //   e.preventDefault();
            //   alert('준비중입니다.');
            //   return;
            // }
          }}
        >
          <span className="menu-title text-truncate" data-i18n="Chat">
            {item.menu}
          </span>
        </Link>
      )}
    </li>
  );
};

const AppContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { principal, notifies } = useSelector((s) => s.auth, shallowEqual);

  const menus = useMemo(() => {
    return getMenuItems(principal, true);
  }, [principal?.type, principal?.permissionCodes?.length]);

  const routes = useMemo(() => {
    return getMenuItems(principal, false);
  }, [principal?.type, principal?.permissionCodes?.length]);

  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location) ?? [{}];

  const [loading, setLoading] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState();
  const [timer, setTimer] = useState(180);

  const handlePress = (e, menu) => {
    const t = principal?.unlimitPrivacy;
    if (menu?.privacy && (!t || dayjs().add(-1, 'hours').isAfter(dayjs(t)))) {
      setLoading(true);
      restApi
        .post(`/accounts/help`, {
          type: 'SMS',
          action: 'ADMIN',
          name: principal?.name,
          userId: principal?.userId,
        })
        .then((r) => {
          setShowPrivacy(menu.path);
          setTimer(180);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
      return { lock: menu?.privacy, redirect: menu.path };
    }
    return undefined;
  };

  const navigate = useNavigate();
  const handlePrivacySubmit = async (e) => {
    e.preventDefault();
    const code = e.target.code.value;
    try {
      await restApi.get(`/accounts/help`, { params: { code } });
      await dispatch(me());
      if (typeof showPrivacy === 'string') {
        navigate(showPrivacy);
      }
      setShowPrivacy(false);
      setTimer(0);
    } catch (e) {
      alert('인증번호가 올바르지 않습니다.');
    }

    return false;
  };

  useLayoutEffect(() => {
    if (principal) {
      const loadToken = async () => {
        const token = await fetchToken();
        if (token) {
          await restApi.post(`/accounts/devices`, { agent: window.navigator.userAgent, fcm: token });
        }
        onMessage(messaging, () => dispatch(me()));
      };
      loadToken().catch(console.warn);
      window.$.app.menu.change(false);
    }
  }, [principal?.id]);

  useEffect(() => {
    if (!route?.name || !principal) return;
    const sendData = async () => {
      await restApi.post(`/account-histories`, {
        name: route?.name,
        path: location.pathname,
        mobile: window.innerWidth < 1023,
      });
    };
    sendData().catch(console.warn);
  }, [route, principal]);

  useEffect(() => {
    let remainingTime;
    if (showPrivacy && timer > 0) {
      remainingTime = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    if (showPrivacy && timer <= 0) {
      //타이머 0 되면
      setShowPrivacy(false);
      alert('인증 유효시간이 초과되었습니다.');
    }
    return () => clearInterval(remainingTime);
  }, [showPrivacy, timer]);

  return (
    <NoteProvider>
      <LayoutContext.Provider value={{ loading, setLoading }}>
        {loading && (
          <div className="loading-wrap">
            <div>
              <img src="/images/loading.gif" />
            </div>
          </div>
        )}
        {!!principal && (
          <>
            <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl admin-navbar">
              <div className="navbar-container d-flex content">
                <div className="bookmark-wrapper d-flex align-items-center">
                  <ul className="nav navbar-nav d-xl-none">
                    <li className="nav-item">
                      <a className="nav-link menu-toggle" href="#">
                        <MenuIco />
                      </a>
                    </li>
                  </ul>
                </div>
                {['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type) && <HeadLectures />}

                <ul className="nav navbar-nav align-items-center ms-auto">
                  <li className="nav-item dropdown-notifications navbar-dropdown dropdown">
                    <a
                      className="nav-link dropdown-toggle hide-arrow"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <img src="/images/lms/package.png" width={'26'} height={'30'} alt="알림아이콘" />
                      {!!principal?.notifyCount && <span className="alarm-count">{principal?.notifyCount}</span>}
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end py-0" style={{ width: 300 }}>
                      <li className="dropdown-notifications-list scrollable-container">
                        {!notifies?.length ? (
                          <p style={{ padding: '20px 0px', margin: 0, textAlign: 'center' }}>알림이 없습니다.</p>
                        ) : (
                          <ul className="list-group list-group-flush">
                            {notifies?.map((notify) => (
                              <li
                                key={notify?.id}
                                className="list-group-item list-group-item-action dropdown-notifications-item"
                              >
                                <Link to={notify.link}>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <p className="mb-0">{notify?.contents}</p>
                                      <small className="text-muted">
                                        {dayjs(notify?.createdAt).format('YYYY-MM-DD')}
                                      </small>
                                    </div>
                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                      <a href="javascript:void(0)" className="dropdown-notifications-read">
                                        <span className="badge badge-dot" />
                                      </a>
                                      <a href="javascript:void(0)" className="dropdown-notifications-archive">
                                        <span className="bx bx-x" />
                                      </a>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item me-25 ">
                    <Link to={'/lms/notes/receive'} className="nav-link btn-gnb-message">
                      <img src="/images/lms/ico-message.png" width={'25'} height={'30'} alt="쪽지아이콘" />
                      {!!principal?.noteCount && <span className="alarm-count">{principal?.noteCount}</span>}
                    </Link>
                  </li>

                  <li className="nav-item dropdown dropdown-user d-none d-xl-flex d-lg-flex">
                    <Link to="/profile" className="nav-link dropdown-toggle dropdown-user-link">
                      <div className="user-nav d-sm-flex d-none">
                        <span className="user-name fw-bolder">{principal?.name}</span>
                        <span className="user-status">{USER_TYPE[principal?.type]}</span>
                      </div>
                      <span className="avatar">
                        <img
                          className="round"
                          src={getAPIHost() + 'api/files/' + principal?.imageId}
                          alt="avatar"
                          height="40"
                          width="40"
                        />
                        <span className="avatar-status-online"></span>
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item d-none d-xl-flex d-lg-flex">
                    <a className="nav-link btn-gnb-message" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                      <MenuIco />
                    </a>
                    <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-user">
                      <Link className="dropdown-item" to={'/profile'}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={14}
                          height={14}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-user me-50"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>{' '}
                        Profile
                      </Link>

                      <button
                        className="dropdown-item"
                        onClick={async () => {
                          if (!window.confirm('로그아웃하시겠습니까?')) return;
                          await restApi.delete(`/accounts/authentication`);
                          window.sessionStorage.clear();
                          dispatch(clearPrincipal());
                          window.location.reload();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={14}
                          height={14}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-power me-50"
                        >
                          <path d="M18.36 6.64a9 9 0 1 1-12.73 0" />
                          <line x1={12} y1={2} x2={12} y2={12} />
                        </svg>{' '}
                        Logout
                      </button>
                    </div>
                  </li>
                </ul>

                {/* 오른쪽 관련 */}
              </div>
            </nav>

            <div className="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true">
              <div className="navbar-header">
                <ul className="nav navbar-nav flex-row">
                  <li className="nav-item me-auto">
                    <Link className="navbar-brand" to="/">
                      <span className="brand-logo">
                        <img src="/assets/images/hLogo_1.png" alt="" />
                      </span>
                      <h2 className="brand-text">
                        <img src="/assets/images/hLogo_2.png" alt="" />
                      </h2>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="shadow-bottom"></div>
              <div className="main-menu-content">
                <CustomScroll heightRelativeToParent="calc(100% - 20px)">
                  <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                    {/* 메뉴들 */}
                    {menus
                      ?.filter((x) => !!x.items.filter((x) => !!x.menu)?.length)
                      ?.map((menu, ix) => (
                        <React.Fragment key={'depth' + ix}>
                          <li className="navigation-header">
                            <span data-i18n="Pages">{menu.label}</span>
                          </li>
                          {menu.items
                            ?.filter((x) => !!x.menu)
                            ?.map((sub, ix2) => {
                              const focus = route?.ix?.[0] === ix && route?.ix?.[1] === ix2;
                              return (
                                <MenuItem
                                  key={sub?.path ?? ix2}
                                  item={sub}
                                  ixData={focus ? route?.ix : []}
                                  focus={focus}
                                  onClick={handlePress}
                                />
                              );
                            })}
                        </React.Fragment>
                      ))}
                  </ul>
                </CustomScroll>
              </div>
            </div>
          </>
        )}

        <div className={`app-content ${!principal ? 'no-menu' : 'content'}`}>{children}</div>

        <div className="sidenav-overlay"></div>
        <div className="drag-target"></div>
        {!!principal && (
          <footer className="footer footer-static footer-light">
            <p className="clearfix mb-0">
              <span className="float-md-start d-block d-md-inline-block mt-25">
                COPYRIGHT &copy;
                <a className="ms-25" href="https://www.imba.ac.kr" target="_blank" rel="noreferrer">
                  IMBA.{' '}
                </a>
                <span className="d-none d-sm-inline-block"> All rights Reserved</span>
              </span>
            </p>
          </footer>
        )}
        <Modal visible={showPrivacy} className={'fade text-start modal-primary'}>
          <div className="modal-dialog modal-dialog-centered">
            <form className="modal-content" onSubmit={handlePrivacySubmit}>
              <div className="modal-header">
                <h5 className="modal-title">SMS 인증</h5>
                <button type="button" className="btn-close" onClick={() => setShowPrivacy(false)} />
              </div>
              <div className="modal-body">
                {/* Inputs Group with Dropdown */}
                <p className="text-center mt-1">
                  <b>{principal?.name}</b> 님<br />
                  문자로 전송된 인증번호를 확인하신 후 아래에 인증번호를 입력해 주세요​
                </p>
                <p className="text-end me-1">유효시간 : {formatTime(timer)}</p>
                <div className="col-12 ms-auto">
                  <fieldset>
                    <div className="d-flex" style={{ flexWrap: 'wrap', gap: 15, marginTop: 10 }}>
                      <div className="input-group d-flex">
                        <span className="text-black me-1 mt-50">인증번호</span>
                        <div className="inp-list">
                          {showPrivacy && (
                            <input
                              name="code"
                              type="text"
                              className="form-control"
                              placeholder=""
                              aria-label="Amount"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary waves-effect">
                  활성화
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary waves-effect"
                  onClick={() => setShowPrivacy(undefined)}
                >
                  취소
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </LayoutContext.Provider>
    </NoteProvider>
  );
};

export default AppContainer;
