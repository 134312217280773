import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../common/api';
import UserSelect from '../../../components/UserSelect';

const datePattern = /^20[1-9][0-9]-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
function isValidDate(date) {
  return datePattern.test(date);
}

export default function LmsBachelorWrite() {
  const { codes, semesters } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const query = useParams();

  const [formData, setFormData] = useState({
    endAt: '',
    listenEndAt: '',
    listenStartAt: '',
    mainClassId: undefined,
    semesterId: undefined,
    staffIds: [],
    examStaffIds: [],
    startAt: '',
    point: undefined,
    active: undefined,
  });

  const [subject, setSubject] = useState();
  const loadSubjectData = useCallback(async () => {
    const { data } = await restApi.get('/admin/main-classes', { params: { limit: 1000 } });
    setSubject(data);
  }, []);

  useEffect(() => {
    loadSubjectData();
  }, [loadSubjectData]);

  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/lectures/${query.id}`);
        setFormData((x) => ({
          ...x,
          mainClassId: data?.mainClass.id,
          semesterId: data?.semester.id,
          staffIds: data?.staffs.map((staff) => staff.id),
          examStaffIds: data?.examStaffs.map((examStaff) => examStaff.id),
          startAt: data?.startAt,
          endAt: data?.endAt,
          listenEndAt: data?.listenEndAt,
          listenStartAt: data?.listenStartAt,
          point: data?.point,
          active: data?.active ? '운영' : '미운영',
        }));
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      active: e.target.active.value === '운영' ? true : false,
    };

    if (!payload.semesterId) {
      alert('년도/학기를 선택해주세요.');
      return false;
    }
    if (!payload.mainClassId) {
      alert('과목명을 선택해주세요.');
      return false;
    }
    if (!payload.staffIds) {
      alert('조교명을 선택해주세요.');
      return false;
    }
    if (![payload.startAt, payload.endAt, payload.listenEndAt, payload.listenStartAt].every(isValidDate)) {
      alert('정확한 날짜를 입력해주세요. (YYYY-MM-DD)');
      return false;
    }
    if (!query.id && !payload.point) {
      alert('수료기준점수를 입력해주세요.');
      return false;
    }

    if (query?.id) {
      await restApi.put(`/lectures/${query?.id}`, payload);
      alert('저장되었습니다.');
      await navigate(`/lms/bachelor/${query?.id}`);
    } else {
      await restApi.post(`/lectures/`, payload);
      alert('등록되었습니다.');
      await navigate('/lms/bachelor/');
    }
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">학사관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">학사관리</li>
                  <li className="breadcrumb-item active">학사관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      {/* Bordered table start */}
      <form className="row" onSubmit={handleSubmit}>
        <div className="row" id="table-bordered">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label">년도 / 학기</label>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <select
                            className="form-select pe-3 me-75 w-auto"
                            value={formData.semesterId}
                            onChange={(e) => {
                              const inputValue = Number(e.target.value);
                              const selected = semesters.filter((obj) => obj.id === inputValue)[0];
                              setFormData((x) => {
                                return {
                                  ...x,
                                  semesterId: inputValue,
                                  startAt: selected.startAt,
                                  endAt: selected.endAt,
                                  listenStartAt: selected.listenStartAt,
                                  listenEndAt: selected.listenEndAt,
                                };
                              });
                            }}
                          >
                            <option>선택</option>
                            {semesters?.map((item) => (
                              <option value={item.id} key={`${item.id}`}>
                                {item.year}년 {codes?.map?.[item.session]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">과목명</label>
                      </th>
                      <td>
                        <select
                          className="form-select pe-3 w-auto"
                          value={formData.mainClassId}
                          name="mainClassId"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => {
                              return {
                                ...x,
                                mainClassId: Number(inputValue),
                              };
                            });
                          }}
                        >
                          <option>선택</option>
                          {subject?.content.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">조교명</label>
                      </th>
                      <td className="d-flex align-items-center">
                        <UserSelect
                          title={'찾아보기'}
                          type={'STAFF'}
                          maxLength={500}
                          name="staffIds"
                          values={formData.staffIds}
                          onChange={(v) => {
                            setFormData((x) => ({ ...x, staffIds: v ?? [] }));
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">시험감독관명</label>
                      </th>
                      <td className="d-flex align-items-center">
                        <UserSelect
                          title={'찾아보기'}
                          type={'EXAMSTAFF'}
                          maxLength={500}
                          name="examStaffIds"
                          values={formData.examStaffIds}
                          onChange={(v) => {
                            setFormData((x) => ({ ...x, examStaffIds: v ?? [] }));
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">운영기간</label>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control flatpickr-date-time flatpickr-input"
                            placeholder="YYYY-MM-DD"
                            value={formData.startAt}
                            style={{ width: 160 }}
                            name="startAt"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setFormData((x) => {
                                return {
                                  ...x,
                                  startAt: inputValue,
                                };
                              });
                            }}
                          />
                          <span className="hipen">~</span>
                          <input
                            type="text"
                            className="form-control flatpickr-date-time flatpickr-input"
                            placeholder="YYYY-MM-DD"
                            style={{ width: 160 }}
                            value={formData.endAt}
                            name="endAt"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setFormData((x) => {
                                return {
                                  ...x,
                                  endAt: inputValue,
                                };
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">청강기간</label>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control flatpickr-date-time flatpickr-input"
                            placeholder="YYYY-MM-DD"
                            style={{ width: 160 }}
                            value={formData.listenStartAt}
                            name="listenStartAt"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setFormData((x) => {
                                return {
                                  ...x,
                                  listenStartAt: inputValue,
                                };
                              });
                            }}
                          />
                          <span className="hipen">~</span>

                          <input
                            type="text"
                            className="form-control flatpickr-date-time flatpickr-input"
                            placeholder="YYYY-MM-DD"
                            style={{ width: 160 }}
                            value={formData.listenEndAt}
                            name="listenEndAt"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setFormData((x) => {
                                return {
                                  ...x,
                                  listenEndAt: inputValue,
                                };
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    {!query?.id && (
                      <tr>
                        <th>
                          <label className="form-label">수료기준</label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            총점
                            <input
                              type="text"
                              className="form-control me-25 ms-1"
                              style={{ width: 100 }}
                              name="point"
                              value={formData.point}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                setFormData((x) => {
                                  return {
                                    ...x,
                                    point: Number(inputValue),
                                  };
                                });
                              }}
                            />
                            점
                          </div>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>
                        <label className="form-label">상태</label>
                      </th>
                      <td>
                        <div
                          className="d-flex align-items-center"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => {
                              return {
                                ...x,
                                active: inputValue,
                              };
                            });
                          }}
                        >
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="active"
                              id="inlineRadio1"
                              value="운영"
                              checked={formData.active === '운영'}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio1">
                              운영
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="active"
                              id="inlineRadio2"
                              value="미운영"
                              checked={formData.active === '미운영'}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio2">
                              미운영
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 txt-r">
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/lms/bachelor');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
        {/* Bordered table end */}
        {/* // con e */}
      </form>
    </div>
  );
}
