import { useQuery } from '@tanstack/react-query';
import produce from 'immer';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getFilesHost, restApi } from '../../../../../common/api';
import { useNote } from '../../../../../components/NoteProvider';

export default function LmsLecturesMembersGroupsId() {
  const note = useNote();

  const { id } = useParams();
  const initialParams = React.useMemo(
    () => ({
      keywordType: 'all',
      keyword: '',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);
  const teamQuery = useQuery(
    ['lectures', lectureId, 'teams', id],
    () => restApi.get(`/lectures/${lectureId}/teams/${id}`).then(({ data: responseData }) => responseData),
    {
      enabled: !!lectureId && !!id,
    },
  );

  const navigate = useNavigate();

  const handleRemove = async (e) => {
    e.preventDefault();

    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/lectures/${lectureId}/teams/${id}`);
    alert('삭제되었습니다.');
    navigate(`/lms/lectures/members/groups`);
  };

  const teamSend = async (teamId) => {
    const { data } = await restApi.get(`/lectures/${lectureId}/teams/${teamId}`);
    note.send(data?.users.concat([data?.master]));
  };

  return (
    <>
      <div className="content-wrapper container-xxl p-0">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">멤버</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">멤버</li>
                    <li className="breadcrumb-item active">그룹</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <Link to="/lms/lectures/members/members" className="nav-link" role="tab">
              원우관리
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/lms/lectures/members/groups" className="nav-link active" role="tab">
              그룹관리
            </Link>
          </li>
        </ul>
        <div className="row">
          <div className="col-12">
            <div className="card mb-1">
              <div className="row d-flex box-flex box-flex2">
                <div className="left">
                  <h4>{teamQuery.data?.title}</h4>
                  <p className="txt-info">
                    <strong>조장</strong>
                    {teamQuery.data?.master?.name}
                  </p>
                  <p className="txt-info">
                    <strong>조원</strong>
                    {teamQuery.data?.names?.join(', ')}
                  </p>
                </div>
                <div className="right">
                  <button
                    type="button"
                    className="btn btn-outline-primary waves-effect"
                    data-bs-toggle="modal"
                    data-bs-target="#btnSendGoup"
                    onClick={() => teamSend(id)}
                  >
                    그룹쪽지
                    <br />
                    보내기
                  </button>
                </div>
              </div>
            </div>

            <div className="col-12 txt-r mb-3">
              <Link to={`/lms/lectures/members/groups/${teamQuery?.data?.id}/edit`}>
                <button className="btn btn-outline-primary waves-effect waves-float waves-light me-1">수정</button>
              </Link>
              <button className="btn btn-danger waves-effect waves-float waves-light" onClick={handleRemove}>
                삭제
              </button>
            </div>

            <div className="card">
              <form
                className="card-header"
                onSubmit={(event) => {
                  event.preventDefault();
                  setParams(
                    produce((draft) => {
                      draft.keywordType = event.target.keywordType.value;
                      draft.keyword = event.target.keyword.value;
                    }),
                  );
                }}
              >
                <div className="col-md-6 col-12 ms-auto">
                  <fieldset>
                    <div className="input-group">
                      <select name="keywordType" className="form-select">
                        <option value="">전체</option>
                        <option value="name">이름</option>
                        <option value="period">IMBA기수</option>
                      </select>
                      <input name="keyword" type="text" className="form-control" placeholder="" aria-label="Amount" />
                      <button className="btn btn-outline-primary waves-effect" type="submit">
                        검색
                      </button>
                    </div>
                  </fieldset>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col />
                    <col style={{ width: '50%' }} />
                  </colgroup>
                  <tbody>
                    {teamQuery.data &&
                      [teamQuery.data?.master, ...(teamQuery.data?.users ?? [])]
                        .filter((user) => {
                          if (params.keyword) {
                            switch (params.keywordType) {
                              case 'all':
                                return user.name?.includes(params.keyword) || user.period?.includes(params.keyword);
                              case 'name':
                                return user.name?.includes(params.keyword);
                              case 'imba':
                                return user.period && user.period?.includes(params.keyword);
                              default:
                                break;
                            }
                          }
                          return true;
                        })
                        .reduce((p, c, i) => {
                          const _i = Math.floor(i / 2);
                          if (p[_i]) {
                            p[_i].push(c);
                          } else {
                            p[_i] = [c];
                          }
                          return p;
                        }, [])
                        .map((users, usersIndex) => {
                          return (
                            <tr key={`User-${usersIndex}`}>
                              {users.map((user, userIndex) => (
                                <td key={`User-${usersIndex}-${user.id}`}>
                                  <div className="row d-flex box-flex2">
                                    <div className="left d-flex align-items-center">
                                      {user.imageId ? (
                                        <div className="img-pic">
                                          <img src={`${getFilesHost()}/${user.imageId}`} alt={user.name} />
                                        </div>
                                      ) : (
                                        <div className="img-pic img-none">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={14}
                                            height={14}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-user font-medium-4"
                                          >
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                            <circle cx={12} cy={7} r={4} />
                                          </svg>
                                        </div>
                                      )}
                                      <div className="col">
                                        <h4>
                                          <button
                                            type="button"
                                            className="name"
                                            onClick={() => note.showUser(user?.id)}
                                          >
                                            {user.name}
                                          </button>
                                          {usersIndex === 0 && userIndex === 0 && (
                                            <span className="badge rounded-pill bg-success ms-75">조장</span>
                                          )}
                                        </h4>
                                        <p className="txt-info">
                                          <strong>
                                            IMBA {user.period}기 / {user.isForeign ? '해외' : '국내'}
                                          </strong>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="right">
                                      <button
                                        type="button"
                                        className="btn btn-outline-primary waves-effect"
                                        data-bs-toggle="modal"
                                        data-bs-target="#btnSend"
                                        onClick={() => {
                                          note.send([user]);
                                        }}
                                      >
                                        쪽지보내기
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
