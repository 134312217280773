import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../../common/api';
import { parseHTML } from '../../../../../common/define';

export default function LmsExamsItemsIdDetails() {
  const flag = true;

  const navigate = useNavigate();
  const codemap = useSelector((s) => s.common.codes?.map, shallowEqual);
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);
  const query = useParams();

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/lectures/0/exams/${query.id}`);
    setData(data);
  }, []);
  

  useEffect(() => {
    loadData();
  }, [loadData]);

  const now = dayjs();

  const handleRemove = async (e) => {
    e.preventDefault();

    const errorMessages = ['정말 삭제하시겠습니까?'];

    if (dayjs(data?.startAt).isBefore(now) && dayjs(data?.endAt).isAfter(now)) {
      errorMessages.push('현재 시험 기간입니다.');
    }
    if (data?.applySize > 0) {
      errorMessages.push('시험 응시자가 있습니다.');
    }

    if (errorMessages.length > 0) {
      if (!window.confirm(errorMessages.join('\n'))) return;
    }

    await restApi.delete(`/lectures/${lectureId}/exams/${query.id}`);
    alert('삭제되었습니다.');
    navigate(`/lms/exams/items`);
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">시험</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">시험</li>
                  <li className="breadcrumb-item active">시험</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link className="nav-link active" to={`/lms/exams/items/${query.id}`}>
            시험정보
          </Link>
        </li>
        {/* 시험 응시가 완료되었을 경우 */}
        <li>
          <Link className="nav-link" to={`/lms/exams/items/${query.id}/paper`}>
            시험지 보기
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/lms/exams/items/${query.id}/users`}>
            응시자 보기
          </Link>
        </li>
      </ul>
      <div className="tab-content">
        {/* tab 01 s */}
        <div className="tab-pane active" id="kr" aria-labelledby="kr-tab" role="tabpanel">
          {/* Bordered table start */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                          <label className="form-label">시험유형</label>
                        </th>
                        {data?.type === 'QUIZ' && (
                          <td>
                            퀴즈 - {data?.lecture?.semester?.year}년 {codemap[data?.lecture?.semester?.session]}{' '}
                            {data?.lecture?.mainClass?.title}
                          </td>
                        )}
                        {data?.type === 'EXAM' && (
                          <td>
                            {codemap[data?.session]} - {data?.lecture?.semester?.year}년{' '}
                            {codemap[data?.lecture?.semester?.session]} {data?.lecture?.mainClass?.title}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">시험명</label>
                        </th>
                        <td>{data?.title}</td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">내용</label>
                        </th>
                        <td>
                          <div
                            className={'content-area'}
                            style={{ fontSize: 'inherit', padding: 0 }}
                            dangerouslySetInnerHTML={{ __html: parseHTML(data?.contents) }}
                          ></div>
                        </td>
                      </tr>
                      {data?.type === 'EXAM' && (
                        <tr>
                          <th>
                            <label className="form-label">시험 방식</label>
                          </th>
                          <td>{data.isOffline ? '오프라인 시험' : ''}{(data.isOffline && data.examStyle != null) ? ', ' : ''}{data?.examStyle === null ? '' : data?.examStyle === 'ONLINE' ? '온라인 시험' : '시험지 다운로드'}</td>
                        </tr>
                      )}
                      <tr>
                        <th>
                          <label className="form-label">공개여부</label>
                        </th>
                        <td>{data?.active ? '공개' : '비공개'}</td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">기간</label>
                        </th>
                        <td>
                          {dayjs(data?.startAt).format('YYYY-MM-DD / HH:mm')} ~ {dayjs(data?.endAt).format('YYYY-MM-DD / HH:mm')}
                          {/* endAt = null */}
                        </td>
                      </tr>
                      {data?.examStyle == 'ONLINE' ? (
                        <tr>
                          <th>
                            <label className="form-label">설정</label>
                          </th>
                          <td>
                            {data?.type == 'QUIZ' && (
                              <p className="txt-info">
                                <strong>시간제한</strong>
                                {data?.timeAttack >= 0 ? `Y - ${data?.timeAttack}분` : 'N'}
                              </p>
                            )}
                            <p className="txt-info">
                              <strong>문제 순서 섞기</strong>
                              {data?.isMixQuestion ? 'Y' : 'N'}
                            </p>
                            <p className="txt-info">
                              <strong>답변 순서 섞기</strong>
                              {data?.suffle ? 'Y' : 'N'}
                            </p>
                            <p className="txt-info">
                              <strong>한 번에 한 문제만 표시</strong>
                              {data?.oneQuestion ? 'Y' : 'N'}
                            </p>
                            <p className="txt-info">
                              <strong>정답 공개하기</strong>
                              {data?.showAnswer ? 'Y' : 'N'}
                            </p>
                            {data?.showAnswer && (
                            <p className="txt-info">
                              <strong>&emsp;{data?.showExamResultOnlyPoint ? '합산 점수 (문제 제외)' : '문제 및 정답'}</strong>
                            </p>
                            )}
                            {data?.showAnswer && (
                              <p className="txt-info">
                                <strong>&emsp;정답노출 기간</strong>
                                {dayjs(data?.showAnswerStartAt).format('YYYY-MM-DD / HH:mm')} ~ {dayjs(data?.showAnswerEndAt).format('YYYY-MM-DD / HH:mm')}
                                {/* showAnswerStartAt / showAnswerEndAt null */}
                              </p>
                            )}
                          </td>
                        </tr>
                      ) : 
                      <tr>
                        <th>
                          <label className="form-label">점수 공개</label>
                        </th>
                        <td>
                          <p className="txt-info">{data?.isShowExamResult ? '공개' : '비공개'}</p>
                          {data?.isShowExamResult && (
                            <p className="txt-info">
                                {dayjs(data?.showExamResultStartAt).format('YYYY-MM-DD / HH:mm')} ~ {dayjs(data?.showExamResultEndAt).format('YYYY-MM-DD / HH:mm')}
                                {/* showAnswerStartAt / showAnswerEndAt null */}
                              </p>
                          )}
                        </td>
                      </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>

              {/* // 시험지 다운로드 e */}
              <div className="col-12 txt-r">
                <button className="btn btn-danger waves-effect waves-float waves-light me-1" onClick={handleRemove}>
                  삭제
                </button>
                <button
                  type="submit"
                  className="btn btn-primary me-1 waves-effect waves-float waves-light"
                  onClick={() => {
                    navigate(`/lms/exams/items/${query.id}/edit`, {state : {flag}});;
                  }}
                >
                  수정
                </button>
                <button
                  type="submit"
                  className="btn btn-outline-secondary waves-effect"
                  onClick={() => {
                    navigate('/lms/exams/items');
                  }}
                >
                  목록
                </button>
              </div>
            </div>
          </div>
          {/* Bordered table end */}
        </div>
      </div>
      {/* // con e */}
    </div>
  );
}
