import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { editorInitOptions } from '../../../../../common/utils';
import { restApi } from '../../../../../common/api';
import BoardAttachList from '../../../../../components/boards/BoardAttachList';
import FileAttacher from '../../../../../components/FileAttacher';
import { useLoading } from '../../../../../nav/AppContainer';
import { parseHTML } from '../../../../../common/define';

export default function SiteBoardEntranceQnaIdIndex() {
  const navigate = useNavigate();
  const query = useParams();

  const [data, setData] = useState();
  const loadData = async () => {
    const { data } = await restApi.get(`/web/messages/${query.id}`);
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [query.id]);

  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/web/messages/${query.id}`);
    alert('삭제되었습니다.');
    window.history.back();
  };

  const reply = data?.replies?.[0];

  const attacher = useRef();
  const editorRef = React.useRef();

  const [loading, setLoading] = useLoading();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!e.target.title.value) {
        alert('제목을 입력하세요');
        return;
      }

      if (!editorRef.current.getContent()) {
        alert('내용을 입력하세요');
        return;
      }

      setLoading(true);

      const ids = await attacher.current.getFileIds();
      try {
        if (reply?.id) {
          await restApi.put(`/web/messages/${reply?.id}`, {
            title: e.target.title.value,
            contentsWithMarkup: editorRef.current.getContent(),
            files: ids,
          });
        } else {
          await restApi.post(`/web/messages`, {
            parent: data?.id,
            type: 'IN_QNA',
            title: e.target.title.value,
            contentsWithMarkup: editorRef.current.getContent(),
            files: ids,
          });
        }
        alert('저장되었습니다.');
        loadData().catch(console.warn);
        navigate(`/site/board/entranceQna`);
      } catch (e) {
        alert('서버와 연결이 올바르지 않습니다.');
      }

      setLoading(false);
    },
    [data, reply?.id],
  );

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">입학 Q&amp;A</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#n">게시판 관리</Link>
                  </li>
                  <li className="breadcrumb-item active">입학 Q&amp;A</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">제목</label>
                    </th>
                    <td>{data?.title}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">내용</label>
                    </th>
                    <td
                      className={'content-area'}
                      dangerouslySetInnerHTML={{ __html: parseHTML(data?.contentsWithMarkup) }}
                    ></td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">첨부파일</label>
                    </th>
                    <td>
                      <p className="my-50">
                        <BoardAttachList ids={data?.files} />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        제목<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="flex">
                        <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control flex-grow-1 w-auto"
                          name={'title'}
                          defaultValue={reply?.title}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        내용<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <Editor
                        initialValue={reply?.contentsWithMarkup}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={editorInitOptions}
                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일
                      </label>
                    </th>
                    <td>
                      <FileAttacher ref={attacher} values={reply?.files || []} limitSize={10} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            {!loading && (
              <button
                type="submit"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                onclick="location.to='entranceQnaView.html'"
              >
                저장
              </button>
            )}
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              data-bs-toggle="modal"
              data-bs-target="#btnCancel"
              onClick={() => {
                navigate('/site/board/entranceQna');
              }}
            >
              취소
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
