import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { restApi } from '../../../common/api';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { editorInitOptions } from '../../../common/utils';
import produce from 'immer';
import LectureSearchItem from '../../../components/LectureSearchItem';
import { setLectureId } from '../../../data/common';

const INIT_ANSWER = ['매우 그렇다', '그렇다', '보통이다', '그렇지 않다', '전혀 그렇지 않다'];

export default function LmsVotesWrite() {
  const navigate = useNavigate();
  const editorRef = useRef();
  const { lectureId: originLecId } = useSelector((x) => x.common, shallowEqual);
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const [lectureId, setLecId] = useState(originLecId);

  const query = useParams();

  const [formData, setFormData] = useState({
    contentsWithMarkup: '',
    endAt: '',
    items: [
      {
        items: [...INIT_ANSWER],
        title: '',
      },
    ],
    startAt: '',
    title: '',
  });

  const dispatch = useDispatch();

  const loadData = async () => {
    const { data } = await restApi.get(`/lectures/${lectureId}/votes/${query.id}`);
    setLecId(data?.lecture?.id);
    setFormData((x) => ({
      ...x,
      title: data?.title,
      contentsWithMarkup: data?.contentsWithMarkup,
      startAt: data?.startAt,
      endAt: data?.endAt,
      items: data?.items,
    }));
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      contentsWithMarkup: editorRef.current.getContent(),
    };

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return false;
    }

    if (!payload.startAt || !payload.endAt) {
      alert('기간을 입력해주세요.');
      return false;
    }

    if (formData?.items.length === 0) {
      alert('설문 문항은 필수항목입니다.');
      return false;
    }

    if (formData?.items?.filter((x) => !x.title).length > 0) {
      alert('질문 제목은 필수항목입니다.');
      return false;
    }

    if (formData?.items?.map((x) => x.items).some((item) => item.some((ans) => ans === null || ans === ''))) {
      alert('답변 문항은 필수항목입니다.');
      return false;
    }

    dispatch(setLectureId(lectureId));
    if (query?.id) {
      await restApi.put(`/lectures/${lectureId}/votes/${query?.id}`, payload);
      alert('저장되었습니다.');
      await navigate(`/lms/votes/${query?.id}`);
    } else {
      await restApi.post(`/lectures/${lectureId}/votes`, payload);
      alert('등록되었습니다.');
      await navigate('/lms/votes/');
    }
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">설문</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link href="index.html">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">설문</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      {/* Bordered table start */}

      <form className="row" onSubmit={handleSubmit}>
        <div className="row" id="table-bordered">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {!['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type) && (
                      <tr>
                        <th>
                          <label className="form-label">과목</label>
                        </th>
                        <td>
                          <LectureSearchItem value={lectureId} onChange={setLecId} />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>
                        <label className="form-label">설문지 명</label>
                      </th>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name={'title'}
                          value={formData.title}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => ({
                              ...x,
                              title: inputValue,
                            }));
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>내용</th>
                      <td>
                        <Editor
                          initialValue={formData?.contentsWithMarkup}
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          init={editorInitOptions}
                          tinymceScriptSrc="/tinymce/tinymce.min.js"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>기간</th>
                      {/* <td>
                        <input
                          type="text"
                          id="fp-range"
                          className="form-control flatpickr-range flatpickr-input"
                          placeholder="YYYY-MM-DD to YYYY-MM-DD"
                          readOnly="readonly"
                          style={{ width: 215 }}
                        />
                      </td> */}
                      <td>
                        <div className={'d-flex align-items-center'}>
                          <input
                            type="date"
                            placeholder="YYYY-MM-DD"
                            className="form-control "
                            style={{ width: 150 }}
                            value={formData.startAt}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setFormData((x) => {
                                return {
                                  ...x,
                                  startAt: inputValue,
                                };
                              });
                            }}
                          />
                          <span className={'ms-1'}>~</span>
                          <input
                            type="date"
                            placeholder="YYYY-MM-DD"
                            className="form-control ms-1"
                            style={{ width: 150 }}
                            value={formData.endAt}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setFormData((x) => {
                                return {
                                  ...x,
                                  endAt: inputValue,
                                };
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex mb-1 align-items-center">
              <div className="col">
                <h4>설문 등록</h4>
                <p className="mb-0">* 설문문항 순서를 드래그로 조정할 수 있습니다.</p>
              </div>
              <div className="w-auto txt-r">
                <button
                  type="button"
                  className="btn btn-outline-dark btn-doc waves-effect"
                  onClick={() => {
                    setFormData(
                      produce((draft) => {
                        draft.items.push({
                          items: [...INIT_ANSWER],
                          title: '',
                        });
                      }),
                    );
                  }}
                >
                  설문 추가
                </button>
              </div>
            </div>
            {/* 설문등록 반복 구간 s */}{' '}
            {formData?.items?.map((question, ix) => (
              <div key={ix} className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                          <label className="form-label">Q{ix + 1}</label>
                          <button
                            type="button"
                            className="btn btn-outline-danger btn-sm waves-effect"
                            onClick={() => {
                              setFormData(
                                produce((draft) => {
                                  draft.items.splice(ix, 1);
                                }),
                              );
                            }}
                          >
                            삭제
                          </button>
                        </th>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            value={question.title}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setFormData(
                                produce((draft) => {
                                  draft.items[ix].title = inputValue;
                                }),
                              );
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>답변 문항</th>
                        <td>
                          {question.items.map((answer, idx) => (
                            <div key={idx} className="d-flex align-items-center add-wrap">
                              <input
                                type="text"
                                className="form-control col"
                                //  defaultValue={answer}
                                value={answer}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  setFormData(
                                    produce((draft) => {
                                      draft.items[ix].items[idx] = inputValue;
                                    }),
                                  );
                                }}
                              />
                              <button
                                type="button"
                                className="btn-del"
                                onClick={() => {
                                  setFormData(
                                    produce((draft) => {
                                      draft.items[ix].items.splice(idx, 1);
                                    }),
                                  );
                                }}
                              >
                                삭제
                              </button>
                            </div>
                          ))}

                          {/* 추가 버튼 영역 s */}
                          <div className="row row-cols-auto me-0 justify-content-sm-between align-items-center">
                            <small className="d-block text-muted">
                              * 설문답변 문항은 기본적으로 매우 그렇다 / 그렇다 / 보통이다 / 그렇지 않다 / 전혀 그렇지
                              않다로 표기되며, 수정이 가능합니다.
                            </small>
                            <button
                              type="button"
                              className="btn btn-outline-primary w-auto btn-add waves-effect"
                              onClick={() => {
                                setFormData(
                                  produce((draft) => {
                                    draft.items[ix].items.push('');
                                  }),
                                );
                              }}
                            >
                              보기 추가
                            </button>
                          </div>
                          {/* // 추가 버튼 영역 e */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
            {/* //  설문등록 반복 구간 e */}
            <div className="col-12 mt-50 txt-r">
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/lms/votes');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* Bordered table end */}
      {/* // con e */}
    </div>
  );
}
