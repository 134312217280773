import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { restApi } from '../../common/api';
import moment from 'moment';
import LmsExamsItemsIdDetails from '../../pages/lms/exams/items/[id]';

const TYPE_TO_LABEL = {
  NOTICE: '공지사항',
  IN_QNA: '입학 Q&A',
  IMBA_NEWS: 'IMBA 소식',
  FREE: '자유게시판',
  STORY: '원우 이야기',
  IN_FAQ: '입학 FAQ',
  CLUB: '동호회 공지사항',
  MEET: '면접안내',
  RESEARCH: '연구회 공지사항',
  contacts: '문의게시판',
};

const TYPE_TO_LINK = {
  NOTICE: '/site/board/notice',
  IN_QNA: '/site/board/entranceQna',
  IMBA_NEWS: '/site/board/imbaNews',
  FREE: '/site/board/ourStory',
  STORY: '/site/board/sknuStory',
  IN_FAQ: '/site/board/faq',
  CLUB: '/site/board/club',
  MEET: '/site/board/interview',
  RESEARCH: '/site/board/research',
  contacts: '/lms/messages/contacts',
};

const DashboardAdmin = (props) => {
  const [entData, setEntData] = useState();
  const loadEntData = useCallback(async () => {
    const { data } = await restApi.get('/web/messages', {
      params: {
        type: 'IN_QNA',
        limit: 5,
      },
    });

    setEntData(data);
  }, []);

  const [stuData, setStuData] = useState();
  const loadStuData = useCallback(async () => {
    const { data } = await restApi.get('/web/messages', {
      params: {
        category: 'BCFR002',
        type: 'FREE',
        limit: 5,
      },
    });

    setStuData(data);
  }, []);

  useEffect(() => {
    Promise.all([loadEntData(), loadStuData()]).catch(console.warn);
  }, [loadEntData, loadStuData]);

  const [date, setDate] = useState('00days');
  const [counts, setCounts] = useState();
  const [lmsCounts, setLmsCounts] = useState();

  useEffect(() => {
    const a = 0 - parseInt(date.substring(0, 2));
    const b = date.substring(2);

    const d = moment().add(a, b).format('YYYY-MM-DD');
    const loadData = async () => {
      const { data } = await restApi.get(`/web/messages/counts`, { params: { startAt: d } });
      setCounts(data);
    };
    loadData().catch(console.warn);
  }, [date]);

  useEffect(() => {
    const a = 0 - parseInt(date.substring(0, 2));
    const b = date.substring(2);

    const d = moment().add(a, b).format('YYYY-MM-DD');
    const loadData = async () => {
      const { data } = await restApi.get(`/lms/messages/counts`, { params: { startAt: d } });
      setLmsCounts(data);
    };
    loadData().catch(console.warn);
  }, [date]);

  const [messageList, setMessageList] = useState();

  useEffect(() => {
    const messageList =
    counts && lmsCounts 
    ? [
        ...counts,
        ...lmsCounts,
     ]
  : [];
  setMessageList(messageList);
  }, [counts, lmsCounts])

  return (
    <>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <div className="content-wrapper container-xxl p-0">
        {/* con s */}
        {/* Bordered table start */}
        <div className="row align-items-strech">
          <div className="col-md-6">
            <h4>
              입학 Q&amp;A
              <Link to={`/site/board/entranceQna/`} className="text-danger">
                {' '}
                (신규 질문 : {entData?.content.filter((item) => item.isNew).length}건 / 답변완료 :{' '}
                {entData?.content.filter((item) => item.hasReply).length}건)
              </Link>
              {/* <a href="#n" className="text-danger">

              </a> */}
            </h4>
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box t-padding">
                  <colgroup>
                    <col />
                    <col style={{ width: '7rem' }} />
                    <col style={{ width: '9rem' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>제목</th>
                      <th>처리상태</th>
                      <th>등록일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entData?.content.map((item) => (
                      <tr key={`ent-${item.id}`}>
                        <td className="txt-100">
                          <Link to={`/site/board/entranceQna/${item.id}`}>{item.title}</Link>
                        </td>
                        <td className="txt-c">{item.hasReply ? '답변완료' : '처리중'}</td>
                        <td className="txt-c">{dayjs(item.createdAt).format('YYYY-MM-DD')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <h4>
              재학생 Q&amp;A
              <Link to={`/site/board/stuQna/`} className="text-danger">
                {' '}
                (신규 질문 : {stuData?.content.filter((item) => item.isNew).length}건 / 답변완료 :{' '}
                {stuData?.content.filter((item) => item.hasReply).length}건)
              </Link>
            </h4>
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box t-padding">
                  <colgroup>
                    <col />
                    <col style={{ width: '7rem' }} />
                    <col style={{ width: '9rem' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>제목</th>
                      <th>처리상태</th>
                      <th>등록일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stuData?.content.map((item) => (
                      <tr key={`stu-${item.id}`}>
                        <td className="txt-100">
                          <Link to={`/site/board/stuQna/${item.id}`}>{item.title}</Link>
                        </td>
                        <td className="txt-c">{item.hasReply ? '답변완료' : '처리중'}</td>
                        <td className="txt-c">{dayjs(item.createdAt).format('YYYY-MM-DD')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* Bordered table end */}
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center mb-50">
              <h4 className="mb-0">신규 게시물 등록 현황</h4>
              <div className="ms-50">
                {/* 선택된 상태: btn-primary class */}
                <button
                  type="button"
                  className={`btn ${
                    date === '00days' ? 'btn-primary' : 'btn-secondary'
                  } btn-sm waves-effect waves-float waves-light`}
                  onClick={() => setDate('00days')}
                >
                  당일
                </button>{' '}
                <button
                  type="button"
                  className={`btn ${
                    date === '07days' ? 'btn-primary' : 'btn-secondary'
                  } btn-sm waves-effect waves-float waves-light`}
                  onClick={() => setDate('07days')}
                >
                  7일
                </button>{' '}
                <button
                  type="button"
                  className={`btn ${
                    date === '14days' ? 'btn-primary' : 'btn-secondary'
                  } btn-sm waves-effect waves-float waves-light`}
                  onClick={() => setDate('14days')}
                >
                  2주
                </button>{' '}
                <button
                  type="button"
                  className={`btn ${
                    date === '01months' ? 'btn-primary' : 'btn-secondary'
                  } btn-sm waves-effect waves-float waves-light`}
                  onClick={() => setDate('01months')}
                >
                  한달
                </button>
              </div>
            </div>
            <div className="m-boardd-flex">
              {messageList?.length > 0 ? (
                messageList?.map((item) => (
                  <div className="card p-2 txt-c" key={item.type}>
                    <h4 className="text-black">
                      <Link to={ TYPE_TO_LINK[item.type]}>{TYPE_TO_LABEL[item.type]}</Link>
                    </h4>
                    <p className="mb-0">{item.count}건</p>
                  </div>
                ))
              ) : (
                <p className="pt-2 pb-2">등록된 신규 게시물이 없습니다.</p>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center mb-50">
              <h4 className="mb-0">자주 사용하는 게시판</h4>
            </div>
            <div className="m-boardd-flex">
              {Object.keys(TYPE_TO_LABEL).map((key) => {
                const count = messageList?.find((x) => x.type === key)?.count || 0;
                return (
                  <div className="card p-2 txt-c" key={key}>
                    <h4 className="text-black">
                      <Link to={TYPE_TO_LINK[key]}>{TYPE_TO_LABEL[key]}</Link>
                    </h4>
                    <p className="mb-0">{count}건</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* // con e */}
      </div>
    </>
  );
};

export default DashboardAdmin;
