import React, { useContext, useEffect, useState } from 'react';
import { downloadLink, parseTime } from '../../common/utils';
import { VideoContext } from '../VideoUploadProvider';
import { getAPIHost, restApi } from '../../common/api';
import FileSaver from 'file-saver';
import { useLoading } from '../../nav/AppContainer';
import { shallowEqual, useSelector } from 'react-redux';

const LectureVideoRow = ({ item, lectureId, handleRemove, onRefresh }) => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const context = useContext(VideoContext);
  const handlePreview = () => {
    context.showPreview(lectureId, item.videoContent?.id);
  };

  const [loading, setLoading] = useLoading();
  const handleChange = async (e) => {
    const file = e.target.files?.[0];

    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('secret', false);
    const { data } = await restApi.post('/files', formData);

    await restApi.put(`/lectures/${lectureId}/videos/${item.id}`, {
      title: item?.title,
      type: item?.type,
      week: item?.week,
      orderIndex: item?.orderIndex,
      videoContentId: item?.videoContent?.id,
      paper: data?.id,
    });
    onRefresh?.();

    setLoading(false);
  };

  const handleFileRemove = async () => {
    setLoading(true);

    await restApi.put(`/lectures/${lectureId}/videos/${item.id}`, {
      type: item?.type,
      week: item?.week,
      orderIndex: item?.orderIndex,
      videoContentId: item?.videoContent?.id,
      paper: undefined,
    });
    onRefresh?.();
    setLoading(false);
  };

  const [fileinfo, setFileinfo] = useState();
  useEffect(() => {
    const loadData = async () => {
      const { data: fdata } = await restApi.get(`/files/infos`, { params: { ids: [item.paper] } });
      setFileinfo(fdata?.[0]);
    };
    if (item.paper) loadData().catch(console.warn);
  }, [item.paper]);

  const downloadFile = () => {
    FileSaver.saveAs(downloadLink(fileinfo), fileinfo.filename);
  };

  return (
    <tr key={`${item.id}`}>
      <td style={{ display: '' }}>
        <button type="button" onClick={handlePreview} className="btn btn-flat-primary waves-effect  txt-l">
          {item.videoContent?.title}
        </button>
        {/* 교안 업로드 됐을 시 */}
        {!item.paper ? (
          principal?.type !== 'TEACHER' && (
            <label>
              <input
                type={'file'}
                style={{ position: 'absolute', opacity: 0, zIndex: 1 }}
                onChange={handleChange}
                disabled={loading}
              />
              <button
                type="button"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                disabled={loading}
              >
                교안 업로드
              </button>
            </label>
          )
        ) : (
          <span>
            <a onClick={downloadFile}>{fileinfo?.filename}</a>
            {principal?.type === 'ADMIN' && (
              <button onClick={handleFileRemove} style={{ border: 'none', backgroundColor: 'white' }}>
                X
              </button>
            )}
          </span>
        )}
      </td>
      <td className="text-center">
        동영상 시간
        <span className="text-black mx-1">{parseTime(item.videoContent?.videoDuration)}</span>
      </td>
      <td style={{ textAlign: 'right' }}>
        <button
          type="button"
          className="btn btn-primary me-1 waves-effect waves-float waves-light"
          onClick={() => context.showPreview(lectureId, item.videoContent?.id)}
        >
          재생
        </button>
        {principal?.type === 'ADMIN' && (
          <button
            type="button"
            disabled={item.userListen}
            className="btn btn-outline-secondary waves-effect"
            onClick={() => handleRemove(item.id)}
          >
            삭제
          </button>
        )}
      </td>
    </tr>
  );
};

export default LectureVideoRow;
