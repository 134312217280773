import React, { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { restApi } from '../../../common/api';
import PaginationMoon from '../../../components/PaginationMoon';
import dayjs from 'dayjs';
import { useLoading } from '../../../nav/AppContainer';
import FileSaver from 'file-saver';

export default function SiteLogIndex() {
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/account-histories', {
      params: {
        page: params?.page || 0,
        startAt: params?.startAt || dayjs().format('YYYY-MM-DD'),
        endAt: params?.endAt || dayjs().format('YYYY-MM-DD'),
        limit: 10,
      },
    });

    setData(data);
  }, [params]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const [, setLoading] = useLoading();

  const xlsDownload = async () => {
    setLoading(true);
    const { data } = await restApi.get('/account-histories/xlsx', {
      responseType: 'arraybuffer',
      params: {
        startAt: params?.startAt || dayjs().format('YYYY-MM-DD'),
        endAt: params?.endAt || dayjs().format('YYYY-MM-DD'),
      },
    });
    FileSaver.saveAs(new Blob([data]), `접속로그관리_${dayjs().format('YYYYMMDD_HHmmss')}.xlsx`);
    setLoading(false);
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">접속로그 관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">접속로그 관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">조회일자</label>
                    </th>
                    <td>
                      <div className="row mx-0 align-items-center">
                        <input
                          type="date"
                          id="fp-default"
                          className="form-control flatpickr-basic me-75 flatpickr-input"
                          placeholder="YYYY-MM-DD"
                          style={{ width: 150 }}
                          value={params?.startAt || dayjs().format('YYYY-MM-DD')}
                          onChange={(e) => {
                            const v = e.target.value;
                            setParams((x) => ({
                              startAt: v,
                              endAt: x.endAt,
                            }));
                          }}
                        />
                        {'~ '}
                        <input
                          type="date"
                          id="fp-default"
                          className="form-control flatpickr-basic me-75 ms-75 flatpickr-input"
                          placeholder="YYYY-MM-DD"
                          style={{ width: 150 }}
                          value={params?.endAt || dayjs().format('YYYY-MM-DD')}
                          onChange={(e) => {
                            const v = e.target.value;
                            setParams((x) => ({
                              startAt: x.startAt,
                              endAt: v,
                            }));
                          }}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-primary waves-effect me-75"
                          style={{ width: 110 }}
                          onClick={() => {
                            const s = dayjs().add(-7, 'days').format('YYYY-MM-DD');
                            const e = dayjs().format('YYYY-MM-DD');
                            setParams({
                              startAt: s,
                              endAt: e,
                            });
                          }}
                        >
                          최근 7일
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary waves-effect"
                          style={{ width: 110 }}
                          onClick={() => {
                            const s = dayjs().add(-1, 'months').format('YYYY-MM-DD');
                            const e = dayjs().format('YYYY-MM-DD');
                            setParams({
                              startAt: s,
                              endAt: e,
                            });
                          }}
                        >
                          최근 1개월
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <div className="col">
                <div className="w-auto txt-r">
                  <button type="button" className="btn btn-outline-dark btn-doc waves-effect" onClick={xlsDownload}>
                    엑셀 다운로드{' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={14}
                      height={14}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-log-in"
                    >
                      <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
                      <polyline points="10 17 15 12 10 7" />
                      <line x1={15} y1={12} x2={3} y2={12} />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="table-responsive scrollbox2">
              <table className="table table-bordered visual-box t-padding">
                <colgroup>
                  <col style={{ width: '6rem' }} />
                  <col style={{ width: '6rem' }} />
                  <col />
                  <col style={{ width: '8rem' }} />
                  <col style={{ width: '10rem' }} />
                  <col style={{ width: '13%' }} />
                  <col style={{ width: '13%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '12%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>구분</th>
                    <th>화면명</th>
                    <th>다운로드</th>
                    <th>사용자유형</th>
                    <th>접속자</th>
                    <th>아이디</th>
                    <th>접속일시</th>
                    <th>접속IP</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.content?.map((item, ix) => (
                    <tr key={`${item.id}`}>
                      <td className="txt-c">{data.totalElements - ix - data.size * data.number}</td>
                      <td className="txt-c">{item?.mobile ? '모바일' : 'PC'}</td>
                      <td className="">{item?.name}</td>
                      <td className="txt-c">{item?.download ? '다운로드' : '없음'}</td>
                      <td className="txt-c">{item?.account?.type}</td>
                      <td className="txt-c">{item?.account?.name}</td>
                      <td className="txt-c">{item?.account?.userId}</td>
                      <td className="txt-c">{dayjs(item?.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                      <td className="txt-c">{item?.remoteAddr}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <nav aria-label="Page navigation">
            <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
          </nav>
        </div>
      </div>
    </div>
  );
}
