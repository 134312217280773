import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getAPIHost, restApi } from '../../common/api';

export default function ProfileIndex() {
  const navigate = useNavigate();

  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/accounts/${principal?.id}/bo`);
    setData(data);
  }, []);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);
  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">개인정보수정</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">개인정보수정</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}

      {/* Bordered table start s */}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="text-primary">기본 정보</h4>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td rowSpan={9}>
                          <img
                            src={`${getAPIHost()}api/files/${data?.imageId}`}
                            alt=""
                            style={{ width: 150, height: 180 }}
                          />
                          <span className="d-block txt-c fs-2 mt-1">{data?.name}</span>
                        </td>
                      </tr>
                      <tr>
                        <th>학번(아이디)</th>
                        <td>{data?.userId}</td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">생년월일</label>
                        </th>
                        <td>{data?.birthday}</td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">성별</label>
                        </th>
                        <td>{data?.gender === 'MALE' ? '남' : '여'}</td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">이메일</label>
                        </th>
                        <td>
                          <a href={`mailto:${data?.email}`}>{data?.email}</a>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">전화번호</label>
                        </th>
                        <td>
                          <a href={`tel:${data?.phone}`}>{data?.phone}</a>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">휴대폰번호</label>
                        </th>
                        <td>
                          <a href={`tel:${data?.mobilePhone}`}>{data?.mobilePhone}</a>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">주소</label>
                        </th>
                        <td>
                          {data?.address} {data?.addressDetails}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">근무지</label>
                        </th>
                        <td>{data?.workPlace}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-12 txt-r">
                <button
                  type="reset"
                  className="btn btn-outline-primary waves-effect"
                  onClick={() => {
                    navigate(`/profile/edit`);
                  }}
                >
                  수정
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Bordered table end e */}
      </div>
      {/* // con e */}
    </div>
  );
}
