import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function SiteBoardBizIdView() {
  const navigate = useNavigate();
  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">Biz. 인사이트</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Biz. 인사이트</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      {/* Bordered table start */}
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-start justify-content-sm-between mb-75">
            <div className="d-flex align-items-start">
              <h4 className="my-0 text-primary">비전, 기업문화, 사업부 소개 비전, 기업문화,</h4>
            </div>
            <div className="d-flex align-items-center justify-content-end" style={{ width: 200 }}>
              <p className="my-0 text-black">리더십역량</p>
            </div>
          </div>
          <div className="video-wrap">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/H9-nrr75o5E"
              title="2023학년도 전기 성균관대 경영전문대학원 핀테크MBA 입학설명회"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            />
          </div>
          <div className="mb-2">내용입니다.</div>
          <div className="col-12 txt-r">
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-float waves-light"
              onClick={() => {
                navigate('/site/board/biz');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>
      {/* Bordered table end */}
      {/* // con e */}
    </div>
  );
}
