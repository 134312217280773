import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserSelect from '../../../../components/UserSelect';
import { restApi } from '../../../../common/api';

export default function LmsTalksMessagesWrite() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [formData, setFormData] = useState({
    contentsWithMarkup: '',
    title: state ? state.message : undefined,
    type: 'SMS',
    users: state ? state.ids : [],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      lectureId: state?.lectureId,
    };
    if (payload.users.length < 1) {
      alert('받는 사람을 선택해주세요.');
      return;
    }
    if (!payload.title) {
      alert('내용을 입력해주세요.');
      return;
    }

    await restApi.post(`/broadcasts`, payload);
    alert('저장되었습니다.');
    navigate('/lms/talks/messages');
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">메시지 보내기</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">메시지 보내기</li>
                  <li className="breadcrumb-item active">SMS 보내기</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link className="nav-link active" to="/lms/talks/messages/write">
            SMS 보내기
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/lms/talks/mail/write">
            메일 보내기
          </Link>
        </li>
      </ul>
      {/* con s */}
      <div className="row" id="table-bordered">
        <form className="col-12" onSubmit={handleSubmit}>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">보내는 사람</label>
                    </th>
                    <td>02-760-0851</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">받는 사람</label>
                    </th>
                    <td>
                      <UserSelect
                        title={'찾아보기'}
                        maxLength={500}
                        name="users"
                        values={formData.users}
                        onChange={(v) => {
                          setFormData((x) => ({ ...x, users: v ?? [] }));
                        }}
                        extraSelectOption
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">문자메시지</label>
                    </th>
                    <td>
                      <div className="d-flex align-items-end">
                        <textarea
                          className="form-control"
                          rows={5}
                          maxLength={1000}
                          name="title"
                          value={formData.title}
                          onChange={(e) => {
                            const v = e.target.value;
                            if (v.length < 1001) {
                              setFormData((x) => ({
                                ...x,
                                title: v,
                              }));
                            } else {
                              alert('1000자 이하로 작성해주세요.');
                            }
                          }}
                        />
                        <span className="txt-limit me-50">{formData?.title?.length ?? 0}/1000</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-float waves-light me-1"
              data-bs-toggle="modal"
              data-bs-target="#btnSend"
            >
              SMS 발송하기
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/lms/talks/messages');
              }}
            >
              취소
            </button>
          </div>
        </form>
      </div>

      {/* // con e */}
    </div>
  );
}
