import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../common/api';
import { useLoading } from '../../../nav/AppContainer';
import { shallowEqual, useSelector } from 'react-redux';

export default function SiteScheduleWrite() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const query = useParams();
  const [loading, setLoading] = useLoading();

  const [formData, setFormData] = useState({
    area: '',
    category: '',
    color: '',
    contents: '',
    endAt: undefined,
    startAt: undefined,
    title: '',
    year: undefined,
  });

  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/calendars/${query.id}`);
        // setInitData(data);
        const init = {
          startAt: dayjs(data.startAt).format('YYYY-MM-DD'),
          endAt: dayjs(data.endAt).format('YYYY-MM-DD'),
        };
        setFormData({ ...data, ...init });
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      startAt: `${formData.startAt}T00:00`,
      endAt: `${formData.endAt}T00:00`,
      year: dayjs(formData.startAt).format('YYYY'),
    };

    if (!formData.startAt || !formData.endAt) {
      alert('일자를 입력해주세요.');
      return false;
    }

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return false;
    }

    // if (!payload.contents) {
    //   alert('내용을 입력해주세요.');
    //   return false;
    // }

    setLoading(true);
    try {
      if (query?.id) {
        await restApi.put(`/calendars/${query?.id}`, payload);
        alert('저장되었습니다.');
        await navigate(`/site/schedule/${query?.id}`);
      } else {
        await restApi.post(`/calendars`, payload);
        alert('등록되었습니다.');
        await navigate('/site/schedule');
      }
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">학사일정</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">학사일정</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}

      <div className="row" id="table-bordered">
        <form className="col-12" onSubmit={handleSubmit}>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-title">
                        색상
                      </label>
                    </th>
                    <td scope="col" colSpan={1} className="p-schedule-add">
                      <div className="checks">
                        <input
                          type="radio"
                          id="cal-c-red"
                          className="cal-c-red"
                          name="color"
                          title="연한 빨강색"
                          checked={formData?.color === 'red'}
                          onClick={() => {
                            setFormData((x) => ({ ...x, color: 'red' }));
                          }}
                        />
                        <label htmlFor="cal-c-red" />
                        <input
                          type="radio"
                          id="cal-c-orange"
                          className="cal-c-orange"
                          name="color"
                          title="연한 주황색"
                          checked={formData?.color === 'orange'}
                          onClick={() => {
                            setFormData((x) => ({ ...x, color: 'orange' }));
                          }}
                        />
                        <label htmlFor="cal-c-orange" />
                        <input
                          type="radio"
                          id="cal-c-yellow"
                          className="cal-c-yellow"
                          name="color"
                          title="연한 노란색"
                          checked={formData?.color === 'yellow'}
                          onClick={() => {
                            setFormData((x) => ({ ...x, color: 'yellow' }));
                          }}
                        />
                        <label htmlFor="cal-c-yellow" />
                        <input
                          type="radio"
                          id="cal-c-green"
                          className="cal-c-green"
                          name="color"
                          title="연한 초록색"
                          checked={formData?.color === 'green'}
                          onClick={() => {
                            setFormData((x) => ({ ...x, color: 'green' }));
                          }}
                        />
                        <label htmlFor="cal-c-green" />
                        <input
                          type="radio"
                          id="cal-c-blue"
                          className="cal-c-blue"
                          name="color"
                          title="연한 파랑색"
                          checked={formData?.color === 'blue'}
                          onClick={() => {
                            setFormData((x) => ({ ...x, color: 'blue' }));
                          }}
                        />
                        <label htmlFor="cal-c-blue" />
                        <input
                          type="radio"
                          id="cal-c-purple"
                          className="cal-c-purple"
                          name="color"
                          title="연한 보라색"
                          checked={formData?.color === 'purple'}
                          onClick={() => {
                            setFormData((x) => ({ ...x, color: 'purple' }));
                          }}
                        />
                        <label htmlFor="cal-c-purple" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        학기
                      </label>
                    </th>
                    <td>
                      <select
                        className="form-select"
                        id="blog-edit-title"
                        style={{ width: 170 }}
                        name="orderIndex"
                        defaultValue={formData?.category}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setFormData((x) => {
                            return {
                              ...x,
                              category: inputValue,
                            };
                          });
                        }}
                      >
                        {codes?.tree?.SCTP.filter((s) => s.code == 'SCTP001' || s.code == 'SCTP002').map((item) => (
                          <option value={item.code} selected={formData?.category == item.code}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">기간</label>
                    </th>
                    <td style={{ display: 'flex' }}>
                      <input
                        type="date"
                        placeholder="YYYY-MM-DD"
                        className="form-control me-25"
                        style={{ width: 136 }}
                        value={formData.startAt}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setFormData((x) => {
                            return {
                              ...x,
                              startAt: inputValue,
                            };
                          });
                        }}
                      />
                      {' ~ '}
                      <input
                        type="date"
                        placeholder="YYYY-MM-DD"
                        className="form-control ms-1"
                        style={{ width: 136 }}
                        value={formData.endAt}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setFormData((x) => {
                            return {
                              ...x,
                              endAt: inputValue,
                            };
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        내용
                      </label>
                    </th>
                    <td>
                      <div className="flex">
                        <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control flex-grow-1 w-auto"
                          name={'title'}
                          defaultValue={formData.title}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => {
                              return {
                                ...x,
                                title: inputValue,
                              };
                            });
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  {/* <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        장소
                      </label>
                    </th>
                    <td>
                      <div className="flex">
                        <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control flex-grow-1 w-auto"
                          name={'title'}
                          defaultValue={formData.area}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => {
                              return {
                                ...x,
                                area: inputValue,
                              };
                            });
                          }}
                        />
                      </div>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <th>
                      <label className="form-label">
                        내용<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <textarea
                        className="form-control"
                        rows={7}
                        name="contents"
                        defaultValue={formData.contents}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setFormData((x) => {
                            return {
                              ...x,
                              contents: inputValue,
                            };
                          });
                        }}
                      />
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-50">
            <div className="col-md-6"></div>
            <div className="col-md-6 txt-r">
              <button
                type="submit"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                data-bs-toggle="modal"
                data-bs-target="#btnSave"
              >
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/site/schedule');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </form>
        {/* con s */}
        {/* <div className="row" id="table-bordered">
          <div className="col-12">
            <div className="row mx-0">
              <select className="form-select mb-2" style={{ width: 100 }}>
                <option value="">2023</option>
              </select>
              <select className="form-select mb-2 ms-75" style={{ width: 100 }}>
                <option value="">1학기</option>
                <option value="">2학기</option>
              </select>
            </div>

            <h4>1 월</h4>
            <div className="card card-schedule">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label" htmlFor="blog-edit-slug">
                          시작 ~ 종료일
                        </label>
                      </th>
                      <td>
                        <div className="row row-cols-auto mx-auto schedule-box">
                          <input
                            type="text"
                            className="form-control flatpickr-range me-75 flatpickr-input"
                            placeholder="YYYY-MM-DD to YYYY-MM-DD"
                            style={{ width: 210 }}
                            readOnly="readonly"
                          />
                          <input
                            className="form-control input"
                            type="text"
                            tabIndex={0}
                            data-search="search"
                            style={{ flex: 1 }}
                            placeholder="내용"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row row-cols-auto mx-auto justify-content-end align-items-center mb-2">
              <h6 className="fw-normal mb-0">*항목 추가 시, 추가 버튼을 눌러 주시기 바랍니다.</h6>
              <button
                type="button"
                className="btn btn-outline-primary waves-effect w-auto btn-add"
                onclick="addSchedule(this);"
              >
                추가
              </button>
            </div>

            <h4>2 월</h4>
            <div className="card card-schedule">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label" htmlFor="blog-edit-slug">
                          시작 ~ 종료일
                        </label>
                      </th>
                      <td>
                        <div className="row row-cols-auto mx-auto schedule-box">
                          <input
                            type="text"
                            className="form-control flatpickr-range me-75 flatpickr-input"
                            placeholder="YYYY-MM-DD to YYYY-MM-DD"
                            style={{ width: 210 }}
                            readOnly="readonly"
                          />
                          <input
                            className="form-control input"
                            type="text"
                            tabIndex={0}
                            data-search="search"
                            style={{ flex: 1 }}
                            placeholder="내용"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row row-cols-auto mx-auto justify-content-end align-items-center mb-2">
              <h6 className="fw-normal mb-0">*항목 추가 시, 추가 버튼을 눌러 주시기 바랍니다.</h6>
              <button
                type="button"
                className="btn btn-outline-primary waves-effect w-auto btn-add"
                onclick="addSchedule(this);"
              >
                추가
              </button>
            </div>
            <div className="col-12 mt-50 txt-r">
              <button
                type="submit"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                data-bs-toggle="modal"
                data-bs-target="#btnSave"
              >
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                data-bs-toggle="modal"
                data-bs-target="#btnCancel"
                onClick={() => {
                  navigate('/site/schedule');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
