import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { restApi } from '../../../../common/api';
import dayjs from 'dayjs';
import BoardAttachList from '../../../../components/boards/BoardAttachList';
import { parseHTML } from '../../../../common/define';

export default function LmsHomeworksIdIndex() {
  const navigate = useNavigate();
  const { lectureId } = useSelector((s) => s.common, shallowEqual);
  const { id } = useParams();

  const [data, setData] = useState();
  const loadData = useCallback(
    async (page) => {
      const { data } = await restApi.get(`/lectures/0/homeworks/${id}`, {});
      setData(data);
    },
    [lectureId, id],
  );
  useEffect(() => {
    loadData(0).catch(console.warn);
  }, [loadData]);

  const handleDelete = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/lectures/0/homeworks/${id}`);
    alert('삭제되었습니다.');
    window.history.back();
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">과제</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">과제</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">과제 구분</label>
                    </th>
                    <td>{data?.type == 'single' ? '개인과제' : '그룹과제'}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">과제명</label>
                    </th>
                    <td>{data?.title}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">기간</label>
                    </th>
                    <td>
                      {dayjs(data?.startAt).format('YYYY-MM-DD / HH:mm')} ~{' '}
                      {dayjs(data?.limitAt).format('YYYY-MM-DD / HH:mm')}
                    </td>
                  </tr>
                  {!!data?.continueAt && (
                    <tr>
                      <th>
                        <label className="form-label">제출기간 연장</label>
                      </th>
                      <td>
                        {dayjs(data?.limitAt).format('YYYY-MM-DD / HH:mm')} ~{' '}
                        {dayjs(data?.continueAt).format('YYYY-MM-DD / HH:mm')}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>
                      <label className="form-label">연관 주차</label>
                    </th>
                    <td>{data?.week > 0 ? data?.week + '주차' : '전체'}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">내용</label>
                    </th>
                    <td className={'content-area'} dangerouslySetInnerHTML={{ __html: parseHTML(data?.contentsWithMarkup) }}></td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일
                      </label>
                    </th>
                    <td>
                      <BoardAttachList ids={data?.files} />
                    </td>
                  </tr>
                  {(
                    <tr>
                      <th>
                        <label className="form-label">점수 공개</label>
                      </th>
                      <td>
                        {data?.showScore ? '공개' : '비공개'}
                        <br></br>
                        {data?.showScore && (
                          <div>
                          {dayjs(data?.showScoreStartAt).format('YYYY-MM-DD / HH:mm')} ~{' '}
                          {dayjs(data?.showScoreEndAt).format('YYYY-MM-DD / HH:mm')}
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button
              type="submit"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={() => {
                navigate(`/lms/homeworks/${data?.type}/${data?.id}/edit`);
              }}
            >
              수정
            </button>
            <button
              type="submit"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={handleDelete}
            >
              삭제
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate(`/lms/homeworks/${data?.type}`);
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>
      {/* // con e */}
    </div>
  );
}
