import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../common/api';
import UserSelect from '../../../components/UserSelect';

export default function LmsSubjectWrite() {
  const navigate = useNavigate();
  const query = useParams();
  const { codes } = useSelector((s) => s.common, shallowEqual);

  const [formData, setFormData] = useState({
    masterId: undefined,
    title: '',
    isAudit: undefined,
    category: undefined,
    siteType: undefined,
    academicCode: undefined,
    isActive: undefined,
  });

  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/admin/main-classes/${query.id}`);
        setFormData((x) => ({
          ...x,
          title: data?.title,
          masterId: data?.master?.id,
          category: data?.category,
          siteType: data?.siteType,
          isAudit: data?.isAudit,
          academicCode: data?.academicCode,
          isActive: data?.isActive,
        }));
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
    };
    if (!payload.masterId) {
      alert('교수명을 선택해주세요.');
      return false;
    }

    if (!payload.title) {
      alert('과목명을 입력해주세요.');
      return false;
    }
    if (!payload.siteType) {
      alert('분류를 입력해주세요.');
      return false;
    }
    if (payload.siteType === 'MCTP002' && !payload.category) {
      alert('분야를 입력해주세요.');
      return false;
    }
    if (!payload.academicCode) {
      alert('학수번호를 입력해주세요.');
      return false;
    }

    if (query?.id) {
      await restApi.put(`/admin/main-classes/${query?.id}`, payload);
      alert('저장되었습니다.');
      await navigate(`/lms/subject/${query?.id}`);
    } else {
      await restApi.post(`/admin/main-classes`, payload);
      alert('등록되었습니다.');
      await navigate('/lms/subject/');
    }
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">과목관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">과목관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <form className="row" onSubmit={handleSubmit}>
        <div className="row" id="table-bordered">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col style={{ width: '35%' }} />
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label">교수명</label>
                      </th>
                      <td className="d-flex align-items-center">
                        <UserSelect
                          title={'찾아보기'}
                          type={'TEACHER'}
                          maxLength={1}
                          name="classMaster"
                          values={[formData?.masterId].filter((x) => !!x)}
                          onChange={(v) => {
                            setFormData((x) => ({ ...x, masterId: v?.[0] }));
                          }}
                        />
                      </td>
                      <th>
                        <label className="form-label">과목명</label>
                      </th>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={formData?.title}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => {
                              return {
                                ...x,
                                title: inputValue,
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">분류</label>
                      </th>
                      <td>
                        <select
                          className="form-select w-auto pe-3"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => {
                              return {
                                ...x,
                                siteType: inputValue,
                              };
                            });
                          }}
                        >
                          <option value="">선택</option>
                          {codes?.tree?.MCTP.map((item) => (
                            <option value={item.code} selected={formData?.siteType == item.code}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <th>
                        <label className="form-label">분야</label>
                      </th>
                      <td>
                        <select
                          className="form-select w-auto pe-3"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => {
                              return {
                                ...x,
                                category: inputValue,
                              };
                            });
                          }}
                        >
                          <option value="">선택</option>
                          {codes?.tree?.MCST.map((item) => (
                            <option value={item.code} selected={formData?.category == item.code}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">청강여부</label>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="inlineRadio2"
                              name={'isAudit'}
                              checked={formData?.isAudit}
                              onClick={(x) => setFormData((x) => ({ ...x, isAudit: true }))}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio2">
                              네
                            </label>
                          </div>{' '}
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="inlineRadio1"
                              name={'isAudit'}
                              checked={!formData?.isAudit}
                              onClick={(x) => setFormData((x) => ({ ...x, isAudit: false }))}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio1">
                              아니오
                            </label>
                          </div>
                        </div>
                      </td>
                      <th>
                        <label className="form-label">학수번호</label>
                      </th>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={formData?.academicCode}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFormData((x) => {
                              return {
                                ...x,
                                academicCode: inputValue,
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-50 txt-r">
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/lms/subject');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* // con e */}
    </div>
  );
}
