import { Link, useLocation,useParams,useNavigate, useSearchParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { restApi } from '../../../../../common/api';




export default function LmsScheduleItemsIdDetails() {

    const navigate = useNavigate();
    const query = useLocation(); 
    const item = query.state
    const { principal } = useSelector((s) => s.auth, shallowEqual);
    const { semesters, codes, semesterId } = useSelector((s) => s.common, shallowEqual);

    const { lectureId: originLecId } = useSelector((x) => x.common, shallowEqual);
    const [lectureId, setLecId] = useState(originLecId);


    console.log(item)
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    
    //     // dispatch(setLectureId(lectureId));
    //     // if (query?.id) {
    //     //   await restApi.put(`/lectures/${lectureId}/exams/${query?.id}`, payload);
    //     //   alert('저장되었습니다.');
    //     //   await navigate(`/lms/exams/items/${query?.id}`);
    //     // } else {
    
    //     // console.log({semesterId, lectureId })
    //     // setFormData((x) => ({ ...x, semesterId:semesterId}));
    //     // console.log(formData)
        
    //       const res = await restApi.post(`/lectures/lectureSchedule/${lectureId}`, formData);
    //       alert('등록되었습니다.');
    //       await navigate(`/lms/lectures/schedule`);
    //     // }
    //   };
      
      const handleDelete = async (e) => {
        e.preventDefault();

        if (!window.confirm('정말 삭제하시겠습니까?')) return;
        await restApi.delete(`/lectures/lectureSchedule/${item.id}`);
        alert('삭제되었습니다.');
        window.history.back();
      };

    return (
    <form className="row">
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">시간표</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">시간표</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // header e */}
        {/* con s */}
        <div className="content-wrapper container-xxl p-0">
        <form className="row" >
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {!['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type) && (
                      <tr>
                        <th>
                          <label className="form-label">학기/교육 *</label>
                        </th>
                        <td className="d-flex align-items-center">
                        {item.semesterYear}년 - {codes?.map?.[item.semesterSession] + " / " + item.lectureTitle}
                      </td>
                      </tr>
                    )}
                    <tr>
                      <th>
                            <label className="form-label">날짜/시간 *</label>
                            </th>
                            <td>
                                {item.date} / {item.timeCode == null ? item.startTime.substring(0,5) + "~" + item.endTime.substring(0,5) :item.timeTitle}
                            </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">장소 *</label>
                      </th>
                      <td>
                        {item.place}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">비고</label>
                      </th>
                      <td>
                        {item.etc}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">공지 노출 *</label>
                      </th>
                      <td className="d-flex align-items-center">
                        {item.noticeYN ? "Y" : "N"}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">이력표기</label>
                      </th>
                      <td>
                        {item.statusText}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-12 txt-r">
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light" 
                onClick={() => {
                    navigate(`/lms/lectures/schedule/${item.id}/edit`, {state:{item}});
                }}
                >
                수정
              </button>
              <button type="delete" className="btn btn-primary me-1 waves-effect waves-float waves-light" onClick={handleDelete}>
                삭제
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                    navigate('/lms/lectures/schedule');
                }}
              >
                목록
              </button>
            </div>
          </div>
        </form>
      </div>
        {/* // con e */}
      </div>
    </form>
    )
}