import { getAPIHost } from './api';
import { getMenuItems } from './define';

export const parseTime = (duration) => {
  const sec = duration % 60;
  const min = Math.floor(duration / 60) % 60;
  const hour = Math.floor(Math.floor(duration / 60) / 60);

  let data = '';

  if (hour) {
    data += String(hour).padStart(2, '0') + ':';
  }
  if (min) {
    data += String(min).padStart(2, '0') + ':';
  }
  data += String(sec).padStart(2, '0');

  return data;
};

export const editorInitOptions = {
  height: 500,
  menubar: false,
  branding: false,
  file_picker_types: 'image',
  file_picker_callback: (cb, value, meta) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    input.addEventListener('change', (e) => {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        /*
        Note: Now we need to register the blob in TinyMCEs image blob
        registry. In the next release this part hopefully won't be
        necessary, as we are looking to handle it internally.
      */
        const id = 'blobid' + new Date().getTime();
        const blobCache = tinymce.activeEditor.editorUpload.blobCache;
        const base64 = reader.result.split(',')[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);

        /* call the callback and populate the Title field with the file name */
        cb(blobInfo.blobUri(), { title: file.name });
      });
      reader.readAsDataURL(file);
    });

    input.click();
  },
  plugins: [
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'preview',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'code',
    'help',
    'wordcount',
    'pageembed',
    'code',
  ],
  skin: 'oxide',
  toolbar:
    'pageembed code | undo redo | fontfamily fontsize | ' +
    'bold italic underline forecolor backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | link image media |' +
    'removeformat | help preview | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  content_css: '/contents.css',
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const DATE_FORMAT = 'YYYY.MM.DD';

export const downloadLink = (file) => {
  if (!file) return undefined;
  return (
    getAPIHost() +
    'api/files/' +
    file.id +
    '?response-content-disposition=' +
    encodeURIComponent(`attachment;filename*=utf-8''${encodeURIComponent(file.filename)}`)
  );
};

export const validPassword = (text) => {
  var reg = /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&\*\(\)+=]).*$/;
  return reg.test(text);
};

export const sessionSetItem = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const localSetItem = (key, value) => {
  localStorage.setItem(key, value);
};

export const getRoute = (principal) => {
  const menuItems = getMenuItems(principal)?.filter((x) => x.path === window.location.pathname);
  if (menuItems?.length) {
    return {
      name: menuItems?.[0]?.name,
      path: window.location.pathname,
      mobile: window.innerWidth < 1023,
      download: true,
    };
  }
};

export const isMobile = () => {
  return window.matchMedia('(max-width: 767px)').matches;
};
