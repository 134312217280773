import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';

export default function LmsMasterIdIndex() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const params = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState();
  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/semesters/${params.id}`);

      setData(data);
    };
    loadData().catch(console.warn);
  }, [params.id]);

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">마스터값 설정</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">마스터값 설정</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      {/* Bordered table start */}
      <div className="row">
        <div className="col-12">
          <h5>년도/학기 설정</h5>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">년도/학기</label>
                    </th>
                    <td>
                      {data?.year}학년도 / {codes?.map?.[data?.session]}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h5>운영기간 설정</h5>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">운영기간</label>
                    </th>
                    <td>
                      {data?.startAt} ~ {data?.endAt}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">청강기간 </label>
                    </th>
                    <td>
                      {data?.listenStartAt} ~ {data?.listenEndAt}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h5>주차등록</h5>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  {data?.weeks.map((item, ix) => (
                    <tr>
                      <th>
                        <label className="form-label">{ix + 1}주차</label>
                      </th>
                      <td>
                        {item.startAt} ~ {item.endAt}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button
              type="submit"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={() => {
                navigate(`/lms/master/${params.id}/edit`);
              }}
            >
              수정
            </button>

            <button
              type="submit"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/lms/master');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>
      {/* Bordered table end */}
      {/* // con e */}
    </div>
  );
}
