import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { restApi } from '../../../common/api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { editorInitOptions } from '../../../common/utils';
import FileAttacher from '../../../components/FileAttacher';
import LectureSearchItem from '../../../components/LectureSearchItem';
import dayjs from 'dayjs';
import { setLectureId } from '../../../data/common';
import { useLoading } from '../../../nav/AppContainer';
import { Colors } from 'chart.js';

const LmsHomeworkForm = (props) => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const dispatch = useDispatch();

  const { lectureId: defLectureId } = useSelector((s) => s.common, shallowEqual);
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const attacher = useRef();
  const editorRef = useRef();

  const now = dayjs();

  const [typeT, setTypeT] = useState(type);

  const [loading, setLoading] = useLoading();

  const [lectureId, setLecId] = useState(defLectureId);
  const [weeks, setWeeks] = useState();
  const [initData, setInitData] = useState();
  const [form, setForm] = useState({
    startTime: now.format('HH:mm'),
    limitTime: now.format('HH:mm'),
    startDate: now.format('YYYY-MM-DD'),
    limitDate: now.add(1, 'day').format('YYYY-MM-DD'),
    showScoreStartDate : now.format('YYYY-MM-DD'),
    showScoreEndDate : now.add(1, 'day').format('YYYY-MM-DD'),
    showScoreStartTime : now.format('HH:mm'),
    showScoreEndTime : now.format('HH:mm'),
    showScore: false,
  });
  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/lectures/${lectureId}/weeks`, {});
    setWeeks(data);
  }, [lectureId]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  useEffect(() => {
    if (id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/lectures/0/homeworks/${id}`);
        setInitData(data);
        setLecId(data?.lecture?.id);
        setForm((x) => ({
          ...x,
          continue: !!data.continueAt,
          startDate: !data?.startAt ? undefined : dayjs(data?.startAt).format('YYYY-MM-DD'),
          startTime: !data?.startAt ? '00:00' : dayjs(data?.startAt).format('HH:mm'),
          limitDate: !data?.limitAt ? undefined : dayjs(data?.limitAt).format('YYYY-MM-DD'),
          limitTime: !data?.limitAt ? '00:00' : dayjs(data?.limitAt).format('HH:mm'),
          continueDate: !data?.continueAt ? undefined : dayjs(data?.continueAt).format('YYYY-MM-DD'),
          continueHour: !data?.continueAt ? '00' : dayjs(data?.continueAt).format('HH'),
          continueMin: !data?.continueAt ? '00' : dayjs(data?.continueAt).format('mm'),
          showScore: data.showScore,
          showScoreStartDate: !data?.showScore ? now.format('YYYY-MM-DD') : dayjs(data?.showScoreStartAt).format('YYYY-MM-DD'),
          showScoreStartTime: !data?.showScore ? now.format('HH:mm') : dayjs(data?.showScoreStartAt).format('HH:mm'),
          showScoreEndDate: !data?.showScore ? now.format('YYYY-MM-DD') : dayjs(data?.showScoreEndAt).format('YYYY-MM-DD'),
          showScoreEndTime: !data?.showScore ? now.format('HH:mm') : dayjs(data?.showScoreEndAt).format('HH:mm'),

        }));
      };
      loadData().catch(console.warn);
    }
  }, [id]);



  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      contentsWithMarkup: editorRef.current.getContent(),
      continueAt:
        !form.continueDate || !form.continue
          ? undefined
          : `${form.continueDate}T${form.continueHour ?? '00'}:${form.continueMin ?? '00'}:00.000Z`,
      startAt: `${form.startDate}T${form.startTime ?? '00:00'}:00.000Z`,
      limitAt: `${form.limitDate}T${form.limitTime ?? '00:00'}:00.000Z`,
      title: e.target.title.value,
      type: e.target.type.value,
      week: e.target.week.value,
      lectureId,
      showScore: form.showScore,
      showScoreStartAt: form.showScore ? `${form.showScoreStartDate}T${form.showScoreStartTime ?? '00:00'}:00.000Z` : null,
      showScoreEndAt: form.showScore ? `${form.showScoreEndDate}T${form.showScoreEndTime ?? '00:00'}:00.000Z` : null,
    };

    if (!payload.lectureId) {
      alert('강의 항목은 필수항목입니다.');
      return;
    }

    if (!payload.type) {
      alert('구분은 필수항목입니다.');
      return;
    }

    if (!payload.title) {
      alert('제목은 필수항목입니다.');
      return;
    }

    if (!form.startDate) {
      alert('시작일은 필수항목입니다.');
      return;
    }
    if (!form.limitDate) {
      alert('마감일은 필수항목입니다.');
      return;
    }
    if (!dayjs(payload.startAt).isBefore(dayjs(payload.limitAt))) {
      alert('마감일이 시작일보다 빠릅니다.');
      return;
    }
    if (payload.continueAt && !dayjs(payload.limitAt).isBefore(dayjs(payload.continueAt))) {
      alert('제출기한 연장일이 마감일보다 빠릅니다.');
      return;
    }

    if (!payload.contentsWithMarkup) {
      alert('내용을 입력해주세요.');
      return false;
    }

    if (form.continue && !form.continueDate) {
      alert('연장일은 필수항목입니다.');
      return;
    }

    if (!form.continue && form.showScore && dayjs(payload.showScoreStartAt).isBefore(dayjs(payload.limitAt))) {
      alert('과제 기간 및 점수 공개일을 확인 해 주세요');
      return;
    }

    if (form.continue && form.showScore && dayjs(payload.showScoreStartAt).isBefore(dayjs(payload.continueAt))) {
      alert('제출기간 연장 및 점수 공개일을 확인 해 주세요');
      return;
    }
    if (form.showScore && dayjs(payload.showScoreEndAt).isBefore(dayjs(payload.showScoreStartAt))) {
      alert('점수 공개일을 확인 해 주세요');
      return;
    }
    setLoading(true);
    try {
      const ids = await attacher.current.getFileIds();
      payload.files = ids;

      if (id) {
        await restApi.put(`/lectures/${lectureId}/homeworks/${id}`, payload);
        alert('저장되었습니다.');
        await navigate('/lms/homeworks/' + payload.type + '/' + id);
      } else {
        await restApi.post(`/lectures/${lectureId}/homeworks`, payload);
        alert('등록되었습니다.');
        dispatch(setLectureId(lectureId));
        await navigate('/lms/homeworks/' + payload.type);
      }
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">과제</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">과제</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // header e */}
        {/* con s */}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {!['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type) && (
                      <tr>
                        <th>
                          <label className="form-label">과목</label>
                        </th>
                        <td>
                          <LectureSearchItem value={lectureId} onChange={setLecId} disabled={!!id} />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>
                        <label className="form-label">과제 구분</label>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <select className="form-select w-auto pe-3" name={'type'} defaultValue={type} onChange={(elem) => {setTypeT(elem.target.value)}}>
                            <option value={''}>선택해주세요</option>
                            <option value={'single'}>개인과제</option>
                            <option value={'group'}>그룹과제</option>
                          </select>
                          {typeT === 'group' && (
                          <label className="ms-1" style={{color:'red'}}>* 그룹과제는 [멤버]-[그룹관리]에서 조편성을 등록하셔야 수강생 화면에 과제가 노출 됩니다.<br/>
                          * 그룹과제는 조편성에서 지정한 조장만 과제 제출이 가능합니다. (조원은 제출 불가!!)</label>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">과제명</label>
                      </th>
                      <td>
                        <input type="text" className="form-control" name={'title'} defaultValue={initData?.title} />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">기간</label>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <input
                            type="date"
                            className="form-control flatpickr-basic flatpickr-input me-25"
                            placeholder="YYYY-MM-DD"
                            style={{ width: 140 }}
                            value={form.startDate}
                            defaultValue={form.startDate}
                            onChange={(e) => {
                              const v = e.target.value;
                              setForm((x) => ({ ...x, startDate: v }));
                            }}
                          />
                          <input
                            type="text"
                            className="form-control flatpickr-basic flatpickr-input"
                            placeholder="HH:MM"
                            style={{ width: 70 }}
                            value={form.startTime}
                            defaultValue={'00:00'}
                            onChange={(e) => {
                              const v = e.target.value;
                              setForm((x) => ({ ...x, startTime: v }));
                            }}
                          />
                          <span className={'ms-25 me-25'}>~</span>
                          <input
                            type="date"
                            id="fp-range"
                            className="form-control flatpickr-range flatpickr-input me-25"
                            placeholder="YYYY-MM-DD"
                            style={{ width: 140 }}
                            value={form.limitDate}
                            onChange={(e) => {
                              const v = e.target.value;
                              setForm((x) => ({ ...x, limitDate: v }));
                            }}
                          />

                          <input
                            type="text"
                            className="form-control flatpickr-basic flatpickr-input"
                            placeholder="HH:MM"
                            style={{ width: 70 }}
                            value={form.limitTime}
                            defaultValue={'00:00'}
                            onChange={(e) => {
                              const v = e.target.value;
                              setForm((x) => ({ ...x, limitTime: v }));
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">제출기간 연장</label>
                      </th>
                      <td>
                        <div className="d-flex align-items-center mb-1">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="inlineRadio1"
                              name={'continue'}
                              checked={!form?.continue}
                              onClick={(x) => setForm((x) => ({ ...x, continue: false }))}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio1">
                              미적용
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="inlineRadio2"
                              name={'continue'}
                              checked={form?.continue}
                              onClick={(x) => setForm((x) => ({ ...x, continue: true }))}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio2">
                              적용
                            </label>
                          </div>
                        </div>
                        {form.continue && (
                          <div className="d-flex align-items-center">
                            {form.limitDate} / {form.limitTime}
                            <span className="hipen">~</span>
                            <input
                              type="date"
                              className="form-control flatpickr-basic flatpickr-input"
                              placeholder="YYYY-MM-DD"
                              style={{ width: 130 }}
                              value={form.continueDate}
                              onChange={(e) => {
                                const v = e.target.value;
                                setForm((x) => ({ ...x, continueDate: v }));
                              }}
                            />
                            <select
                              className="form-select w-auto pe-3 ms-75"
                              value={form.continueHour}
                              onChange={(e) => {
                                const v = e.target.value;
                                setForm((x) => ({ ...x, continueHour: v }));
                              }}
                            >
                              {Array(24)
                                .fill('')
                                .map((_, ix) => (
                                  <option key={`${ix}`} value={String(ix).padStart(2, '0')}>
                                    {String(ix).padStart(2, '0')}
                                  </option>
                                ))}
                            </select>
                            <span className="hipen">:</span>
                            <select
                              className="form-select w-auto pe-3 ms-75"
                              value={form.continueMin}
                              onChange={(e) => {
                                const v = e.target.value;
                                setForm((x) => ({ ...x, continueMin: v }));
                              }}
                            >
                              {Array(6)
                                .fill('')
                                .map((_, ix) => (
                                  <option key={`${ix}`} value={String(ix * 10).padStart(2, '0')}>
                                    {String(ix * 10).padStart(2, '0')}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">연관 주차</label>
                      </th>
                      <td>
                        <select
                          className="form-select w-auto pe-3"
                          name={'week'}
                          defaultValue={String(initData?.week)}
                          key={`${initData?.week}`}
                        >
                          <option value={0}>전체</option>
                          {weeks?.map((week) => (
                            <option value={String(week.index)} key={`${week.index}`}>
                              {week.index}주차
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">내용</label>
                      </th>
                      <td>
                        <Editor
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          initialValue={initData?.contentsWithMarkup}
                          init={editorInitOptions}
                          tinymceScriptSrc="/tinymce/tinymce.min.js"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label" htmlFor="blog-edit-slug">
                          첨부파일
                        </label>
                      </th>

                      <td>
                        <FileAttacher ref={attacher} values={initData?.files ?? []} limitSize={100} />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">점수 공개</label>
                      </th>
                      <td>
                        <div className="d-flex align-items-center mb-1">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="inlineRadio3"
                              name={'showScore'}
                              checked={form?.showScore}
                              onClick={(x) => setForm((x) => ({ ...x, showScore: true }))}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio3">
                              공개
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="inlineRadio4"
                              name={'showScore'}
                              checked={!form?.showScore}
                              onClick={(x) => setForm((x) => ({ ...x, showScore: false }))}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio4">
                              미공개
                            </label>
                          </div>
                        </div>
                        {form.showScore && (
                          <div className="d-flex align-items-center">
                            <input
                            type="date"
                            className="form-control flatpickr-basic flatpickr-input me-25"
                            placeholder="YYYY-MM-DD"
                            style={{ width: 140 }}
                            value={form.showScoreStartDate}
                            defaultValue={form.showScoreStartDate}
                            onChange={(e) => {
                              const v = e.target.value;
                              setForm((x) => ({ ...x, showScoreStartDate: v }));
                            }}
                          />
                          <input
                            type="text"
                            className="form-control flatpickr-basic flatpickr-input"
                            placeholder="HH:MM"
                            style={{ width: 70 }}
                            value={form.showScoreStartTime}
                            defaultValue={'00:00'}
                            onChange={(e) => {
                              const v = e.target.value;
                              setForm((x) => ({ ...x, showScoreStartTime: v }));
                            }}
                          />
                            <span className="hipen">~</span>
                            <input
                            type="date"
                            className="form-control flatpickr-basic flatpickr-input me-25"
                            placeholder="YYYY-MM-DD"
                            style={{ width: 140 }}
                            value={form.showScoreEndDate}
                            defaultValue={form.showScoreEndDate}
                            onChange={(e) => {
                              const v = e.target.value;
                              setForm((x) => ({ ...x, showScoreEndDate: v }));
                            }}
                          />
                          <input
                            type="text"
                            className="form-control flatpickr-basic flatpickr-input"
                            placeholder="HH:MM"
                            style={{ width: 70 }}
                            value={form.showScoreEndTime}
                            defaultValue={'00:00'}
                            onChange={(e) => {
                              const v = e.target.value;
                              setForm((x) => ({ ...x, showScoreEndTime: v }));
                            }}
                          />
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <small className="d-block text-muted mt-m15">
              ※ 제출기간 연장을 설정하게 되면 마감일 이후에 늦게 제출한 과제를 추가로 받을 수 있으며, 해당 과제에
              대해서는 '지각'으로 표시가 됩니다.
            </small>
            <div className="col-12 mt-50 txt-r">
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/lms/homeworks/single');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
        {/* // con e */}
      </div>
    </form>
  );
};

export default LmsHomeworkForm;
