import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { restApi } from '../../../common/api';
import PaginationMoon from '../../../components/PaginationMoon';
import { shallowEqual, useSelector } from 'react-redux';
import DownIco from '../../../components/icons/DownIco';
import { useLoading } from '../../../nav/AppContainer';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';



export default function LmsSubjectIndex() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    // console.log(params)
    const { data } = await restApi.get('/admin/main-classes', { params: { ...params } });

    setData(data);
  }, [params]);


  const [, setLoading] = useLoading();

  const xlsDownload = async () => {
    setLoading(true);
    try {
      const params = queryString.parse(window.location.search);
      // console.log(params)

      const { data } = await restApi.get('/admin/main-classes/xlsx', {
        params: { limit: '100',
      },
        responseType: 'arraybuffer',
      });
      // console.log(data)
      FileSaver.saveAs(new Blob([data]), `과목_${dayjs().format('YYYYMMDD_HHmmss')}.xlsx`);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
      console.warn(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">과목관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">과목관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      {/* Bordered table start */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
            <button type="button" className="btn btn-outline-dark btn-doc waves-effect" onClick={xlsDownload}>
                    엑셀 다운로드{' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={14}
                      height={14}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-log-in"
                    >
                      <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
                      <polyline points="10 17 15 12 10 7" />
                      <line x1={15} y1={12} x2={3} y2={12} />
                    </svg>
                  </button>
              {/* Inputs Group with Dropdown */}
              <div className="col-md-6 col-12 ms-auto">
                <fieldset>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      setParams({
                        keyword: event.target.keyword.value,
                        keywordType: event.target.keywordType.value,
                      });
                    }}
                  >
                    <div className="input-group">
                      <select
                        name="keywordType"
                        className="form-select"
                        id="selectDefault"
                        defaultValue={params.keywordType}
                      >
                        <option value={'title'}>과목명</option>
                        <option value={'master.name'}>교수명</option>
                        <option value={'academicCode'}>학수번호</option>
                      </select>
                      <input
                        name="keyword"
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        defaultValue={params.keyword}
                      />
                      <button className="btn btn-outline-primary waves-effect" type="submit">
                        검색
                      </button>
                    </div>
                  </form>
                </fieldset>
              </div>
              {/* Inputs Group with Dropdown end */}
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '6rem' }} />
                  <col />
                  <col style={{ width: '12rem' }} />
                  <col style={{ width: '10rem' }} />
                  <col style={{ width: '10rem' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>과목명</th>
                    <th>교수</th>
                    <th>분류</th>
                    <th>분야</th>
                    <th>학수번호</th>
                    <th>청강여부</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.content.map((item, index) => (
                    <tr key={item.id}>
                      <td className="txt-c">{data.totalElements - data.size * data.number - index}</td>
                      <td>
                        <Link to={`/lms/subject/${item.id}`} className="txt-underline">
                          {item.title}
                        </Link>
                      </td>
                      <td className="txt-c">
                        <Link to={`/lms/subject/${item.id}`} className="txt-underline">
                          {item.master.name}
                        </Link>
                      </td>
                      <td className="txt-c">{codes?.tree?.MCTP?.find((c) => c.code === item.siteType)?.label}</td>
                      <td className="txt-c">{codes?.tree?.MCST?.find((c) => c.code === item.category)?.label}</td>
                      <td className="txt-c">{item.academicCode}</td>
                      <td className="txt-c">{item.isAudit ? 'Y' : 'N'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 txt-r">
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-float waves-light"
              onClick={() => {
                navigate('/lms/subject/write');
              }}
            >
              등록
            </button>
          </div>
          {/* pagination s */}
          <nav aria-label="Page navigation">
            <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
          </nav>
          {/* // pagination e */}
        </div>
      </div>
      {/* Bordered table end */}
      {/* // con e */}
    </div>
  );
}
