import React from 'react';
import { Link } from 'react-router-dom';

const LnbCommunity = ({ ix = 0 }) => {
  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">자유게시판</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#n">게시판 관리</Link>
                  </li>
                  <li className="breadcrumb-item active">자유게시판</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link className={`nav-link ${ix === 0 ? 'active' : ''}`} to="/site/board/ourStory">
            우리들 이야기
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${ix === 1 ? 'active' : ''}`} to="/site/board/stuQna">
            재학생 Q&A
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${ix === 2 ? 'active' : ''}`} to="/site/board/special">
            명사특강
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${ix === 3 ? 'active' : ''}`} to="/site/board/dataLibrary">
            강의/학습자료실
          </Link>
        </li>
      </ul>
    </>
  );
};
export default LnbCommunity;
