import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { restApi } from '../../../common/api';
import queryString from 'query-string';
import { shallowEqual, useSelector } from 'react-redux';

export default function LmsMasterIndex() {
  const { codes } = useSelector((s) => s.common, shallowEqual);

  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = React.useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();

  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/semesters', { params });
    setData(data);
  }, [params]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">마스터값 설정</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">마스터값 설정</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      {/* Bordered table start */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              {/* Inputs Group with Dropdown */}
              <div className="col-md-6 col-12 ms-auto">
                <fieldset>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      setParams({
                        keyword: event.target.keyword.value,
                        keywordType: event.target.keywordType.value,
                      });
                    }}
                  >
                    <div className="input-group">
                      <select
                        name="keywordType"
                        className="form-select"
                        id="selectDefault"
                        defaultValue={params.keywordType}
                      >
                        <option value={''}>전체</option>
                        <option value={'writer'}>작성자</option>
                      </select>
                      {/* <span class="input-group-text" id="basic-addon-search1"
                    ><i data-feather="search"></i
                  ></span> */}
                      <input
                        name="keyword"
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        defaultValue={params.keyword}
                      />
                      <button className="btn btn-outline-primary waves-effect" type="submit">
                        검색
                      </button>
                    </div>
                  </form>
                </fieldset>
              </div>
              {/* Inputs Group with Dropdown end */}
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>년도 / 학기</th>
                    <th>운영기간</th>
                    <th>청강기간</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.content.map((item, index) => (
                    <tr>
                      <td className="txt-c">{`${data.totalElements - data.size * data.number - index}`}</td>
                      <td className="txt-l">
                        <Link to={`/lms/master/${item.id}`}>{`${item.year}학년도 ${codes?.map?.[item?.session]}`}</Link>
                      </td>
                      <td className="txt-c">{`${item.startAt} ~ ${item.endAt}`}</td>
                      <td className="txt-c">{`${item.listenStartAt} ~ ${item.listenEndAt}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 txt-r">
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-float waves-light"
              onClick={() => {
                navigate('/lms/master/write');
              }}
            >
              등록
            </button>
          </div>
          {/* pagination s */}
          {/* <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center mt-2">
          <li class="page-item prev">
            <Link class="page-link" to="#"></Link>
          </li>
          <li class="page-item"><Link class="page-link" to="#">1</Link></li>
          <li class="page-item"><Link class="page-link" to="#">2</Link></li>
          <li class="page-item"><Link class="page-link" to="#">3</Link></li>
          <li class="page-item active" aria-current="page">
            <Link class="page-link" to="#">4</Link>
          </li>
          <li class="page-item"><Link class="page-link" to="#">5</Link></li>
          <li class="page-item"><Link class="page-link" to="#">6</Link></li>
          <li class="page-item"><Link class="page-link" to="#">7</Link></li>
          <li class="page-item next">
            <Link class="page-link" to="#"></Link>
          </li>
        </ul>
      </nav> */}
          {/* // pagination e */}
        </div>
      </div>
      {/* Bordered table end */}
      {/* // con e */}
    </div>
  );
}
