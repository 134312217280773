import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { restApi } from '../common/api';
import Modal from './Modal';
import PaginationMoon from './PaginationMoon';
import produce from 'immer';
import { useLoading } from '../nav/AppContainer';
import { all } from 'axios';

const UserSelectForExam = ({
  online,
  offline,
  onChange,
  type = 'USER',
  maxLength,
  title,
  extraParams,
  lectureId,
  extraSelectOption = false,
  isOver,
  isOffline,
  examStyle,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState({});
  const [params, setParams] = useState({});
  const [query, setQuery] = useState({});
  const [cachedNames, setCachedNames] = useState({});
  // const [isForeign, setIsForeign] = useState((!isOffline)); //검색조건에서 해외 여부
  const [checkUserSet, setCheckUserSet] = useState();
  // const [tempValue, setTempValue] = useState();
  // const [tempValue2, setTempValue2] = useState();
  const [allUserIds, setAllUserIds] = useState();
  const [onlineUserIds, setOnlineUserIds] = useState();
  const [offlineUserIds, setOfflineUserIds] = useState();
  const [isSelectInput, setIsSelectInput] = useState(false);

  const [foreignIds, setForeignIds] = useState();
  const [domesticIds, setDomesticIds] = useState();

  const [loading, setLoading] = useLoading();

  useLayoutEffect(() => {
    const loadData = async (ids) => {
      await Promise.all(
        ids.map(async (id) => {
          const { data } = await restApi.get(`/accounts/${id}/bo`);
          setUsers((x) => ({ ...x, [data?.id]: data }));
          setCachedNames((x) => ({ ...x, [data?.id]: data.name }));
        }),
      );
    };
    let ids = (online ?? []).filter((x) => !Object.keys(users).includes(String(x)));
    if (ids.length) loadData(ids).catch(console.warn);
  }, [online,offline, users]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/lectures/${lectureId}/exams/accounts`, {
      params: {
        ...params,
        limit: 15
      },
    });
    // console.log(data);
    setData(data);
    // setOfflineUserIds(offline);
    // setCachedNames(online.content.map((item) => item.name));
    
  }, [lectureId, params, extraParams, extraSelectOption, query.type, type, isOver]);

  useEffect(() => {
    if (showModal) {
      loadData().catch(console.warn);
    }
  }, [showModal, loadData]);

  const handleSubmit = (e) => {
    // if (query.keywordType === 'isForeign') {
    //   setIsForeign(true);
    // } else {
    //   setIsForeign(false);
      setParams(query);
    // }
    return false;
  };

  const handleClose = () => {
    setOfflineUserIds(offline);
    setOnlineUserIds(online);
    setCheckUserSet();
    setShowModal(false);
  };

  useEffect(() => {
    // console.log(isOver)
    const loadUserData = async () => {
      setLoading(true);
      const { data } = await restApi.get(`/lectures/${lectureId}/exams/accounts`, {
        params: {
          limit: 99999,
        },
      });
      setAllUserIds(data.content.map((item) => item.id));
      setOnlineUserIds(online);
      setOfflineUserIds(offline);
      setForeignIds(data.content.filter((item) => item.isForeign).map((item) => item.id));
      //new
      setDomesticIds(data.content.filter((item) => !item.isForeign).map((item) => item.id));
      //
      // if (online?.length === allUserIds?.length) {
      //   setCheckUserSet('all');
      // } else if (online?.length === onlineUserIds?.length) {
      //   setCheckUserSet('foreignOnly');
      // } else {
      //   setCheckUserSet('offlineOnly');
      // }
      setLoading(false);
    };
    if (lectureId != null) {
      loadUserData();
    }
  }, [online]);

  useEffect(() => {
    console.log(checkUserSet)
    console.log(online,offline)
    console.log(onlineUserIds,offline)
    if (checkUserSet === 'all') {
      setOnlineUserIds(allUserIds);
      setOfflineUserIds(null);
    } else if (checkUserSet === 'foreignOnly') {
      setOnlineUserIds(foreignIds);
      setOfflineUserIds(domesticIds);
    }  else {
      setOnlineUserIds(null);
      setOfflineUserIds(allUserIds);
    }
  }, [checkUserSet]);

  
  return (
    <>
      <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
          <button
          disabled={title == '시험 응시자' ? examStyle == undefined : examStyle != undefined}
          type="button"
          className="btn btn-primary waves-effect waves-float waves-light"
          onClick={() => {
            if (isOver) {
              alert("종료 된 시험은 응시자 선택이 불가능 합니다.")
            } else {
              // setTempValue(online);
              // setTempValue2(offline);
              setShowModal(true);
            }
          }}
        >
          검색
        </button>        
          <p className="my-50" style={{ marginLeft: 30 }}>
            온라인 {online?.length > 0 ? online?.length : '0'}명 선택, 오프라인 {isOffline? offline?.length > 0 ? offline?.length : '0' : '0'}명 선택
            {online?.length > 0 ? (
              <button
                type="button"
                className="btn-file-del"
                onClick={() => {
                  if (isOver) {
                    alert("종료 된 시험은 응시자 선택이 불가능 합니다.")
                  } else {
                    onChange(
                      produce(online, (draft) => {
                        draft.length = 0;
                      }),
                      produce(offline, (draft) => {
                        draft.length = 0;
                      }),
                    ); 
                  }
                }}
              >
                파일삭제
              </button>
            ) : null}
          </p>
      </div>
      <Modal className="fade text-start modal-primary" visible={showModal}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button type="button" className="btn-close" onClick={handleClose} />
            </div>
            <div className="modal-body txt-c">
              {/* Inputs Group with Dropdown */}
              <div className="col-12 ms-auto">
                <fieldset>
                  <div className="input-group">
                    <select
                      className="form-select"
                      id="selectDefault"
                      value={query.keywordType}
                      onChange={(e) => {
                        const v = e.target.value;
                        if (v == 'type') {
                          setIsSelectInput(true);
                        } else {
                          setIsSelectInput(false);
                        }
                        setQuery((x) => ({ ...x, keywordType: v }));
                      }}
                    >
                      <option value={''}>전체</option>
                      <option value={'name'}>이름</option>
                      <option value={'period'}>기수</option>
                      <option value={'isForeign'}>해외</option>
                      {extraSelectOption && <option value={'type'}>구분</option>}
                    </select>
                    {isSelectInput ? (
                      <select
                        className="form-select"
                        style={{ flex: '1 1 auto' }}
                        defaultValue={'student'}
                        name="typeSelector"
                        onChange={(e) => {
                          const v = e.target.value;
                          setQuery((x) => ({
                            ...x,
                            attending:
                              v.toUpperCase() === 'USER' ? true : v.toUpperCase() === 'GRADUATE' ? false : undefined,
                            type: v.toUpperCase() === 'GRADUATE' ? 'USER' : v.toUpperCase(),
                          }));
                        }}
                      >
                        <option value={'user'}>원우(재학생)</option>
                        <option value={'graduate'}>원우(졸업생)</option>
                        <option value={'teacher'}>교수</option>
                        <option value={'staff'}>튜터</option>
                        <option value={'manager'}>행정실</option>
                        <option value={'admin'}>지원실</option>
                        <option value={'examstaff'}>시험감독관</option>
                      </select>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        value={query.keyword}
                        disabled={query.keywordType === 'isForeign'}
                        onChange={(e) => {
                          const v = e.target.value;
                          setQuery((x) => ({ ...x, keyword: v }));
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode == 13) {
                            e.preventDefault();
                            return handleSubmit();
                          }
                        }}
                      />
                    )}
                    <button className="btn btn-outline-primary waves-effect" type="button" onClick={handleSubmit}>
                      검색
                    </button>
                  </div>
                </fieldset>
              </div>

              <div className="pt-50">
                  <div>
                    <button
                      type="button"
                      className={`btn btn-sm rounded ${!checkUserSet ? 'btn-secondary' : 'btn-primary'}`}
                      onClick={() => setCheckUserSet('foreignOnly')}
                    >
                      해외원우
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm rounded ms-25 ${checkUserSet === 'all' ? 'btn-primary' : 'btn-secondary'}`}
                      onClick={() => {
                        if (checkUserSet === 'all') {
                          setCheckUserSet();
                        } else {
                          setCheckUserSet('all');
                        }
                      }}
                    >
                      전체
                    </button>
                  </div>
                <div className="pt-50">
                  <strong>선택된 사용자</strong>
                </div>
                  <div>{checkUserSet === 'all' ? '전체원우' : checkUserSet === 'foreignOnly' ? '해외원우' : onlineUserIds?.map((x) => cachedNames?.[x] ?? x)?.join(',')}</div>
              </div>

              {/* Inputs Group with Dropdown end */}
              <div className="table-responsive mt-1">
                <table className="table table-bordered">
                  <colgroup>
                    <col style={{ width: '4rem' }} />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    {type == 'USER' ? (
                      <tr>
                        <th />
                        <th>이름</th>
                        <th>국내/해외</th>
                        <th>IMBA 기수</th>
                        <th>학번</th>
                      </tr>
                    ) : (
                      <tr>
                        <th />
                        <th>이름</th>
                        <th>ID</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {data?.content?.map((item) => {
                      const checked = onlineUserIds?.includes(item.id);
                      return (
                        <tr key={`${item.id}`}>
                          <td>
                            <input
                              className="form-check-input"
                              type={maxLength > 1 ? 'checkbox' : 'radio'}
                              name="choice1"
                              checked={checked}
                              onChange={() => {
                                if (!checked) setUsers((x) => ({ ...x, [item.id]: item }));
                                setCachedNames((x) => ({ ...x, [item?.id]: item.name }));
                                const handleItemToggle = (draft, item) => {
                                  const ix = draft.indexOf(item.id);
                                  if (ix >= 0) {
                                    draft.splice(ix, 1);
                                  } else {
                                    draft.push(item.id);
                                  }
                                  if (draft.length >= maxLength) {
                                    draft.splice(0, draft.length - maxLength);
                                  }
                                };
                                const handleItemToggle2 = (draft, item) => {
                                  const ix = draft.indexOf(item.id);
                                  if (ix >= 0) {
                                    draft.splice(ix, 1);
                                  } else {
                                    draft.push(item.id);
                                  }
                                  if (draft.length >= maxLength) {
                                    draft.splice(0, draft.length - maxLength);
                                  }
                                };

                                setOnlineUserIds(produce(onlineUserIds, (draft) => handleItemToggle(draft, item)));
                                if (isOffline) {
                                  setOfflineUserIds(produce(offlineUserIds, (draft) => handleItemToggle2(draft, item)));
                                }
                              }}
                            />
                          </td>
                          {type == 'USER' ? (
                            <>
                              <td>{item.name}</td>
                              <td>{item.isForeign ? '해외' : '국내'}</td>
                              <td>{item.period}기</td>
                              <td>{item.idno || item.userId || item.id}</td>
                            </>
                          ) : (
                            <>
                              <td>{item.name}</td>
                              <td>{item.id || item.userId}</td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
                <div className="mt-1">
                  <button
                    type="button"
                    className="btn btn-primary me-1 waves-effect waves-float waves-light"
                    onClick={() => {
                      onChange(
                        produce(online, (draft) => {
                          draft.length = 0;
                          if (onlineUserIds != null) {
                            draft.push(...onlineUserIds);
                          }
                        }),
                        produce(offline, (draft) => {
                          draft.length = 0;
                          if (offlineUserIds != null && isOffline) {
                            draft.push(...offlineUserIds);
                          }
                        }),
                      );
                      setOnlineUserIds(online);
                      setOfflineUserIds(offline)
                      setCheckUserSet();
                      setShowModal(false);
                    }}
                  >
                    저장
                  </button>
                  <button type="button" className="btn btn-outline-secondary waves-effect" onClick={handleClose}>
                    취소
                  </button>
                </div>
              {/* pagination s */}
              <nav aria-label="Page navigation">
                <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
              </nav>
              {/* // pagination e */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserSelectForExam;
