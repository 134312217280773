import dayjs from 'dayjs';
import produce from 'immer';
import queryString from 'query-string';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getConfigValue, restApi } from '../../../../../common/api';
import ExamAddPoint from '../../../../../components/ExamAddPoint';
import ExamSelector from '../../../../../components/ExamSelector';
import FileAttacher from '../../../../../components/FileAttacher';
import { useLoading } from '../../../../../nav/AppContainer';
import { parseHTML } from '../../../../../common/define';

const numReg = /^[0-9]+(\.[0-9]{1,2})?$/;

export default function LmsExamsItemsIdDetailsPaper() {
  const navigate = useNavigate();
  const codemap = useSelector((s) => s.common.codes?.map, shallowEqual);
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual) ?? 0;
  const query = useParams();

  const attachers = useRef({});

  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = React.useState(queryString.parse(window.location.search));
  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [showModal, setShowModal] = useState(false);
  const [addPoint, setAddPoint] = useState(false);
  const [order, setOrder] = useState();
  const [sample, setSample] = useState({});
  const [examData, setExamData] = useState([
    {
      answers: [],
      correctAnswer: undefined,
      correctAnswers: [],
      orderIndex: undefined,
      question: undefined,
      questionType: undefined,
    },
  ]);
  const [data, setData] = useState();
  const [files, setFiles] = useState([]);

  const loadData = useCallback(async () => {
    if (query.id) {
      const { data } = await restApi.get(`/lectures/0/exams/${query.id}`);
      setData(data);
      setExamData(
        data.questions?.map((x) => {
          const o = { ...x };
          if (!o.correctAnswers) {
            if (o.questionType === 'multiple') {
              o.correctAnswer = o.answers.indexOf(o.correctAnswer);
            }
          } else {
            if (o.questionType === 'multiple-one' || o.questionType === 'multiple-many') {
              if (Array.isArray(o.correctAnswers)) {
                o.correctAnswers = o.correctAnswers.map((answer) => o.answers.indexOf(answer));
              } else {
                o.correctAnswers = o.answers.indexOf(o.correctAnswers);
              }
            }
          }
          return o;
        }),
      );
      const { data: fdata } = await restApi.get(`/files/infos`, { params: { ids: data.fileIds } });
      setFiles(fdata?.map((x) => ({ id: x.id, name: x.filename })));
    }
  }, [query.id]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  useEffect(() => {}, []);

  const [loading, setLoading] = useLoading(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (dayjs(data?.startAt).isBefore(dayjs())) {
      alert('시험 시작 후에는 수정할 수 없습니다.');
      return;
    }

    const payload = {
      items: examData?.map((x) => {
        const o = { ...x };
        if (!o.correctAnswers) {
          if (o.questionType === 'multiple') {
            o.correctAnswer = o.answers[x.correctAnswer];
          }
        } else {
          if (o.questionType === 'multiple-one' || o.questionType === 'multiple-many') {
            if (Array.isArray(o.correctAnswers)) {
              o.correctAnswers = o.correctAnswers.map((index) => o.answers[index]);
            } else {
              o.correctAnswers = [o.answers[o.correctAnswers]];
            }
          } else if (o.questionType === 'single' && !Array.isArray(o.correctAnswers)) {
            o.correctAnswers = [o.correctAnswers];
          }
        }
        return o;
      }),
    };

    const errorMessages = [];

    for (let i = 0; i < payload.items.length; i++) {
      const item = payload.items[i];
      if (!item.question) {
        errorMessages.push(`${i + 1}번 문제를 입력해주세요.`);
        continue;
      }
      if (!item.questionType) {
        errorMessages.push(`${i + 1}번 문제 유형을 선택해주세요.`);
        continue;
      }
      if (
        (item.questionType === 'multiple-one' || item.questionType === 'multiple-many') &&
        item.answers.some((str) => str === '')
      ) {
        errorMessages.push(`${i + 1}번 보기 내용을 입력해주세요.`);
        continue;
      }
      if (item.questionType === 'multiple-one' && !item.correctAnswers) {
        errorMessages.push(`${i + 1}번 정답을 입력해주세요`);
        continue;
      }
      if (item.questionType === 'multiple-many' && (!item.correctAnswers || item.correctAnswers.length <= 1)) {
        errorMessages.push(`${i + 1}번 정답을 2개 이상 입력해주세요`);
        continue;
      }
      if (item.point) {
        if (!numReg.test(item.point)) {
          errorMessages.push(`${i + 1}번 배점은 소수점 둘째자리까지 입력가능합니다`);
          continue;
        } else {
          item.point = Number(item.point);
        }
      } else {
        errorMessages.push(`${i + 1}번 배점을 입력해주세요`);
        continue;
      }
      if (item.questionType === 'single' && !item.correctAnswers) {
        if (!window.confirm(`${i + 1}번 주관식 정답을 입력하지 않았습니다. 제출하시겠습니까?`)) {
          return;
        }
      }
    }

    if (errorMessages.length > 0) {
      alert(errorMessages.join('\n'));
      return;
    }

    setLoading(true);
    payload.items = await Promise.all(
      payload.items.map(async (v, ix) => {
        const files = await attachers.current?.[ix]?.getFileIds();
        return { ...v, files };
      }),
    );

    await restApi.put(`/lectures/${lectureId}/exams/${query.id}/questionsNew`, payload);
    alert('저장되었습니다.');
    setLoading(false);
    await navigate(`/lms/exams/items/${query.id}`);
  };

  const uploadFile = async () => {
    setLoading(true);
    const a = await Promise.all(
      files?.map(async (x) => {
        if (x.id) return x.id;

        const formData = new FormData();
        formData.append('file', x);
        formData.append('secret', false);
        const { data } = await restApi.post('/files', formData);
        return data?.id;
      }),
    );

    const payload = {
      fileIds: a,
    };

    await restApi.put(`/lectures/${lectureId}/exams/${query.id}/questionsNew`, payload);
    alert('저장되었습니다.');
    await navigate(`/lms/exams/items/${query.id}`);
    setLoading(false);
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">시험</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">시험</li>
                  <li className="breadcrumb-item active">시험</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <Link className="nav-link " to={`/lms/exams/items/${query.id}`}>
              시험정보
            </Link>
          </li>
          {/* 시험 응시가 완료되었을 경우 */}
          <li>
            <Link className="nav-link active" to={`/lms/exams/items/${query.id}/paper`}>
              시험지 보기
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={`/lms/exams/items/${query.id}/users`}>
              응시자 보기
            </Link>
          </li>
        </ul>
        <button
          disabled={(data?.isOffline && data?.examStyle === null) || data?.examStyle === 'DOWNLOAD'}
          onClick={() => {
            const d = restApi.store_data;
            window.open(
              `${getConfigValue('WEB_HOST')}lms/lectures/${lectureId}/exams/${
                query.id
              }/apply?_layout=none&k=${encodeURI(d.access_token)}`,
              'exam_preview',
              'width=1000, height=1200, scrollbars=yes',
            );
          }}
          className="btn btn-outline-primary waves-effect"
        >
          시험지 미리보기
        </button>
      </div>
      <form className="tab-content" onSubmit={handleSubmit}>
        <div className="tab-pane active" id="en" aria-labelledby="en-tab" role="tabpanel">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col style={{ width: '35%' }} />
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                          <label className="form-label">시험유형</label>
                        </th>
                        {data?.type === 'QUIZ' && (
                          <td>
                            퀴즈 - {data?.lecture?.semester?.year}년 {codemap[data?.lecture?.semester?.session]}{' '}
                            {data?.lecture?.mainClass?.title}
                          </td>
                        )}
                        {data?.type === 'EXAM' && (
                          <td>
                            {codemap[data?.session]} - {data?.lecture?.semester?.year}년{' '}
                            {codemap[data?.lecture?.semester?.session]} {data?.lecture?.mainClass?.title}
                          </td>
                        )}
                        <th>
                          <label className="form-label">시험방식</label>
                        </th>
                        {data?.type === 'EXAM' && (
                          <td>{data.isOffline ? '오프라인 시험' : ''}{(data.isOffline && data.examStyle != null) ? ', ' : ''}{data?.examStyle === null ? '' : data?.examStyle === 'ONLINE' ? '온라인 시험' : '시험지 다운로드'}</td>
                        )}
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">시험명</label>
                        </th>
                        <td colSpan={3}>{data?.title}</td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">시험지 안내문구</label>
                        </th>
                        <td colSpan={3}>
                          <div
                            className={'content-area'}
                            dangerouslySetInnerHTML={{ __html: parseHTML(data?.contents) }}
                          ></div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">공개여부</label>
                        </th>
                        <td>{data?.active ? '공개' : '비공개'}</td>
                        <th>
                          <label className="form-label">문항유형(문항수)</label>
                        </th>
                        {data?.examStyle === 'ONLINE' ? (
                          data?.questions.length > 1 && (
                            <td>
                              {data?.questions.some((q) => q.questionType === 'multiple-one')
                                ? '객관식(단일정답)'
                                : data?.questions?.some((q) => q.questionType === 'multiple-many')
                                ? '객관식(복수정답)'
                                : data?.questions?.some((q) => q.questionType === 'single')
                                ? '주관식'
                                : '혼합형'}{' '}
                              ({data?.questions?.length}문제)
                            </td>
                          )
                        ) : (
                          data?.isOffline ? (
                            <td>-</td>
                          ) : (
                            <td>파일 다운로드 형식</td>
                          )
                        )}
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">점수 공개</label>
                        </th>
                        {data?.examStyle === 'ONLINE' ? (
                        <td colSpan={3}>
                        <p className="txt-info">{data?.showAnswer ? '공개' : '비공개'}</p>
                        {data?.showAnswer && (
                          <p className="txt-info">
                              {dayjs(data?.showAnswerStartAt).format('YYYY-MM-DD / HH:mm')} ~ {dayjs(data?.showAnswerEndAt).format('YYYY-MM-DD / HH:mm')}
                              {/* showAnswerStartAt / showAnswerEndAt null */}
                            </p>
                        )}
                        </td>
                        ) : (
                           <td colSpan={3}>
                          <p className="txt-info">{data?.isShowExamResult ? '공개' : '비공개'}</p>
                          {data?.isShowExamResult && (
                            <p className="txt-info">
                                {dayjs(data?.showExamResultStartAt).format('YYYY-MM-DD / HH:mm')} ~ {dayjs(data?.showExamResultEndAt).format('YYYY-MM-DD / HH:mm')}
                                {/* showAnswerStartAt / showAnswerEndAt null */}
                              </p>
                          )}
                        </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* Bordered table end e */}
          </div>

          {data?.examStyle === 'ONLINE' ? (
            <div className="row">
              <div className="col-12 mb-md-0 mb-2">
                {!dayjs(data?.startAt).isBefore(dayjs()) && (
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="me-3 mt-50">시험문제</h5>
                        <button
                          type="button"
                          className="btn btn-outline-dark btn-doc waves-effect"
                          data-bs-toggle="modal"
                          data-bs-target="#btnNewpaper"
                          onClick={() => setShowModal(true)}
                        >
                          기존 시험 불러오기
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {examData?.map((q, ix) => {
                  const handleRemove = () => {
                    setExamData(
                      produce((draft) => {
                        draft.splice(ix, 1);
                      }),
                    );
                  };

                  const handleChange = (name, value) => {
                    setExamData(
                      produce((draft) => {
                        draft[ix][name] = value;
                      }),
                    );
                  };

                  return (
                    <div className="card mt-2" key={`question-${ix}`}>
                      <div className="table-responsive">
                        <table className="table table-bordered visual-box">
                          <colgroup>
                            <col style={{ width: '11rem' }} />
                            <col />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>
                                <label className="form-label">문제 {ix + 1}</label>
                                {!dayjs(data?.startAt).isBefore(dayjs()) && (
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger btn-sm waves-effect ms-1"
                                    onClick={handleRemove}
                                  >
                                    삭제
                                  </button>
                                )}
                              </th>
                              <td>
                                <textarea
                                  className="form-control my-75"
                                  rows={5}
                                  placeholder=""
                                  value={q.question}
                                  onChange={(e) => handleChange('question', e.target.value)}
                                  disabled={dayjs(data?.startAt).isBefore(dayjs())}
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label className="form-label">사진첨부</label>
                              </th>
                              <td>
                                <FileAttacher
                                  ref={(e) => {
                                    attachers.current[ix] = e;
                                  }}
                                  values={q.files ?? []}
                                  limitSize={100}
                                  showDesc={false}
                                  showPreview
                                  disabled={dayjs(data?.startAt).isBefore(dayjs())}
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label className="form-label">보기유형</label>
                              </th>
                              <td className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <select
                                    className="form-select pe-3 me-75"
                                    style={{ width: 'auto' }}
                                    value={q.questionType}
                                    onChange={(e) => handleChange('questionType', e.target.value)}
                                    disabled={dayjs(data?.startAt).isBefore(dayjs())}
                                  >
                                    <option value="">선택해주세요</option>
                                    <option value="multiple-one">객관식(단일정답)</option>
                                    <option value="multiple-many">객관식(복수정답)</option>
                                    <option value="single">주관식</option>
                                  </select>
                                  {q.questionType === 'multiple-one' && (
                                    <select
                                      className="form-select w-auto pe-3"
                                      value={String(q?.answers?.length || '')}
                                      onChange={(e) => {
                                        if (!e.target.value) return;
                                        const v = parseInt(e.target.value) - q.answers.length;
                                        setExamData(
                                          produce((draft) => {
                                            if (v < 0) {
                                              draft[ix].answers.splice(q.answers.length + v, Math.abs(v));
                                            } else {
                                              draft[ix].answers.push(...Array(v).fill(''));
                                            }
                                          }),
                                        );
                                      }}
                                      disabled={dayjs(data?.startAt).isBefore(dayjs())}
                                    >
                                      <option value={''}>문항수</option>
                                      <option value={'2'}>2</option>
                                      <option value={'3'}>3</option>
                                      <option value={'4'}>4</option>
                                      <option value={'5'}>5</option>
                                    </select>
                                  )}
                                  {q.questionType === 'multiple-many' && (
                                    <select
                                      className="form-select w-auto pe-3"
                                      value={String(q?.answers?.length || '')}
                                      onChange={(e) => {
                                        if (!e.target.value) return;
                                        const v = parseInt(e.target.value) - q.answers.length;
                                        setExamData(
                                          produce((draft) => {
                                            if (v < 0) {
                                              draft[ix].answers.splice(q.answers.length + v, Math.abs(v));
                                            } else {
                                              draft[ix].answers.push(...Array(v).fill(''));
                                            }
                                          }),
                                        );
                                      }}
                                      disabled={dayjs(data?.startAt).isBefore(dayjs())}
                                    >
                                      <option value={''}>문항수</option>
                                      <option value={'3'}>3</option>
                                      <option value={'4'}>4</option>
                                      <option value={'5'}>5</option>
                                    </select>
                                  )}
                                </div>
                                <span>※ 객관식(복수 정답)의 경우, 모든 답안을 맞춰야만 최종 정답으로 인정됩니다.</span>
                              </td>
                            </tr>
                            {q.questionType == 'multiple-one' && q.answers?.length > 0 && (
                              <>
                                <tr>
                                  <th>보기내용</th>
                                  <td>
                                    {q.answers?.map((a, idx) => (
                                      <div className="d-flex align-items-center add-wrap" key={`answer-${idx}`}>
                                        <div className="form-check form-check-inline w-100px">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            checked={q.correctAnswers == idx}
                                            onChange={() => {
                                              setExamData(
                                                produce((draft) => {
                                                  draft[ix].correctAnswers = [idx];
                                                }),
                                              );
                                            }}
                                            disabled={dayjs(data?.startAt).isBefore(dayjs())}
                                          />
                                          <label className="form-check-label ps-25" htmlFor="ch01">
                                            보기 {idx + 1}
                                          </label>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control col"
                                          value={q.answers[idx]}
                                          onChange={(e) =>
                                            setExamData(
                                              produce((draft) => {
                                                draft[ix].answers[idx] = e.target.value;
                                              }),
                                            )
                                          }
                                          disabled={dayjs(data?.startAt).isBefore(dayjs())}
                                        />
                                      </div>
                                    ))}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <label className="form-label">정답선택</label>
                                  </th>
                                  <td>보기 앞에 체크박스를 선택해주세요</td>
                                </tr>
                              </>
                            )}
                            {q.questionType == 'multiple-many' && q.answers?.length > 0 && (
                              <>
                                <tr>
                                  <th>보기내용</th>
                                  <td>
                                    {q.answers?.map((a, idx) => (
                                      <div className="d-flex align-items-center add-wrap" key={`answer-${idx}`}>
                                        <div className="form-check form-check-inline w-100px">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={q.correctAnswers?.includes(idx)}
                                            onChange={() => {
                                              setExamData(
                                                produce((draft) => {
                                                  const answerIndex = draft[ix].correctAnswers?.indexOf(idx);
                                                  let selectedAnswers = draft[ix].correctAnswers || [];

                                                  if (answerIndex > -1) {
                                                    selectedAnswers.splice(answerIndex, 1);
                                                  } else {
                                                    selectedAnswers.push(idx);
                                                  }

                                                  draft[ix].correctAnswers = selectedAnswers;
                                                }),
                                              );
                                            }}
                                            disabled={dayjs(data?.startAt).isBefore(dayjs())}
                                          />
                                          <label className="form-check-label ps-25" htmlFor={`ch${idx}`}>
                                            보기 {idx + 1}
                                          </label>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control col"
                                          value={q.answers[idx]}
                                          onChange={(e) =>
                                            setExamData(
                                              produce((draft) => {
                                                draft[ix].answers[idx] = e.target.value;
                                              }),
                                            )
                                          }
                                          disabled={dayjs(data?.startAt).isBefore(dayjs())}
                                        />
                                      </div>
                                    ))}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <label className="form-label">정답선택</label>
                                  </th>
                                  <td>보기 앞에 2개 이상의 체크박스를 선택해주세요.</td>
                                </tr>
                              </>
                            )}
                            {q.questionType == 'single' && (
                              <tr>
                                <th>
                                  <label className="form-label">정답</label>
                                </th>
                                <td>
                                  <textarea
                                    rows="5"
                                    className="form-control"
                                    value={q.correctAnswers}
                                    disabled={dayjs(data?.startAt).isBefore(dayjs())}
                                    onChange={(e) => {
                                      const v = e.target.value;
                                      setExamData(
                                        produce((draft) => {
                                          draft[ix].correctAnswers = v;
                                        }),
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th>
                                <label className="form-label">
                                  배점
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm waves-effect ms-1"
                                    onClick={() => {
                                      setOrder(ix);
                                      setAddPoint(true);
                                    }}
                                  >
                                    추가배점
                                  </button>
                                </label>
                              </th>
                              <td>
                                <input
                                  type="text"
                                  className="form-control col"
                                  name="point"
                                  value={q.point}
                                  onChange={(e) => {
                                    const v = e.target.value;
                                    setExamData(
                                      produce((draft) => {
                                        draft[ix].point = v;
                                      }),
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })}

                <ExamAddPoint
                  visible={addPoint}
                  onExit={() => setAddPoint(false)}
                  lectureId={data?.lecture?.id}
                  index={order}
                  examData={examData}
                />

                {/* 문항 추가 버튼 s */}
                {!dayjs(data?.startAt).isBefore(dayjs()) && (
                  <div className="col-md-12 d-flex justify-content-center">
                    <table>
                      <colgroup>
                        <col width={70} />
                        <col width={150} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>
                            <label className="form-label">보기유형</label>
                          </th>
                          <td>
                            <div className="d-flex align-items-center">
                              <select
                                className="form-select pe-3 w-auto"
                                value={sample?.questionType}
                                onChange={(e) => {
                                  setSample((prevSample) =>
                                    produce(prevSample, (draft) => {
                                      draft.questionType = e.target.value;
                                      draft.answers = [];
                                    }),
                                  );
                                }}
                              >
                                <option value="">선택해주세요</option>
                                <option value="multiple-one">객관식(단일정답)</option>
                                <option value="multiple-many">객관식(복수정답)</option>
                                <option value="single">주관식</option>
                              </select>
                              {sample?.questionType === 'multiple-one' && (
                                <select
                                  className="form-select w-auto pe-3 ms-25"
                                  value={String(sample?.answers?.length || '')}
                                  onChange={(e) => {
                                    const v = parseInt(e.target.value);
                                    setSample((prevSample) =>
                                      produce(prevSample, (draft) => {
                                        if (v < (draft.answers.length || 0)) {
                                          draft.answers.splice(v, (draft.answers.length || 0) - v);
                                        } else {
                                          draft.answers.push(...Array(v - (draft.answers.length || 0)).fill(''));
                                        }
                                      }),
                                    );
                                  }}
                                >
                                  <option value={''}>문항수</option>
                                  <option value={'2'}>2</option>
                                  <option value={'3'}>3</option>
                                  <option value={'4'}>4</option>
                                  <option value={'5'}>5</option>
                                </select>
                              )}
                              {sample?.questionType === 'multiple-many' && (
                                <select
                                  className="form-select w-auto pe-3 ms-25"
                                  value={String(sample?.answers?.length || '')}
                                  onChange={(e) => {
                                    const v = parseInt(e.target.value);
                                    setSample((prevSample) =>
                                      produce(prevSample, (draft) => {
                                        if (v < (draft.answers.length || 0)) {
                                          draft.answers.splice(v, (draft.answers.length || 0) - v);
                                        } else {
                                          draft.answers.push(...Array(v - (draft.answers.length || 0)).fill(''));
                                        }
                                      }),
                                    );
                                  }}
                                >
                                  <option value={''}>문항수</option>
                                  <option value={'3'}>3</option>
                                  <option value={'4'}>4</option>
                                  <option value={'5'}>5</option>
                                </select>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <label className="form-label">배점</label>
                          </th>
                          <td>
                            <input
                              type="text"
                              className="form-control col"
                              name="point"
                              value={sample?.point}
                              onChange={(e) => {
                                const v = e.target.value;
                                setSample((prevSample) =>
                                  produce(prevSample, (draft) => {
                                    draft.point = v;
                                  }),
                                );
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      type="button"
                      className="btn btn-outline-primary ms-50 waves-effect"
                      onClick={() => {
                        setExamData(
                          produce((draft) => {
                            if (Object.keys(sample).length !== 0) {
                              draft.push({
                                questionType: sample.questionType,
                                answers: sample?.answers || [],
                                point: sample.point,
                              });
                            } else {
                              draft.push({
                                question: '',
                                answers: [],
                              });
                            }
                          }),
                        );
                      }}
                    >
                      문항추가
                    </button>
                  </div>
                )}
                {/*// 문항 추가 버튼 e */}
              </div>

              <div className="col-12 txt-r mb-2">
                {!dayjs(data?.startAt).isBefore(dayjs()) && (
                  <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                    저장
                  </button>
                )}
                <button
                  className="btn btn-outline-primary waves-effect"
                  onClick={() => {
                    navigate('/lms/exams/items');
                  }}
                >
                  목록
                </button>
              </div>
            </div>
          ) : (
            
            <div className="row">
              {data?.examStyle === 'DOWNLOAD' && ( 
              <div className="col-12">
                <div>
                  <div className="table-responsive">
                    <table className="table table-bordered visual-box"></table>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex col-md-6 col-12">
                    <h5>시험지 등록관리</h5>
                    <p className="text-danger ms-1">※ 용량은 50MB이내로 업로드 해주시기 바랍니다.</p>
                  </div>
                  <div className="file-box">
                    <input
                      type="file"
                      id="fileEtc"
                      onChange={(e) => {
                        const v = e.target.files;
                        if (files.length + v.length > 1) {
                          alert('파일은 1개만 첨부 가능합니다.');
                          return;
                        }
                        const file = v.item(0);
                        setFiles((x) => x.concat(file));
                      }}
                    />
                    <label htmlFor="fileEtc" className="btn btn-primary waves-effect">
                      찾아보기
                    </label>
                  </div>
                </div>
                <div
                  id="dropzone"
                  className="dropzone my-1"
                  onDragEnter={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onDragLeave={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onDragOver={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onDrop={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    const v = e.dataTransfer.files;
                    if (files.length + v.length > 1) {
                      alert('파일은 1개만 첨부 가능합니다.');
                      return;
                    }
                    const file = v.item(0);
                    setFiles((x) => x.concat(file));
                  }}
                >
                  {!files?.length ? (
                    <div className="file-txt">등록된 시험지가 없습니다.</div>
                  ) : (
                    <div className="file-txt">
                      {files?.map((file, ix) => (
                        <p className="d-flex align-items-center">
                          <a href="#n">{file?.name}</a>
                          <button
                            type="button"
                            className="btn-file-del"
                            onClick={() => {
                              setFiles(
                                produce((draft) => {
                                  draft.splice(ix, 1);
                                }),
                              );
                            }}
                          >
                            파일삭제
                          </button>
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                
                {/*// 등록된 시험지가 없는 경우 e */}
              </div>
              )}
              <div className="col-12 txt-r">
                  {!dayjs(data?.startAt).isBefore(dayjs()) && data?.examStyle === 'DOWNLOAD' && (
                    <button
                      type="button"
                      onClick={() => uploadFile()}
                      className="btn btn-primary me-1 waves-effect waves-float waves-light"
                    >
                      등록
                    </button>
                  )}
                  <button
                    className="btn btn-outline-primary waves-effect"
                    onClick={() => {
                      navigate(`/lms/exams/items`);
                    }}
                  >
                    목록
                  </button>
                </div>
            </div>
          )}
        </div>
      </form>
      {/* // con e */}

      <ExamSelector
        visible={showModal}
        onExit={() => setShowModal(false)}
        lectureId={data?.lecture?.id}
        onSelected={(q) => {
          setExamData(
            q.questions?.map((x) => {
              const o = { ...x };
              if (!o.correctAnswers) {
                if (o.questionType === 'multiple') {
                  o.correctAnswer = o.answers.indexOf(o.correctAnswer);
                }
              } else {
                if (o.questionType === 'multiple-one' || o.questionType === 'multiple-many') {
                  if (Array.isArray(o.correctAnswers)) {
                    o.correctAnswers = o.correctAnswers.map((answer) => o.answers.indexOf(answer));
                  } else {
                    o.correctAnswers = o.answers.indexOf(o.correctAnswers);
                  }
                }
              }
              return o;
            }),
          );
        }}
      />
    </div>
  );
}
