import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { restApi } from '../../../../common/api';
import PaginationMoon from '../../../../components/PaginationMoon';
import dayjs from 'dayjs';

export default function LmsTalksMessagesIndex() {
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/broadcasts', {
      params: {
        ...params,
        type: ['SMS','MMS'],
      },
    });

    setData(data);
  }, [params]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">메시지 보내기</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">메시지 보내기</li>
                  <li className="breadcrumb-item active">SMS 보내기</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link className={`nav-link active`} to="/lms/talks/messages">
            SMS 보내기
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link`} to="/lms/talks/mail">
            메일 보내기
          </Link>
        </li>
      </ul>
      {/* con s */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '6rem' }} />
                    <col style={{ width: '25%' }} />
                    <col />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10rem' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>받는사람</th>
                      <th>내용</th>
                      <th>구분</th>
                      <th>보낸사람</th>
                      <th>발송일</th>
                      <th>상태</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.content?.map((item, ix) => (
                      <tr>
                        <td className="txt-c">{ix + 1 + data?.size * data?.number}</td>
                        <td className="txt-c">{item.names.map((name) => name).join(', ')}</td>
                        <td>{item.title}</td>
                        <td className="txt-c">{item.type}</td>
                        <td className="txt-c">{item.createdBy.name}</td>
                        <td className="txt-c">{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                        <td className="txt-c">{item.status == 'READY' ? '발송준비' : '발송완료'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <Link to={'/lms/talks/messages/write'}>
              <button type="submit" className="btn btn-primary waves-effect waves-float waves-light">
                SMS 보내기
              </button>
            </Link>
          </div>
          {/* pagination s */}
          <nav aria-label="Page navigation">
            <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
          </nav>
          {/* // pagination e */}
        </div>
      </div>

      {/* // con e */}
    </div>
  );
}
