import dayjs from 'dayjs';
import produce from 'immer';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';

export default function SiteProfessorManagerProfessorIndex() {
  const { codes } = useSelector((s) => s.common, shallowEqual);

  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/teachers', { params });
    setData(data);
  }, [params]);
  //

  useEffect(() => {
    loadData();
  }, [loadData]);

  const [selected, setSelected] = useState([]);
  const isAllChecked = data?.content?.map((v) => v.id).filter((x) => !selected.includes(x)).length === 0;
  const handleRemove = async () => {
    if (!selected.length) {
      alert('선택 후 클릭해주세요.');
      return;
    }

    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    await Promise.all(
      selected.map((id) => {
        return restApi.put(`/teachers/${id}`, { printType: null });
      }),
    );

    loadData();
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">교수진 관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">교수진 관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="col-md-6 col-12 ms-auto">
                <fieldset>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      setParams({
                        keyword: event.target.keyword.value,
                        // keywordType: event.target.keyword.value,
                      });
                    }}
                  >
                    <div className="input-group">
                      <input
                        name="keyword"
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        defaultValue={params.keyword}
                      />
                      <button className="btn btn-outline-primary waves-effect" type="submit">
                        검색
                      </button>
                    </div>
                  </form>
                </fieldset>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '4rem' }} />
                  <col style={{ width: '6rem' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '30%' }} />
                  <col />
                  <col style={{ width: '14%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isAllChecked}
                        onClick={() => {
                          if (isAllChecked) {
                            setSelected([]);
                          } else {
                            setSelected(data?.content?.map((v) => v.id));
                          }
                        }}
                      />
                    </th>
                    <th>번호</th>
                    <th>과목 카테고리</th>
                    <th>과목명</th>
                    <th>교수명</th>
                    <th>등록일</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, ix) => (
                    <tr key={`${item.id}`}>
                      <td className="txt-c">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selected?.includes(item.id)}
                          onClick={() => {
                            setSelected(
                              produce((draft) => {
                                const ix = draft.indexOf(item.id);
                                if (ix >= 0) {
                                  draft.splice(ix, 1);
                                } else {
                                  draft.push(item.id);
                                }
                              }),
                            );
                          }}
                        />
                      </td>
                      <td className="txt-c">{data?.length - ix}</td>
                      <td className="txt-c">
                        <Link to={`/site/professorManager/professor/${item.id}`}>{codes?.map[item?.printType]}</Link>
                      </td>
                      <td className="txt-c">
                        <Link to={`/site/professorManager/professor/${item.id}`}>{item?.eduRole}</Link>
                      </td>
                      <td className="txt-c">
                        <Link to={`/site/professorManager/professor/${item.id}`}>{item?.name}</Link>
                      </td>
                      <td className="txt-c">{dayjs(item?.createdAt).format('YYYY-MM-DD')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-50">
            <div className="col-md-6">
              <button
                type="button"
                className="btn btn-outline-danger waves-effect"
                data-bs-toggle="modal"
                data-bs-target="#btnDel"
                onClick={handleRemove}
              >
                삭제
              </button>
            </div>
            <div className="col-md-6 txt-r">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-float waves-light"
                onClick={() => {
                  navigate('/site/professorManager/professor/write');
                }}
              >
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
