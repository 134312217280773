import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { getAPIHost, restApi } from '../../../../../common/api';

export default function SiteProfessorManagerProfessorIdIndex() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const query = useParams();

  const [data, setData] = useState();
  const loadData = async () => {
    const { data } = await restApi.get(`/teachers/${query.id}`);
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [query.id]);

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">교수진 관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">교수진 관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">번호</label>
                    </th>
                    <td>{data?.id}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        과목 카테고리
                      </label>
                    </th>
                    <td>{codes?.map[data?.printType]}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        과목명<span className="require">*</span>
                      </label>
                    </th>
                    <td>{data?.eduRole}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        교수명<span className="require">*</span>
                      </label>
                    </th>
                    <td>{data?.name}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        사진<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <p className="my-50">
                        {/* <Link to="#" id="blog-image-text">
                          C:\fakepath\banner.jpg
                        </Link> */}
                        {data?.imageId && (
                          <img src={`${getAPIHost()}api/files/${data.imageId}`} style={{ maxWidth: 200 }} />
                        )}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        연구실 위치<span className="require">*</span>
                      </label>
                    </th>
                    <td>{data?.workPlace}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        전화번호<span className="require">*</span>
                      </label>
                    </th>
                    <td>{data?.phone}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        이메일<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <a href={`mailto:${data?.email}`}>{data?.email}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">이력사항</label>
                    </th>
                    <td>
                      <div className="experience-box">
                        <h4 className="fw-normal text-black">관심 분야</h4>
                        <div>{data?.interests}</div>
                      </div>

                      <div className="experience-box">
                        <h4 className="fw-normal text-black">학력</h4>
                        <div>{data?.education}</div>
                      </div>
                      <div className="experience-box">
                        <h4 className="fw-normal text-black">약력 / 경력</h4>
                        <div>{data?.career}</div>
                      </div>
                      <div className="experience-box">
                        <h4 className="fw-normal text-black">학술지 논문</h4>
                        <div>{data?.treatise}</div>
                      </div>
                      <div className="experience-box">
                        <h4 className="fw-normal text-black">수상 / 공훈</h4>
                        <div>{data?.award}</div>
                      </div>
                      <div className="experience-box">
                        <h4 className="fw-normal text-black">학술회의논문</h4>
                        <div>{data?.conferencePaper}</div>
                      </div>
                      <div className="experience-box">
                        <h4 className="fw-normal text-black">단행본</h4>
                        <div>{data?.monograph}</div>
                      </div>
                      <div className="experience-box">
                        <h4 className="fw-normal text-black">특허 / 프로그램</h4>
                        <div>{data?.patent}</div>
                      </div>
                      <div className="experience-box">
                        <h4 className="fw-normal text-black">Press / Media / Link</h4>
                        <div>{data?.media}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button
              type="submit"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={() => {
                navigate(`/site/professorManager/professor/${query.id}/edit`);
              }}
            >
              수정
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/site/professorManager/professor');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
