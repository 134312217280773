import React, { useCallback, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import LectureSearch from '../../../../components/LectureSearch';
import RequireLecture from '../../../../components/RequireLecture';
import queryString from 'query-string';
import { shallowEqual, useSelector } from 'react-redux';
import { restApi } from '../../../../common/api';
import dayjs from 'dayjs';

const LmsExamsItemsIndex = (props) => {
  const flag = false;

  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const navigate = useNavigate();
  const codemap = useSelector((s) => s.common.codes?.map, shallowEqual);
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    if (!lectureId) return;
    const { data } = await restApi.get(`/lectures/${lectureId}/exams`, { params: { limit: 9999 } });
    setData(data);
  }, [lectureId]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  return (
    <>
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">시험</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">시험</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LectureSearch />

        <RequireLecture lectureId={lectureId}>
          {/* // header e */}
          {/* con s */}
          {/* Bordered table start */}
          <div className="row">
            <div className="col-12">
              <h5>시험</h5>
              <div className="card">
                <div className="table-responsive">
                  <table className="table visual-box">
                    <colgroup>
                      <col />
                      <col style={{ width: '11rem' }} />
                    </colgroup>

                    <tbody>
                      {data?.content
                        ?.filter((x) => x.type === 'EXAM')
                        ?.map((item) => (
                          <tr key={`${item.id}`}>
                            <td>
                              <h4 className="text-black mb-1">
                                <Link to={`/lms/exams/items/${item.id}`}>
                                  [{codemap?.[item.session] ?? item.session}] {item.title}
                                </Link>
                              </h4>
                              <p className="txt-info">
                                <span className="d-block">
                                  <strong>시험일</strong>
                                  {dayjs(item.startAt).format('YYYY-MM-DD / HH:mm')}
                                </span>
                                <span className="d-block">
                                  <strong>시험 방식</strong>
                                  {item.isOffline ? '오프라인 시험' : ''}
                                  {(item.isOffline && item.examStyle != null) ? ', ' : ''}
                                  {item.examStyle === null ? '' : item.examStyle === 'ONLINE' ? '온라인 시험' : '시험지 다운로드'}
                                  <text style={{color: 'blue', padding:10}}>{item.examStyle === 'ONLINE'  ? item.showAnswer ? '※ 점수 공개 시험  (점수 입력 필수!)' : '' : ''}</text>
                                  <text style={{color: 'blue', padding:10}}>{item.examStyle === 'DOWNLOAD'|| item.isOffline ? item.isShowExamResult ? '※ 점수 공개 시험  (점수 입력 필수!)' : '' : ''}</text>

                                </span>
                              </p>
                            </td>
                            <td className="txt-c">
                              {dayjs(item?.startAt).isAfter(dayjs()) ? null : dayjs(item?.endAt).isBefore(dayjs()) ? (
                                <div className="row mx-0">
                                  {item.isOffline && item.examStyle == null ? (<Link className='disabled-link'style={{ padding: 0 }}>
                                    <button
                                      disabled={item.isOffline && item.examStyle == null}
                                      type="button"
                                      className="btn btn-outline-dark mb-25 waves-effect"
                                      style={{ width: 126 }}
                                    >
                                      재응시 확인
                                    </button>
                                  </Link>) 
                                  : 
                                  (<Link to={`/lms/exams/items/${item.id}/retry`} style={{ padding: 0 }}>
                                  <button
                                    type="button"
                                    className="btn btn-outline-dark mb-25 waves-effect"
                                    style={{ width: 126 }}
                                  >
                                    재응시 확인
                                  </button>
                                </Link>)}
                                  
                                  <span className="bg-secondary btn text-white " style={{ width: 126 }}>
                                    완료
                                  </span>
                                </div>
                              ) : (
                                <div className="row mx-0">
                                  <span className="bg-success btn text-white " style={{ width: 126 }}>
                                    진행중
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <small className="d-block text-muted mt-m15 mb-2">
                * 재응시는 시험기한 내 시험을 치르지 못한 일부 원우에게 응시기회를 주는 기능입니다.
              </small>
              {/* Bordered table end */}
            </div>
            <div className="col-12">
              <h5>퀴즈</h5>
              <div className="card">
                <div className="table-responsive">
                  <table className="table visual-box">
                    <colgroup>
                      <col />
                      <col style={{ width: '11rem' }} />
                    </colgroup>
                    <tbody>
                      {data?.content
                        ?.filter((x) => x.type === 'QUIZ')
                        ?.map((item) => (
                          <tr key={`${item.id}`}>
                            <td>
                              <h4 className="text-black mb-1">
                                <Link to={`/lms/exams/items/${item.id}`}>{item.title}</Link>
                              </h4>
                              <p className="txt-info">
                                <span className="d-block">
                                  <strong>마감일</strong>
                                  {dayjs(item.endAt).format('YYYY-MM-DD / HH:mm')}
                                </span>
                                <span className="d-block">
                                  <strong>시험 방식</strong>
                                  {item.examStyle === 'ONLINE' ? '온라인 시험' : '시험지 다운로드'}
                                </span>
                                {item.timeAttack > 0 && (
                                  <span className="d-block">
                                    <strong>시간제한</strong>
                                    {item.timeAttack}
                                  </span>
                                )}
                              </p>
                            </td>
                            <td className="txt-c">
                              {dayjs(item?.startAt).isAfter(dayjs()) ? null : dayjs(item?.endAt).isBefore(dayjs()) ? (
                                <div className="row mx-0">
                                  {item.retryAllow ? (
                                    <Link to={`/lms/exams/items/${item.id}/retry`} style={{ padding: 0 }}>
                                      <button
                                        type="button"
                                        className="btn btn-outline-dark mb-25 waves-effect"
                                        style={{ width: 126 }}
                                      >
                                        재응시 확인
                                      </button>
                                    </Link>
                                  ) : (
                                    <Link to={`/lms/exams/items/${item.id}/retry`} style={{ padding: 0 }}>
                                      <button
                                        type="button"
                                        className="btn btn-outline-dark mb-25 waves-effect"
                                        style={{ width: 126 }}
                                      >
                                        재응시
                                      </button>
                                    </Link>
                                  )}
                                  <span className="bg-secondary btn text-white " style={{ width: 126 }}>
                                    완료
                                  </span>
                                </div>
                              ) : (
                                <div className="row mx-0">
                                  <span className="bg-success btn text-white " style={{ width: 126 }}>
                                    진행중
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>

                    {/* <tr className="closing">
                      <td>
                        <h4 className="text-black mb-1">
                          <Link to={`/lms/exams/items/2`}>Quiz 3 – STP전략 </Link>
                        </h4>
                        <p className="txt-info">
                          <span className="d-block">
                            <strong>마감일</strong>2023-02-25 / 18:30 까지
                          </span>
                          <span className="d-block">
                            <strong>시험 방식</strong>온라인 시험
                          </span>
                          <span className="d-block">
                            <strong>시간제한</strong>9분
                          </span>
                        </p>
                      </td>
                      <td className="txt-c">
                        <div className="row mx-0">
                          <button type="button" className="btn btn-outline-dark btn-sm mb-25 waves-effect">
                            재응시 확인
                          </button>
                          <span className="badge bg-secondary btn-sm"> 완료 </span>
                        </div>
                      </td>
                    </tr> */}
                  </table>
                </div>
              </div>
              <small className="d-block text-muted mt-m15 mb-2">
                * 재응시는 시험기한 내 시험을 치르지 못한 일부 원우에게 응시기회를 주는 기능입니다.
              </small>
              {/* Bordered table end */}
            </div>
            <div className="col-12 txt-r">
              {/* <Link to={`/lms/exams/items/write`}>
                <button type="submit" className="btn btn-primary waves-effect waves-float waves-light">
                  등록
                </button>
              </Link> */}
              <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-float waves-light"
                  onClick={() => {
                    navigate(`/lms/exams/items/write`, {state : {flag}});
                  }}
                >
                  등록
                </button>
            </div>
          </div>
          {/* // con e */}
        </RequireLecture>
      </div>
      {/* Bordered table end */}
      {/* // con e */}
    </>
  );
};
export default LmsExamsItemsIndex;
