import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restApi } from '../common/api';

export const me = createAsyncThunk('principal/get', async (thunkAPI) => {
  try {
    const { data: principal } = await restApi.get(`/accounts/authentication`);
    let notifies = [];
    if (principal?.id) {
      const { data: items } = await restApi.get(`/accounts/notifies`);
      notifies = items.content;
    }
    if (!['CP', 'ADMIN', 'TEACHER', 'STAFF', 'MANAGER','EXAMSTAFF'].includes(principal.type)) throw { message: 'no permissions' };
    restApi.principal = principal;
    return { principal, notifies };
  } catch (e) {
    console.warn(e);
    throw e;
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    principal: undefined,
    notifies: [],
  },
  reducers: {
    setPrincipal: (state, action) => {
      state.principal = action.payload.principal;
    },
    clearPrincipal: (state) => {
      state.principal = undefined;
    },
    setTimeout: (state, action) => {
      state.timeout = action.payload;
    },
  },
  extraReducers: {
    [me.fulfilled]: (state, action) => {
      state.principal = action.payload.principal;
      state.permission = action.payload.permission;
      state.notifies = action.payload.notifies;
    },
  },
});

export const { setPrincipal, clearPrincipal } = authSlice.actions;

export default authSlice.reducer;
