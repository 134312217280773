import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../../common/api';
import BoardAttachList from '../../../../../components/boards/BoardAttachList';
import { useDispatch } from 'react-redux';
import { me } from '../../../../../data/auth';
import { parseHTML } from '../../../../../common/define';

export default function LmsMessagesNoticesIdIndex() {
  const navigate = useNavigate();
  const query = useParams();

  const dispatch = useDispatch();
  const [data, setData] = useState();
  const loadData = async () => {
    const { data } = await restApi.get(`/lms/messages/${query.id}`);
    setData((prevData) => ({
      ...prevData,
      ...(data.webMessage ? { ...data.webMessage, isWebMessage: true } : { ...data, isWebMessage: false }),
    }));

    dispatch(me());
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [query.id]);

  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/lms/messages/${query.id}`);
    alert('삭제되었습니다.');
    window.history.back();
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">공지사항</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">공지사항</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">제목</label>
                    </th>
                    <td>{data?.title}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">내용</label>
                    </th>
                    <td
                      className={'content-area'}
                      dangerouslySetInnerHTML={{ __html: parseHTML(data?.contentsWithMarkup) }}
                    />
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일
                      </label>
                    </th>
                    <td>
                      <BoardAttachList ids={data?.files} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-50">
            <div className="col-6">
              {data?.isWebMessage &&
                '※ 해당 공지는 홈페이지>공지사항에서 작성된 글로, 원글에서 수정 및 삭제가 가능합니다.'}
            </div>
            <div className="col-6 txt-r">
              {!data?.isWebMessage && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-1 waves-effect waves-float waves-light"
                    onClick={() => {
                      navigate(`/lms/messages/notices/${query.id}/edit`);
                    }}
                  >
                    수정
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary me-1 waves-effect waves-float waves-light"
                    onClick={handleRemove}
                  >
                    삭제
                  </button>
                </>
              )}
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/lms/messages/notices');
                }}
              >
                목록
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
