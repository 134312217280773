import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { getAPIHost, restApi } from '../../../../common/api';
import dayjs from 'dayjs';
import produce from 'immer';
import PaginationMoon from '../../../../components/PaginationMoon';
import Modal from '../../../../components/Modal';

export default function SiteSiteManagerPopUpIndex() {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));
  const [showPopupModal, setShowPopupModal] = useState();

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/banners', { params: { ...params, sort: 'id', order: 'DESC' } });

    setData(data);
  }, [params]);

  useEffect(() => {
    loadData();
  }, [loadData, showPopupModal]);

  const [selected, setSelected] = useState([]);
  const isAllChecked = data?.content?.map((v) => v.id).filter((x) => !selected.includes(x)).length === 0;
  const handleRemove = async () => {
    if (!selected.length) {
      alert('선택 후 클릭해주세요.');
      return;
    }

    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    await Promise.all(
      selected.map((id) => {
        return restApi.delete(`/banners/${id}`);
      }),
    );

    loadData();
  };

  const today = dayjs().format('YYYY-MM-DD');
  const checkOngoing = (startDate, endDate) => {
    if (dayjs(today).isBefore(startDate)) {
      return '예정';
    }
    if (dayjs(today).isAfter(endDate)) {
      return '종료';
    }
    return '진행';
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">팝업 관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">사이트관리</Link>
                  </li>
                  <li className="breadcrumb-item active">팝업 관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="col">
                <button
                  className="btn btn-primary waves-effect waves-float waves-light"
                  onClick={() => setShowPopupModal(true)}
                >
                  팝업 노출 관리
                </button>
              </div>
              <div className="col-md-6 col-12 ms-auto">
                <fieldset>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      setParams({
                        keyword: event.target.keyword.value,
                        keywordType: event.target.keywordType.value,
                      });
                    }}
                  >
                    <div className="input-group">
                      <select
                        name="keywordType"
                        className="form-select"
                        id="selectDefault"
                        defaultValue={params.keywordType}
                      >
                        <option value={''}>전체</option>
                        <option value={'popup.name'}>팝업명</option>
                      </select>

                      <input
                        name="keyword"
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        defaultValue={params.keyword}
                      />
                      <button className="btn btn-outline-primary waves-effect" type="submit">
                        검색
                      </button>
                    </div>
                  </form>
                </fieldset>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '4rem' }} />
                  <col style={{ width: '6rem' }} />
                  <col style={{ width: '10rem' }} />
                  <col />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isAllChecked}
                        onClick={() => {
                          if (isAllChecked) {
                            setSelected([]);
                          } else {
                            setSelected(data?.content?.map((v) => v.id));
                          }
                        }}
                      />
                    </th>
                    <th>번호</th>
                    <th>팝업구분</th>
                    <th>팝업명</th>
                    <th>팝업시작</th>
                    <th>팝업종료</th>
                    <th>팝업여부</th>
                    <th>노출여부</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.content?.map((item) => (
                    <tr key={`${item.id}`}>
                      <td className="txt-c">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selected?.includes(item.id)}
                          onClick={() => {
                            setSelected(
                              produce((draft) => {
                                const ix = draft.indexOf(item.id);
                                if (ix >= 0) {
                                  draft.splice(ix, 1);
                                } else {
                                  draft.push(item.id);
                                }
                              }),
                            );
                          }}
                        />
                      </td>
                      <td className="txt-c">{item.id}</td>
                      <td className="txt-c">{item.type === 'TOP' ? '띠배너' : '메인팝업'}</td>
                      <td>
                        <div className="flex">
                          <Link to={`/site/siteManager/popup/${item.id}`}>
                            {item.imageId ? (
                              <img
                                // src="../assets/images/no-img.png"
                                src={`${getAPIHost()}api/files/${item.imageId}`}
                                className="me-2"
                                height={128}
                                width={200}
                                alt={item.title}
                              />
                            ) : (
                              <img
                                src="/assets/images/no-img.png"
                                className="me-2"
                                height={128}
                                width={200}
                                alt="이미지 없음"
                              />
                            )}
                            {item.title}
                          </Link>
                        </div>
                      </td>
                      <td className="txt-c">{item.startAt}</td>
                      <td className="txt-c">{dayjs(item.endAt).format('YYYY-MM-DD')}</td>
                      <td className="txt-c">{checkOngoing(item.startAt, item.endAt)}</td>
                      <td className="txt-c">{item.active ? '노출' : '비노출'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-50">
            <div className="col-md-6">
              <button
                type="button"
                className="btn btn-outline-danger waves-effect"
                data-bs-toggle="modal"
                data-bs-target="#btnDel"
                onClick={handleRemove}
              >
                삭제
              </button>
            </div>
            <div className="col-md-6 txt-r">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-float waves-light"
                onClick={() => {
                  navigate('/site/siteManager/popup/write');
                }}
              >
                등록
              </button>
            </div>
          </div>
          <nav aria-label="Page navigation">
            <PaginationMoon
              data={data}
              onClick={(page) => {
                setParams((x) => ({ ...x, page }));
              }}
            />
          </nav>
        </div>
      </div>

      <PopupModal visible={showPopupModal} onExit={() => setShowPopupModal(false)} checkOngoing={checkOngoing} />
    </div>
  );
}

function PopupModal({ visible, onExit, checkOngoing }) {
  const [tab, setTab] = useState('TOP');

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get('/banners/manage/all', { params: { order: 'DESC', type: tab } });
    setData(
      data.content
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map((item, index) => ({ ...item, orderIndex: index + 1 })),
    );
  }, [tab]);
  useEffect(() => {
    loadData();
  }, [loadData]);

  async function handleSubmit() {
    await restApi.put(`/banners/update-all`, data);
    alert('저장되었습니다.');
    onExit();
    window.onload();
  }

  return (
    <Modal className="fade text-start modal-primary" visible={!!visible}>
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: 1200, width: '90%' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">팝업 노출 관리</h5>
            <button type="button" className="btn-close" onClick={onExit} />
          </div>
          <div className="modal-body">
            <div className="card">
              <div className="card-header">
                <div>
                  <button
                    className={`btn ${tab === 'TOP' ? 'btn-primary' : 'btn-outline-primary'} me-1`}
                    onClick={() => setTab('TOP')}
                  >
                    띠배너
                  </button>
                  <button
                    className={`btn ${tab === 'POPUP' ? 'btn-primary' : 'btn-outline-primary'}`}
                    onClick={() => setTab('POPUP')}
                  >
                    팝업
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>제목</th>
                      <th>작성일</th>
                      <th colSpan={2}>순서</th>
                      <th>팝업 시작 ~ 팝업 종료</th>
                      <th>팝업여부</th>
                      <th>팝업종료</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length === 0 ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          데이터가 없습니다.
                        </td>
                      </tr>
                    ) : (
                      data?.map((item, i) => {
                        return (
                          <tr key={`popup-${item.id}`}>
                            <td className="txt-c">{item.id}</td>
                            <td className="txt-c">{item.title}</td>
                            <td className="txt-c">{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                            <td className="txt-c">{item.orderIndex}</td>
                            <td className="txt-c">
                              <button
                                className="btn me-1"
                                style={{ padding: '0.1rem' }}
                                disabled={i === 0}
                                onClick={() => {
                                  if (i === 0) {
                                    return;
                                  }
                                  setData(
                                    produce(data, (draft) => {
                                      const temp = draft[i].orderIndex;
                                      draft[i].orderIndex = draft[i - 1].orderIndex;
                                      draft[i - 1].orderIndex = temp;
                                      draft.sort((a, b) => a.orderIndex - b.orderIndex);
                                    }),
                                  );
                                }}
                              >
                                ▲
                              </button>
                              <button
                                className="btn"
                                style={{ padding: '0.1rem' }}
                                disabled={i === data.length - 1}
                                onClick={() => {
                                  if (i === data.length - 1) {
                                    return;
                                  }
                                  setData(
                                    produce(data, (draft) => {
                                      const temp = draft[i].orderIndex;
                                      draft[i].orderIndex = draft[i + 1].orderIndex;
                                      draft[i + 1].orderIndex = temp;
                                      draft.sort((a, b) => a.orderIndex - b.orderIndex);
                                    }),
                                  );
                                }}
                              >
                                ▼
                              </button>
                            </td>
                            <td className="txt-c">
                              <div className="d-flex align-items-center justify-content-center gap-50">
                                <input
                                  type="date"
                                  className="form-control flatpickr-basic flatpickr-input "
                                  style={{ width: 134 }}
                                  defaultValue={item.startAt}
                                  onChange={(e) => {
                                    setData(
                                      produce(data, (draft) => {
                                        draft[i].startAt = e.target.value;
                                      }),
                                    );
                                  }}
                                />
                                {' ~ '}
                                <input
                                  type="date"
                                  className="form-control flatpickr-basic flatpickr-input "
                                  style={{ width: 134 }}
                                  defaultValue={item.endAt}
                                  onChange={(e) => {
                                    setData(
                                      produce(data, (draft) => {
                                        draft[i].endAt = e.target.value;
                                      }),
                                    );
                                  }}
                                />
                              </div>
                            </td>
                            <td className="txt-c">{checkOngoing(item.startAt, item.endAt)}</td>
                            <td className="txt-c">
                              <button
                                className="btn btn-outline-primary"
                                onClick={async () => {
                                  if (window.confirm('종료하시겠습니까?')) {
                                    await restApi.put(`/banners/${item.id}/end-popup`, {
                                      endAt: dayjs().subtract(1, 'day'),
                                      orderIndex: item.orderIndex,
                                      startAt: item.startAt,
                                    });
                                    setData(
                                      produce(data, (draft) => {
                                        draft.splice(i, 1);
                                      }),
                                    );
                                  }
                                }}
                              >
                                종료
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-2 mb-1 txt-c">
              <button
                type="submit"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                onClick={handleSubmit}
              >
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  onExit();
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
