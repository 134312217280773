import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';

export default function LmsBachelorIdIndex() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const query = useParams();

  const [data, setData] = useState();
  const loadData = async () => {
    const { data } = await restApi.get(`/lectures/${query.id}`);
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [query.id]);

  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/lectures/${query.id}`);
    alert('삭제되었습니다.');
    window.history.back();
  };
  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">학사 관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">학사 관리</li>
                  <li className="breadcrumb-item active">학사 관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      {/* Bordered table start */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">년도 / 학기</label>
                    </th>
                    <td>
                      {data?.semester.year}학년도 / {codes?.map?.[data?.semester.session]}
                    </td>
                  </tr>
                  <tr>
                    <th>교수</th>
                    <td>{data?.mainClass.master.name}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">과목명</label>
                    </th>
                    <td>{data?.mainClass.title}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">조교명</label>
                    </th>
                    <td>
                      {data?.staffs.map((item) => (
                        <span className="me-25">{item.name}</span>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">시험감독관명</label>
                    </th>
                    <td>
                      {data?.examStaffs && data?.examStaffs.map((item) => (
                        <span className="me-25">{item.name}</span>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">분류</label>
                    </th>
                    <td>{codes?.tree?.MCTP?.find((c) => c.code === data?.mainClass.siteType)?.label}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">분야</label>
                    </th>
                    <td>{codes?.tree?.MCST?.find((c) => c.code === data?.mainClass.category)?.label}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">운영기간</label>
                    </th>
                    <td>
                      {data?.startAt} ~ {data?.endAt}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">청강기간</label>
                    </th>
                    <td>
                      {data?.listenStartAt} ~ {data?.listenEndAt}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">수료기준</label>
                    </th>
                    <td>
                      <div className="d-flex align-items-center">
                        총점<span className="text-black me-25 ms-1">{data?.point}</span>점
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">상태</label>
                    </th>
                    <td>{data?.active ? '운영' : '미운영'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 txt-r">
            <button
              type="submit"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={() => {
                navigate(`/lms/bachelor/view/${query.id}/edit`);
              }}
            >
              수정
            </button>
            <button
              type="button"
              onClick={handleRemove}
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
            >
              삭제
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/lms/bachelor');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>
      {/* Bordered table end */}
      {/* // con e */}
    </div>
  );
}
