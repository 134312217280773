import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { restApi } from '../common/api';
import Modal from './Modal';
import PaginationMoon from './PaginationMoon';
import produce from 'immer';
import { useLoading } from '../nav/AppContainer';

const UserSelect = ({
  values,
  onChange,
  type = 'USER',
  maxLength,
  title,
  extraParams,
  isExam = false,
  lectureId,
  extraSelectOption = false,
  isOver
}) => {
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState({});
  const [params, setParams] = useState({});
  const [query, setQuery] = useState(isExam ? { keywordType: 'isForeign' } : {});
  const [cachedNames, setCachedNames] = useState({});
  const [isForeign, setIsForeign] = useState(isExam); //검색조건에서 해외 여부
  const [checkUserSet, setCheckUserSet] = useState();
  const [tempValue, setTempValue] = useState();
  const [allUserIds, setAllUserIds] = useState();
  const [foreignUserIds, setForeignUserIds] = useState();
  const [isSelectInput, setIsSelectInput] = useState(false);

  const [loading, setLoading] = useLoading();

  useLayoutEffect(() => {
    if (!isExam) {
      const loadData = async (ids) => {
        await Promise.all(
          ids.map(async (id) => {
            const { data } = await restApi.get(`/accounts/${id}/bo`);
            setUsers((x) => ({ ...x, [data?.id]: data }));
            setCachedNames((x) => ({ ...x, [data?.id]: data.name }));
          }),
        );
      };
      let ids = (values ?? []).filter((x) => !Object.keys(users).includes(String(x)));
      if (ids.length) loadData(ids).catch(console.warn);
    }
  }, [values, isExam, users]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    //console.log({ isExam, lectureId });
    if (isExam && lectureId) {
      const { data } = await restApi.get(`/lectures/${lectureId}/exams/accounts`, {
        params: {
          ...params,
          limit: isForeign ? 15 : 5,
        },
      });
      // console.log(data);
      setData(data);
    } else {
      const { data } = await restApi.get(`/accounts/bo`, {
        params: {
          ...params,
          ...extraParams,
          type: extraSelectOption ? query.type : type,
          limit: 5,
        },
      });
      setData(data);
    }
  }, [isExam, lectureId, params, isForeign, extraParams, extraSelectOption, query.type, type, isOver]);

  useEffect(() => {
    if (showModal) {
      loadData().catch(console.warn);
    }
  }, [showModal, loadData]);

  const handleSubmit = (e) => {
    if (query.keywordType === 'isForeign') {
      setIsForeign(true);
    } else {
      setIsForeign(false);
      setParams(query);
    }
    return false;
  };

  const handleClose = () => {
    setCheckUserSet();
    setShowModal(false);
  };

  useEffect(() => {
    // console.log(isOver)
    if (isExam && lectureId) {
      const loadUserData = async () => {
        setLoading(true);
        const { data } = await restApi.get(`/lectures/${lectureId}/exams/accounts`, {
          params: {
            limit: 99999,
          },
        });
        setAllUserIds(data.content.map((item) => item.id));
        setForeignUserIds(data.content.filter((item) => item.isForeign).map((item) => item.id));
        if (values?.length === allUserIds?.length) {
          setCheckUserSet('all');
        }
        if (values?.length === foreignUserIds?.length) {
          setCheckUserSet('foreignOnly');
        }
        setLoading(false);
      };
      loadUserData();
    }
  }, [values, isExam]);

  useEffect(() => {
    if (isExam) {
      if (checkUserSet === 'all') {
        setTempValue(allUserIds);
      } else if (checkUserSet === 'foreignOnly') {
        setTempValue(foreignUserIds);
      } else {
        setTempValue([]);
      }
    }
  }, [checkUserSet, isExam]);

  
  return (
    <>
      <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
          <button
          // disabled={title == '시험 응시자' ? examStyle == undefined : examStyle != undefined}
          type="button"
          className="btn btn-primary waves-effect waves-float waves-light"
          onClick={() => {
            if (isOver) {
              alert("종료 된 시험은 응시자 선택이 불가능 합니다.")
            } else {
              if (isExam) {
                setTempValue(values);
              }
              setShowModal(true);
            }
          }}
        >
          검색
        </button>        
        {isForeign ? (
          <p className="my-50" style={{ marginLeft: 30 }}>
            총 {values?.length}명 선택
            {values?.length > 0 ? (
              <button
                type="button"
                className="btn-file-del"
                onClick={() => {
                  if (isOver) {
                    alert("종료 된 시험은 응시자 선택이 불가능 합니다.")
                  } else {
                    onChange(
                      produce(values, (draft) => {
                        draft.length = 0;
                      }),
                    );
                  }
                }}
              >
                파일삭제
              </button>
            ) : null}
          </p>
        ) : (
          values?.map((value, ix) => {
            const item = users?.[value];
            return (
              <p className="my-50" style={{ marginLeft: 30 }}>
                <a id="blog-image-text">{item?.name}</a>
                <button
                  type="button"
                  className="btn-file-del"
                  onClick={() => {
                    if (isOver) {
                      alert("종료 된 시험은 응시자 선택이 불가능 합니다.")
                    } else {
                      onChange(
                        produce(values, (draft) => {
                          draft.splice(ix, 1);
                        }),
                      );
                    }
                  }}
                >
                  파일삭제
                </button>
              </p>
            );
          })
        )}
      </div>
      <Modal className="fade text-start modal-primary" visible={showModal}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button type="button" className="btn-close" onClick={handleClose} />
            </div>
            <div className="modal-body txt-c">
              {/* Inputs Group with Dropdown */}
              <div className="col-12 ms-auto">
                <fieldset>
                  <div className="input-group">
                    <select
                      className="form-select"
                      id="selectDefault"
                      value={query.keywordType}
                      onChange={(e) => {
                        const v = e.target.value;
                        if (v == 'type') {
                          setIsSelectInput(true);
                        } else {
                          setIsSelectInput(false);
                        }
                        setQuery((x) => ({ ...x, keywordType: v }));
                      }}
                    >
                      <option value={''}>전체</option>
                      <option value={'name'}>이름</option>
                      <option value={'period'}>기수</option>
                      {isExam && <option value={'isForeign'}>해외</option>}
                      {extraSelectOption && <option value={'type'}>구분</option>}
                    </select>
                    {isSelectInput ? (
                      <select
                        className="form-select"
                        style={{ flex: '1 1 auto' }}
                        defaultValue={'student'}
                        name="typeSelector"
                        onChange={(e) => {
                          const v = e.target.value;
                          setQuery((x) => ({
                            ...x,
                            attending:
                              v.toUpperCase() === 'USER' ? true : v.toUpperCase() === 'GRADUATE' ? false : undefined,
                            type: v.toUpperCase() === 'GRADUATE' ? 'USER' : v.toUpperCase(),
                          }));
                        }}
                      >
                        <option value={'user'}>원우(재학생)</option>
                        <option value={'graduate'}>원우(졸업생)</option>
                        <option value={'teacher'}>교수</option>
                        <option value={'staff'}>튜터</option>
                        <option value={'manager'}>행정실</option>
                        <option value={'admin'}>지원실</option>
                        <option value={'examstaff'}>시험감독관</option>
                      </select>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        value={query.keyword}
                        disabled={query.keywordType === 'isForeign'}
                        onChange={(e) => {
                          const v = e.target.value;
                          setQuery((x) => ({ ...x, keyword: v }));
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode == 13) {
                            e.preventDefault();
                            return handleSubmit();
                          }
                        }}
                      />
                    )}
                    <button className="btn btn-outline-primary waves-effect" type="button" onClick={handleSubmit}>
                      검색
                    </button>
                  </div>
                </fieldset>
              </div>

              <div className="pt-50">
                {isExam && isForeign && (
                  <div>
                    <button
                      type="button"
                      className={`btn btn-sm rounded ${!checkUserSet ? 'btn-secondary' : 'btn-primary'}`}
                      onClick={() => setCheckUserSet('foreignOnly')}
                    >
                      해외원우
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm rounded ms-25 ${checkUserSet === 'all' ? 'btn-primary' : 'btn-secondary'}`}
                      onClick={() => {
                        if (checkUserSet === 'all') {
                          setCheckUserSet();
                        } else {
                          setCheckUserSet('all');
                        }
                      }}
                    >
                      전체
                    </button>
                  </div>
                )}
                <div className="pt-50">
                  <strong>선택된 사용자</strong>
                </div>
                {!isForeign ? (
                  <div>{values?.map((x) => cachedNames?.[x] ?? x)?.join(',')}</div>
                ) : (
                  <div>{checkUserSet === 'all' ? '전체원우' : checkUserSet === 'foreignOnly' ? '해외원우' : null}</div>
                )}
              </div>

              {/* Inputs Group with Dropdown end */}
              <div className="table-responsive mt-1">
                <table className="table table-bordered">
                  <colgroup>
                    <col style={{ width: '4rem' }} />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    {type == 'USER' ? (
                      <tr>
                        <th />
                        <th>이름</th>
                        <th>국내/해외</th>
                        <th>IMBA 기수</th>
                        <th>학번</th>
                      </tr>
                    ) : (
                      <tr>
                        <th />
                        <th>이름</th>
                        <th>ID</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {data?.content?.map((item) => {
                      const checked = isExam ? tempValue?.includes(item.id) : values?.includes(item.id);

                      return (
                        <tr key={`${item.id}`}>
                          <td>
                            <input
                              className="form-check-input"
                              type={maxLength > 1 ? 'checkbox' : 'radio'}
                              name="choice1"
                              checked={checked}
                              onClick={() => {
                                if (!checked) setUsers((x) => ({ ...x, [item.id]: item }));
                                setCachedNames((x) => ({ ...x, [item?.id]: item.name }));
                                const handleItemToggle = (draft, item) => {
                                  const ix = draft.indexOf(item.id);
                                  if (ix >= 0) {
                                    draft.splice(ix, 1);
                                  } else {
                                    draft.push(item.id);
                                  }
                                  if (draft.length >= maxLength) {
                                    draft.splice(0, draft.length - maxLength);
                                  }
                                };

                                if (isExam) {
                                  setTempValue(produce(tempValue, (draft) => handleItemToggle(draft, item)));
                                } else {
                                  onChange(produce(values, (draft) => handleItemToggle(draft, item)));
                                }
                              }}
                            />
                          </td>
                          {type == 'USER' ? (
                            <>
                              <td>{item.name}</td>
                              <td>{item.isForeign ? '해외' : '국내'}</td>
                              <td>{item.period}기</td>
                              <td>{item.idno || item.userId || item.id}</td>
                            </>
                          ) : (
                            <>
                              <td>{item.name}</td>
                              <td>{item.id || item.userId}</td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {isExam && isForeign && (
                <div className="mt-1">
                  <button
                    type="button"
                    className="btn btn-primary me-1 waves-effect waves-float waves-light"
                    onClick={() => {
                      onChange(
                        produce(values, (draft) => {
                          draft.length = 0;
                          draft.push(...tempValue);
                        }),
                      );
                      setTempValue(values);
                      setCheckUserSet();
                      setShowModal(false);
                    }}
                  >
                    저장
                  </button>
                  <button type="button" className="btn btn-outline-secondary waves-effect" onClick={handleClose}>
                    취소
                  </button>
                </div>
              )}
              {/* pagination s */}
              <nav aria-label="Page navigation">
                <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
              </nav>
              {/* // pagination e */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserSelect;
