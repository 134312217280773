import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import queryString from 'query-string';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { getFilesHost, restApi } from '../../../../../common/api';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import LectureSearch from '../../../../../components/LectureSearch';
import { useNote } from '../../../../../components/NoteProvider';

export default function LmsLecturesMembersMembersIndex() {
  const note = useNote();

  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);
  const [, setSearchParams] = useSearchParams();
  const initialParams2 = useMemo(() => {
    const params2 = queryString.parse(window.location.search);
    return {
      keywordType: params2.keywordType ?? 'all',
      keyword: params2.keyword ?? '',
      limit: Number(10000),
      order: params2.order ?? 'DESC',
      page: Number(params2.page ?? 0),
      sort: params2.sort ?? 'createdAt',
    };
  }, []);
  const initialParams = useMemo(() => {
    const params = queryString.parse(window.location.search);
    return {
      keywordType: params.keywordType ?? 'all',
      keyword: params.keyword ?? '',
      limit: Number(params.limit ?? 10),
      order: params.order ?? 'DESC',
      page: Number(params.page ?? 0),
      sort: params.sort ?? 'createdAt',
    };
  }, []);
  const [params2, setParams2] = React.useState(initialParams2);
  const usersQuery2 = useQuery(
    ['lectures', lectureId, 'teams', 'users', params2],
    () =>
      restApi
        .get(`/lectures/${lectureId}/teams/users`, {
          params: { ...params2, keywordType: params2.keywordType === 'all' ? undefined : params2.keywordType },
        })
        .then(({ data: responseData }) => responseData),
    {
      enabled: !!lectureId,
    },
  );
  const [params, setParams] = React.useState(initialParams);
  const usersQuery = useQuery(
    ['lectures', lectureId, 'teams', 'users', params],
    () =>
      restApi
        .get(`/lectures/${lectureId}/teams/users`, {
          params: { ...params, keywordType: params.keywordType === 'all' ? undefined : params.keywordType },
        })
        .then(({ data: responseData }) => responseData),
    {
      enabled: !!lectureId,
    },
  );

  const [selected, setSelected] = useState([]);
  const [selectedUserInfo, setSelectedUserInfo] = useState([]);


  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/semesters', { params });

    setData(data);
  }, [params]);

  const isAllChecked =
  usersQuery.data?.content?.filter((x) => !selected.includes(x.id))?.length === 0 && usersQuery.data?.content?.length > 0;

  const handleAllClick = () => {
    setSelected(
      produce((draft) => {
        if (!isAllChecked) {
          usersQuery.data?.content?.map((x) =>
          !selected.includes(x.id) && draft.push(x.id))
        } else {
          usersQuery.data.content?.map((x) => 
          selected.includes(x.id) && draft.splice(draft.indexOf(x.id), 1))
        }
      }),
    );
    setSelectedUserInfo(
      produce((draft) => {
        if (!isAllChecked) {
          usersQuery.data?.content?.map((x) =>
            !selected.includes(x.id) && draft.push(x))
        } else {
          usersQuery.data.content?.map((x) => 
          selected.includes(x.id) && draft.splice(draft.indexOf(x.id), 1))
        }
      }),
    );
  };

  useEffect(() => {
    setSearchParams(params);
  }, [params]);
  useEffect(() => {
    loadData();
  }, [loadData]);
  return (
    <>
      <div className="content-wrapper container-xxl p-0">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">멤버</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">멤버</li>
                    <li className="breadcrumb-item active">개인관리</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <Link to="/lms/lectures/members/members" className="nav-link active" role="tab">
              개인관리{' '}
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/lms/lectures/members/groups" className="nav-link" role="tab">
              그룹관리
            </Link>
          </li>
        </ul>

        <LectureSearch />
        {lectureId && (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header align-items-end">
                  <div className="col">
                    <input
                      className="form-check-input"
                      id=""
                      type="checkbox"
                      checked={isAllChecked}
                      onChange={handleAllClick}
                    />  
                    <button
                      type="button"
                      className="btn btn-outline-primary waves-effect"
                      onClick={() => {
                        
                        if (!selected.length) {
                          alert('1명 이상 선택해주세요.');
                          return; 
                        }
                        note.send(selectedUserInfo);
                      }}
                      style={{ marginLeft: '10px'}}
                    >
                      선택 한 원우 쪽지보내기
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary waves-effect"
                      onClick={() => {
                        // console.log(usersQuery2.data.content)
                        note.send(usersQuery2.data.content);
                      }}
                      style={{ marginLeft: '10px'}}
                    >
                      전체 원우에게 쪽지보내기
                    </button>
                  </div>
                  <form
                    className="col-md-6 col-12 ms-auto"
                    onSubmit={(event) => {
                      event.preventDefault();
                      setParams(
                        produce((draft) => {
                          draft.keywordType = event.target.keywordType.value;
                          draft.keyword = event.target.keyword.value;
                        }),
                      );
                    }}
                  >
                    <fieldset>
                      <div className="input-group">
                        <select
                          name="keywordType"
                          className="form-select"
                          id="selectDefault"
                          defaultValue={initialParams.keywordType}
                        >
                          <option value="">전체</option>
                          <option value="name">이름</option>
                          <option value="period">IMBA 기수</option>
                        </select>
                        <input
                          name="keyword"
                          type="text"
                          className="form-control"
                          placeholder=""
                          aria-label="Amount"
                          defaultValue={initialParams.keyword}
                        />
                        <button className="btn btn-outline-primary waves-effect" type="submit">
                          검색
                        </button>
                      </div>
                    </fieldset>
                  </form>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col />
                      <col style={{ width: '50%' }} />
                    </colgroup>
                    <tbody>
                      {usersQuery.data?.content
                        .reduce((p, c, i) => {
                          const _i = Math.floor(i / 2);
                          if (p[_i]) {
                            p[_i].push(c);
                          } else {
                            p[_i] = [c];
                          }
                          return p;
                        }, [])
                        .map((users, usersIndex) => {
                          return (
                            <tr key={`Users-${usersIndex}`}>
                              {users.map((user) => {
                                const findIndex = selected.indexOf(user.id);
                                const checked = findIndex > -1;
                                return (
                                  <td key={`Users-${usersIndex}-${user.id}`}>
                                    <div className="row d-flex box-flex2">
                                      <div className="left d-flex align-items-center">
                                      <input
                                          className="form-check-input"
                                          id=""
                                          type="checkbox"
                                          checked={checked}
                                          onChange={() => {
                                            setSelected(
                                              produce((draft) => {
                                                if (checked) {
                                                  draft.splice(findIndex, 1);
                                                } else {
                                                  draft.push(user.id);
                                                }
                                              }),
                                            );
                                            setSelectedUserInfo(
                                              produce((draft) => {
                                                if (checked) {
                                                  draft.splice(findIndex, 1);
                                                } else {
                                                  draft.push(user);
                                                }
                                              }),
                                            );
                                          }}
                                          style={{ marginRight: '10px'}}
                                        />
                                        {user.imageId ? (
                                          <div className="img-pic">
                                            <img src={`${getFilesHost()}/${user.imageId}`} alt={user.name} />
                                          </div>
                                        ) : (
                                          <div className="img-pic img-none">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={14}
                                              height={14}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              className="feather feather-user font-medium-4"
                                            >
                                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                              <circle cx={12} cy={7} r={4} />
                                            </svg>
                                          </div>
                                        )}
                                        <div className="col">
                                          <h4>
                                            <button
                                              type="button"
                                              className="name"
                                              onClick={() => note.showUser(user?.id)}
                                            >
                                              {user.name}
                                            </button>
                                          </h4>
                                          <p className="txt-info">
                                            <strong>
                                              IMBA {user.period}기 /{' '}
                                              {user.isForeign ? (user.area ? user.area.join(' ') : '해외') : '국내'}
                                            </strong>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="right">
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary waves-effect"
                                          onClick={() => {
                                            note.send([user]);
                                          }}
                                        >
                                          쪽지보내기
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* pagination s */}
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center mt-2">
                  <li className="page-item prev">
                    <Link
                      to={`/lms/lectures/members/members?${queryString.stringify({
                        ...params,
                        page: Math.max(0, params.page - 1),
                      })}`}
                      className="page-link"
                      onClick={() => {
                        setParams(
                          produce((draft) => {
                            draft.page = Math.max(0, params.page - 1);
                          }),
                        );
                      }}
                    />
                  </li>
                  {[
                    ...new Array(
                      usersQuery.data ? Math.ceil(usersQuery.data?.totalElements / usersQuery.data?.size) : 0,
                    ),
                  ].map((_, index) => {
                    return (
                      <li key={`Page-${index + 1}`} className={`page-item${index === params.page ? ' active' : ''}`}>
                        <Link
                          to={`/lms/lectures/members/members?${queryString.stringify({ ...params, page: index })}`}
                          className="page-link"
                          aria-current={index === params.page ? 'page' : undefined}
                          onClick={() => {
                            setParams(
                              produce((draft) => {
                                draft.page = index;
                              }),
                            );
                          }}
                        >
                          {index + 1}
                        </Link>
                      </li>
                    );
                  })}
                  <li className="page-item next">
                    <Link
                      to={`/lms/lectures/members/members?${queryString.stringify({
                        ...params,
                        page: Math.min(
                          Math.ceil(usersQuery.data?.totalElements / usersQuery.data?.size) - 1,
                          params.page + 1,
                        ),
                      })}`}
                      className="page-link"
                      onClick={() => {
                        setParams(
                          produce((draft) => {
                            draft.page = Math.min(
                              Math.ceil(usersQuery.data?.totalElements / usersQuery.data?.size) - 1,
                              params.page + 1,
                            );
                          }),
                        );
                      }}
                    />
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
