import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';
import PaginationMoon from '../../../components/PaginationMoon';
import LectureSearch from '../../../components/LectureSearch';
import RequireLecture from '../../../components/RequireLecture';
import dayjs from 'dayjs';
import { useLoading } from '../../../nav/AppContainer';
import FileSaver from 'file-saver';

const LmsHomeworksIndex = (props) => {
  const params = useParams();
  const type = params?.type ?? window.location.pathname?.substring(window.location.pathname?.lastIndexOf('/') + 1);
  const { lectureId } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const [data, setData] = useState();

  const loadData = useCallback(
    async (page) => {
      if (!lectureId) return;
      const { data } = await restApi.get(`/lectures/${lectureId ?? 0}/homeworks`, {
        params: { type },
      });
      setData(data);
    },
    [type, lectureId],
  );

  useEffect(() => {
    loadData(0).catch(console.warn);
  }, [loadData]);

  const [, setLoading] = useLoading();
  const handleDownload = async (item) => {
    setLoading(true);
    const filename = `과제_${item?.title}_${dayjs().format('YYYYMMDD')}`;
    try {
      const { data } = await restApi.get(`/files/compression/direct`, {
        responseType: 'arraybuffer',
        params: {
          ids: item?.files ?? [],
          name: 'direct.zip',
        },
      });
      FileSaver.saveAs(new Blob([data]), `${filename}.zip`);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
      console.warn(e);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">과제</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">과제</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // header e */}
        <LectureSearch />

        {/* con s */}
        <ul className="nav nav-tabs" role="tablist">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <Link to={'/lms/homeworks/single'} className={`nav-link ${type == 'single' ? 'active' : ''}`} role="tab">
                개인과제
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/lms/homeworks/group'} className={`nav-link ${type == 'group' ? 'active' : ''}`} role="tab">
                그룹과제
              </Link>
            </li>
          </ul>
        </ul>

        <RequireLecture lectureId={lectureId}>
          <div role="tabpanel">
            {/* Bordered table start */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="table-responsive">
                    <table className="table visual-box">
                      <colgroup>
                        <col />
                        <col style={{ width: '14rem' }} />
                      </colgroup>
                      <tbody>
                        {data?.content?.map((item) => (
                          <tr key={`${item.id}`}>
                            <td>
                              <span className="badge bg-warning mb-25">
                                {item.week === 0 ? '전체' : item.week + '주차'}
                              </span>{' '}
                              {dayjs(item.limitAt).diff(dayjs(), 'minutes') < 0 ? (
                                <span className="badge bg-danger mb-25">종료</span>
                              ) : (
                                <span className="badge bg-success mb-25">진행중</span>
                              )}
                              <h4 className="text-black mb-1">
                                <Link to={`/lms/homeworks/${type}/${item.id}`}>{item.title}</Link>

                                {!!item.files?.length && (
                                  <>
                                    <span
                                      style={{ marginLeft: 10, display: 'inline-block' }}
                                      onClick={() => handleDownload(item)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className="feather feather-paperclip"
                                      >
                                        <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                                      </svg>
                                    </span>
                                  </>
                                )}
                              </h4>
                              <p className="txt-info">
                                <strong>마감일</strong>
                                {dayjs(item.limitAt).format('M월 D일 H:mm')}
                                <text style={{color: 'blue', padding:10}}>{item.showScore ? '※ 점수 공개 과제 (점수 입력 필수!)' : ''}</text>
                              </p>

                            </td>
                            <td className="txt-c">
                              <div className="row mx-0">
                                <Link to={`/lms/homeworks/${type}/${item.id}/detail`}>
                                  <button type="button" className="btn btn-outline-primary waves-effect">
                                    제출과제 보기
                                  </button>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-12 txt-r">
                  <Link to={`/lms/homeworks/${type}/create`}>
                    <button type="submit" className="btn btn-primary waves-effect waves-float waves-light">
                      등록
                    </button>
                  </Link>
                </div>

                {/* pagination s */}
                <PaginationMoon data={data} onClick={loadData} />
                {/* // pagination e */}
              </div>
            </div>
            {/* Bordered table end */}
          </div>
        </RequireLecture>

        {/* // con e */}
      </div>
    </>
  );
};

export default LmsHomeworksIndex;
