import React, { useLayoutEffect, useRef } from 'react';

const Modal = ({ visible, children, className }) => {
  const modal = useRef();
  const bsModal = useRef();
  useLayoutEffect(() => {
    if (!bsModal.current) {
      bsModal.current = new window.bootstrap.Modal(modal.current, {
        keyboard: false,
        backdrop: 'static',
      });
    }
    if (visible) {
      bsModal.current.show();
    } else {
      bsModal.current.hide();
    }
  }, [visible]);

  return (
    <div ref={modal} className={`modal fade ${className}`} tabIndex={-1}>
      {children}
    </div>
  );
};

export default Modal;
