import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import BoardAttachSingle from '../../../../components/boards/BoardAttachSingle';
import VideoChoice from '../../../../components/VideoChoice';
import { useLoading } from '../../../../nav/AppContainer';

export default function SiteProfessorManagerSubjectWrite() {
  const { codes } = useSelector((s) => s.common, shallowEqual);

  const navigate = useNavigate();
  const query = useParams();

  const introThumb = useRef();
  const trialThumb = useRef();

  const [formData, setFormData] = useState({
    category: undefined,
    description: undefined,
    imageIntro: undefined,
    imageTrial: undefined,
    siteType: undefined,
    videoIntroId: undefined,
    videoTrialId: undefined,
    isActive: false,
  });

  const [noType, setNoType] = useState();
  const [loading, setLoading] = useLoading();

  const thisSubject = formData.id && noType?.filter((item) => item.id == formData.id)[0];

  const handleOnchange = (e) => {
    const v = e.target.value;
    setFormData((x) => ({
      ...x,
      [e.target.name]: v,
    }));
  };

  const loadSubjectData = useCallback(async () => {
    const { data } = await restApi.get('/main-classes');
    setNoType(data.content.filter((item) => !item.videoIntroId || !item.videoTrialId));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      id: Number(formData.id),
    };

    setLoading(true);
    try {
      payload.imageIntro = await introThumb.current.getFileId();
      payload.imageTrial = await trialThumb.current.getFileId();

      if (query?.id) {
        await restApi.put(`/admin/main-classes/${query?.id}/extra`, payload);
        alert('저장되었습니다.');
        await navigate(`/site/professorManager/subject/${query?.id}`);
      } else {
        await restApi.put(`/admin/main-classes/${formData.id}/extra`, payload);
        alert('등록되었습니다.');
        await navigate('/site/professorManager/subject');
      }
    } catch (e) {
      alert('오류가 발생했습니다.');
    }
    setLoading(false);
  };

  useEffect(() => {
    loadSubjectData();
  }, [loadSubjectData]);

  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/admin/main-classes/${query?.id}`);
        const init = {
          videoIntroId: data.videoIntro?.id,
          videoTrialId: data.videoTrial?.id,
        };

        setFormData({ ...data, ...init });
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">교과목소개 등록</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">교과목소개 등록</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <form className="col-12" onSubmit={handleSubmit}>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col style={{ width: '60%' }} />
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">과목</label>
                    </th>
                    {query?.id ? (
                      <td>
                        <span className="me-75 text-black-50">분류</span>
                        <span className="me-2">
                          {codes?.tree?.MCTP?.find((c) => c.code === formData?.siteType)?.label}
                        </span>
                        <span className="me-75 text-black-50">분야</span>
                        <span className="me-2">
                          {codes?.tree?.MCST?.find((c) => c.code === formData?.category)?.label}
                        </span>
                        <span className="me-75 text-black-50">과목명</span>
                        <span className="me-2">{formData.title}</span>
                      </td>
                    ) : (
                      <td className="d-flex gap-1 align-items-center">
                        <select className="form-select me-1" style={{ width: 170 }} name="id" onChange={handleOnchange}>
                          <option value="">선택</option>
                          {noType?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          ))}
                        </select>
                        <span className="me-75 text-black-50">분야</span>
                        <span className="me-2">
                          {codes?.tree?.MCTP?.find((c) => c.code === thisSubject?.siteType)?.label}
                        </span>
                        <span className="me-75 text-black-50">분류</span>
                        <span className="me-2">
                          {codes?.tree?.MCST?.find((c) => c.code === thisSubject?.category)?.label}
                        </span>
                      </td>
                    )}
                    <th>
                      <label className="form-label">교수명</label>
                    </th>
                    <td>{query?.id ? formData.master?.name : thisSubject?.master.name}</td>
                  </tr>

                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        소개영상 썸네일
                      </label>
                    </th>
                    <td colSpan={3}>
                      <BoardAttachSingle ref={introThumb} defaultValue={formData.imageIntro || undefined} />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        소개영상
                      </label>
                    </th>
                    <td colSpan={3}>
                      <VideoChoice
                        type={['VIDEO', 'ETC']}
                        value={formData.videoIntroId}
                        onChange={(v) => setFormData((x) => ({ ...x, videoIntroId: v }))}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        강의미리보기
                        <br />
                        썸네일
                      </label>
                    </th>
                    <td colSpan={3}>
                      <BoardAttachSingle ref={trialThumb} defaultValue={formData.imageTrial || undefined} />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        강의미리보기 영상
                      </label>
                    </th>
                    <td colSpan={3}>
                      <VideoChoice
                        type={['VIDEO', 'ETC']}
                        value={formData.videoTrialId}
                        onChange={(v) => setFormData((x) => ({ ...x, videoTrialId: v }))}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">강의소개</label>
                    </th>
                    <td colSpan={3}>
                      <textarea
                        className="form-control"
                        rows={7}
                        value={formData.description}
                        name="description"
                        onChange={handleOnchange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">공개여부</label>
                    </th>
                    <td colSpan={3}>
                      <div className="d-flex align-items-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineRadio2"
                            name={'isActive'}
                            checked={formData.isActive}
                            onClick={(x) => setFormData((x) => ({ ...x, isActive: true }))}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio2">
                            네
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineRadio1"
                            name={'isActive'}
                            checked={!formData?.isActive}
                            onClick={(x) => setFormData((x) => ({ ...x, isActive: false }))}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio1">
                            아니오
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
              저장
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/site/professorManager/subject');
              }}
            >
              취소
            </button>
          </div>
        </form>
      </div>
      {/* // con e */}
    </div>
  );
}
