import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { restApi } from '../common/api';
import Modal from './Modal';

const ExamAddPoint = ({ visible, onExit, index, examData, lectureId }) => {
  const query = useParams();
  const [points, setPoints] = useState({});

  const [data, setData] = useState([]);

  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/lectures/${lectureId}/exams/${query.id}/users`, { params: { limit: 9999 } });
    const items = data?.content?.filter((x) => !!x.item) ?? [];
    setData(items);
  }, [lectureId]);

  useEffect(() => {
    if (!visible) return;
    loadData().catch(console.warn);
  }, [visible, loadData]);

  useEffect(() => {
    setPoints(
      data.reduce((a, b) => {
        a[b?.account?.id] = b.item.points?.[index];
        return a;
      }, {}),
    );
  }, [data, index]);

  const questData = useMemo(() => {
    const o = examData?.[index];
    if (!o) return undefined;
    return {
      ...o,
      correctAnswer: o.correctAnswers?.map((x) => o.answers?.[x] || x)?.join(','),
    };
  }, [examData, index]);

  const handleClick = async (user) => {
    const point = points[user.account.id];
    if (questData?.point < point) {
      alert('점수를 확인해주세요. 최대 ' + questData?.point + '점');
      return;
    }

    if (point === '' || !/^\d+(\.\d{1,2})?$/.test(point)) {
      alert('점수는 소수점 둘째 자리까지만 입력할 수 있습니다.');
      return;
    }

    const sendPoints = [...user.item.points];
    sendPoints[index] = point;
    const payload = {
      points: sendPoints?.map((x) => x || 0),
    };

    await restApi.post(`/lectures/${lectureId}/exams/${query.id}/apply/${user.account.id}`, payload);
    alert('저장되었습니다.');
    await loadData();
  };

  return (
    <Modal className="fade text-start modal-primary" id="pointmodal" visible={visible}>
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '60%' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">배점 추가</h5>
            <button type="button" className="btn-close" onClick={onExit} />
          </div>
          <div className="modal-body">
            <div>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box mb-1">
                    <colgroup>
                      <col width={150} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th className="txt-c">
                          <strong>문제 {index + 1}</strong>
                          <br />({questData?.point}점)
                        </th>
                        <td style={{ background: '#f2f4f7' }}>
                          <b>{questData?.question}</b>
                          <br />
                          정답 : {questData?.correctAnswer}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: 70 }} />
                      <col style={{ width: 100 }} />
                      <col style={{ width: 100 }} />
                      <col />
                      <col style={{ width: 100 }} />
                      <col style={{ width: 100 }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>이름</th>
                        <th>기수</th>
                        <th>답안</th>
                        <th>평가</th>
                        <th>제출</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 ? (
                        data
                          ?.filter((list) => list.item)
                          ?.map((user, i) => {
                            const uid = user?.account?.id;
                            return (
                              <tr key={`exam-${i}`}>
                                <td className="txt-c">{i + 1}</td>
                                <td className="txt-c">{user.account.name}</td>
                                <td className="txt-c">{user.account.period}기</td>
                                <td className="txt-c">{user.item.answers?.[index]}</td>
                                <td className="txt-c">
                                  <input
                                    type="text"
                                    className="form-control d-sm-inline-block "
                                    name="personalPoint"
                                    value={points[uid] ?? ''}
                                    onChange={(e) =>
                                      setPoints((x) => ({ ...x, [uid]: e.target.value.replace(/[^0-9.]+/g, '') }))
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleClick(user);
                                      }
                                    }}
                                  />
                                </td>
                                <td className="txt-c">
                                  <button className="btn btn-primary" onClick={() => handleClick(user)} type={'button'}>
                                    저장
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan="5" className="txt-c">
                            응시자가 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExamAddPoint;
