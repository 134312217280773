import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import UserSelect from '../../../../../components/UserSelect';
import { restApi } from '../../../../../common/api';
import dayjs from 'dayjs';

const numReg = /^[0-9]+$/;
const timeReg = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
function isValidTime(time) {
  if (!timeReg.test(time)) {
    return false;
  }
  const [hours, minutes] = time.split(':');
  if (!numReg.test(hours) || !numReg.test(minutes)) {
    return false;
  }
  const parsedHours = parseInt(hours, 10);
  const parsedMinutes = parseInt(minutes, 10);
  if (parsedHours < 0 || parsedHours > 23 || parsedMinutes < 0 || parsedMinutes > 59) {
    return false;
  }
  return true;
}

export default function LmsExamsItemsRetry() {
  const navigate = useNavigate();
  const query = useParams();

  const { lectureId: originLecId } = useSelector((x) => x.common, shallowEqual);

  const [lectureId, setLecId] = useState(originLecId);

  const [formData, setFormData] = useState({
    retryStartDate: undefined,
    retryStartTime: '00:00',
    retryEndDate: undefined,
    retryEndTime: '00:00',
    retryAccounts: [],
    retryMemo: '',
  });

  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/lectures/0/exams/${query.id}`);
        setLecId(data?.lecture?.id);
        setFormData((x) => ({
          retryStartDate: data.retryStartAt ? dayjs(data.retryStartAt).format('YYYY-MM-DD') : undefined,
          retryStartTime: data.retryStartAt ? dayjs(data.retryStartAt).format('HH:mm') : '00:00',
          retryEndDate: data.retryEndAt ? dayjs(data.retryEndAt).format('YYYY-MM-DD') : undefined,
          retryEndTime: data.retryEndAt ? dayjs(data.retryEndAt).format('HH:mm') : '00:00',
          retryAccounts: data.retryAccounts,
          retryMemo: data.retryMemo,
        }));
      };
      loadData().catch(console.warn);
    }
  }, [lectureId, query?.id]);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.retryAccounts.length <= 0) {
      alert('대상 학생을 선택해 주세요.');
      return;
    }

    const dt = [formData.retryStartDate, formData.retryEndDate].filter((x) => !x);
    if (dt.length > 0) {
      alert('기간을 입력해주세요.');
      return;
    }

    if (!isValidTime(formData.retryStartTime) || !isValidTime(formData.retryEndTime)) {
      alert('시간을 정확히 입력해주세요. ex) 00:00');
      return false;
    }

    const payload = {
      retryStartAt: `${formData.retryStartDate} ${formData.retryStartTime}:00`,
      retryEndAt: `${formData.retryEndDate} ${formData.retryEndTime}:00`,
      // retryStartAt: `${formData.retryStartDate}T${formData.retryStartTime}:00.000Z`,
      // retryEndAt: `${formData.retryEndDate}T${formData.retryEndTime}:00.000Z`,
      retryAccounts: formData.retryAccounts,
      retryMemo: formData.retryMemo,
    };

    await restApi.put(`/lectures/${lectureId}/exams/${query?.id}/retry`, payload);
    alert('재응시 처리가 완료되였습니다.');
    navigate(`/lms/exams/items`);
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">시험</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">시험</li>
                  <li className="breadcrumb-item active">시험</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}

      <div className="content-wrapper container-xxl p-0">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label">대상학생</label>
                      </th>
                      <td>
                        <UserSelect
                          maxLength={100}
                          extraParams={{ lectureId }}
                          values={formData.retryAccounts ?? []}
                          onChange={(v) => {
                            setFormData((x) => ({ ...x, retryAccounts: v }));
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">기간</label>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <input
                            type="date"
                            className="form-control flatpickr-basic flatpickr-input me-25"
                            placeholder="YYYY-MM-DD"
                            style={{ width: 140 }}
                            value={formData.retryStartDate}
                            defaultValue={formData.retryStartDate}
                            onChange={(e) => {
                              const v = e.target.value;
                              setFormData((x) => ({ ...x, retryStartDate: v }));
                            }}
                          />{' '}
                          <input
                            type="text"
                            className="form-control flatpickr-basic flatpickr-input"
                            placeholder="HH:MM"
                            style={{ width: 70 }}
                            value={formData.retryStartTime}
                            defaultValue={'00:00'}
                            onChange={(e) => {
                              const v = e.target.value;
                              setFormData((x) => ({ ...x, retryStartTime: v }));
                            }}
                          />
                          <span className={'ms-25 me-25'}>~</span>
                          <input
                            type="date"
                            id="fp-range"
                            className="form-control flatpickr-range flatpickr-input me-25"
                            placeholder="YYYY-MM-DD"
                            style={{ width: 140 }}
                            value={formData.retryEndDate}
                            onChange={(e) => {
                              const v = e.target.value;
                              setFormData((x) => ({ ...x, retryEndDate: v }));
                            }}
                          />
                          <input
                            type="text"
                            className="form-control flatpickr-basic flatpickr-input"
                            placeholder="HH:MM"
                            style={{ width: 70 }}
                            value={formData.retryEndTime}
                            defaultValue={'00:00'}
                            onChange={(e) => {
                              const v = e.target.value;
                              setFormData((x) => ({ ...x, retryEndTime: v }));
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">비고</label>
                      </th>
                      <td>
                        <textarea
                          type="text"
                          className="form-control"
                          name={'title'}
                          value={formData.retryMemo}
                          defaultValue={formData.retryMemo}
                          onChange={(e) => {
                            const v = e.target.value;
                            setFormData((x) => ({ ...x, retryMemo: v }));
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* 온라인 시험 s */}

            {/* // 온라인 시험 e */}
            {/* 시험지 다운로드 s */}

            {/* // 시험지 다운로드 e */}
            <div className="col-12 txt-r">
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                저장
              </button>
              <button
                type="submit"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/lms/exams/items');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
