import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { restApi } from '../../../common/api';
import moment from 'moment';
import PaginationMoon from '../../../components/PaginationMoon';
import produce from 'immer';
import { shallowEqual, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import DownIco from '../../../components/icons/DownIco';
import UpIco from '../../../components/icons/UpIco';
import Modal from '../../../components/Modal';

export default function SiteScheduleIndex() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));
  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const category = params.category ?? 'SCTP001';

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/calendars', {
      params: {
        ...params,
        category: params.category ?? 'SCTP001',
      },
    });

    setData(data);
  }, [params]);

  useEffect(() => {
    loadData();
  }, [loadData]);
  const [selected, setSelected] = useState([]);
  const isAllChecked = data?.content?.map((v) => v.id).filter((x) => !selected.includes(x)).length === 0;
  const handleRemove = async () => {
    if (!selected.length) {
      alert('선택 후 클릭해주세요.');
      return;
    }

    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    await Promise.all(
      selected.map((id) => {
        return restApi.delete(`/calendars/${id}`);
      }),
    );

    loadData();
  };

  const handleDownloadSample = () => {
    FileSaver.saveAs('/assets/xls/upload_calendar2.xlsx', '학사일정_업로드양식.xlsx');
  };

  const [uploadForm, setUploadForm] = useState(0);
  const handleUploadSubmit = async (e) => {
    e.preventDefault();

    const files = e.target.filedata.files;
    if (files.length === 0) {
      alert('파일선택은 필수항목입니다.');
      return;
    } else if (!files[0].name.endsWith('.xlsx')) {
      alert('허용된 파일은 xlsx입니다.');
      return;
    }

    setUploadForm(2);
    try {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('category', category);

      const { data } = await restApi.post(`/calendars/xlsx2`, formData);
      if (data.error) {
        alert(
          '아래 사유로 엑셀업로드가 불가능합니다.\n===================\n' +
            data.message +
            '\n-' +
            data.items?.join('\n -'),
        );
        setUploadForm(1);
      } else {
        const msg =
          '엑셀 업로드 시 다음과 같은 변동사항이 있습니다.\n\n' +
          `신규 추가 레코드 : ${data.creates.length}건` +
          '\n진행하시겠습니까?';
        if (!window.confirm(msg)) {
          setUploadForm(1);
          return;
        }

        await Promise.all(
          data.creates?.map((x) => {
            return restApi.post(`/calendars`, x);
          }),
        );
        alert('적용되었습니다.');
        await loadData();
        setUploadForm(0);
      }
    } catch (e) {
      console.warn(e);
      alert('서버와 연결이 올바르지 않습니다.');
      setUploadForm(1);
    }

    // onClick={() => setUploadForm(2)}
  };
  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">학사일정</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">학사일정</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="nav nav-tabs" role="tablist">
        {codes?.tree?.SCTP.filter((s) => s.code == 'SCTP001' || s.code == 'SCTP002').map((item) => (
          <li className="nav-item">
            <Link
              className={`nav-link ${category === item.code ? 'active' : ''}`}
              onClick={() =>
                setParams({
                  ...params,
                  category: item.code,
                  page: 0,
                })
              }
            >
              {item.label}
            </Link>
          </li>
        ))}
      </ul>

      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="col-md-6 col-12" style={{ display: 'flex', gap: '10px' }}>
                <button
                  type="button"
                  className="btn btn-outline-dark btn-doc waves-effect"
                  onClick={handleDownloadSample}
                >
                  양식 다운로드 <DownIco />
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark btn-doc waves-effect"
                  onClick={() => setUploadForm(1)}
                >
                  엑셀 업로드 <UpIco />
                </button>
              </div>

              <div className="col-md-6 col-12 ms-auto">
                <fieldset>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      setParams({
                        keyword: event.target.keyword.value,
                        keywordType: event.target.keywordType.value,
                      });
                    }}
                  >
                    <div className="input-group">
                      <select
                        name="keywordType"
                        className="form-select"
                        id="selectDefault"
                        defaultValue={params.keywordType}
                      >
                        <option value={''}>전체</option>
                        <option value={'year'}>년도</option>
                        <option value={'createdBy.name'}>작성자</option>
                      </select>
                      <input
                        name="keyword"
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        defaultValue={params.keyword}
                      />
                      <button className="btn btn-outline-primary waves-effect" type="submit">
                        검색
                      </button>
                    </div>
                  </form>
                </fieldset>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '4rem' }} />
                  <col style={{ width: '6rem' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '12%' }} />
                  <col />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '14%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onClick={() => {
                          if (isAllChecked) {
                            setSelected([]);
                          } else {
                            setSelected(data?.content?.map((v) => v.id));
                          }
                        }}
                      />
                    </th>
                    <th>번호</th>
                    <th>년도</th>
                    <th>시작일</th>
                    <th>종료일</th>
                    <th>내용</th>
                    <th>작성자</th>
                    <th>등록일</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.content?.map((item, ix) => (
                    <tr>
                      <td className="input-box">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selected?.includes(item.id)}
                          onClick={() => {
                            setSelected(
                              produce((draft) => {
                                const ix = draft.indexOf(item.id);
                                if (ix >= 0) {
                                  draft.splice(ix, 1);
                                } else {
                                  draft.push(item.id);
                                }
                              }),
                            );
                          }}
                        />
                      </td>
                      <td className="txt-c">{`${data.totalElements - data.size * data.number - ix}`}</td>
                      <td className="txt-c">{item.year}</td>
                      <td className="txt-c">{moment(item.startAt).format('M/DD')}</td>
                      <td className="txt-c">{moment(item.endAt).format('M/DD')}</td>
                      <td>
                        <Link to={`/site/schedule/${item.id}`}>{item.title}</Link>
                      </td>
                      <td className="txt-c">{item.account?.name}</td>
                      <td className="txt-c">{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-50">
            <div className="col-md-6">
              <button type="button" className="btn btn-outline-danger waves-effect" onClick={handleRemove}>
                삭제
              </button>
            </div>
            <div className="col-md-6 txt-r">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-float waves-light"
                onClick={() => {
                  navigate('/site/schedule/write');
                }}
              >
                등록
              </button>
            </div>
          </div>

          <nav aria-label="Page navigation">
            <PaginationMoon
              data={data}
              onClick={(page) => {
                setParams((x) => ({ ...x, page }));
              }}
            />
          </nav>
        </div>
      </div>
      <Modal className={'fade text-start modal-primary show'} visible={!!uploadForm}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">학사일정 업로드</h5>
              {uploadForm === 1 && <button type="button" className="btn-close" onClick={() => setUploadForm(0)} />}
            </div>
            <div className="modal-body">
              {uploadForm <= 1 ? (
                <form onSubmit={handleUploadSubmit}>
                  <div className="row mt-1">
                    <div className="col-12 mb-md-0">
                      <p>
                        엑셀을 업로드하여 다수의 정보를 한번에 입력할 수 있습니다.
                        <br />
                        정해진 엑셀 양식에 값을 입력하여 업로드 하세요 ( 지원하는 파일 양식 : xlsx)
                      </p>
                    </div>
                    <div className="col-12 mb-md-0 txt-r">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-doc waves-effect"
                        onClick={handleDownloadSample}
                      >
                        엑셀양식 파일
                      </button>
                    </div>
                    <div className="d-flex flex-column mt-2 mb-2">
                      <div className="featured-info">
                        {uploadForm === 1 && <input className="form-control" type="file" name={'filedata'} />}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary waves-effect waves-float waves-light">
                      저장
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary waves-effect"
                      onClick={() => setUploadForm(0)}
                    >
                      취소
                    </button>
                  </div>
                </form>
              ) : (
                <div className="spinner-wrap">
                  <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <p>
                    원우정보를 DB에 업로드 중입니다.
                    <br />
                    잠시만 기다려 주십시오
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
