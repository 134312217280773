import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import BoardAttachSingle from '../../../../components/boards/BoardAttachSingle';
import { useLoading } from '../../../../nav/AppContainer';

export default function SiteSiteManagerPopUpWrite() {
  const navigate = useNavigate();
  const query = useParams();

  const attacherP = useRef();
  const attacherM = useRef();

  const [initData, setInitData] = useState({
    type: 'TOP',
  });
  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/banners/${query.id}`);
        setInitData(data);
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  const [loading, setLoading] = useLoading();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...initData,
      type: e.target.type.value,
      title: e.target.title.value,
      active: e.target.active.value === '노출',
      startAt: e.target.startAt.value,
      endAt: e.target.endAt.value,
      linkTarget: e.target.linkTarget.value,
      link: e.target.link.value,
      dimensions: { x: e.target.dimensionsX.value, y: e.target.dimensionsY.value },
      orderIndex: e.target.orderIndex.value,
    };

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return false;
    }

    if (!payload.startAt) {
      alert('시작일을 입력해주세요.');
      return false;
    }

    if (!payload.endAt) {
      alert('종료일을 입력해주세요.');
      return false;
    }

    if (payload.active && !payload.orderIndex) {
      alert('노출 순서를 입력해주세요.');
      return;
    }

    if (attacherP.current?.isEmpty()) {
      alert('PC 이미지를 업로드해주세요.');
      return;
    }

    if (attacherM.current?.isEmpty()) {
      alert('모바일 이미지를 업로드해주세요.');
      return;
    }

    setLoading(true);
    try {
      const pId = await attacherP.current?.getFileId();
      const mId = await attacherM.current?.getFileId();

      payload.imageId = pId;
      payload.imageIdM = mId;

      if (query?.id) {
        await restApi.put(`/banners/${query?.id}`, payload);
        alert('저장되었습니다.');
        await navigate(`/site/siteManager/popup/${query?.id}`);
      } else {
        await restApi.post(`/banners/`, payload);
        alert('등록되었습니다.');
        await navigate('/site/siteManager/popup/');
      }
    } catch (e) {
      // console.log(e);
      // alert(e);
      // alert('파일을 모두 첨부해주세요.');
    }
    setLoading(false);
  };
  return (
    <form onSubmit={handleSubmit} className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">팝업 관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">사이트관리</Link>
                  </li>
                  <li className="breadcrumb-item active">팝업 관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        팝업명<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="flex">
                        <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control flex-grow-1 w-auto"
                          defaultValue={initData?.title}
                          name={'title'}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-title">
                        팝업 구분
                      </label>
                    </th>
                    <td>
                      <select
                        key={initData?.type || ''}
                        className="form-select"
                        style={{ width: 170 }}
                        name="type"
                        defaultValue={initData?.type}
                      >
                        <option value="TOP">띠배너</option>
                        <option value="POPUP">팝업</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="fp-range">
                        팝업 노출기간<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="d-flex flex-column flex-md-row align-items-center">
                        <input
                          type="date"
                          id="fp-range"
                          className="form-control flatpickr-range flatpickr-input"
                          placeholder="YYYY-MM-DD to YYYY-MM-DD"
                          style={{ maxWidth: 150 }}
                          name="startAt"
                          defaultValue={initData?.startAt}
                        />{' '}
                        <span style={{ margin: '0px 10px' }}>~</span>
                        <input
                          type="date"
                          id="fp-range"
                          className="form-control flatpickr-range flatpickr-input"
                          placeholder="YYYY-MM-DD to YYYY-MM-DD"
                          style={{ maxWidth: 150 }}
                          name="endAt"
                          defaultValue={initData?.endAt}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        팝업 링크
                      </label>
                    </th>
                    <td>
                      <div className="col-12">
                        <select
                          className="form-select mb-75"
                          style={{ width: 170 }}
                          name="linkTarget"
                          defaultValue={initData?.linkTarget}
                        >
                          {/* <option>적용 안 함</option> */}
                          <option value="_blank">새창</option>
                          <option value="_top">현재창</option>
                        </select>
                      </div>
                      <input
                        type="url"
                        id="blog-edit-slug"
                        className="form-control"
                        placeholder="https://"
                        name="link"
                        defaultValue={initData?.link}
                      />
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-top">
                        팝업 위치
                      </label>
                    </th>
                    <td className="flex">
                      {' X축:'}{' '}
                      <input
                        type="text"
                        id="blog-edit-top"
                        className="form-control ms-1"
                        name="dimensionsX"
                        defaultValue={initData?.dimensions?.x}
                        style={{ width: 170, marginRight: 10 }}
                      />
                      {' Y축:'}
                      <input
                        type="text"
                        id="blog-edit-left"
                        className="form-control ms-1"
                        style={{ width: 170 }}
                        name="dimensionsY"
                        defaultValue={initData?.dimensions?.y}
                      />
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <label className="form-label">노출여부</label>
                    </th>
                    <td>
                      <select
                        className="form-select mb-75"
                        style={{ width: 170 }}
                        name="active"
                        defaultValue={initData?.active}
                      >
                        <option value={'노출'}>노출</option>
                        <option value={'비노출'}>비노출</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">노출순서</label>
                    </th>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: 170 }}
                        name="orderIndex"
                        defaultValue={initData?.orderIndex}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value && !/^\d+$/.test(value)) {
                            e.target.value = value.slice(0, -1);
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <BoardAttachSingle ref={attacherP} name={'imageId'} defaultValue={initData?.imageId} />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일(모바일)<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <BoardAttachSingle ref={attacherM} name={'imageIdM'} defaultValue={initData?.imageIdM} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {!loading && (
            <div className="col-12 mt-50 txt-r">
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/site/siteManager/popup');
                }}
              >
                취소
              </button>
            </div>
          )}
        </div>
      </div>
      {/* // con e */}
    </form>
  );
}
