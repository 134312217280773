import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAPIHost, restApi } from '../../../../common/api';
const USER_TYPE = {
  TEACHER: '교수',
  STAFF: '튜터',
  MANAGER: '행정실',
  ADMIN: '지원실',
  USER: '원우',
  CP: 'CP',
  EXAMSTAFF: '시험감독관',
};
const CREWS_TYPE = {
  research: '연구회',
  club: '동호회',
};
export default function LmsAccountsIdIndex() {
  const navigate = useNavigate();
  const query = useParams();

  const [crews, setCrews] = useState([]);
  const [data, setData] = useState();
  const loadData = async () => {
    const { data } = await restApi.get(`/accounts/${query.id}/bo`);
    setData(data);
  };

  useEffect(() => {
    const loadCrew = async () => {
      const { data } = await restApi.get(`/crews`);
      setCrews(data.content);
    };
    loadCrew().catch(console.warn);

    loadData().catch(console.warn);
  }, [query.id]);

  // console.log(crews);
  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/accounts/${query.id}`);
    alert('삭제되었습니다.');
    window.history.back();
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">계정/권한관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">계정/권한관리</li>
                  <li className="breadcrumb-item active">계정관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link className="nav-link active" role="tab" to="#n">
            계정관리
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" role="tab" to="/lms/accounts/permission">
            권한설정
          </Link>
        </li>
      </ul>
      {/* Bordered table start s */}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="text-primary">기본 정보</h4>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td rowSpan={9}>
                          {data?.imageId ? (
                            <img
                              src={`${getAPIHost()}api/files/${data?.imageId}`}
                              alt=""
                              style={{ width: 150, height: 180 }}
                            />
                          ) : (
                            <img src="../../../assets/images/no-img.png" alt="" style={{ width: 150, height: 180 }} />
                          )}
                          <span className="d-block txt-c fs-2 mt-1">{data?.name}</span>
                          {data?.type === 'USER' && (
                            <p className="txt-c">
                              IMBA {data?.period}기
                              <br />({data?.attending ? '재학생' : '졸업생'})
                            </p>
                          )}
                        </td>
                        <th>분류</th>
                        <td>
                          {data?.type === 'USER'
                            ? `${USER_TYPE[data?.type]} / IMBA ${data?.period}기`
                            : USER_TYPE[data?.type]}
                        </td>
                      </tr>
                      <tr>
                        <th>학번(아이디)</th>
                        <td>{data?.userId}</td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">생년월일</label>
                        </th>
                        <td>
                          {data?.birthday &&
                            `${dayjs(data?.birthday).format('YYYY년 M월 D일')} / ${data?.lunar ? '음력' : '양력'}`}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">성별</label>
                        </th>
                        <td>{data?.gender === 'MALE' ? '남' : '여'}</td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">이메일</label>
                        </th>
                        <td>
                          <a href={`mailto:${data?.email}`}>{data?.email}</a>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">전화번호</label>
                        </th>
                        <td>
                          <a href={`tel:${data?.phone}`}>{data?.phone}</a>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">휴대폰번호</label>
                        </th>
                        <td>
                          <a href={`tel:${data?.mobilePhone}`}>{data?.mobilePhone}</a>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">주소</label>
                        </th>
                        <td>
                          {data?.address} {data?.addressDetails}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {data?.type === 'USER' && (
                <>
                  <h4 className="text-primary">추가정보</h4>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-bordered visual-box">
                        <colgroup>
                          <col style={{ width: '11rem' }} />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>
                              <label className="form-label">기업분류</label>
                            </th>
                            <td>{data?.workCorpType}</td>
                          </tr>
                          <tr>
                            <th>
                              <label className="form-label">직종</label>
                            </th>
                            <td>{data?.workJobType}</td>
                          </tr>
                          <tr>
                            <th>
                              <label className="form-label">직장명</label>
                            </th>
                            <td>{data?.companyName}</td>
                          </tr>
                          <tr>
                            <th>부서</th>
                            <td>{data?.companyTeam}</td>
                          </tr>
                          <tr>
                            <th>직위</th>
                            <td>{data?.companyPosition}</td>
                          </tr>
                          <tr>
                            <th>
                              <label className="form-label">직장주소</label>
                            </th>
                            <td>
                              {data?.companyAddress} {data?.companyAddressDetails}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <label className="form-label">해외원우</label>
                            </th>
                            <td>{data?.isForeign ? (data?.area ? data?.area.join(' ') : '해외') : '미해당(국내)'}</td>
                          </tr>
                          <tr>
                            <th>
                              <label className="form-label">회사소개</label>
                            </th>
                            <td>{data?.companyBio}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <h4 className="text-primary">동호회(연구회) 게시판 글쓰기 권한</h4>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-bordered visual-box">
                        <colgroup>
                          <col style={{ width: '11rem' }} />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>글쓰기 권한</th>
                            <td>
                              {data?.permissions.map((pms) => {
                                const [type, id] = pms.split(':');
                                return type === 'CALENDAR' ? (
                                  <p>IMBA 캘린더</p>
                                ) : (
                                  crews
                                    .filter((crew) => {
                                      return crew.type.toUpperCase() === type && crew.id === Number(id);
                                    })
                                    .map((crew) => (
                                      <p>
                                        {CREWS_TYPE[crew.type]} / {crew.name}
                                      </p>
                                    ))
                                );
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
              <div className="col-12 txt-r">
                <button
                  type="submit"
                  className="btn btn-primary me-1 waves-effect waves-float waves-light"
                  onClick={() => {
                    navigate(`/lms/accounts/${query.id}/edit`);
                  }}
                >
                  수정
                </button>
                <button
                  type="reset"
                  className="btn btn-outline-primary waves-effect"
                  onClick={() => {
                    navigate('/lms/accounts');
                  }}
                >
                  목록
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Bordered table end e */}
      </div>
      {/* // con e */}
    </div>
  );
}
