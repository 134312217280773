import React, { useEffect, useState } from 'react';
import { setSemesterId } from '../data/common';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { restApi } from '../common/api';


const LectureSearchItem = ({ onChange, defaultValue, value, disabled, noAll }) => {
  const { semesters, codes, semesterId } = useSelector((s) => s.common, shallowEqual);
  const dispatch = useDispatch();

  const [lectures, setLectures] = useState([]);
  useEffect(() => {
    if (!semesterId) {
      setLectures([]);
      return;
    }

    const loadData = async () => {
      const { data } = await restApi.get(`/lectures`, {
        params: { semesterId, limit: 1000, order: 'ASC', sort: 'mainClass.title' },
      });
      setLectures(data?.content);
    };
    loadData().catch(console.warn);
  }, [semesterId]);

  return (
    <div className="d-flex align-items-center">
      <select
        className="form-select pe-3 me-75 w-auto"
        value={semesterId}
        onChange={(e) => {
          const value = e.target.value;
          // console.log(value)
          dispatch(setSemesterId(value));
        }}
        disabled={disabled}
      >
        {semesters?.map((item) => (
          <option value={item.id} key={`${item.id}`}>
            {item.year}년 {codes?.map?.[item.session]}
          </option>
        ))}
      </select>
      {!!lectures?.length && (
        <select
          className="form-select w-auto pe-3"
          name={'lectureId'}
          defaultValue={defaultValue}
          value={value}
          onChange={(e) => {
            const value = e.target.value;
            // console.log(value)
            onChange?.(value);
          }}
          disabled={disabled}
        >
          {!noAll && <option value={''}>전체</option>}
          {noAll !== true && !!noAll && <option value={''}>{noAll}</option>}
          {lectures?.map((item) => (
            <option value={item.id} key={`${item.id}`}>
              {item.mainClass.title} ({item.mainClass.master?.name} 교수)
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default LectureSearchItem;
