import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import { editorInitOptions } from '../../../../common/utils';
import VideoChoice from '../../../../components/VideoChoice';
import { shallowEqual, useSelector } from 'react-redux';
import BoardAttachSingle from '../../../../components/boards/BoardAttachSingle';
import { useLoading } from '../../../../nav/AppContainer';

export default function SiteBoardBizWrite() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const query = useParams();

  const attacher = useRef();
  const editorRef = useRef();

  const [initData, setInitData] = useState();
  const [videoContentId, setVideoContentId] = useState();
  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/ebizs/${query.id}`);
        setInitData(data);
        setVideoContentId(data?.videoContent?.id);
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  const [loading, setLoading] = useLoading();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...initData,
      contentsWithMarkup: editorRef.current.getContent(),
      title: e.target.title.value,
      category: e.target.category.value,
      videoContentId,
    };

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return false;
    }

    if (!payload.contentsWithMarkup) {
      alert('내용을 입력해주세요.');
      return false;
    }

    setLoading(true);
    try {
      payload.imageId = await attacher.current.getFileId();

      if (query?.id) {
        await restApi.put(`/ebizs/${query?.id}`, payload);
        alert('저장되었습니다.');
        await navigate(`/site/board/biz/${query?.id}`);
      } else {
        await restApi.post(`/ebizs`, payload);
        alert('등록되었습니다.');
        await navigate('/site/board/biz');
      }
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">Biz. 인사이트</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link tbody="index.html">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Biz. 인사이트</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      {/* Bordered table start */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">카테고리</label>
                    </th>
                    <td>
                      <select className="form-select w-auto pe-3" name="category" defaultValue={initData?.category}>
                        {codes?.tree?.CBIZ?.map((code) => (
                          <option value={code.code} key={code.code}>
                            {code.label}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">제목</label>
                    </th>
                    <td>
                      <input type="text" className="form-control" name="title" defaultValue={initData?.title} />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">내용</label>
                    </th>
                    <td>
                      <Editor
                        initialValue={initData?.contentsWithMarkup}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={editorInitOptions}
                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        영상 파일
                      </label>
                    </th>
                    <td>
                      {/* <div className="d-flex flex-column flex-md-row">
                        <div className="featured-info">
                          <p className="my-50">
                            <a href="#">C:\fakepath\banner.jpg</a>
                            <button type="button" className="btn-file-del">
                              파일삭제
                            </button>
                          </p>
                          <div className="d-inline-block">
                            <input className="form-control" type="file" multiple="" />
                          </div>
                        </div>
                      </div> */}
                      <VideoChoice
                        // value={form?.videoContentId}
                        // onChange={(v) => setForm((x) => ({ ...x, videoContentId: v }))}
                        // defaultValue={initData?.videoContent.id}
                        type="EBIZ"
                        value={videoContentId}
                        onChange={setVideoContentId}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        썸네일
                      </label>
                    </th>
                    <td>
                      <BoardAttachSingle ref={attacher} name={'imageId'} defaultValue={initData?.imageId} />
                      {/* <div className="d-flex flex-column flex-md-row">
                        <div className="featured-info">
                          <p className="my-50">
                            <a href="#">C:\fakepath\banner.jpg</a>
                            <button type="button" className="btn-file-del">
                              파일삭제
                            </button>
                          </p>
                          <div className="d-inline-block">
                            <input className="form-control" type="file" multiple="" />
                          </div>
                        </div>
                      </div> */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {!loading && (
            <div className="col-12 mt-50 txt-r">
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/site/board/biz');
                }}
              >
                취소
              </button>
            </div>
          )}
        </div>
      </div>
      {/* Bordered table end */}
      {/* // con e */}
    </form>
  );
}
