import dayjs from 'dayjs';
import produce from 'immer';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import BoardAttachList from '../../../../components/boards/BoardAttachList';
import PaginationMoon from '../../../../components/PaginationMoon';
import { useLoading } from '../../../../nav/AppContainer';
import FileSaver from 'file-saver';

export default function SiteBoardApplicationIndex() {
  const navigate = useNavigate();

  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));
  useEffect(() => {
    setSearchParams(params);
  }, [params]);
  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/admission-guides`);
    setData(data);
  }, []);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  useEffect(() => {
    loadData();
  }, [loadData]);
  const [selected, setSelected] = useState([]);
  const isAllChecked = data?.content?.map((v) => v.id).filter((x) => !selected.includes(x)).length === 0;

  const handleRemove = async () => {
    if (!selected.length) {
      alert('선택 후 클릭해주세요.');
      return;
    }

    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    await Promise.all(
      selected.map((id) => {
        return restApi.delete(`/admission-guides/${id}`);
      }),
    );

    loadData();
  };

  const [, setLoading] = useLoading();
  const handleDownload = async (item) => {
    setLoading(true);
    const filename = `신청자_다운로드_${item?.title}_${dayjs().format('YYYYMMDD')}`;
    try {
      const { data } = await restApi.get(`/surveys/${item.surveyId}/xlsx`, {
        responseType: 'arraybuffer',
      });
      FileSaver.saveAs(new Blob([data]), `${filename}.xlsx`);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
      console.warn(e);
    }
    setLoading(false);
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* con s */}
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">모집요강</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#n">입학관리</Link>
                  </li>
                  <li className="breadcrumb-item active">모집요강</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link
            className="nav-link active"
            id="tab1"
            data-bs-toggle="page"
            to="./guide.html"
            aria-controls="tab1"
            role="tab"
            aria-selected="true"
          >
            모집요강
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            id="tab2"
            data-bs-toggle="page"
            to="./interview.html"
            aria-controls="tab2"
            role="tab"
            aria-selected="false"
          >
            면접안내
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            id="tab3"
            data-bs-toggle="page"
            to="./faq.html"
            aria-controls="tab3"
            role="tab"
            aria-selected="false"
          >
            입학FAQ
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            id="tab4"
            data-bs-toggle="page"
            to="./entranceQna.html"
            aria-controls="tab4"
            role="tab"
            aria-selected="false"
          >
            입학Q&amp;A
          </Link>
        </li>
      </ul> */}
      {/* Bordered table start */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              {/* Inputs Group with Dropdown */}
              <div className="col-md-6 col-12 ms-auto">
                <fieldset>
                  <div className="input-group">
                    <select className="form-select" id="selectDefault">
                      <option value={1}>전체</option>
                      <option value={2}>제목</option>
                      <option value={3}>작성자</option>
                    </select>
                    {/* <span class="input-group-text" id="basic-addon-search1"
                  ><i data-feather="search"></i
                ></span> */}
                    <input type="text" className="form-control" placeholder="" aria-label="Amount" />
                    <button className="btn btn-outline-primary waves-effect" type="button">
                      검색
                    </button>
                  </div>
                </fieldset>
              </div>
              {/* Inputs Group with Dropdown end */}
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '4rem' }} />
                  <col style={{ width: '6rem' }} />
                  <col />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '14%' }} />
                  <col style={{ width: '14%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <input
                        className="form-check-input"
                        id=""
                        type="checkbox"
                        checked={isAllChecked}
                        onClick={() => {
                          if (isAllChecked) {
                            setSelected([]);
                          } else {
                            setSelected(data?.content?.map((v) => v.id));
                          }
                        }}
                      />
                    </th>
                    <th>번호</th>
                    <th>제목</th>
                    <th>모집요강 파일</th>
                    <th>원서접수기간</th>
                    <th>작성자</th>
                    <th>등록일</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.content?.map((item, ix) => (
                    <tr>
                      <td className="txt-c">
                        <input
                          className="form-check-input"
                          id=""
                          type="checkbox"
                          checked={selected?.includes(item.id)}
                          onClick={() => {
                            setSelected(
                              produce((draft) => {
                                const ix = draft.indexOf(item.id);
                                if (ix >= 0) {
                                  draft.splice(ix, 1);
                                } else {
                                  draft.push(item.id);
                                }
                              }),
                            );
                          }}
                        />
                      </td>
                      <td className="txt-c">{data?.totalElements - ix - data?.size * data?.number}</td>
                      <td>
                        <Link to={`/site/board/application/${item.id}`}>{item?.title}</Link>
                      </td>
                      <td>
                        <BoardAttachList ids={item.file} />
                      </td>
                      <td className="txt-c">{item.time}</td>
                      <td className="txt-c">{item.createdBy.name}</td>
                      <td className="txt-c">{dayjs(item.createdAt).format('YYYY-MM-DD')}</td>
                      <td className="txt-c">
                        {!!item.surveyId && (
                          <button
                            type="button"
                            className="btn btn-primary waves-effect"
                            onClick={() => handleDownload(item)}
                          >
                            신청자
                            <br />
                            다운로드
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-50">
            <div className="col-md-6">
              <button type="button" className="btn btn-outline-danger waves-effect" onClick={handleRemove}>
                삭제
              </button>
            </div>
            <div className="col-md-6 txt-r">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-float waves-light"
                onClick={() => {
                  navigate('/site/board/application/write');
                }}
              >
                등록
              </button>
            </div>
          </div>
          {/* pagination s */}
          <nav aria-label="Page navigation">
            <PaginationMoon
              data={data}
              onClick={(page) => {
                setParams((x) => ({ ...x, page }));
              }}
            />
          </nav>
          {/* // pagination e */}
        </div>
      </div>
      {/* Bordered table end */}
      {/* // con e */}
    </div>
  );
}
