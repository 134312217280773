import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../../common/api';

export default function SiteBoardImbaVideoIdIndex() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState();
  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/web/youtubes/${id}`);
      setData(data);
    };
    loadData().catch(console.warn);
  }, [id]);

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">게시판 관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#n">게시판 관리</Link>
                  </li>
                  <li className="breadcrumb-item active">IMBA 홍보동영상</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        제목<span className="require">*</span>
                      </label>
                    </th>
                    <td>{data?.title}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        공개여부<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <select className="form-select" value={data?.active ? 'true' : 'false'}>
                        <option value={'true'}>노출</option>
                        <option value={'false'}>비노출</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        내용<span className="require">*</span>
                      </label>
                    </th>
                    <td>{data?.description}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        영상<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <iframe
                        src={`https://www.youtube.com/embed/${data?.id}`}
                        frameBorder="0"
                        style={{ width: '100%', height: 600 }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/site/board/imbaVideo');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>
      {/* // con e */}
    </div>
  );
}
