import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import LectureSearch from '../../../components/LectureSearch';
import queryString from 'query-string';
import { restApi } from '../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../common/utils';
import PaginationMoon from '../../../components/PaginationMoon';

export default function LmsVotesIndex() {
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const navigate = useNavigate();
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/lectures/${lectureId}/votes`, { params });

    setData(data);
  }, [lectureId, params]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const today = dayjs().format('YYYY-MM-DD');
  const checkOngoing = (startDate, endDate) => {
    if (dayjs(today).isBefore(startDate, 'days')) {
      return '예정';
    }
    if (dayjs(today).isAfter(endDate, 'days')) {
      return '종료';
    }
    if (dayjs(today).isBetween(startDate, endDate, 'days')) {
      return '진행';
    }
    if (dayjs(today).isSame(startDate, 'days')) {
      return '진행';
    }
    if (dayjs(today).isSame(endDate, 'days')) {
      return '진행';
    }
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">설문</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">설문</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}

      <LectureSearch />
      {/* con s */}
      {/* Bordered table start */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          {/* 조회 전 */}
          {/* <p class="no-data">조회를 먼저 진행해 주세요.</p> */}
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '4rem' }} />
                  <col />
                  <col style={{ width: '14rem' }} />
                  <col style={{ width: '7rem' }} />
                  <col style={{ width: '7rem' }} />
                  <col style={{ width: '7rem' }} />
                  <col style={{ width: '10rem' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>설문지명</th>
                    <th>설문기간</th>
                    <th>상태</th>
                    <th>응답</th>
                    <th>미응답</th>
                    <th>등록일</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.content?.map((item, ix) => (
                    <tr key={`${item.id}`}>
                      <td className="txt-c">{data?.totalElements - data?.size * data?.number - ix}</td>
                      <td>
                        <Link to={`/lms/votes/${item.id}`}>{item?.title}</Link>
                      </td>
                      <td className="txt-c">
                        {dayjs(item?.startAt).format(DATE_FORMAT)} ~ {dayjs(item?.endAt).format(DATE_FORMAT)}
                      </td>
                      <td className="txt-c">
                        {/* {dayjs().isBetween(item?.startAt, item?.endAt, 'days') ? '진행' : '종료'} */}
                        {checkOngoing(item?.startAt, item?.endAt)}
                      </td>
                      <td className="txt-c">{item?.applySize || '-'}</td>
                      <td className="txt-c">{item?.lecture?.accountSize - item?.applySize || '-'}</td>
                      <td className="txt-c"> {dayjs(item?.createdAt).format(DATE_FORMAT)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-sm-between mb-75">
            <div className="d-flex align-items-center">
              {/*<h4 className="my-0 text-primary">마케팅관리론</h4>*/}
              {/*<span className="ms-75 text-black-50">김정구 교수 | 2022학년도 2학기 | 기간 : 2022/10/01~2022/10/11</span>*/}
            </div>
            <div className="d-flex align-items-center">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-float waves-light"
                onClick={() => {
                  navigate('/lms/votes/write');
                }}
              >
                등록
              </button>
            </div>
          </div>
          {/* pagination s */}
          <nav aria-label="Page navigation">
            <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
          </nav>
          {/* // pagination e */}
        </div>
      </div>
      {/* Bordered table end */}
      {/* // con e */}
    </div>
  );
}
