import dayjs from 'dayjs';
import produce from 'immer';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import LectureSearch from '../../../../components/LectureSearch';
import NoticeOrderModal from '../../../../components/NoticeOrderModal';
import PaginationMoon from '../../../../components/PaginationMoon';

export default function LmsMessagesNoticesIndex() {
  const navigate = useNavigate();
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);

  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [fixedData, setFixedData] = useState();
  const loadFixedData = useCallback(async () => {
    if (['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type) && !lectureId) return;

    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/lms/messages/important', {
      params: {
        ...params,
        page: 0,
        lectureId,
        type: 'notice',
      },
    });
    setFixedData(data);
  }, [params, lectureId, principal?.type, showModal]);

  useEffect(() => {
    loadFixedData();
  }, [loadFixedData]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    if (['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type) && !lectureId) return;

    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/lms/messages', {
      params: {
        ...params,
        lectureId,
        type: 'notice',
        limit: fixedData ? 20 - fixedData?.content.length : 20,
      },
    });
    setData(data);
  }, [params, lectureId, principal?.type, fixedData, showModal]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">공지사항</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">공지사항</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LectureSearch />
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="col-6">
                {!['TEACHER', 'STAFF', 'EXAMSTAFF'].includes(principal?.type) && (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-float waves-light"
                  onClick={() => setShowModal(true)}
                >
                  전체 공지 관리
                </button>
                )}
              </div>
              <div className="col-6">
                <fieldset>
                  <form
                    className="input-group"
                    onSubmit={(event) => {
                      event.preventDefault();
                      setParams(
                        produce((draft) => {
                          draft.keywordType = event.target.keywordType.value;
                          draft.keyword = event.target.keyword.value;
                        }),
                      );
                    }}
                  >
                    <select name="keywordType" className="form-select" defaultValue={params.keywordType}>
                      <option value="">전체</option>
                      <option value="title">제목</option>
                      <option value="contents">내용</option>
                      <option value="account.name">작성자</option>
                    </select>
                    <input
                      type="text"
                      name="keyword"
                      className="form-control"
                      placeholder=""
                      aria-label="Amount"
                      defaultValue={params.keyword}
                    />
                    <button className="btn btn-outline-primary waves-effect" type="submit">
                      검색
                    </button>
                  </form>
                </fieldset>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '6rem' }} />
                  {!lectureId && <col style={{ width: '12rem' }} />}
                  <col style={{ width: '6rem' }} />
                  <col />
                  <col style={{ width: '10rem' }} />
                  <col style={{ width: '6rem' }} />
                  <col style={{ width: '10rem' }} />
                  <col style={{ width: '6rem' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    {!lectureId && <th>과목</th>}
                    <th>구분</th>
                    <th>제목</th>
                    <th>작성자</th>
                    <th>첨부</th>
                    <th>등록일</th>
                    <th>조회수</th>
                  </tr>
                </thead>
                <tbody>
                  {fixedData?.content.map((notice, index) => {
                    return (
                      <tr key={`Notice-${notice.id}`}>
                        <td className="txt-c">공지</td>
                        {!lectureId && (
                          <td
                            style={{
                              maxWidth: '12rem',
                            }}
                          >
                            <p
                              style={{ margin: 0, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                            >
                              {notice.lecture?.mainClass?.title}
                            </p>
                          </td>
                        )}
                        <td className="txt-c">{notice?.category ? codes?.map?.[notice?.category] : notice.lecture ? '수업' : '학사'}</td>
                        <td>
                          <Link to={`/lms/messages/notices/${notice.id}`}>{notice.title}</Link>
                        </td>
                        <td className="txt-c">{notice.account?.name}</td>
                        <td className="txt-c">
                          {!!notice.files?.length && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={14}
                              height={14}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-paperclip"
                            >
                              <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                            </svg>
                          )}
                        </td>
                        <td className="txt-c">{dayjs(notice.createdAt).format('YYYY-MM-DD')}</td>
                        <td className="txt-c">{notice.hit}</td>
                      </tr>
                    );
                  })}
                  {data?.content.map((item, index) => {
                    const notice = item.webMessage ? item.webMessage : item;
                    return (
                      <tr key={`Notice-${item.id}`}>
                        <td className="txt-c">{data?.totalElements - data?.size * data?.number - index}</td>
                        {!lectureId && (
                          <td
                            style={{
                              maxWidth: '12rem',
                            }}
                          >
                            <p
                              style={{ margin: 0, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                            >
                              {notice.lecture?.mainClass?.title}
                            </p>
                          </td>
                        )}
                        <td className="txt-c">{notice?.category ? codes?.map?.[notice?.category] : notice.lecture ? '수업' : '학사'}</td>
                        <td>
                          <Link to={`/lms/messages/notices/${item.id}`}>{notice.title}</Link>
                        </td>
                        <td className="txt-c">{notice.account?.name ?? notice.writer}</td>
                        <td className="txt-c">
                          {!!notice.files?.length && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={14}
                              height={14}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-paperclip"
                            >
                              <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                            </svg>
                          )}
                        </td>
                        <td className="txt-c">{dayjs(notice.createdAt).format('YYYY-MM-DD')}</td>
                        <td className="txt-c">{notice.hit}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 txt-r">
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-float waves-light"
              onClick={() => {
                navigate('/lms/messages/notices/write');
              }}
            >
              등록
            </button>
          </div>
          <nav aria-label="Page navigation">
            <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
          </nav>
        </div>
      </div>
      {fixedData && (
        <NoticeOrderModal
          visible={showModal}
          fixedData={fixedData?.content}
          onExit={() => setShowModal(false)}
          type={'lms'}
        />
      )}
    </div>
  );
}
