import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restApi } from '../common/api';
import dayjs from 'dayjs';

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

const initialState = {
  semesters: [],
  semesterId: null,
  lectureId: null,
  lectureData: undefined,
  codes: {},
};

export const getSemesters = createAsyncThunk('commons/semesters', async () => {
  const { data } = await restApi.get(`/semesters`, { params: { limit: 1000 } });
  return data;
});

export const getCodes = createAsyncThunk('commons/cpdes', async () => {
  const { data } = await restApi.get(`/codes/map`);
  return data;
});

const CommonSlice = createSlice({
  name: 'Common',
  initialState,
  reducers: {
    init(state, action) {
      state = action.payload?.common || state;
    },
    initCommon(state, action) {
      state = action.payload || state;
    },
    setSemesterId(state, action) {
      state.semesterId = action.payload;
    },
    setLectureId(state, action) {
      state.lectureId = action.payload;
    },
    setLectureData(state, action) {
      state.lectureData = action.payload;
    },
  },
  extraReducers: {
    [getCodes.fulfilled]: (state, action) => {
      state.codes = action.payload;
    },
    [getSemesters.fulfilled]: (state, action) => {
      state.semesters = action.payload?.content ?? [];
      let sid = state.semesters.filter((x) => dayjs().isBetween(x.startAt, x.endAt, 'day'))?.[0]?.id;
      if (!sid) sid = state.semesters?.[0]?.id;
      state.semesterId = sid;
    },
  },
});

export const { init, setLectureId, setLectureData, setSemesterId } = CommonSlice.actions;

export default CommonSlice.reducer;
