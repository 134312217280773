import React, { useEffect, useMemo, useRef, useState } from 'react';
import { restApi } from '../../../../common/api';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import FileAttacher from '../../../../components/FileAttacher';
import { Link, useNavigate, useParams } from 'react-router-dom';
import VideoChoice from '../../../../components/VideoChoice';
import LectureSearchItem from '../../../../components/LectureSearchItem';
import { setLectureId } from '../../../../data/common';
import { useLoading } from '../../../../nav/AppContainer';

const LecturesOfflinesForm = (props) => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { lectureId: initLec } = useSelector((s) => s.common, shallowEqual);
  const [lectureId, setLecId] = useState(initLec);
  const [form, setForm] = useState({
    accessPublic: true,
    area: '',
    startDate: moment().format('YYYY-MM-DD'),
    startHour: '00',
    startMin: '00',
    endHour: '00',
    endMin: '00',
    comming: true,
    commingDate: moment().format('YYYY-MM-DD'),
    commingHour: '00',
    commingMin: '00',
    title: '',
    zoomId: '',
    zoomLink: '',
    videoContentId: undefined,
    paper: undefined,
  });

  useEffect(() => {
    if (!id) return;

    const loadData = async () => {
      const { data } = await restApi.get(`/lectures/0/offlines/study/${id}`);
      setLecId(data?.lecture?.id);
      //
      const s = moment(data.startAt);
      const e = moment(data.endAt);
      const c = data.comming ? moment(data.comming) : undefined;
      setForm((x) => ({
        ...x,
        accessPublic: data.accessPublic,
        area: data.area,
        startDate: s.format('YYYY-MM-DD'),
        startHour: s.format('HH'),
        startMin: s.format('mm'),
        endHour: e.format('HH'),
        endMin: e.format('mm'),
        comming: !!data.comming,
        commingDate: c?.format('YYYY-MM-DD'),
        commingHour: c?.format('HH'),
        commingMin: c?.format('mm'),
        title: data?.title,
        zoomId: data?.zoomId,
        zoomLink: data?.zoomLink,
        videoContentId: data?.videoContent?.id,
        paper: data?.paper,
        roomId: data?.room?.id,
      }));
    };
    loadData().catch(console.warn);
  }, [id]);

  const [rooms, setRooms] = useState();
  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/lectures/attandances/rooms`);
      setRooms(data);
    };
    loadData().catch(console.warn);
  }, []);

  const attacher = useRef();
  const [loading, setLoading] = useLoading();

  const handleSubmit = async () => {
    setLoading(true);

    const ids = await attacher.current.getFileIds();

    const param = {
      area: form.area,
      endAt: `${form.startDate}T${form.endHour}:${form.endMin}:00`,
      startAt: `${form.startDate}T${form.startHour}:${form.startMin}:00`,
      title: form.title,
      zoomId: form.zoomId,
      zoomLink: form.zoomLink,
      accessPublic: form.accessPublic,
      videoContentId: form.videoContentId,
      paper: ids?.[0] || undefined,
      roomId: form.roomId,
    };

    if (form.comming) {
      param.comming = `${form.commingDate ?? moment().format('YYYY-MM-DD')}T${form.commingHour ?? '00'}:${
        form.commingMin ?? '00'
      }:00`;
    }

    dispatch(setLectureId(lectureId));
    if (id) {
      await restApi.put(`/lectures/${lectureId}/offlines/study/${id}`, param);
      alert('저장되었습니다.');
      navigate(`/lms/lectures/offline/view/${id}`);
    } else {
      await restApi.post(`/lectures/${lectureId}/offlines/study`, param);
      alert('등록되었습니다.');
      navigate(`/lms/lectures/offline`);
    }

    setLoading(false);
  };

  const file = useMemo(() => [form.paper], [form.paper]);

  return (
    <form
      onSubmit={(e) => {
        handleSubmit();
        e.preventDefault();
      }}
    >
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">오프라인 강의</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">오프라인 강의</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // header e */}
        {/* con s */}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {!['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type) && (
                      <tr>
                        <th>
                          <label className="form-label">과목</label>
                        </th>
                        <td>
                          <LectureSearchItem
                            value={lectureId}
                            onChange={setLecId}
                            disabled={!!id}
                            noAll={'과목을 선택하세요.'}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>
                        <label className="form-label">수업명</label>
                      </th>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={form.title}
                          onChange={(e) => {
                            const value = e.target.value;
                            setForm((x) => ({ ...x, title: value }));
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">날짜 / 시간</label>
                      </th>
                      <td>
                        <div className="row mx-auto">
                          <input
                            type="date"
                            className="form-control flatpickr-basic me-3 flatpickr-input"
                            placeholder="YYYY-MM-DD"
                            style={{ width: 150 }}
                            value={form.startDate}
                            onChange={(e) => {
                              const value = e.target.value;
                              setForm((x) => ({ ...x, startDate: value }));
                            }}
                          />
                          <select
                            className="form-select w-auto pe-3"
                            value={form.startHour}
                            onChange={(e) => {
                              const value = e.target.value;
                              setForm((x) => ({ ...x, startHour: value }));
                            }}
                          >
                            {Array(24)
                              .fill('')
                              .map((_, ix) => (
                                <option key={`${ix}`} value={String(ix).padStart(2, '0')}>
                                  {String(ix).padStart(2, '0')}
                                </option>
                              ))}
                          </select>
                          <span className="txt-hipen">:</span>
                          <select
                            className="form-select w-auto pe-3"
                            value={form.startMin}
                            onChange={(e) => {
                              const value = e.target.value;
                              setForm((x) => ({ ...x, startMin: value }));
                            }}
                          >
                            {Array(6)
                              .fill('')
                              .map((_, ix) => (
                                <option key={`${ix}`} value={String(ix * 10).padStart(2, '0')}>
                                  {String(ix * 10).padStart(2, '0')}
                                </option>
                              ))}
                          </select>
                          <span className="txt-hipen">부터</span>
                          <select
                            className="form-select w-auto pe-3"
                            value={form.endHour}
                            onChange={(e) => {
                              const value = e.target.value;
                              setForm((x) => ({ ...x, endHour: value }));
                            }}
                          >
                            {Array(24)
                              .fill('')
                              .map((_, ix) => (
                                <option key={`${ix}`} value={String(ix).padStart(2, '0')}>
                                  {String(ix).padStart(2, '0')}
                                </option>
                              ))}
                          </select>
                          <span className="txt-hipen">:</span>
                          <select
                            className="form-select w-auto pe-3"
                            value={form.endMin}
                            onChange={(e) => {
                              const value = e.target.value;
                              setForm((x) => ({ ...x, endMin: value }));
                            }}
                          >
                            {Array(6)
                              .fill('')
                              .map((_, ix) => (
                                <option key={`${ix}`} value={String(ix * 10).padStart(2, '0')}>
                                  {String(ix * 10).padStart(2, '0')}
                                </option>
                              ))}
                          </select>
                          <span className="txt-hipen">까지</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">강의실</label>
                      </th>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={form.area}
                          onChange={(e) => {
                            const value = e.target.value;
                            setForm((x) => ({ ...x, area: value }));
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">비콘</label>
                      </th>
                      <td>
                        <select
                          type="text"
                          className="form-select"
                          value={form.roomId}
                          onChange={(e) => {
                            const value = e.target.value;
                            setForm((x) => ({ ...x, roomId: value }));
                          }}
                        >
                          <option value={''}>선택하세요 (미 선택시 자동출석이 되지 않습니다)</option>
                          {rooms?.map((item) => (
                            <option key={`${item.id}`} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">화상 접속정보</label>
                      </th>
                      <td>
                        <div className="row mx-auto mb-75">
                          <span className="txt-hipen text-black me-1">ZOOM ID</span>
                          <input
                            type="text"
                            className="form-control"
                            value={form.zoomId}
                            onChange={(e) => {
                              const value = e.target.value;
                              setForm((x) => ({ ...x, zoomId: value }));
                            }}
                            style={{ width: 250 }}
                          />
                        </div>
                        <div className="row d-flex mx-auto">
                          <span className="txt-hipen text-black">ZOOM URL</span>
                          <input
                            type="text"
                            className="form-control flex-grow-1 w-auto"
                            value={form.zoomLink}
                            onChange={(e) => {
                              const value = e.target.value;
                              setForm((x) => ({ ...x, zoomLink: value }));
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h5>동영상 정보</h5>
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label">동영상 선택 </label>
                      </th>
                      <td>
                        <VideoChoice
                          value={form?.videoContentId}
                          onChange={(v) => setForm((x) => ({ ...x, videoContentId: v }))}
                        />
                      </td>
                    </tr>
                    {!!form?.videoContentId && (
                      <tr>
                        <th>
                          <label className="form-label">공개 여부 </label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                checked={form.accessPublic}
                                onClick={() => setForm((x) => ({ ...x, accessPublic: true }))}
                                defaultValue="option1"
                                defaultChecked=""
                              />
                              <label className="form-check-label" htmlFor="inlineRadio1">
                                공개
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                defaultValue="option2"
                                checked={!form.accessPublic}
                                onClick={() => setForm((x) => ({ ...x, accessPublic: false }))}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio2">
                                비공개
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="inlineRadioOptions"
                                id="inlineRadio3"
                                defaultValue="option3"
                                checked={form.accessPublic && form.comming}
                                disabled={!form.accessPublic}
                                onClick={() => setForm((x) => ({ ...x, comming: !x.comming }))}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio3">
                                예정
                              </label>
                            </div>
                            <input
                              type="date"
                              className="form-control flatpickr-basic flatpickr-input"
                              placeholder="YYYY-MM-DD"
                              style={{ width: 130 }}
                              disabled={!form.comming}
                              value={form.commingDate}
                              onChange={(e) => {
                                const v = e.target.value;
                                setForm((x) => ({ ...x, commingDate: v }));
                              }}
                            />
                            <select
                              className="form-select w-auto pe-3 ms-75"
                              value={form.commingHour}
                              onChange={(e) => {
                                const value = e.target.value;
                                setForm((x) => ({ ...x, commingHour: value }));
                              }}
                              disabled={!form.comming}
                            >
                              {Array(24)
                                .fill('')
                                .map((_, ix) => (
                                  <option key={`${ix}`} value={String(ix).padStart(2, '0')}>
                                    {String(ix).padStart(2, '0')}
                                  </option>
                                ))}
                            </select>
                            <span className={' ms-75'}>{' : '}</span>
                            <select
                              className="form-select w-auto pe-3 ms-75"
                              value={form.commingMin}
                              onChange={(e) => {
                                const value = e.target.value;
                                setForm((x) => ({ ...x, commingMin: value }));
                              }}
                              disabled={!form.comming}
                            >
                              {Array(6)
                                .fill('')
                                .map((_, ix) => (
                                  <option key={`${ix}`} value={String(ix * 10).padStart(2, '0')}>
                                    {String(ix * 10).padStart(2, '0')}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <h5>강의교안</h5>
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label">강의교안 선택 </label>
                      </th>
                      <td>
                        <FileAttacher ref={attacher} values={file} maxLength={1} limitSize={100} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-12 mt-50 txt-r">
              <button
                type="submit"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                disabled={loading}
              >
                저장
              </button>
              <button
                disabled={loading}
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/lms/lectures/offline');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
        {/* // con e */}
      </div>
    </form>
  );
};

export default LecturesOfflinesForm;
