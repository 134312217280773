import React, { useCallback, useEffect, useState } from 'react';
import Modal from './Modal';
import dayjs from 'dayjs';
import PaginationMoon from './PaginationMoon';
import { restApi } from '../common/api';
import { shallowEqual, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';

const ExamSelector = ({ lectureId, visible, onExit, onSelected }) => {
  const codemap = useSelector((s) => s.common.codes?.map, shallowEqual);
  const [listData, setListData] = useState();
  const [selected, setSelected] = useState();

  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = React.useState(queryString.parse(window.location.search));
  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/lectures/${lectureId}/exams/past`, { params });
    setListData(data);
  }, [lectureId, params]);

  useEffect(() => {
    if (visible) {
      loadData().catch(console.warn);
    }
  }, [visible, loadData]);

  const handleSelect = async (e) => {
    e.preventDefault();
    const { data: qdata } = await restApi.get(`/lectures/${lectureId}/exams/${selected}`);
    onSelected(qdata);
    onExit();
  };

  return (
    <Modal className="fade text-start modal-primary" visible={visible}>
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '60%' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">이전 시험문제 목록</h5>
            <button type="button" className="btn-close" onClick={onExit} />
          </div>
          <div className="modal-body">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                setParams({
                  keyword: event.target.keyword.value,
                  keywordType: event.target.keywordType.value,
                });
              }}
            >
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                          <label className="form-label">검색어</label>
                        </th>
                        <td>
                          <div className="align-items-center d-flex">
                            <select
                              className="form-select pe-3"
                              style={{ width: 'auto' }}
                              name="keywordType"
                              defaultValue={params.keywordType}
                            >
                              <option value="">전체</option>
                              <option value="title">시험지명</option>
                              <option value="session">시험유형</option>
                              <option value="type">문항유형</option>
                            </select>
                            <input
                              type="text"
                              name="keyword"
                              defaultValue={params.keyword}
                              className="form-control d-sm-inline-block ms-1"
                              style={{ flex: 1 }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 txt-r mb-2">
                <button type="submit" className="btn btn-primary waves-effect">
                  조회
                </button>
              </div>
            </form>
            <div>
              <div className="card">
                <div className="card-header">
                  <div className="col-12 txt-r">
                    <button
                      type="submit"
                      className="btn btn-primary me-1 waves-effect waves-float waves-light"
                      onClick={handleSelect}
                    >
                      선택
                    </button>
                    <button type="reset" className="btn btn-outline-secondary waves-effect" onClick={onExit}>
                      취소
                    </button>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '4rem' }} />
                      {/* <col /> */}
                      <col style={{ width: '10rem' }} />
                      <col style={{ width: '10rem' }} />
                      <col style={{ width: '10rem' }} />
                      <col style={{ width: '10rem' }} />
                      <col style={{ width: '11rem' }} />
                      <col style={{ width: '8rem' }} />
                    </colgroup>
                    <thead>
                      <tr>
                      < th colSpan={2}>학기/교육</th>
                        <th >시험문제</th>
                        {/* <th>문항수</th> */}
                        <th>시험유형</th>
                        <th>문항유형</th>
                        <th>등록일</th>
                        <th>사용여부</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listData?.content
                        .filter((item) => item.examStyle === 'ONLINE')
                        .map((item, i) => (
                          <tr key={`exam-${i}`}>
                            <td className="txt-c">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="examId"
                                value={item.id}
                                onChange={(e) => {
                                  setSelected(e.target.value);
                                }}
                              />
                            </td>
                            <td>{item.lecture.semester.year}년 {codemap?.[item.lecture.semester.session] ?? item.lecture.semester.session} </td>
                            <td>{item.title}</td>
                            {/* <td className="txt-c">20</td> */}
                            <td className="txt-c">{codemap?.[item.session] ?? item.session}</td>
                            <td className="txt-c">{item.type == 'EXAM' ? '시험' : '퀴즈'}</td>
                            <td className="txt-c">{dayjs(item.createdAt).format('YYYY-MM-DD')}</td>
                            <td className="txt-c">{item.active ? '사용' : '미사용'}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* pagination s */}
              <nav aria-label="Page navigation">
                <PaginationMoon data={listData} onClick={(page) => setParams((x) => ({ ...x, page }))} />
              </nav>
              {/* // pagination e */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExamSelector;
