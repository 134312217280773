import { useQuery } from '@tanstack/react-query';
import produce from 'immer';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { restApi } from '../../../../../common/api';
import LectureSearch from '../../../../../components/LectureSearch';
import { useNote } from '../../../../../components/NoteProvider';

export default function LmsLecturesMembersGroupsIndex() {
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);
  const [, setSearchParams] = useSearchParams();
  const initialParams = React.useMemo(() => {
    const params = queryString.parse(window.location.search);
    return {
      keywordType: params.keywordType ?? 'all',
      keyword: params.keyword ?? '',
      limit: Number(params.limit ?? 10),
      order: params.order ?? 'ASC',
      page: Number(params.page ?? 0),
      sort: params.sort ?? 'title',
    };
  }, []);
  const [params, setParams] = useState(initialParams);
  const teamsQuery = useQuery(
    ['lectures', lectureId, 'team', params],
    () =>
      restApi
        .get(`/lectures/${lectureId}/teams`, {
          params: { ...params, keywordType: params.keywordType === 'all' ? undefined : params.keywordType },
        })
        .then(({ data: responseData }) => responseData),
    {
      enabled: !!lectureId,
    },
  );

  const note = useNote();
  const teamSend = async (teamId) => {
    const { data } = await restApi.get(`/lectures/${lectureId}/teams/${teamId}`);
    note.send(data?.users.concat([data?.master]));
  };

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  return (
    <>
      <div className="content-wrapper container-xxl p-0">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">멤버</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">멤버</li>
                    <li className="breadcrumb-item active">그룹</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <Link to="/lms/lectures/members/members" className="nav-link" role="tab">
              원우관리
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/lms/lectures/members/groups" className="nav-link active" role="tab">
              그룹관리
            </Link>
          </li>
        </ul>

        <LectureSearch />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <form
                className="card-header"
                onSubmit={(event) => {
                  event.preventDefault();
                  setParams(
                    produce((draft) => {
                      draft.keywordType = event.target.keywordType.value;
                      draft.keyword = event.target.keyword.value;
                    }),
                  );
                }}
              >
                <div className="col-md-6 col-12 ms-auto">
                  <fieldset>
                    <div className="input-group">
                      <select name="keywordType" className="form-select" defaultValue={initialParams.keywordType}>
                        <option value="all">전체</option>
                        <option value="title">그룹명</option>
                        <option value="name">이름</option>
                      </select>
                      <input
                        name="keyword"
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        defaultValue={initialParams.keyword}
                      />
                      <button className="btn btn-outline-primary waves-effect" type="submit">
                        검색
                      </button>
                    </div>
                  </fieldset>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col />
                    <col style={{ width: '50%' }} />
                  </colgroup>
                  <tbody>
                    {teamsQuery.data?.content
                      .reduce((p, c, i) => {
                        const _i = Math.floor(i / 2);
                        if (p[_i]) {
                          p[_i].push(c);
                        } else {
                          p[_i] = [c];
                        }
                        return p;
                      }, [])
                      .map((teams, teamsIndex) => {
                        return (
                          <tr key={`Team-${teamsIndex}`}>
                            {teams.map((team, teamIndex) => (
                              <td key={`Team-${teamsIndex}-${team.id}`}>
                                <div className="row d-flex box-flex2">
                                  <div className="left d-flex align-items-center">
                                    <div className="col">
                                      <h4>
                                        <Link to={`/lms/lectures/members/groups/${team.id}`}>{team.title}</Link>
                                      </h4>
                                      <p className="txt-info">
                                        <strong>조장</strong>
                                        {team.master?.name}
                                      </p>
                                      <p className="txt-info">
                                        <strong>조원</strong>
                                        {team.names?.join(', ')}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right">
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary waves-effect"
                                      onClick={() => teamSend(team.id)}
                                    >
                                      그룹쪽지
                                      <br />
                                      보내기
                                    </button>
                                  </div>
                                </div>
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-12 txt-r">
              <Link to={'/lms/lectures/members/groups/create'}>
                <button type="submit" className="btn btn-primary waves-effect waves-float waves-light">
                  등록
                </button>
              </Link>
            </div>

            <nav aria-label="Page navigation">
              <ul className="pagination justify-content-center mt-2">
                <li className="page-item prev">
                  <Link
                    to={`/lms/lectures/members/groups?${queryString.stringify({
                      ...params,
                      page: Math.max(0, params.page - 1),
                    })}`}
                    className="page-link"
                    onClick={() => {
                      setParams(
                        produce((draft) => {
                          draft.page = Math.max(0, params.page - 1);
                        }),
                      );
                    }}
                  />
                </li>
                {[
                  ...new Array(teamsQuery.data ? Math.ceil(teamsQuery.data?.totalElements / teamsQuery.data?.size) : 0),
                ].map((_, index) => {
                  return (
                    <li key={`Page-${index + 1}`} className={`page-item${index === params.page ? ' active' : ''}`}>
                      <Link
                        to={`/lms/lectures/members/groups?${queryString.stringify({ ...params, page: index })}`}
                        className="page-link"
                        aria-current={index === params.page ? 'page' : undefined}
                        onClick={() => {
                          setParams(
                            produce((draft) => {
                              draft.page = index;
                            }),
                          );
                        }}
                      >
                        {index + 1}
                      </Link>
                    </li>
                  );
                })}
                <li className="page-item next">
                  <Link
                    to={`/lms/lectures/members/groups?${queryString.stringify({
                      ...params,
                      page: Math.min(
                        Math.ceil(teamsQuery.data?.totalElements / teamsQuery.data?.size) - 1,
                        params.page + 1,
                      ),
                    })}`}
                    className="page-link"
                    onClick={() => {
                      setParams(
                        produce((draft) => {
                          draft.page = Math.min(
                            Math.ceil(teamsQuery.data?.totalElements / teamsQuery.data?.size) - 1,
                            params.page + 1,
                          );
                        }),
                      );
                    }}
                  />
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
