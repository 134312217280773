import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { restApi } from '../../../../common/api';
import { editorInitOptions } from '../../../../common/utils';
import FileAttacher from '../../../../components/FileAttacher';

export default function SiteBoardFapWrite() {
  const navigate = useNavigate();
  const query = useParams();

  const attacher = useRef();
  const editorRef = useRef();

  const [initData, setInitData] = useState();
  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/web/messages/${query.id}`);
        setInitData(data);
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...initData,
      contentsWithMarkup: editorRef.current.getContent(),
      title: e.target.title.value,
      type: 'IN_FAQ',
    };

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return false;
    }

    if (!payload.contentsWithMarkup) {
      alert('내용을 입력해주세요.');
      return false;
    }

    const ids = await attacher.current.getFileIds();
    payload.files = ids;

    if (query?.id) {
      await restApi.put(`/web/messages/${query?.id}`, payload);
      alert('저장되었습니다.');
      await navigate(`/site/board/faq/${query?.id}`);
    } else {
      await restApi.post(`/web/messages`, payload);
      alert('등록되었습니다.');
      await navigate('/site/board/faq?category=' + (query.category ?? ''));
    }
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">입학 FAQ</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#n">게시판 관리</Link>
                  </li>
                  <li className="breadcrumb-item active">입학 FAQ</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <form
        className="row"
        onSubmit={handleSubmit}
        onReset={() => {
          navigate('/site/board/faq');
        }}
      >
        <div className="row" id="table-bordered">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label" htmlFor="blog-edit-slug">
                          질문<span className="require">*</span>
                        </label>
                      </th>
                      <td>
                        <div className="flex">
                          <input
                            type="text"
                            id="blog-edit-slug"
                            className="form-control flex-grow-1 w-auto"
                            // defaultValue=""
                            name={'title'}
                            defaultValue={initData?.title}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">
                          답변<span className="require">*</span>
                        </label>
                      </th>
                      <td>
                        <Editor
                          initialValue={initData?.contentsWithMarkup}
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          init={editorInitOptions}
                          tinymceScriptSrc="/tinymce/tinymce.min.js"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label" htmlFor="blog-edit-slug">
                          첨부파일
                        </label>
                      </th>
                      <td>
                        <FileAttacher ref={attacher} values={initData?.files} limitSize={10} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-50 txt-r">
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/site/board/faq');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* // con e */}
    </div>
  );
}
