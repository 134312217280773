import React, { useContext, useEffect, useState } from 'react';
import { VideoContext } from './VideoUploadProvider';
import { restApi } from '../common/api';

const VideoChoice = ({ type = ['OFFLINE'], value, onChange = () => {} }) => {
  const context = useContext(VideoContext);

  const [item, setItem] = useState();

  useEffect(() => {
    if (value !== item?.id && !!value) {
      const loadData = async () => {
        const { data } = await restApi.get(`/contents/${value?.id || value}`);
        setItem({ id: data.id, title: data.title });
      };
      loadData().catch(console.warn);
    }
  }, [value]);

  const handleShowVideo = () => {
    context.createForm(type, (item) => {
      setItem(item);
      onChange(item.id);
    });
  };

  return (
    <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
      <button type="button" className="btn btn-primary waves-effect waves-float waves-light" onClick={handleShowVideo}>
        선택
      </button>
      {item && (
        <p className="my-50" style={{ marginLeft: 30 }}>
          <a id="blog-image-text">{item.title}</a>
          <button
            type="button"
            className="btn-file-del"
            onClick={() => {
              onChange(undefined);
              setItem(undefined);
            }}
          >
            파일삭제
          </button>
        </p>
      )}
    </div>
  );
};

export default VideoChoice;
