import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { restApi } from '../../../../common/api';
import PaginationMoon from '../../../../components/PaginationMoon';
import dayjs from 'dayjs';
import produce from 'immer';

export default function SiteBoardImbaVideoIndex() {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const [payload, setPayload] = useState();
  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/web/youtubes', { params: { ...params, limit: 10 } });

    setData(data);
    setPayload(data?.content?.map((v) => ({ id: v.id, active: v.active })));
  }, [params]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const handleSubmit = async (e) => {
    const args = payload.reduce(
      (a, c) => {
        if (c.active) {
          a.active.push(c.id);
        } else {
          a.deactive.push(c.id);
        }
        return a;
      },
      { active: [], deactive: [] },
    );

    await restApi.put(`/web/youtubes`, args);
    loadData().catch(console.warn);
    alert('저장되었습니다.');
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* con s */}
      {/* header s */}
      <div className="content-header row">
        <div className="contentcontentsView.html-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">IMBA 홍보동영상</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/n">게시판 관리</Link>
                  </li>
                  <li className="breadcrumb-item active">IMBA 홍보동영상</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* Bordered table start */}

      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              {/* Inputs Group with Dropdown */}
              <div className="col-md-6 col-12 ms-auto">
                <fieldset>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      setParams({
                        keyword: event.target.keyword.value,
                      });
                    }}
                  >
                    <div className="input-group">
                      <input
                        name="keyword"
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        defaultValue={params.keyword}
                      />
                      <button className="btn btn-outline-primary waves-effect" type="submit">
                        검색
                      </button>
                    </div>
                  </form>
                </fieldset>
              </div>
              {/* Inputs Group with Dropdown end */}
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '6rem' }} />
                  <col style={{ width: '12%' }} />
                  <col />
                  <col style={{ width: '14%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>노출여부</th>
                    <th>제목</th>
                    <th>등록일</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.content?.map((item, ix) => (
                    <tr key={`${item.id}`}>
                      <td className="txt-c">{data?.totalElements - ix - data?.size * data?.number}</td>
                      <td className="txt-c">
                        <select
                          className="form-select"
                          name={'active_' + item?.id}
                          value={String(payload?.find((x) => x.id === item.id)?.active)}
                          onChange={(e) => {
                            const v = e.target.value;
                            setPayload(
                              produce((draft) => {
                                const ix = draft.findIndex((x) => x.id === item.id);
                                draft[ix].active = v === 'true';
                              }),
                            );
                          }}
                        >
                          <option value={'true'}>노출</option>
                          <option value={'false'}>비노출</option>
                        </select>
                      </td>
                      <td>
                        <Link to={`/site/board/imbaVideo/${item.id}`}>{item.title}</Link>
                      </td>
                      <td className="txt-c">{dayjs(item.publishedAt).format('YYYY-MM-DD')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="row mt-50">
            <div className="col-md-6"></div>
            <div className="col-md-6 txt-r">
              <button
                className="btn btn-primary waves-effect waves-float waves-light"
                data-bs-toggle="modal"
                data-bs-target="#btnSave"
                onClick={handleSubmit}
              >
                저장
              </button>
            </div>
          </div>

          {/* pagination s */}
          <PaginationMoon
            data={data}
            onClick={(page) => {
              setParams((x) => ({ ...x, page }));
            }}
          />
          {/* // pagination e */}
        </div>
      </div>
      {/* Bordered table end */}
      {/* // con e */}
    </div>
  );
}
