import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../../common/api';
import BoardAttachList from '../../../../../components/boards/BoardAttachList';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { me } from '../../../../../data/auth';
import { parseHTML } from '../../../../../common/define';

export default function SiteBoardNoticeIdIndex() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const query = useParams();

  const dispatch = useDispatch();
  const [data, setData] = useState();
  const loadData = async () => {
    const { data } = await restApi.get(`/web/messages/${query.id}`);
    setData(data);
    dispatch(me());
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [query.id]);

  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/web/messages/${query.id}`);
    alert('삭제되었습니다.');
    window.history.back();
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">공지사항</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#n">게시판 관리</Link>
                  </li>
                  <li className="breadcrumb-item active">공지사항</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-title">
                        카테고리
                      </label>
                    </th>
                    <td>{codes?.map?.[data?.category]}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        제목<span className="require">*</span>
                      </label>
                    </th>
                    <td>{data?.title}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        내용<span className="require">*</span>
                      </label>
                    </th>
                    <td
                      className={'content-area'}
                      dangerouslySetInnerHTML={{ __html: parseHTML(data?.contentsWithMarkup) }}
                    ></td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        첨부파일
                      </label>
                    </th>
                    <td>
                      <BoardAttachList ids={data?.files} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*<div className="row" id="table-bordered">*/}
          {/*  <div className="col-12">*/}
          {/*    <div className="card">*/}
          {/*      <div className="table-responsive">*/}
          {/*        <table className="table table-bordered visual-box">*/}
          {/*          <colgroup>*/}
          {/*            <col style={{ width: '11rem' }} />*/}
          {/*            <col />*/}
          {/*          </colgroup>*/}
          {/*          <tbody>*/}
          {/*            <tr>*/}
          {/*              <th>*/}
          {/*                <input className="form-check-input mx-25" type="checkbox" />*/}
          {/*                홍길동*/}
          {/*              </th>*/}
          {/*              <td>*/}
          {/*                등록한 댓글이 보여짐*/}
          {/*                <p className="text-black-50 mb-0">2022.11.20</p>*/}
          {/*              </td>*/}
          {/*            </tr>*/}
          {/*            <tr>*/}
          {/*              <th>*/}
          {/*                <input className="form-check-input mx-25" type="checkbox" />*/}
          {/*                홍길동*/}
          {/*              </th>*/}
          {/*              <td>*/}
          {/*                등록한 댓글이 보여짐*/}
          {/*                <p className="text-black-50 mb-0">2022.11.20</p>*/}
          {/*              </td>*/}
          {/*            </tr>*/}
          {/*          </tbody>*/}
          {/*        </table>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="row mt-50">
            <div className="col-md-6">
              {/*  <button*/}
              {/*    type="button"*/}
              {/*    className="btn btn-outline-danger waves-effect"*/}
              {/*    data-bs-toggle="modal"*/}
              {/*    data-bs-target="#btnDel"*/}
              {/*  >*/}
              {/*    덧글 삭제*/}
              {/*  </button>*/}
            </div>
            <div className="col-md-6 txt-r">
              <button type="button" className="btn btn-outline-danger waves-effect me-1" onClick={handleRemove}>
                글 삭제
              </button>
              <button
                type="submit"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                onClick={() => {
                  navigate(`/site/board/notice/${query?.id}/edit`);
                }}
              >
                수정
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/site/board/notice');
                }}
              >
                목록
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* // con e */}
    </div>
  );
}
