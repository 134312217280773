import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { restApi } from '../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment/moment';
import PaginationMoon from '../../../components/PaginationMoon';

const LmsHomeworkDetails = (props) => {
  const { type, id } = useParams();
  const { lectureId } = useSelector((s) => s.common, shallowEqual);

  const [data, setData] = useState();

  const loadData = useCallback(
    async (page) => {
      const { data } = await restApi.get(`/lectures/${lectureId}/homeworks/${id}`, {});
      setData(data);
    },
    [lectureId, id],
  );

  useEffect(() => {
    loadData(0).catch(console.warn);
  }, [loadData]);

  return (
    <>
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">과제</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">과제</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // header e */}

        {/* con s */}
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <Link to={'/lms/homeworks/single'} className={`nav-link ${type == 'single' ? 'active' : ''}`} role="tab">
              개인과제
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/lms/homeworks/group'} className={`nav-link ${type == 'group' ? 'active' : ''}`} role="tab">
              그룹과제
            </Link>
          </li>
        </ul>

        <div className="row">
          <div className="col-12">
            <div className="card mb-1">
              <div className="row d-flex box-flex">
                <div className="left">
                  <span className="badge bg-warning mb-25">{data?.week === 0 ? '전체' : data?.week + '주차'}</span>
                  <span className="badge btn-success mb-25">진행중</span>
                  <h4>{data?.title}</h4>
                  <p className="txt-info">
                    <strong>마감일 </strong>
                    {moment(data?.limitAt).format('M월 D일 H:mm')}
                  </p>
                </div>
                <div className="right row align-items-center justify-content-end">
                  미제출
                  <h2 className="display-6 text-danger w-auto mb-0">{data?.notYet ?? 0}</h2>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <div className="col-md-6 col-12">
                  <button type="button" className="btn btn-outline-dark btn-doc waves-effect">
                    과제 일괄 다운로드
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-log-in"
                    >
                      <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                      <polyline points="10 17 15 12 10 7"></polyline>
                      <line x1="15" y1="12" x2="3" y2="12"></line>
                    </svg>
                  </button>
                  <button type="button" className="btn btn-outline-dark btn-doc waves-effect">
                    채점지 다운로드
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-log-in"
                    >
                      <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                      <polyline points="10 17 15 12 10 7"></polyline>
                      <line x1="15" y1="12" x2="3" y2="12"></line>
                    </svg>
                  </button>
                </div>
                {/* Inputs Group with Dropdown */}
                <div className="col-md-6 col-12 ms-auto">
                  <fieldset>
                    <div className="input-group">
                      <select className="form-select" id="selectDefault">
                        <option>전체</option>
                        <option>이름</option>
                        <option>점수</option>
                        <option>미제출</option>
                      </select>

                      <input type="text" className="form-control" placeholder="" aria-label="Amount" />
                      <button className="btn btn-outline-primary waves-effect" type="button">
                        검색
                      </button>
                    </div>
                  </fieldset>
                </div>
                {/* Inputs Group with Dropdown end */}
              </div>

              {type === 'single' ? (
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '10%' }} />
                      <col style={{ width: '15%' }} />
                      <col style={{ width: '10%' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      {data?.users?.content?.map((item, ix) => (
                        <React.Fragment key={item.key}>
                          <tr>
                            <td rowSpan="2" className="txt-c">
                              {data?.users?.totalElements - ix}
                            </td>
                            <td rowSpan="2" className="txt-c">
                              <div className="row mx-0">
                                <p className="text-primary">{item.name}</p>
                                {/*<button type="button" className="btn btn-primary waves-effect waves-float waves-light">*/}
                                {/*  평가수정*/}
                                {/*</button>*/}
                              </div>
                            </td>
                            <td rowSpan="2" className="txt-c">
                              -{/*A+*/}
                            </td>
                            <td>
                              <p className="my-50">
                                {/*<a href="#">C:\fakepath\banner.jpg</a>*/}
                                {/*<span className="badge rounded-pill bg-danger ms-1">지각</span>*/}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="txt-info">
                                미제출
                                {/*<strong>제출일시</strong>2022-10-31 20:00*/}
                              </p>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '10%' }} />
                      <col style={{ width: '15%' }} />
                      <col style={{ width: '10%' }} />
                      <col />
                      <col style={{ width: 150 }} />
                    </colgroup>
                    <tbody>
                      {data?.teams?.content?.map((item, ix) => (
                        <React.Fragment key={`${item.id}`}>
                          <tr>
                            <td rowSpan={2} className="txt-c">
                              {data?.teams?.totalElements - ix}
                            </td>
                            <td rowSpan={2} className="txt-c">
                              <div className="row mx-0">
                                <p className="text-primary">{item.title}</p>
                                {/*<button type="button" className="btn btn-primary waves-effect waves-float waves-light">*/}
                                {/*  평가수정*/}
                                {/*</button>*/}
                              </div>
                            </td>
                            <td rowSpan={2} className="txt-c">
                              {/*A+*/}
                            </td>
                            <td>
                              <p className="txt-info">
                                <strong>조장</strong>
                                {item.master?.name}
                              </p>
                              <p className="txt-info">
                                <strong>조원</strong>
                                {item.names?.join(', ')}
                              </p>
                            </td>
                            <td rowSpan={2}>
                              {/*<p className="txt-info">*/}
                              {/*  <strong>제출일시</strong>*/}
                              {/*  <br />*/}
                              {/*  2022-10-31 20:00*/}
                              {/*</p>*/}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="my-50">
                                {/*<a href="#">C:\fakepath\banner.jpg</a>*/}
                                {/*<span className="badge rounded-pill bg-danger ms-1">지각</span>*/}
                                미제출
                              </p>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            {/* pagination s */}
            <nav aria-label="Page navigation">
              <PaginationMoon data={type == 'single' ? data?.users : data?.teams} onClick={loadData} />
            </nav>
            {/* // pagination e */}
          </div>
        </div>
        {/* // con e */}
      </div>
    </>
  );
};

export default LmsHomeworkDetails;
