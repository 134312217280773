import React, { useContext, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAPIHost, restApi } from '../../../../../common/api';
import { VideoContext } from '../../../../../components/VideoUploadProvider';

export default function SiteProfessorManagerSubjectIdIndex() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const context = useContext(VideoContext);
  const navigate = useNavigate();
  const query = useParams();

  const [data, setData] = useState();
  const loadData = async () => {
    const { data } = await restApi.get(`/admin/main-classes/${query.id}`);
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [query.id]);

  return (
    <div className="content-wrapper container-xxl p-0">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">교과목소개</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">교과목소개</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        교과목 선택
                      </label>
                    </th>
                    <td>
                      <span className="me-75 text-black-50">분류</span>
                      <span className="me-2">{codes?.tree?.MCTP?.find((c) => c.code === data?.siteType)?.label}</span>
                      <span className="me-75 text-black-50">분야</span>
                      <span className="me-2">{codes?.tree?.MCST?.find((c) => c.code === data?.category)?.label}</span>
                      <span className="me-75 text-black-50">과목명</span>
                      <span className="me-2">{data?.title}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">교수명</label>
                    </th>
                    <td>{data?.master.name}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">내용</label>
                    </th>
                    <td>{data?.description}</td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        소개영상
                      </label>
                    </th>
                    <td className="d-flex flex-column gap-1">
                      {data?.imageIntro && (
                        <img
                          src={`${getAPIHost()}api/files/${data?.imageIntro}`}
                          style={{ width: 500, maxWidth: '100%' }}
                        />
                      )}
                      {data?.videoIntro && (
                        <button
                          type="button"
                          className="btn btn-outline-dark waves-effect w-25"
                          disabled={!data?.videoIntro}
                          data-bs-toggle="modal"
                          data-bs-target="#btnPreview"
                          onClick={() => {
                            context.showPreview(0, data?.videoIntro.id);
                          }}
                        >
                          영상보기
                        </button>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        강의 맛보기
                      </label>
                    </th>
                    <td className="d-flex flex-column gap-1">
                      {data?.imageTrial && (
                        <img
                          src={`${getAPIHost()}api/files/${data?.imageTrial}`}
                          style={{ width: 500, maxWidth: '100%' }}
                        />
                      )}
                      {data?.videoTrial && (
                        <button
                          type="button"
                          className="btn btn-outline-dark waves-effect w-25"
                          disabled={!data?.videoTrial}
                          data-bs-toggle="modal"
                          data-bs-target="#btnPreview"
                          onClick={() => {
                            context.showPreview(0, data?.videoTrial.id);
                          }}
                        >
                          영상보기
                        </button>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">공개여부</label>
                    </th>
                    <td>{data?.isActive ? 'Y' : 'N'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button
              type="submit"
              className="btn btn-primary me-1 waves-effect waves-float waves-light"
              onClick={() => {
                navigate(`/site/professorManager/subject/${query?.id}/edit`);
              }}
            >
              수정
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/site/professorManager/subject');
              }}
            >
              목록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
