import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../../common/utils';
import { groupBy } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { useLoading } from '../../../../nav/AppContainer';
import FileSaver from 'file-saver';
import DownIco from '../../../../components/icons/DownIco';
import { parseHTML } from '../../../../common/define';

export default function LmsVotesIdIndex() {
  const navigate = useNavigate();
  const query = useParams();

  const [data, setData] = useState();
  const [applyed, setApplyed] = useState();
  const loadData = async () => {
    const { data } = await restApi.get(`/lectures/0/votes/${query.id}`);
    setData(data);
    const { data: applyed } = await restApi.get(`/lectures/0/votes/${query.id}/applyed`);
    setApplyed(applyed);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [query.id]);

  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/lectures/0/votes/${query.id}`);
    alert('삭제되었습니다.');
    window.history.back();
  };

  const [, setLoading] = useLoading();
  const handleDownloadZip = async (item) => {
    setLoading(true);
    const filename = `설문_${data?.title}_${dayjs().format('YYYYMMDD')}`;
    try {
      const { data } = await restApi.get(`/lectures/0/votes/${query.id}/xlsx`, {
        responseType: 'arraybuffer',
      });
      FileSaver.saveAs(new Blob([data]), `${filename}.xlsx`);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
      console.warn(e);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="content-wrapper container-xxl p-0">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">설문</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">설문</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col txt-r mb-1">
          <button type="button" className="btn btn-outline-dark btn-doc waves-effect" onClick={handleDownloadZip}>
            엑셀 다운로드 <DownIco />
          </button>
        </div>

        <div className="row" id="table-bordered">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label">설문지 명</label>
                      </th>
                      <td>{data?.title}</td>
                    </tr>
                    <tr>
                      <th>내용</th>
                      <td
                        className={'content-area'}
                        dangerouslySetInnerHTML={{ __html: parseHTML(data?.contentsWithMarkup) }}
                      ></td>
                    </tr>
                    <tr>
                      <th>기간</th>
                      <td>
                        {dayjs(data?.startAt).format(DATE_FORMAT)} ~ {dayjs(data?.endAt).format(DATE_FORMAT)}
                      </td>
                    </tr>

                    <tr>
                      <th>응답</th>
                      <td>
                        <p className="txt-info">
                          {(((data?.applySize ?? 0) * 100) / (data?.lecture?.accountSize ?? 1)).toFixed(0)}% ( 응답 :{' '}
                          {data?.applySize ?? 0} 명 / 미응답 : {data?.lecture?.accountSize - data?.applySize ?? 0} 명)
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="survey-box">
              {data?.items?.map((item, ix) => (
                <div className="card col-md-6 p-2" key={`${ix}`}>
                  <h5 className="mb-1">Q1. {item.title}</h5>
                  <table className="table table-bordered visual-box">
                    <tbody>
                      {item.items?.map((str, ix2) => {
                        const applies = applyed?.map((x) => x?.response?.[ix]);
                        let g = groupBy(applies, (x) => x);
                        const sorted = Object.keys(g)
                          .map((key) => {
                            return { key, size: g[key].length };
                          })
                          .sort((a, b) => {
                            return a.size > b.size ? 1 : a.size < b.size ? -1 : 0;
                          });
                        return (
                          <tr key={`${ix2}`}>
                            <td>
                              <div className="form-check">
                                <input className="form-check-input" type="radio" name="check01" id="check01" disabled />
                                <label
                                  htmlFor="check01"
                                  className="form-check-label"
                                  style={{ fontWeight: sorted?.[0]?.key == str ? 'bold' : 'normal' }}
                                >
                                  {str} ({applies?.filter((x) => x === str).length} 명)
                                </label>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
            <div className="col-12 mt-50 txt-r">
              <button
                type="button"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                onClick={() => {
                  navigate(`/lms/votes/${query.id}/edit`);
                }}
              >
                수정
              </button>
              <button
                type="button"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                onClick={handleRemove}
              >
                삭제
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                onClick={() => {
                  navigate('/lms/votes');
                }}
              >
                목록
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
