import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { restApi } from '../../../../common/api';
import UserSelect from '../../../../components/UserSelect';
import BoardAttachSingle from '../../../../components/boards/BoardAttachSingle';
import { useLoading } from '../../../../nav/AppContainer';

export default function SiteProfessorManagerProfessorWrite() {
  const navigate = useNavigate();
  const query = useParams();
  const attacher = useRef();
  const { codes } = useSelector((s) => s.common, shallowEqual);

  const [loading, setLoading] = useLoading();
  const [formData, setFormData] = useState({
    name: '',
    eduRole: null, //과목명
    imageId: null,
    workPlace: '',
    phone: '',
    email: '',
    interests: null, //관심분야 / 연구/교육분야
    education: null, //학력
    career: null, //주요경력
    printType: null, //FO 화면 출력시 분야 카테고리
    // 항목 추가
    treatise: null,
    award: null,
    conferencePaper: null,
    monograph: null,
    patent: null,
    media: null,
  });

  const handleOnchange = (e) => {
    const v = e.target.value;
    setFormData((x) => ({
      ...x,
      [e.target.name]: v,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      email: [e.target.emailFront.value, formData.emailBack].join('@'),
      phone: [e.target.phone1.value, e.target.phone2.value, e.target.phone3.value].join('-'),
    };

    if (!payload.eduRole) {
      alert('과목명을 입력해주세요.');
      return;
    }
    if (!payload.workPlace) {
      alert('연구소 위치를 입력해주세요.');
      return;
    }

    if (!formData.emailFront || !formData.emailBack) {
      alert('메일주소를 정확히 입력해주세요.');
      return;
    }

    if (!formData.phone1 || !formData.phone2 || !formData.phone3) {
      alert('전화번호를 정확히 입력해주세요.');
      return;
    }

    setLoading(true);
    try {
      payload.imageId = await attacher.current.getFileId();

      if (query?.id) {
        await restApi.put(`/teachers/${query?.id}`, payload);
        alert('저장되었습니다.');
        await navigate(`/site/professorManager/professor/${query?.id}`);
      } else {
        await restApi.put(`/teachers/${formData.id}`, payload);
        alert('등록되었습니다.');
        await navigate('/site/professorManager/professor');
      }
    } catch (e) {
      // alert('사진을 첨부해주세요.');
      alert(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/teachers/${query.id}`);
        const init = {
          emailFront: data.email?.split('@')[0],
          emailBack: data.email?.split('@')[1],
          phone1: data.phone?.split?.('-')?.[0],
          phone2: data.phone?.split?.('-')?.[1],
          phone3: data.phone?.split?.('-')?.[2],
        };
        setFormData({ ...data, ...init });
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  // 새로운 교수 정보 등록 할 때
  const loadAccountData = async () => {
    const { data } = await restApi.get(`/accounts/${formData.id}/bo`);
    setFormData({
      ...formData,
      workPlace: data.workPlace,
      phone1: data.phone?.split?.('-')?.[0],
      phone2: data.phone?.split?.('-')?.[1],
      phone3: data.phone?.split?.('-')?.[2],
      imageId: data.imageId,
      emailFront: data.email?.split('@')[0],
      emailBack: data.email?.split('@')[1],
    });
  };
  useEffect(() => {
    loadAccountData().catch(console.warn);
  }, [formData.id]);

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">교수진 관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">교수진 관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <form className="col-12" onSubmit={handleSubmit}>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label className="form-label">
                        교수명<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      {query?.id ? (
                        formData.name
                      ) : (
                        <div className="flex">
                          <UserSelect
                            title={'찾아보기'}
                            type={'TEACHER'}
                            maxLength={1}
                            values={[formData.id].filter((x) => !!x)}
                            onChange={(v) => {
                              setFormData((x) => ({ ...x, id: v?.[0] }));
                            }}
                            extraParams={{ onlyNoSiteTeacher: true }}
                          />
                        </div>
                      )}
                      {/* <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control w-auto"
                          name="name"
                          onChange={handleOnchange}
                        /> */}
                      {/* <span className="txt-limit">0/20</span> */}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        과목 카테고리<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <select className="form-select w-auto pe-3" name="printType" onChange={handleOnchange}>
                        <option value="">선택</option>
                        {codes?.tree?.TETP.map((item) => (
                          <option value={item.code} selected={formData.printType == item.code} key={item.code}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        과목명<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <input
                        type="text"
                        id="blog-edit-slug"
                        className="form-control flex-grow-1 w-auto"
                        name="eduRole"
                        value={formData.eduRole}
                        onChange={handleOnchange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        연구실 위치<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <input
                        type="text"
                        id="blog-edit-slug"
                        className="form-control"
                        name="workPlace"
                        value={formData.workPlace}
                        onChange={handleOnchange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        전화번호<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="row mx-0">
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: 100 }}
                          name="phone1"
                          value={formData.phone1}
                          onChange={handleOnchange}
                        />
                        <span className="hipen">-</span>
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: 100 }}
                          name="phone2"
                          value={formData.phone2}
                          onChange={handleOnchange}
                        />
                        <span className="hipen">-</span>
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: 100 }}
                          name="phone3"
                          value={formData.phone3}
                          onChange={handleOnchange}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">
                        이메일<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: 200 }}
                          name="emailFront"
                          value={formData.emailFront}
                          onChange={handleOnchange}
                        />
                        <span className="mx-75">@</span>
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: 200 }}
                          name="emailBack"
                          value={formData.emailBack}
                          onChange={handleOnchange}
                        />
                        <select
                          className="form-select w-auto pe-3 ms-75"
                          value={formData.emailBack}
                          name={'emailBack'}
                          onChange={handleOnchange}
                        >
                          <option value="">직접입력</option>
                          {codes?.tree?.MAIL?.map((code) => (
                            <option value={code.label} key={code.code}>
                              {code.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label" htmlFor="blog-edit-slug">
                        사진<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <BoardAttachSingle ref={attacher} name={'imageId'} defaultValue={formData.imageId} />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">관심분야</label>
                    </th>
                    <td>
                      <textarea
                        className="form-control char-textarea"
                        value={formData.interests}
                        name="interests"
                        onChange={handleOnchange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">학력</label>
                    </th>
                    <td>
                      <textarea
                        className="form-control char-textarea"
                        value={formData.education}
                        name="education"
                        onChange={handleOnchange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">약력 / 경력</label>
                    </th>
                    <td>
                      <textarea
                        className="form-control char-textarea"
                        value={formData.career}
                        name="career"
                        onChange={handleOnchange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">학술지 논문</label>
                    </th>
                    <td>
                      <textarea
                        className="form-control char-textarea"
                        value={formData.treatise}
                        name="treatise"
                        onChange={handleOnchange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">수상 / 공훈</label>
                    </th>
                    <td>
                      <textarea
                        className="form-control char-textarea"
                        value={formData.award}
                        name="award"
                        onChange={handleOnchange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">학술회의논문</label>
                    </th>
                    <td>
                      <textarea
                        className="form-control char-textarea"
                        value={formData.conferencePaper}
                        name="conferencePaper"
                        onChange={handleOnchange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">단행본</label>
                    </th>
                    <td>
                      <textarea
                        className="form-control char-textarea"
                        value={formData.monograph}
                        name="monograph"
                        onChange={handleOnchange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">특허 / 프로그램</label>
                    </th>
                    <td>
                      <textarea
                        className="form-control char-textarea"
                        value={formData.patent}
                        name="patent"
                        onChange={handleOnchange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className="form-label">Press / Media / Link</label>
                    </th>
                    <td>
                      <textarea
                        className="form-control char-textarea"
                        value={formData.media}
                        name="media"
                        onChange={handleOnchange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
              저장
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              data-bs-toggle="modal"
              data-bs-target="#btnCancel"
              onClick={() => {
                navigate('/site/professorManager/professor');
              }}
            >
              취소
            </button>
          </div>
        </form>
      </div>
      {/* // con e */}
    </div>
  );
}
