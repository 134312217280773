import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setLectureId } from '../data/common';
import LectureSearchItem from './LectureSearchItem';

const LectureSearch = (props) => {
  const { lectureId } = useSelector((s) => s.common, shallowEqual);
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const dispatch = useDispatch();

  if (['TEACHER', 'STAFF','EXAMSTAFF'].includes(principal?.type)) return null;
  return (
    <>
      <div className="card" style={props?.style}>
        <div className="table-responsive">
          <table className="table table-bordered visual-box">
            <colgroup>
              <col style={{ width: '11rem' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <label className="form-label">학기 / 교육</label>
                </th>
                <td>
                  <LectureSearchItem
                    value={lectureId}
                    onChange={(value) => {
                      dispatch(setLectureId(value));
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default LectureSearch;
