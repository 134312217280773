import React, { useCallback, useContext, useEffect, useState } from 'react';
import PaginationMoon from '../../../../components/PaginationMoon';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { restApi } from '../../../../common/api';
import dayjs from 'dayjs';
import RequireLecture from '../../../../components/RequireLecture';
import LectureSearch from '../../../../components/LectureSearch';
import { VideoContext } from '../../../../components/VideoUploadProvider';

const LecturesOfflinesIndex = (props) => {
  const context = useContext(VideoContext);

  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { lectureId } = useSelector((s) => s.common, shallowEqual);
  const [data, setData] = useState();
  const loadData = useCallback(
    async (page) => {
      const { data } = await restApi.get(`/lectures/${lectureId}/offlines/study`, {
        params: {
          sort: 'startAt',
          order: 'ASC',
        },
      });
      setData(data);
    },
    [lectureId],
  );

  useEffect(() => {
    loadData(0).catch(console.warn);
  }, [loadData]);

  return (
    <>
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">오프라인 강의</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">오프라인 강의</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LectureSearch />

        <RequireLecture lectureId={lectureId}>
          <div className="row">
            <div className="col-12">
              {data?.content?.map((item) => {
                const activeZoom = dayjs().diff(item.startAt, 'seconds') > 0 && dayjs().diff(item.endAt, 'seconds') < 0;
                return (
                  <div className="card mb-1" key={`${item.id}`}>
                    <div className="row d-flex box-flex">
                      <div className="left">
                        <h4>
                          <Link to={`/lms/lectures/offline/view/${item.id}`}>{item.title}</Link>
                        </h4>
                        <p className="txt-info">
                          <strong>시간</strong>
                          {dayjs(item.startAt).format('YYYY-MM-DD HH:mm')} ~ {dayjs(item.endAt).format('HH:mm')}
                        </p>
                        <p className="txt-info">
                          <strong>장소</strong>
                          {item.area}
                        </p>
                      </div>
                      <div className="right">
                        <button
                          type="button"
                          className="btn btn-outline-primary waves-effect"
                          onClick={() => window.open(item.zoomLink)}
                          // disabled={!item.zoomLink || !activeZoom}
                          disabled={!item.zoomLink}
                        >
                          화상 접속정보
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-dark waves-effect"
                          disabled={!item.videoContent}
                          data-bs-toggle="modal"
                          data-bs-target="#btnPreview"
                          onClick={() => {
                            context.showPreview(0, item.videoContent?.id);
                          }}
                        >
                          영상보기
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}

              {principal?.type !== 'CP' && (
                <div className="col-12 txt-r">
                  <Link to={'/lms/lectures/offline/create'}>
                    <button type="submit" className="btn btn-primary waves-effect waves-float waves-light">
                      등록
                    </button>
                  </Link>
                </div>
              )}

              <nav aria-label="Page navigation">
                <PaginationMoon data={data} onClick={loadData} />
              </nav>
            </div>
          </div>
        </RequireLecture>
      </div>
    </>
  );
};

export default LecturesOfflinesIndex;
