import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { restApi } from '../common/api';
import axios from 'axios';
import { shallowEqual, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { parseTime } from '../common/utils';
import { CONTENT_TYPES } from '../common/define';
import PaginationMoon from './PaginationMoon';
import { useLoading } from '../nav/AppContainer';
import Modal from './Modal';

export const VideoContext = React.createContext({});

const VideoUploadForm = ({ type: oType, onSubmit }) => {
  const type = typeof oType === 'string' ? oType : undefined;

  const { lectureData } = useSelector((s) => s.common, shallowEqual);
  const [mainClassId, setMainClassId] = useState(lectureData?.mainClass?.id);

  const [formData, setFormData] = useState({});
  const [metadata, setMetadata] = useState();

  const [mainClasses, setMainClasses] = useState();
  useEffect(() => {
    if (!!lectureData?.mainClass) return;
    const loadData = async () => {
      const { data } = await restApi.get(`/main-classes`, { params: { limit: 1000 } });
      setMainClasses(data?.content?.sort((a, b) => a.title.localeCompare(b.title)));
    };
    loadData().catch(console.warn);
  }, [!!lectureData?.mainClass]);

  const [loading, setLoading] = useLoading();
  const handleChange = useCallback(async (e) => {
    setMetadata({ loading: true });
    setLoading(true);
    const { data: prepare } = await restApi.post(`/contents/kollus`);
    setMetadata({
      uploadKey: prepare.result.upload_file_key,
      loading: true,
    });

    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('redirection_scope', 'no');
    formData.append('disable_alert', '1');
    formData.append('upload-file', file);

    const { data } = await axios.post(prepare.result.upload_url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    setLoading(false);
    if (!!data.error) throw data;

    setMetadata({
      uploadKey: prepare.result.upload_file_key,
      loading: false,
      filename: file.name,
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!metadata?.uploadKey || metadata.loading) {
      alert('영상은 필수항목입니다.');
      return false;
    }
    if (!formData.title) {
      alert('제목은 필수항목입니다.');
      return false;
    }
    if (!formData.type && !type) {
      alert('종류 필수항목입니다.');
      return false;
    }

    const payload = {
      ...formData,
      mainClassId: mainClassId,
      mediaId: metadata?.uploadKey,
      videoKey: '',
      videoDuration: 0,
      type: type || formData?.type,
    };

    const { data } = await restApi.post(`/contents`, payload);
    onSubmit?.({ id: data.id, title: data.title });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="card pb-2">
            {/* 업로드 전 s */}
            {!metadata ? (
              <div className="d-flex flex-column flex-md-row align-items-center m-2 border-b">
                <div className="upload-ico">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-video"
                    >
                      <polygon points="23 7 16 12 23 17 23 7"></polygon>
                      <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
                    </svg>
                  </div>
                </div>
                <div className="featured-info">
                  <div className="file-wrap">
                    <div className="file-box">
                      <input type="file" id="fileMp4" onChange={handleChange} />
                      <label htmlFor="fileMp4" className="btn btn-outline-primary waves-effect">
                        녹화영상 업로드 (*.mp4)
                      </label>
                    </div>
                  </div>
                  <small className="text-danger">
                    특정 편집도구로 내보내기한 MP4의 경우 일부 기기에서 정상 재생되지 않을 수 있습니다.
                    <br />
                    이런 경우, 등록된 콘텐츠 상의 '재변환' 기능을 실행하여 모든 시청 환경에 최적화된 영상으로
                    변환해주시기 바랍니다.
                  </small>
                </div>
              </div>
            ) : metadata?.loading ? (
              <div className="d-flex flex-column flex-md-row align-items-center m-2 mt-0 border-b">
                <p style={{ textAlign: 'center', width: '100%', padding: '100px 0px 60px' }}>업로드중</p>
              </div>
            ) : (
              <div className="d-flex flex-column flex-md-row align-items-center m-2 mt-0 border-b">
                <div className="featured-info col">
                  <table className="table table-borderless pd-0">
                    <colgroup>
                      <col style={{ width: '9rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th colSpan="2">
                          <h4 className="text-primary mb-1">
                            <a>{metadata?.filename}</a>
                          </h4>
                        </th>
                      </tr>
                      <tr>
                        <th>자막</th>
                        <td>
                          {/* <Link to="#n"
                                                          >마케팅관리_동영상강의_1주차_01_자막.txt</Link
                                                        > */}
                          {/* <button type="button" class="btn-file-del">
                                                          파일삭제
                                                        </button> */}
                          <div className="file-box">
                            <input type="file" id="fileEtc" />
                            <label htmlFor="fileEtc" className="btn btn-outline-primary waves-effect">
                              자막 업로드
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {/* // 업로드 전 e */}

            <table className="table table-borderless pd-0">
              <colgroup>
                <col style={{ width: '9rem' }} />
                <col />
              </colgroup>
              <tbody>
                {!lectureData?.mainClass && (
                  <tr>
                    <th>과목</th>
                    <td>
                      <select
                        className="form-select w-auto pe-3"
                        value={mainClassId}
                        onChange={(e) => {
                          const v = e.target.value;
                          setMainClassId(v);
                        }}
                      >
                        <option value={''}>선택해주세요</option>
                        {mainClasses?.map((item) => (
                          <option key={`${item.id}`} value={item.id}>
                            {item.title} ({item.master?.name})
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                )}
                {!type && (
                  <tr>
                    <th>수업 유형</th>
                    <td>
                      <select
                        className="form-select w-auto pe-3"
                        value={formData.type}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFormData((x) => ({ ...x, type: value }));
                        }}
                      >
                        <option>선택해주세요</option>
                        {Object.keys(CONTENT_TYPES)
                          ?.filter((x) => !oType || oType.includes(x))
                          ?.map((key) => (
                            <option key={key} value={key}>
                              {CONTENT_TYPES[key]}
                            </option>
                          ))}
                      </select>
                    </td>
                  </tr>
                )}
                <tr>
                  <th>콘텐츠 제목</th>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={formData?.title}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((x) => ({ ...x, title: value }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>콘텐츠 내용</th>
                  <td>
                    <textarea
                      className="form-control char-textarea"
                      placeholder="Counter"
                      style={{ height: 200 }}
                      value={formData?.contents}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((x) => ({ ...x, contents: value }));
                      }}
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12 txt-r">
            <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
              저장
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const VideoUploadList = ({ type, onClick }) => {
  const { lectureData } = useSelector((s) => s.common, shallowEqual);
  const [mainClassId, setMainClassId] = useState(lectureData?.mainClass?.id);

  const [params, setParams] = useState({ limit: 5 });
  const [data, setData] = useState();
  const [isExcludeProgress, setIsExcludeProgress] = useState(false);

  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/contents`, { params: { ...params, mainClassId, type } });
    setData(data);
  }, [params, mainClassId, type]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            {/* Inputs Group with Dropdown */}
            <div className="col-md-6 col-12 ms-auto">
              <fieldset>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    setParams({
                      keyword: event.target.keyword.value,
                      keywordType: event.target.keywordType.value,
                    });
                  }}
                >
                  <div className="input-group">
                    <select
                      name="keywordType"
                      className="form-select"
                      id="selectDefault"
                      defaultValue={params.keywordType}
                    >
                      <option value={''}>전체</option>
                      <option value={'title'}>제목</option>
                      <option value={'content'}>내용</option>
                      <option value={'subject'}>과목</option>
                    </select>

                    <input
                      name="keyword"
                      type="text"
                      className="form-control"
                      placeholder="제목, 내용 검색"
                      aria-label="Amount"
                      defaultValue={params.keyword}
                    />
                    <button className="btn btn-outline-primary waves-effect" type="submit">
                      검색
                    </button>
                  </div>
                </form>
              </fieldset>
            </div>
            {/* Inputs Group with Dropdown end */}
          </div>

          <div className="table-responsive bd-top">
            <table className="table visual-box">
              <colgroup>
                <col />
                <col style={{ width: '11rem' }} />
                <col style={{ width: '11rem' }} />
              </colgroup>
              <tbody>
                {data?.content?.map((item) => (
                  <tr key={`${item.id}`}>
                    <td>
                      <h4 className="text-black mb-1">
                        <Link to="#n" data-bs-toggle="modal" data-bs-target="#btnPreview">
                          {item.title}
                        </Link>
                      </h4>
                      <p className="txt-info">
                        <span className="d-block">
                          <strong>등록</strong>
                          {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                        <span className="d-block">
                          <strong>동영상시간</strong>
                          {parseTime(item.videoDuration)}
                        </span>
                        <span className="d-block">
                          <strong>수정</strong>
                          {!!item.updatedOn && dayjs(item.updatedOn).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                      </p>
                    </td>
                    <td className="txt-c">{item?.mainClass?.title}</td>
                    <td className="txt-c">
                      <div className="row mx-0 gap-25">
                        <p className="text-primary m-0">{CONTENT_TYPES[item.type]}</p>

                        <div className="d-flex gap-25">
                          <input
                            className="form-check-input"
                            id="isExcludeProgress"
                            type="checkbox"
                            checked={item.isExcludeProgress}
                            onChange={(e) => {
                              // console.log(e.target.checked);
                              setIsExcludeProgress(e.target.checked);
                            }}
                          />
                          <label className="form-check-label" htmlFor="isExcludeProgress">
                            진도율 제외
                          </label>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm waves-effect waves-float waves-light mt-25"
                          onClick={() =>
                            onClick({ id: item.id, title: item.title, isExcludeProgress: isExcludeProgress })
                          }
                        >
                          선택
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* pagination s */}
        <nav aria-label="Page navigation">
          <PaginationMoon
            data={data}
            onClick={(page) => {
              setParams((x) => ({ ...x, page }));
            }}
          />
        </nav>
        {/* // pagination e */}
      </div>
    </div>
  );
};
const VideoUploadProvider = ({ children }) => {
  const [show, setShow] = useState({});

  const modal = useRef();
  const bsModal = useRef();

  useLayoutEffect(() => {
    if (show?.active) {
      bsModal.current = new window.bootstrap.Modal(modal.current, {
        keyboard: false,
      });
      bsModal.current.show();
    }
  }, [show]);

  const [tab, setTab] = useState(0);
  const handler = useRef();
  const createForm = useCallback((type, fn) => {
    setShow({ active: true, type });
    handler.current = fn;

    setTab(!!type ? 1 : 0);
  }, []);

  const [videoContent, setVideoContent] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [editForm, setEditForm] = useState();

  const handler2 = useRef();
  const showPreview = useCallback(async (lectureId, id, fn) => {
    const [resp1, resp2] = await Promise.all([restApi.get(`/contents/${id}`), restApi.get(`/contents/${id}/play`)]);

    setVideoContent(resp1.data);
    setVideoUrl(resp2?.data?.playUrl);
    handler2.current = fn;
  }, []);

  const handleExitVideo = () => {
    setVideoContent(undefined);
    setTimeout(() => {
      setVideoUrl(undefined);
    }, 300);
  };

  const handleFinish = useCallback((o) => {
    handler.current?.(o);
    bsModal.current?.hide();
  }, []);

  const [listeners, setListeners] = useState([]);

  const [loading, setLoading] = useLoading();
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    await restApi.put(`/contents/${videoContent?.id}`, editForm);
    setEditForm(undefined);
    setLoading(false);

    handleExitVideo();
    if (handler2.current) {
      handler2.current();
    }

    await Promise.all(listeners.map((fn) => fn?.()));
  };

  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    try {
      await restApi.delete(`/contents/${videoContent?.id}`);
      handleExitVideo();
      if (handler2.current) {
        handler2.current();
      }
    } catch (e) {
      alert('사용중인 컨텐츠가 있는 경우 삭제가 불가능합니다.');
    }
  };

  const addListener = (arg) => {
    setListeners((v) => [...v, arg]);
  };
  const removeListener = (arg) => {
    setListeners((v) => v.filter((each) => each !== arg));
  };

  return (
    <VideoContext.Provider value={{ createForm, showPreview, addListener, removeListener }}>
      {children}

      <div ref={modal} className="modal fade text-start modal-primary" tabIndex={-1}>
        <div className="modal-dialog  modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">동영상 업로드</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShow({})}
              />
            </div>
            <div className="modal-body">
              {!show?.active ||
                (!!show && !!show?.type && (
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className={`nav-link ${(tab === 1 && 'active') ?? ''}`} id="kr-tab" onClick={() => setTab(1)}>
                        콘텐츠 목록
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${(tab === 0 && 'active') ?? ''}`} id="en-tab" onClick={() => setTab(0)}>
                        콘텐츠 등록
                      </a>
                    </li>
                  </ul>
                ))}
              <div className="tab-content">
                {show?.active && tab === 0 && (
                  <div className="tab-pane active" id="en" aria-labelledby="en-tab" role="tabpanel">
                    <VideoUploadForm
                      key={'upload_' + JSON.stringify(show?.type)}
                      type={show?.type}
                      onSubmit={handleFinish}
                    />
                  </div>
                )}
                {!!show?.type && tab === 1 && (
                  <div className="tab-pane active" id="kr" aria-labelledby="kr-tab" role="tabpanel">
                    <VideoUploadList type={show?.type} onClick={handleFinish} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal visible={!!videoContent?.id} className="fade text-start modal-primary">
        <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={handleSubmit}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">동영상 미리보기</h5>
              <button type="button" className="btn-close" onClick={() => handleExitVideo()}></button>
            </div>
            <div className="modal-body">
              {!!videoUrl && (
                <div>
                  <iframe
                    width="100%"
                    height="472"
                    src={videoUrl}
                    frameBorder="0"
                    allowFullScreen
                    webkitallowfullscreen
                    mozallowfullscreen
                    allow="encrypted-media"
                  ></iframe>
                </div>
              )}
              <div className="d-flex align-items-center mt-3 mb-2">
                <h5 className="text-black col me-2 mb-0">
                  {!!editForm ? (
                    <input
                      type="text"
                      class="form-control"
                      value={editForm.title}
                      onChange={(e) => {
                        const value = e.target.value;
                        setEditForm((x) => ({ ...x, title: value }));
                      }}
                    />
                  ) : (
                    <>{videoContent?.title}</>
                  )}
                </h5>
                {!editForm && (
                  <div className="txt-r">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        setEditForm({
                          title: videoContent.title,
                          contents: videoContent.contents,
                        });
                      }}
                    >
                      수정
                    </button>
                    &nbsp;
                    <button type="button" className="btn btn-secondary btn-sm" onClick={handleRemove}>
                      삭제
                    </button>
                  </div>
                )}
              </div>
              <div className="table-responsive">
                <table className="table table-striped t-border">
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '35%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '35%' }} />
                  <tbody>
                    <tr>
                      <th>등록일시</th>
                      <td>{dayjs(videoContent?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <th>수정일시</th>
                      <td>-</td>
                    </tr>
                    <tr>
                      <th>유형</th>
                      <td>{CONTENT_TYPES[videoContent?.type]}</td>
                      <th>재생시간</th>
                      <td>{parseTime(videoContent?.videoDuration)}</td>
                    </tr>
                    <tr>
                      <th>조회수</th>
                      <td>{videoContent?.hit ?? 0}</td>
                      <th>공개설정</th>
                      <td>{videoContent?.videoDuration > 0 ? '공개' : '처리중'}</td>
                    </tr>
                    <tr>
                      <th>내용</th>
                      <td colSpan="3">
                        {!!editForm ? (
                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder=""
                            value={editForm.contents}
                            onChange={(e) => {
                              const value = e.target.value;
                              setEditForm((x) => ({ ...x, contents: value }));
                            }}
                          ></textarea>
                        ) : (
                          <>{videoContent?.contents}</>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {!!editForm && !loading && (
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  저장
                </button>
                <button type="button" className="btn btn-outline-primary" onClick={() => setEditForm(undefined)}>
                  취소
                </button>
              </div>
            )}
          </div>
        </form>
      </Modal>
    </VideoContext.Provider>
  );
};

export default VideoUploadProvider;
