import produce from 'immer';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../common/api';
import BoardAttachSingle from '../../../../components/boards/BoardAttachSingle';
import { useLoading } from '../../../../nav/AppContainer';

export default function SiteBoardApplicationWrite() {
  const navigate = useNavigate();
  const query = useParams();
  const attacher = useRef();
  const [loading, setLoading] = useLoading();
  const [initData, setInitData] = useState({
    guides: [''],
  });

  useEffect(() => {
    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/admission-guides/${query.id}`);
        setInitData(data);
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...initData,

      iframeLink: e.target.iframeLink.value,
      applyLink: e.target.applyLink.value,
      // guides: e.target.guides.value ? e.target.guides.value.split(/[.?!]\s/g) : undefined,
      link: e.target.link.value,
      time: e.target.time.value,
      title: e.target.title.value,
    };

    if (!payload.title) {
      alert('제목을 입력해주세요.');
      return false;
    }
    if (!payload.guides) {
      alert('유의사항을 입력해주세요.');
      return false;
    }

    setLoading(true);
    try {
      const ids = await attacher.current.getFileId();
      payload.file = ids;

      if (query?.id) {
        await restApi.put(`/admission-guides/${query?.id}`, payload);
        alert('저장되었습니다.');
        await navigate(`/site/board/application/${query?.id}`);
      } else {
        await restApi.post(`/admission-guides`, payload);
        alert('등록되었습니다.');
        await navigate('/site/board/application');
      }
    } catch (e) {
      alert('파일을 첨부해주세요.');
    }
    setLoading(false);
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">모집요강</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#n">입학관리</Link>
                  </li>
                  <li className="breadcrumb-item active">모집요강</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}

      {/* con s */}
      <form
        className="row"
        onSubmit={handleSubmit}
        onReset={() => {
          navigate('/site/board/application');
        }}
      >
        <div className="row" id="table-bordered">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label" htmlFor="blog-edit-slug">
                          제목<span className="require">*</span>
                        </label>
                      </th>
                      <td>
                        <div className="flex">
                          <input
                            type="text"
                            id="blog-edit-slug"
                            className="form-control flex-grow-1 w-auto"
                            name={'title'}
                            defaultValue={initData?.title}
                          />
                          <span className="txt-limit">0/50</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label" htmlFor="blog-edit-slug">
                          모집요강 파일<span className="require">*</span>
                        </label>
                      </th>
                      <td>
                        <BoardAttachSingle ref={attacher} name={'file'} defaultValue={initData?.file} />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">입학설명회 링크</label>
                      </th>
                      <td>
                        <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control flex-grow-1 w-50"
                          name={'applyLink'}
                          defaultValue={initData?.applyLink}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">
                          유의사항<span className="require">*</span>
                        </label>
                      </th>
                      <td>
                        {/* <Editor
                          // initialValue={initData?.contentsWithMarkup}
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          init={editorInitOptions}
                          tinymceScriptSrc="/tinymce/tinymce.min.js"
                        /> */}
                        {/* <textarea
                          className="form-control flex-grow-1 w-100"
                          name={'guides'}
                          defaultValue={initData?.guides}
                          style={{ height: '500px' }}
                        /> */}
                        {/* 로 문장 끝날 때마다 스플릿해서 나누기 벨류는 textarea.value */}
                        {initData?.guides.map((guide, ix) => (
                          <div key={`guide-${ix}`} className="d-flex mb-1">
                            <textarea
                              type="text"
                              className="form-control"
                              // value={initData?.guides[ix]}
                              value={guide}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                setInitData(
                                  produce((draft) => {
                                    draft.guides[ix] = inputValue;
                                  }),
                                );
                              }}
                            />
                            <button
                              type="button"
                              className="btn-del"
                              onClick={() => {
                                setInitData(
                                  produce((draft) => {
                                    draft.guides.splice(ix, 1);
                                  }),
                                );
                              }}
                            >
                              삭제
                            </button>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="btn btn-outline-primary w-auto btn-add waves-effect"
                          onClick={() => {
                            setInitData(
                              produce((draft) => {
                                draft.guides.push('');
                              }),
                            );
                          }}
                        >
                          항목 추가
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">e북 링크</label>
                      </th>
                      <td>
                        <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control flex-grow-1 w-50"
                          name={'iframeLink'}
                          defaultValue={initData?.iframeLink}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">
                          원서접수 기간<span className="require">*</span>
                        </label>
                      </th>
                      <td>
                        <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control flex-grow-1 w-50"
                          name={'time'}
                          defaultValue={initData?.time}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">
                          원서접수 링크<span className="require">*</span>
                        </label>
                      </th>
                      <td>
                        <input
                          type="text"
                          id="blog-edit-slug"
                          className="form-control flex-grow-1 w-50"
                          name={'link'}
                          defaultValue={initData?.link}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-50 txt-r">
              <button
                type="submit"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                data-bs-toggle="modal"
                data-bs-target="#btnSave"
              >
                저장
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary waves-effect"
                data-bs-toggle="modal"
                data-bs-target="#btnList"
                onClick={() => {
                  navigate('/site/board/application');
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* // con e */}
    </div>
  );
}
