import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

var firebaseConfig = {
  apiKey: 'AIzaSyCZ-Vz9-wUqWGWC0q7rXAARdwfLdhEEU8I',
  authDomain: 'skku-imba.firebaseapp.com',
  projectId: 'skku-imba',
  storageBucket: 'skku-imba.appspot.com',
  messagingSenderId: '825971745320',
  appId: '1:825971745320:web:24b8a78ad44e78759c8b1d',
};

const vapidKey = 'BDY_ZqX2owBeXzJGR_1QJMVwwcWa1oBc2cW0zaqRS_HtoRk5pCw4_OBzOHDl7dRfYgvfUMDPP2dDltpT5sk3vLk';

export let firebaseApp = undefined;
export let messaging = undefined;

export const fetchToken = () => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig);
  }
  if (!messaging) {
    messaging = getMessaging(firebaseApp);
  }
  return getToken(messaging, { vapidKey });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
