import React, { useCallback, useEffect, useState } from 'react';
import { setLectureData, setLectureId } from '../data/common';
import dayjs from 'dayjs';
import { getConfigValue, restApi } from '../common/api';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const HeadLectures = (props) => {
  const dispatch = useDispatch();
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { map } = useSelector((s) => s.common?.codes, shallowEqual);
  const lectureId = useSelector((s) => s.common?.lectureId, shallowEqual);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get('/lectures/mines');
    setData(data);

    var sortingField = "id"
    data.sort( function(a,b) {
      return b[sortingField] - a[sortingField];
    });


    const lastLid = localStorage.getItem('LAST_LID');
    var currLec = data?.find((x) => String(x.id) == lastLid);
    if (!currLec) {
      currLec = data?.[0];
      localStorage.setItem('LAST_LID', String(currLec?.id));
    }

    dispatch(setLectureId(currLec?.id));
    dispatch(setLectureData(currLec));
  }, [principal?.id]);
  useEffect(() => {
    loadData().catch(console.warn);
  }, []);

  return (
    <div className={'head-lecture'} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <select
        className="form-select pe-3 me-75"
        onChange={(event) => {
          const id = event.target.value;
          dispatch(setLectureId(id));
          dispatch(setLectureData(data?.find((x) => x.id == id)));
          localStorage.setItem('LAST_LID', String(id));
        }}
        value={lectureId}
        style={{ fontSize: 16, fontWeight: 'bold', maxWidth: '100%', minWidth: 200 }}
      >
        {data?.map((item, idx) => {
          return (
            <option value={`${item.id}`} key={`${item.id}${idx}`}>
              {item.semester?.year}년 {map?.[item.semester?.session]} {item.mainClass.title}{' '}
              {dayjs(item.semester?.startAt).format('YYYY/MM/DD')} ~{dayjs(item.semester?.endAt).format('YYYY/MM/DD')}
            </option>
          );
        })}
      </select>
      <button
        onClick={() => {
          window.open('/redirect?r=' + encodeURIComponent(`${getConfigValue('WEB_HOST')}lms/lectures/${lectureId}/notices`));
        }}
        className={'btn btn-primary waves-effect waves-float waves-light'}
        style={{ whiteSpace: 'nowrap', width: 200 }}
      >
        강의실 미리보기
      </button>
    </div>
  );
};

export default HeadLectures;
